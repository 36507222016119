import { getFraction } from "../../../../components/utils/GetFraction";
import {
  MeasurementInfoInput,
  NutritionInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import { AddDishVariables } from "../../../chefsbase/dishes/addDish/types/AddDish";
import {
  Dish,
  Dish_dish_products_foods_food_measurementInfo,
  Dish_dish_recipes_recipes,
} from "../../../chefsbase/dishes/types/Dish";
import { dishes_dishes } from "../../../chefsbase/dishes/types/dishes";
import { healthlabelInfoToOutput } from "../../../chefsbase/dishes/utils/MapToInput";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import { measureToMeasureInput } from "../../createClientPage/forms/DishCard/Mappers";
import { scaleNutrition } from "../courses/Functions";
import { toNutritionInput } from "./nutrition/ToNutritionInput";

export const mapNutrition = (r: Dish_dish_recipes_recipes): NutritionInput => {
  const originalQ = {
    quantity: r.nutrition.quantity.quantity,
    unit: measureToMeasureInput(r.nutrition.quantity.unit),
  };
  const newQ = {
    quantity: r.quantity.quantity,
    unit: measureToMeasureInput(r.quantity.unit),
  };

  const frac = getFraction(originalQ, newQ);

  const scaled = scaleNutrition(frac, toNutritionInput(r.nutrition.nutrition));

  return scaled;
};

export const dishResultToAddDishVariables = (
  i: number,
  form: AddMenuVariables,
  index: number,
  dishForm: AddDishVariables,
  result: Dish
): AddDishVariables => {
  return {
    ...dishForm,
    input: {
      ...dishForm.input,
      type: result.dish.type,
      urls: [],
    },
    portions: form.courses[index].selectables[i].quantitiesSelected.map(
      (r) => r.portion
    ),
    products: {
      dishPortion: {
        name: result.dish.products.dishPortionOutput.name,
        quantity: {
          quantity: result.dish.products.dishPortionOutput.quantity.quantity,
          unit: measureToMeasureInput(
            result.dish.products.dishPortionOutput.quantity.unit
          ),
        },
      },
      ingredients: result.dish.products.foods.map((r) => ({
        name: r.food.name,
        id: r.food.id,
        quantity: {
          quantity: r.quantity.quantity,
          unit: measureToMeasureInput(r.quantity.unit),
        },
        nutrition: toNutritionInput(r.food.nutrition.nutrition),
        img: "",
      })),
    },
    recipes: {
      dishPortion: {
        name: result.dish.recipes.dishPortion.name,
        quantity: {
          quantity: result.dish.recipes.dishPortion.quantity.quantity,
          unit: measureToMeasureInput(
            result.dish.recipes.dishPortion.quantity.unit
          ),
        },
      },
      ingredients: result.dish.recipes.recipes.map((r) => ({
        name: r.name,
        id: r.id,
        quantity: {
          quantity: r.quantity.quantity,
          unit: measureToMeasureInput(r.quantity.unit),
        },
        nutrition: mapNutrition(r),
        img: "",
      })),
    },
  };
};

export const resultToRecipesInput = (
  result: Dish
): QuantityToNameIdAndNutritionInput[] => {
  return result.dish.recipes.recipes.map((r) => ({
    healthlabelInfo: healthlabelInfoToOutput(r.healthLabelInfo),
    name: r.name,
    id: r.id,
    quantity: {
      quantity: r.quantity.quantity,
      unit: measureToMeasureInput(r.quantity.unit),
    },
    nutrition: mapNutrition(r),
    img: "",
  }));
};

export const resultToProductsInput = (
  result: Dish
): QuantityToNameIdAndNutritionInput[] => {
  return result.dish.products.foods.map((r) => ({
    measurementInfo: mapMeasurementInfoToInput(r.food.measurementInfo),
    name: r.food.name,
    id: r.food.id,
    quantity: {
      quantity: r.quantity.quantity,
      unit: measureToMeasureInput(r.quantity.unit),
    },
    nutrition: toNutritionInput(r.food.nutrition.nutrition),
    img: "",
    healthlabelInfo: healthlabelInfoToOutput(r.food.healthLabelInfo),
  }));
};

export const mapMeasurementInfoToInput = (
  m: Dish_dish_products_foods_food_measurementInfo | null
): MeasurementInfoInput | null => {
  const measurementInfo: MeasurementInfoInput = {
    serving: m?.serving,
    cup: m?.cup,
    whole: m?.whole,
    teaspoon: m?.teaspoon,
    tablespoon: m?.tablespoon,
  };
  return measurementInfo;
};
