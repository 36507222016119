import { Stack, Divider, Box, Typography } from "@mui/material";
import React from "react";
import { GridRowOneItem } from "../../../../components/layouts/Grids";
import { CurrentMenu_currentMenu_courses } from "../../types/CurrentMenu";
import { MicroMacroField } from "../MicroMacroField/types";
import { DisplayComposableButton } from "./DisplayComposableButton";
import { PerComposable } from "./PerComposable";

export const ShowComposables = ({
  courses,
  microMacroForm,
}: {
  microMacroForm: MicroMacroField;
  courses: CurrentMenu_currentMenu_courses[];
}) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0.5}
      child={
        <>
          <Stack
            spacing={2}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {courses.map((course) => {
              if (course.composables.length > 0)
                return (
                  <PerComposable
                    microMacroForm={microMacroForm}
                    course={course}
                  />
                );
            })}
          </Stack>
        </>
      }
    />
  );
};
