import { ComposablesInput } from "../../../../globalTypes";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";

export const mapToMenuInput = (form: AddMenuVariables): AddMenuVariables => {
  const old = form.courses;
  form.courses.forEach((c, index) => {
    const updated: ComposablesInput[] = c.composables.map((composable, i) => ({
      ...composable,
      composableRounds: composable.composableRounds.map((c) => ({
        ...c,
        foods: c.foods.filter((f) => f.name !== ""),
        recipes: c.recipes.filter((f) => f.name !== ""),
      })),
    }));
    old[index] = {
      composables: updated
        .filter((c) => c.name !== "Create Your Own")
        .map((comp) => ({
          ...comp,
          img: comp.img
            ? comp.img.filter((it) => it !== null && it !== undefined)
            : [""],
        })),
      name: old[index].name,
      selectables: c.selectables.filter((i) => i.dishname !== "Test Dish"),
    };
  });
  const resultingForm: AddMenuVariables = {
    input: form.input,
    combos: form.combos,
    courses:
      old.filter((i) => i.name != "").length > 0
        ? old.filter((i) => i.name != "")
        : form.courses,
  };

  return resultingForm;
};
