import {
  AmountPerQuantityInput,
  OptionInput,
  OptionsInput,
} from "../../../globalTypes";
import { small } from "../portions/PortionInput";

export const defaultOptions: OptionsInput[] = [
  {
    name: "Vegan",
    option: OptionInput.NO,
  },
];

export const defaultHours: AmountPerQuantityInput = {
  amount: 0,
  quantity: small.quantity,
};
