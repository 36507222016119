import { gql, useMutation } from "@apollo/client";
import { useSimpleQuery } from "../../utils/apollo";
import { Clientmenus } from "../chefsbase/menus/types/Clientmenus";
import { CurrentMenu } from "./types/CurrentMenu";
import {
  SetMenuAsCurrent,
  SetMenuAsCurrentVariables,
} from "./types/SetMenuAsCurrent";
import { updatefood, updatefoodVariables } from "./types/updatefood";
import { Foods } from "./types/Foods";
import { Menu } from "./types/Menu";

export const FoodsMutation = gql`
  mutation updatefood($foodform: [FoodForm!]!) {
    updatefood(foodform: $foodform)
  }
`;
export const FoodsQuery = gql`
  query Foods {
    foods
  }
`;

export const useFoodsQuery = ({
  onCompleted,
}: {
  onCompleted: (result: Foods) => void;
}) => {
  const { loading, data, error } = useSimpleQuery(FoodsQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error };
};

export const CurrentMenuQuery = gql`
  query CurrentMenu($user: String!) {
    currentMenu(user: $user) {
      id
      name
      combos {
        comboid
        price
        dishes {
          round
          dish {
            name
            id
          }
          portion {
            nutrition {
              kcal
              prottotal
              fatstotal
              saturedfat
              carbscarbs
              carbssugar
              natrium
              fibres
              cholesterol
              starch
            }
            name
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
          }
        }
      }
      courses {
        name
        composables {
          img
          name
          basePrice
          type
          composableRounds {
            number
            name
            foods {
              measurementInfo {
                serving
                cup
                tablespoon
                whole
                teaspoon
              }
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
            recipes {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
          }
        }
        selectables {
          type
          selectables {
            quantitiesSelected {
              portion {
                nutrition {
                  kcal
                  cholesterol
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  starch
                }
                name
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                price
              }
              selected
            }
            dish {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              img
              name
              id
              type
              options {
                name
                option
              }
              portions {
                name
                price
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useCurrentMenuQuery = ({
  onCompleted,
  user,
}: {
  user: string;
  onCompleted: (result: CurrentMenu) => void;
}) => {
  const { loading, data, error } = useSimpleQuery(CurrentMenuQuery, {
    variables: {
      user: user,
      offset: 0,
      limit: 3000,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error };
};

const SetCurrentMenuMutation = gql`
  mutation SetMenuAsCurrent($id: String!) {
    setMenuAsCurrent(id: $id)
  }
`;

export const useSetCurrentMenuMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [setCurrentMenu, { loading, error }] = useMutation<
    SetMenuAsCurrent,
    SetMenuAsCurrentVariables
  >(SetCurrentMenuMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    setCurrentMenu,
    loading,
    error,
  };
};

export const useFoodMutation = () => {
  const [updatefood, { loading, error }] = useMutation<
    updatefood,
    updatefoodVariables
  >(FoodsMutation);
  return {
    updatefood,
    loading,
    error,
  };
};

export const useMenuQuery = ({
  onCompleted,
  id,
}: {
  id: string;
  onCompleted: (result: Menu) => void;
}) => {
  const { loading, data, error } = useSimpleQuery(MenuQuery, {
    variables: {
      id: id,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error };
};

export const MenuQuery = gql`
  query Menu($id: String!) {
    menu(id: $id) {
      id
      name
      combos {
        comboid
        price
        dishes {
          round
          dish {
            name
            id
          }
          portion {
            nutrition {
              kcal
              prottotal
              fatstotal
              saturedfat
              carbscarbs
              carbssugar
              natrium
              fibres
              cholesterol
              starch
            }
            name
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
          }
        }
      }
      courses {
        name
        composables {
          img
          name
          basePrice
          type
          composableRounds {
            number
            name
            foods {
              measurementInfo {
                serving
                cup
                tablespoon
                whole
                teaspoon
              }
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
            recipes {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
          }
        }
        selectables {
          type
          selectables {
            quantitiesSelected {
              portion {
                nutrition {
                  kcal
                  cholesterol
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  starch
                }
                name
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                price
              }
              selected
            }
            dish {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              img
              name
              id
              type
              options {
                name
                option
              }
              portions {
                name
                price
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
