import { Box, Typography } from "@mui/material";
import { emptySelectable } from "../../../../components/defaults/selectables/SelectableInput";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import {
  ImageButton,
  ImageSrc,
  ImageBackdrop,
  ImageMarked,
  Image,
} from "../../../../components/image/ImageButton";
import { MySelectablesInput } from "../types/MySelectablesInput";
import { ComposablesInput } from "../../../../globalTypes";
import { TrashButton } from "../../../../components/Buttons";
import { Form } from "react-router-dom";

export const ComposablesDishButton = ({
  composable,
  setopen,
  deleteItem,
}: {
  deleteItem: () => void;
  setopen: (bool: boolean) => void;
  composable: ComposablesInput;
}) => {
  const image = {
    url:
      composable.img &&
      composable.img[0] &&
      composable.img[0] !== "" &&
      composable.img.filter((img) => img !== "").length > 0
        ? composable.img.filter((img) => img !== "")[0]
        : "/images/DishBackground.webp",
    title: composable.name,
    width: `${100}%`,
  };
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: 1000 }}>
      <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: image.width,
        }}
        onClick={() => {
          setopen(true);
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {image.title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>
      {composable.name !== "Create Your Own" && (
        <TrashButton onClick={() => deleteItem()} />
      )}
    </Box>
  );
};

export const AddComposablesDishButton = ({
  setopen,
}: {
  setopen: (bool: boolean) => void;
}) => {
  const image = {
    url: "/images/MCB_logo.png",
    title: "Add Creatable Dish",
    width: `${100}%`,
  };
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: 1000 }}>
      <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: image.width,
        }}
        onClick={() => {
          setopen(true);
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {image.title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>
    </Box>
  );
};
