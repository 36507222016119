import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { VscTrash } from "react-icons/vsc";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import { FormField } from "../../../components/forms/singleForms/FormField";
import { ComposablesInput, KitchenType } from "../../../globalTypes";
import { toComposableForm } from "./utils/MapToInput";
import { DeleteDialog } from "../components/DeleteDialog";
import { useComposableQuery } from "../menus/api";
import {
  Composables,
  Composables_composables,
} from "../menus/types/Composables";
import { ComposableDialog } from "../../clientbase/createClientPage/forms/ComposableDishDialog";
import { recipeResultToQuantityToRecipe } from "../../clientbase/components/mappers/RecipeToQuantityToRecipes";
import { useLazyRecipesQuery } from "../recipesAndIngredients/api";
import { recipes } from "../recipesAndIngredients/types/recipes";

import { QuantityToRecipe } from "../../clientbase/types/types";

export const ComposablesPage = () => {
  const [name, setName] = useState("");
  const [type, settype] = useState("");
  const [filteredcomposables, setcomposables] = useState<
    Composables_composables[]
  >([]);

  const { data } = useComposableQuery({
    onCompleted: (result: Composables) => setcomposables(result.composables),
  });

  const dishes: Composables_composables[] = data ? data.composables : [];

  const setNameFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredcomposables.filter((i) =>
            i.name.toLowerCase().includes(a.toLowerCase())
          )
        : dishes;

    setcomposables(n);
  };
  const setTypeFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredcomposables.filter((i) =>
            i.type.toLowerCase().includes(a.toLowerCase())
          )
        : dishes;

    setcomposables(n);
  };
  const [recipes, setrecipes] = useState<QuantityToRecipe[]>([]);

  return (
    <MainBox
      childComp={
        <>
          <BoxTitle title="Creatables" />
          <div style={{ height: 300, width: "100%" }}>
            <Table>
              <TopRow
                name={name}
                type={type}
                setNameFilter={(a: string) => setNameFilter(a)}
                setTypeFilter={(a: string) => setTypeFilter(a)}
                setname={(a: string) => setName(a)}
                settype={(a: string) => settype(a)}
              />
              <BodyRows
                recipes={recipes}
                setrecipes={(f: QuantityToRecipe[]) => setrecipes(f)}
                filteredcomposables={filteredcomposables}
                setcomposables={(f: Composables_composables[]) =>
                  setcomposables(f)
                }
              />
            </Table>
          </div>
        </>
      }
    />
  );
};

const TopRow = ({
  name,
  type,
  setname,
  settype,
  setNameFilter,
  setTypeFilter,
}: {
  name: string;
  type: string;
  setname: (a: string) => void;
  settype: (a: string) => void;
  setTypeFilter: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Search Dish:</TableCell>
          <TableCell>
            <FormField
              value={name}
              setValue={(a) => {
                setname(a);
                setNameFilter(a);
              }}
            />
          </TableCell>
          <TableCell>Search On Type:</TableCell>
          <TableCell>
            <FormField
              value={type}
              setValue={(a) => {
                settype(a);
                setTypeFilter(a);
              }}
            />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const BodyRows = ({
  filteredcomposables,
  setcomposables,
  recipes,
  setrecipes,
}: {
  recipes: QuantityToRecipe[];
  setrecipes: (a: QuantityToRecipe[]) => void;
  filteredcomposables: Composables_composables[];
  setcomposables: (a: Composables_composables[]) => void;
}) => {
  return (
    <>
      {filteredcomposables.map((d, index) => (
        <>
          <ComposableRow
            setrecipes={setrecipes}
            recipes={recipes}
            remove={() => {
              const newSelected = [...filteredcomposables];
              newSelected.splice(index, 1);
              setcomposables(newSelected);
            }}
            d={d}
          />
        </>
      ))}
    </>
  );
};

const ComposableRow = ({
  d,
  remove,
  recipes,
  setrecipes,
}: {
  recipes: QuantityToRecipe[];
  setrecipes: (a: QuantityToRecipe[]) => void;
  remove: () => void;
  d: Composables_composables;
}) => {
  //Buttons
  const [form, setForm] = useState(toComposableForm(d));

  const [openDish, setOpenDish] = useState(false);
  const { getRecipes, data: rec } = useLazyRecipesQuery({
    onCompleted: (result: recipes) =>
      setrecipes(recipeResultToQuantityToRecipe(result)),
  });
  return (
    <>
      <DishTopRow
        id={d.name}
        form={form}
        setopen={(a: boolean) => {
          getRecipes();
          setOpenDish(true);
        }}
        remove={() => remove()}
      />
      <ComposableDialog
        recipes={recipes}
        title={`See ${d.name}`}
        open={openDish}
        setopen={() => setOpenDish(!openDish)}
        composableForm={form}
        setComposable={(a: ComposablesInput) => setForm(a)}
        pushComposable={() => {}} //TODO
      />
    </>
  );
};

const DishTopRow = ({
  form,
  setopen,
  remove,
  id,
}: {
  id: string;
  remove: () => void;
  form: ComposablesInput;
  setopen: (a: boolean) => void;
}) => {
  const [openDelete, setopenDelete] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <SubmitButton
            variant="contained"
            onClick={() => {
              setopen(true);
            }}
          >
            Open
          </SubmitButton>
        </TableCell>
        <TableCell>{form.name}</TableCell>
        <TableCell></TableCell>
        <TableCell>
          <SubmitButton variant="contained" onClick={() => setopenDelete(true)}>
            <VscTrash />
          </SubmitButton>
        </TableCell>
      </TableRow>
      <DeleteDialog
        open={openDelete}
        onClose={() => setopenDelete(false)}
        name={form.name}
        id={id}
        kitchenType={KitchenType.Composable}
        onCompleted={() => {
          remove();
        }}
      />
    </>
  );
};
