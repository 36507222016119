import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import config from "../config";
import { setContext } from "@apollo/client/link/context";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Provider = ({ children }: { children: any }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const httpLink = new HttpLink({
    uri: config.endpoint,
  });

  const [token, setToken] = useState<string>("");
  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {}

    if (!token) return { headers, ...rest };

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const client = React.useRef<any>();

  let headers: Record<string, string> = {};
  if (token !== null) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const current = (client.current = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    headers: headers,
  }));

  return <ApolloProvider client={current}>{children}</ApolloProvider>;
};
