import { emptyCourse } from "../../../../components/defaults/courses/Course";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import { GridRowTwoItems } from "../../../../components/layouts/Grids";
import { createNameToId } from "../../../../components/utils/CreateNameToId";
import { createRange } from "../../../../components/utils/CreateRange";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";

export const NameAndNumberOfCoursesInput = ({
  form,
  setForm,
  numberofcourses,
  setnumberofcourses,
}: {
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  numberofcourses: number;
  setnumberofcourses: (a: number) => void;
}) => {
  return (
    <>
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={6}
        firstchild={
          <FormField
            label="Name Your Menu"
            value={form.input.name}
            setValue={(a: string) =>
              setForm({
                input: { name: a, img: form.input.img },
                courses: form.courses,
                combos: form.combos,
              })
            }
          />
        }
        secondchild={
          <FormSelect
            options={createNameToId(createRange(1, 10))}
            label="How many courses?"
            value={String(numberofcourses)}
            setValue={(a: string) => {
              const courses = [...form.courses];
              const difference = Number(a) - courses.length;
              difference > 0 &&
                createRange(0, difference).forEach((it) =>
                  courses.push(emptyCourse)
                );
              difference < 0 && courses.splice(Number(a) + 1, -difference - 1);
              setForm({
                input: form.input,
                courses: courses,
                combos: form.combos,
              });
              setnumberofcourses(Number(a));
            }}
          />
        }
        secondlength={2}
      />
    </>
  );
};
