import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import {
  aestheticBackground,
  backgroundContainer,
} from "./components/layouts/Colors";
import { ParentCompProps, GridRowOneItem } from "./components/layouts/Grids";
import { Header } from "./components/layouts/Header";
import { theme } from "./theme";

export const AddPageNoClient: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;

  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters style={{ background: aestheticBackground }}>
        {childComp}
      </Container>
    </ThemeProvider>
  );
};

export const AddPage: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container
        fixed
        disableGutters
        style={{ background: backgroundContainer }}
      >
        <Grid container>
          <GridRowOneItem before={0} after={0} child={childComp} />
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export const FrontPage: React.FC<ParentCompProps> = withAuthenticationRequired(
  (props) => {
    const { childComp } = props;

    return (
      <ThemeProvider theme={theme}>
        <Header />
        <Container maxWidth={false} disableGutters>
          <Grid container>
            <GridRowOneItem before={1} after={2} child={childComp} />
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }
);
