import { MeasureInput, QuantityInput } from "../../globalTypes";
import { Recipe_recipe_products_foods_food_measurementInfo } from "../../screens/chefsbase/recipes/types/Recipe";
import {
  gramMeasure,
  milliliterMeasure,
  milliliterMeasureInput,
} from "../defaults/portions/PortionInput";

export const getFractionWithServing = (
  measurementInfo:
    | Recipe_recipe_products_foods_food_measurementInfo
    | null
    | undefined,
  original: QuantityInput,
  input: QuantityInput
): number => {
  if (original === input) return 1;
  if (original.unit.label.toLowerCase() === input.unit.label.toLowerCase())
    return input.quantity / original.quantity;

  const per = measurementInfo
    ? getAmountFor(measurementInfo, input.unit) * input.quantity
    : 1.0;
  var result: number;
  switch (true) {
    case input.unit.label === "Serving" ||
      input.unit.label === "Cup" ||
      input.unit.label === "Tablespoon" ||
      input.unit.label === "Teaspoon" ||
      input.unit.label === "Whole":
      result = per;
      break;
    case original.unit.label.toLowerCase() === input.unit.label.toLowerCase():
      result = input.quantity / original.quantity;
      break;
    case (original.unit.label.toLowerCase() === "gram" &&
      input.unit.label.toLowerCase() === "kilogram") ||
      (original.unit.label.toLowerCase() === "milligram" &&
        input.unit.label.toLowerCase() === "gram") ||
      (original.unit.label.toLowerCase() === "milliliter" &&
        input.unit.label.toLowerCase() === "liter"):
      result = input.quantity / (original.quantity / 1000);
      break;
    case (original.unit.label.toLowerCase() === "kilogram" &&
      input.unit.label.toLowerCase() === "gram") ||
      (original.unit.label.toLowerCase() === "gram" &&
        input.unit.label.toLowerCase() === "milligram") ||
      (original.unit.label.toLowerCase() === "liter" &&
        input.unit.label.toLowerCase() === "milliliter"):
      result = input.quantity / (original.quantity * 1000);
      break;
    case original.unit.label.toLowerCase() === "milligram" &&
      input.unit.label.toLowerCase() === "kilogram":
      result = input.quantity / (original.quantity * 1000000);
      break;
    case (original.unit.label.toLowerCase() === "kilogram" &&
      input.unit.label.toLowerCase() === "milligram") ||
      (original.unit.label.toLowerCase() === "liter" &&
        input.unit.label.toLowerCase() === "milliliter"):
      result = input.quantity / (original.quantity / 1000000);
      break;
    case original.unit.label.toLowerCase() === input.unit.label.toLowerCase():
      result = input.quantity / original.quantity;
      break;
    default:
      result = 1;
  }
  return result;
};

export const getAmountFor = (
  measurements: Recipe_recipe_products_foods_food_measurementInfo,
  unit: MeasureInput
): number => {
  var result: number | null;
  switch (true) {
    case unit.label.toLowerCase() === "serving":
      result = measurements.serving;
      break;
    case unit.label.toLowerCase() === "cup":
      result = measurements.cup;
      break;
    case unit.label.toLowerCase() === "whole":
      result = measurements.whole;
      break;
    case unit.label.toLowerCase() === "tablespoon":
      result = measurements.tablespoon;
      break;
    case unit.label.toLowerCase() === "teaspoon":
      result = measurements.teaspoon;
      break;
    default:
      result = 1;
  }
  return result && result !== 1 ? result / 100 : 1;
};

export const subtractAmounts = (
  original: QuantityInput,
  input: QuantityInput
): QuantityInput => {
  const convertToGrams = (quantity: number, unit: MeasureInput): number => {
    if (
      unit.label.toLocaleLowerCase() === "kilogram" ||
      unit.label.toLocaleLowerCase() === "liter"
    ) {
      return quantity * 1000;
    }
    return quantity;
  };
  const convertUnit = (
    original: MeasureInput,
    input: MeasureInput
  ): MeasureInput => {
    if (
      original.label.toLowerCase() === "kilogram" ||
      input.label.toLowerCase() === "kilogram"
    ) {
      return gramMeasure;
    }
    if (
      original.label.toLowerCase() === "liter" ||
      input.label.toLowerCase() === "liter"
    ) {
      return milliliterMeasure;
    }

    return original;
  };

  var result =
    convertToGrams(original.quantity, original.unit) -
    convertToGrams(input.quantity, input.unit);
  const unitResult = convertUnit(original.unit, input.unit);

  return {
    quantity: result,
    unit: unitResult,
  };
};

export const getFraction = (
  original: QuantityInput,
  input: QuantityInput
): number => {
  var result: number;
  if (original.quantity === 0) return 0;

  switch (true) {
    case original.unit.label.toLowerCase() === input.unit.label.toLowerCase():
      result = input.quantity / original.quantity;
      break;
    case (original.unit.label.toLowerCase() === "gram" &&
      input.unit.label.toLowerCase() === "kilogram") ||
      (original.unit.label.toLowerCase() === "milligram" &&
        input.unit.label.toLowerCase() === "gram") ||
      (original.unit.label.toLowerCase() === "milliliter" &&
        input.unit.label.toLowerCase() === "liter"):
      result = input.quantity / (original.quantity / 1000);
      break;
    case (original.unit.label.toLowerCase() === "kilogram" &&
      input.unit.label.toLowerCase() === "gram") ||
      (original.unit.label.toLowerCase() === "gram" &&
        input.unit.label.toLowerCase() === "milligram") ||
      (original.unit.label.toLowerCase() === "liter" &&
        input.unit.label.toLowerCase() === "milliliter"):
      result = input.quantity / (original.quantity * 1000);
      break;
    case original.unit.label.toLowerCase() === "milligram" &&
      input.unit.label.toLowerCase() === "kilogram":
      result = input.quantity / (original.quantity * 1000000);
      break;
    case (original.unit.label.toLowerCase() === "kilogram" &&
      input.unit.label.toLowerCase() === "milligram") ||
      (original.unit.label.toLowerCase() === "liter" &&
        input.unit.label.toLowerCase() === "milliliter"):
      result = input.quantity / (original.quantity / 1000000);
      break;
    case original.unit.label.toLowerCase() === input.unit.label.toLowerCase():
      result = input.quantity / original.quantity;
      break;
    default:
      result = 1;
  }
  return result;
};
