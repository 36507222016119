import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { setNutr } from "../../../../components/edamam/GetNutrition";
import {
  aestheticBackground,
  backgroundField,
  buttonColor,
} from "../../../../components/layouts/Colors";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import {
  CurrentMenu_currentMenu_courses_composables,
  CurrentMenu_currentMenu_courses_composables_composableRounds_foods,
  CurrentMenu_currentMenu_courses_composables_composableRounds_recipes,
} from "../../types/CurrentMenu";
import { MapToProductPriceInput } from "./Functions";
import { DialogComposable } from "./DialogComposable";
import { MicroMacroField } from "../MicroMacroField/types";
import { passMicroMacroForm, passOptions } from "../courses/DisplayDishButton";
import { totalNutrition } from "../courses/Functions";
import {
  zeroNutritionInput,
  zeronutrition,
} from "../../../chefsbase/dishes/components/EmptyVariables";
import { SubmitButton } from "../../../../components/Buttons";

export const DisplayComposableButton = ({
  dish,
  microMacroForm,
}: {
  microMacroForm: MicroMacroField;
  dish: CurrentMenu_currentMenu_courses_composables;
}) => {
  const [open, setopen] = useState(false);
  const [added, setadded] = useState<
    CurrentMenu_currentMenu_courses_composables_composableRounds_foods[]
  >([]);
  const images =
    dish.img && dish.img.length > 0
      ? dish.img
      : ["/images/DishBackground.webp"];

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card
        onClick={() => setopen(true)}
        sx={{
          minWidth: "auto",
          maxWidth: "auto",
          height: "auto",
          backgroundColor: aestheticBackground,
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={images.length > 0 ? images[0] : "/images/DishBackground.webp"}
        />
        <CardContent
          sx={{ maxHeight: "auto", maxWidth: "auto" }}
          style={{ margin: "0 16px" }}
        >
          <Stack direction="column" spacing={0}>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "FairField Display, serif",
                fontSize: "20px",
              }}
              align="center"
              gutterBottom
              variant="body1"
              component="div"
            >
              {dish.name}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "FairField Display, serif",
                fontSize: "15px",
              }}
              align="center"
              gutterBottom
              variant="body1"
              component="div"
            >{`From €${dish.basePrice.toFixed(2)}`}</Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            <GridRowOneItem
              before={0}
              after={0}
              child={
                <>
                  {dish.composableRounds
                    .map((c) =>
                      c.foods
                        .map((f) => f.name)
                        .concat(...c.recipes.map((f) => f.name))
                    )
                    .flat()
                    .map((i, index1) => (
                      <>
                        {i && index1 < 4 && (
                          <Typography variant="body2" noWrap>
                            *{i}
                          </Typography>
                        )}
                      </>
                    ))}
                  {dish.composableRounds
                    .map((c) =>
                      c.foods
                        .map((f) => f.name)
                        .concat(...c.recipes.map((f) => f.name))
                    )
                    .flat().length > 4 && <b>...:</b>}
                </>
              }
            />
          </Typography>
        </CardContent>
      </Card>
      <DialogComposable
        dish={dish}
        added={added}
        images={images}
        open={open}
        setopen={setopen}
        setadded={setadded}
      />
    </Grid>
  );
};
