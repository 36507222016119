/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum KitchenType {
  Composable = "Composable",
  Dish = "Dish",
  Ingredient = "Ingredient",
  Menu = "Menu",
  Product = "Product",
  Recipe = "Recipe",
}

export enum Material {
  LIQUID = "LIQUID",
  PIECES = "PIECES",
  SOLID = "SOLID",
  TABLESPOON = "TABLESPOON",
  TEASPOON = "TEASPOON",
  UNIT = "UNIT",
}

export enum OptionInput {
  NO = "NO",
  OPTIONAL = "OPTIONAL",
  YES = "YES",
}

export interface AccountInput {
  id: string;
  email: string;
  password: string;
  accesstoken?: string | null;
  restaurantName?: string | null;
  description?: string | null;
  location?: string | null;
  fullName?: string | null;
}

export interface AmountPerQuantityInput {
  amount: number;
  quantity: QuantityInput;
}

export interface ComboOfferInput {
  dishes: PortionToDishInput[];
  price: number;
}

export interface ComposableRoundInput {
  number: number;
  name: string;
  foods: IngredientToQuantityToSalePriceInput[];
  recipes: IngredientToQuantityToSalePriceInput[];
}

export interface ComposablesInput {
  name: string;
  type: string;
  img?: string[] | null;
  composableRounds: ComposableRoundInput[];
  basePrice: number;
}

export interface CourseInput {
  name: string;
  selectables: SelectablesInput[];
  composables: ComposablesInput[];
}

export interface DishIngredientsInput {
  dishPortion: PortionInput;
  ingredients: QuantityToNameIdAndNutritionInput[];
}

export interface DishInput {
  name: string;
  type: string;
  method: string;
  urls?: string[] | null;
}

export interface DishToQuantityInput {
  id: string;
  name: string;
  quantity: QuantityInput;
}

export interface FoodForm {
  id: string;
  img: string;
  quantityToNutrition: QuantityToNutritionInput;
}

export interface FoodInput {
  name: string;
  id: string;
  img?: string | null;
  nutrition: QuantityToNutritionInput;
}

export interface HealthLabelInfo {
  lupine: boolean;
  meat: boolean;
  nut: boolean;
  peanut: boolean;
  shellfish: boolean;
  selery: boolean;
  sesame: boolean;
  soy: boolean;
  fish: boolean;
  crustacean: boolean;
  sulphide: boolean;
  mustard: boolean;
  dairy: boolean;
  gluten: boolean;
  egg: boolean;
  milk: boolean;
  pork: boolean;
  mollusk: boolean;
  vegan?: boolean | null;
}

export interface IngredientToPriceInput {
  ingredientid: string;
  ingredientname: string;
  priceperdate: PricePerDateInput[];
  article: string;
}

export interface IngredientToQuantityToSalePriceInput {
  id: string;
  quantity: QuantityInput;
  price: number;
  name: string;
  nutrition: QuantityToNutritionInput;
  img?: string | null;
  measurementInfo?: MeasurementInfoInput | null;
  healthLabelInfo?: HealthLabelInfo | null;
}

export interface MeasureInput {
  uri: string;
  label: string;
  weight: number;
}

export interface MeasurementInfoInput {
  serving?: number | null;
  cup?: number | null;
  teaspoon?: number | null;
  tablespoon?: number | null;
  whole?: number | null;
}

export interface MenuInput {
  name: string;
  img?: string | null;
}

export interface NameToIdInput {
  name: string;
  id: string;
}

export interface NutritionInput {
  kcal: number;
  carbscarbs: number;
  carbssugar: number;
  fatstotal: number;
  prottotal: number;
  starch: number;
  fibres: number;
  cholesterol: number;
  natrium: number;
  saturedfat: number;
}

export interface OptionsInput {
  name: string;
  option: OptionInput;
}

export interface PortionInput {
  name: string;
  quantity: QuantityInput;
  price?: number | null;
}

export interface PortionSelectedInput {
  portion: PortionInput;
  selected: boolean;
}

export interface PortionToDishInput {
  round: number;
  dishid: string;
  name: string;
  portion: PortionInput;
  nutrition: NutritionInput;
}

export interface PricePerDateInput {
  date: string;
  price: number;
  quantityInput: QuantityInput;
  supplierid: string;
  suppliername: string;
}

export interface ProductInput {
  name: string;
  category: string;
  material: Material;
  brand: string;
  quantityPerUnit: QuantityInput;
  quantityTeaSpoon: QuantityInput;
  quantityEatSpoon: QuantityInput;
  price: AmountPerQuantityInput;
  nutrition: QuantityToNutritionInput;
}

export interface QuantityInput {
  quantity: number;
  unit: MeasureInput;
}

export interface QuantityToFoodInput {
  quantity: QuantityInput;
  food: NameToIdInput;
}

export interface QuantityToNameIdAndNutritionInput {
  name: string;
  id: string;
  quantity: QuantityInput;
  nutrition: NutritionInput;
  img?: string | null;
  measurementInfo?: MeasurementInfoInput | null;
  healthlabelInfo?: HealthLabelInfo | null;
}

export interface QuantityToNameToIdInput {
  name: string;
  id: string;
  quantityInput: QuantityInput;
}

export interface QuantityToNutritionInput {
  quantity: QuantityInput;
  nutrition: NutritionInput;
}

export interface RecipeForTinderInput {
  id: string;
  quantity: QuantityInput;
  name: string;
  type: string;
  method: string;
  nutrition: QuantityToNutritionInput;
  img?: string[] | null;
  healthLabelInfo: HealthLabelInfo;
  foods: QuantityToFoodInput[];
}

export interface RecipeInput {
  name: string;
  type?: string | null;
  method: string;
  quantity: QuantityInput;
  img: string[];
}

export interface SelectablesInput {
  dishid: string;
  dishname: string;
  quantitiesSelected: PortionSelectedInput[];
  img?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
