import {
  Box,
  TableHead,
  TableRow,
  Table,
  CardContent,
  TableContainer,
  Stack,
  Typography,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import {
  GridRowFiveItems,
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../../components/layouts/Grids";
import { createRange } from "../../../../../components/utils/CreateRange";
import { AddMenuVariables } from "../../../../chefsbase/menus/addMenu/types/AddMenu";
import {
  ComboOfferInput,
  ComposablesInput,
  PortionInput,
  PortionToDishInput,
  SelectablesInput,
} from "../../../../../globalTypes";
import { emptyCombo } from "../../../../../components/defaults/Combo";
import React, { useState } from "react";
import { AddableCombo } from "./AddableCombo";
import { backgroundContainer } from "../../../../../components/layouts/Colors";
import { dishes_dishes } from "../../../../chefsbase/dishes/types/dishes";
import { readFileAsDataURL } from "../../../components/imgReader/ReadFileAsDataUrl";
import { returnStringsIfNotNullOrUndefined } from "../../../../../components/utils/NullableStrings";
import { SubmitButton, TrashButton } from "../../../../../components/Buttons";
import { ImageButton } from "../../../../../components/image/ImageButton";
import {
  FormAutoCompleteDishSelectable,
  FormSelect,
} from "../../../../../components/forms/singleForms/FormSelect";
import { NametoId } from "../../../../../components/types/types";
import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../../components/forms/singleForms/FormField";
import { emptyMySelectable } from "../../../../../components/defaults/selectables/SelectableInput";
import {
  mapDishToSelectable,
  mapSelectableToMySelectable,
} from "../../../components/mappers/selectable/MapToSelectables";
import { MySelectablesInput } from "../../types/MySelectablesInput";
import { FormQuantity } from "../../../../chefsbase/recipes/addrecipes/CreateYourRecipe";
import { SelectPortion } from "./AddableSelectableForCombo";
import { small } from "../../../../../components/defaults/portions/PortionInput";
import { zeroNutritionInput } from "../../../../chefsbase/dishes/components/EmptyVariables";

import { sortPortions } from "../../../../chefsbase/dishes/dishDialog/DishContent";
import {
  createNameToId,
  createNameToIdForString,
} from "../../../../../components/utils/CreateNameToId";
import {
  distinctstrings,
  distinctstringsOneArray,
} from "../../../../../components/utils/DistinctStrings";
import {
  distinctItems,
  distinctPortions,
} from "../../../components/courses/DisplayDishButton";

export const emptyDishToPortionInput: PortionToDishInput = {
  dishid: "",
  name: "",
  portion: small,
  nutrition: zeroNutritionInput,
  round: 1,
};

export const addDishToPortionInput = (
  previousDish: PortionToDishInput
): PortionToDishInput => ({
  round: previousDish.round,
  dishid: previousDish.dishid,
  name: previousDish.name,
  portion: previousDish.portion,
  nutrition: previousDish.nutrition,
});

const emptyFormForCombo: ComboOfferInput = {
  dishes: [emptyDishToPortionInput, emptyDishToPortionInput],
  price: 10,
};
const addFormForCombo = (previousform: ComboOfferInput): ComboOfferInput => ({
  dishes: previousform.dishes.map((previousDish) =>
    addDishToPortionInput(previousDish)
  ),
  price: previousform.price,
});

export type Supplement = {
  dish: NametoId;
  supplement: number;
};
export type TypeToPortion = {
  round: number;
  type: string;
  portion: string;
};

export type CombosForTypes = {
  typeToPortions: TypeToPortion[];
  price: number;
  supplements: Supplement[];
};
const emptySupplement: Supplement = {
  dish: { name: "", id: "" },
  supplement: 0,
};
const emptyTypeToPortion = (round: number): TypeToPortion => ({
  type: "",
  portion: "Small",
  round: round,
});

export const emptyCombosForTypes: CombosForTypes = {
  typeToPortions: [emptyTypeToPortion(1), emptyTypeToPortion(2)],
  price: 10,
  supplements: [],
};

export const ComboForTypes = ({
  form,
  comboForTypes,
  setComboForTypes,
  remove,
  i,
}: {
  i: number;
  remove: () => void;
  form: AddMenuVariables;
  comboForTypes: CombosForTypes;
  setComboForTypes: (a: CombosForTypes) => void;
}) => {
  const types = distinctstringsOneArray(form.courses.map((c) => c.name));
  const dishes: NametoId[] = form.courses
    .filter((c) =>
      comboForTypes.typeToPortions.map((ttp) => ttp.type).includes(c.name)
    )
    .map((c) => c.selectables.map((s) => ({ name: s.dishname, id: s.dishid })))
    .flat();

  return (
    <>
      <TableRow>
        <TableCell>
          <GridRowTwoItems
            before={0}
            inbetween={0}
            firstlength={3}
            secondlength={9}
            firstchild={
              <GridRowTwoItems
                before={0}
                inbetween={0}
                firstlength={3}
                secondlength={8.5}
                firstchild={<Box sx={{ mt: 1 }}>{i + 1}</Box>}
                secondchild={
                  <Box sx={{ mt: 5 }}>
                    <TrashButton onClick={() => remove()} />
                  </Box>
                }
              />
            }
            secondchild={
              <FormFieldInputAdornmentNumber
                label="Price For Combo"
                input="€"
                value={String(comboForTypes.price)}
                setValue={(a) => {
                  setComboForTypes({
                    ...comboForTypes,
                    price: Number(a),
                  });
                }}
              />
            }
          />
          <TableCell>
            {comboForTypes.supplements.map((supplement, index) => {
              return (
                <TableRow>
                  <TableCell>
                    <GridRowTwoItems
                      before={0}
                      inbetween={0}
                      firstlength={7}
                      secondlength={5}
                      firstchild={
                        <Box sx={{ mt: 1 }}>{`Sup ${index + 1}`}</Box>
                      }
                      secondchild={
                        <Box sx={{ mt: 1 }}>
                          <TrashButton
                            onClick={() => {
                              const old = [...comboForTypes.supplements];
                              old.splice(index, 1);
                              setComboForTypes({
                                ...comboForTypes,
                                supplements: old,
                              });
                            }}
                          />
                        </Box>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelect
                      options={dishes}
                      value={supplement.dish.name}
                      setValue={(a) => {
                        const old = [...comboForTypes.supplements];
                        old[index] = {
                          ...old[index],
                          dish: {
                            name: a,
                            id: dishes.find((d) => d.name === a)
                              ? dishes.find((d) => d.name === a)!.id
                              : "",
                          },
                        };
                        setComboForTypes({
                          ...comboForTypes,
                          supplements: old,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormFieldInputAdornmentNumber
                      label="(€) Extra"
                      input="€"
                      value={String(supplement.supplement)}
                      setValue={(a) => {
                        const old = [...comboForTypes.supplements];
                        old[index] = {
                          ...old[index],
                          supplement: Number(a),
                        };
                        setComboForTypes({
                          ...comboForTypes,
                          supplements: old,
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableCell>
          <SubmitButton
            variant="outlined"
            color="inherit"
            onClick={() => {
              const old = [...comboForTypes.supplements];
              old.push(emptySupplement);
              setComboForTypes({
                ...comboForTypes,
                supplements: old,
              });
            }}
          >
            + Supplement
          </SubmitButton>
        </TableCell>
        <TableCell>
          {comboForTypes.typeToPortions.map((ttp, index) => {
            return (
              <TableRow>
                <TableCell>
                  <GridRowTwoItems
                    before={0}
                    inbetween={0}
                    firstlength={7}
                    secondlength={5}
                    firstchild={
                      <Box sx={{ mt: 1 }}>{`Round ${index + 1}`}</Box>
                    }
                    secondchild={
                      <Box sx={{ mt: 1 }}>
                        <TrashButton
                          onClick={() => {
                            const old = [...comboForTypes.typeToPortions];
                            old.splice(index, 1);
                            setComboForTypes({
                              ...comboForTypes,
                              typeToPortions: old,
                            });
                          }}
                        />
                      </Box>
                    }
                  />
                </TableCell>
                <TableCell>
                  <FormSelect
                    options={createNameToIdForString(types)}
                    value={ttp.type}
                    setValue={(a) => {
                      const old = [...comboForTypes.typeToPortions];
                      old[index] = {
                        ...old[index],
                        type: a,
                      };
                      setComboForTypes({
                        ...comboForTypes,
                        typeToPortions: old,
                      });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <FormSelect
                    options={createNameToIdForString(
                      form.courses.find(
                        (c) =>
                          c.name === comboForTypes.typeToPortions[index].type
                      )
                        ? distinctstringsOneArray(
                            form.courses
                              .find(
                                (c) =>
                                  c.name ===
                                  comboForTypes.typeToPortions[index].type
                              )!
                              .selectables.map((s) =>
                                sortPortions(
                                  s.quantitiesSelected
                                    .map((q) => q.portion)
                                    .filter((q) => q.name !== "Manual")
                                ).map((i) => i.name)
                              )
                              .flat()
                          )
                        : ["Small", "Medium", "Large", "Extra Large"]
                    )}
                    label="For portion"
                    value={comboForTypes.typeToPortions[index].portion}
                    setValue={(a) => {
                      const old = [...comboForTypes.typeToPortions];
                      old[index] = {
                        ...old[index],
                        portion: a,
                      };
                      setComboForTypes({
                        ...comboForTypes,
                        typeToPortions: old,
                      });
                    }}
                  />
                </TableCell>
                {index === comboForTypes.typeToPortions.length - 1 && (
                  <TableCell>
                    <>
                      <SubmitButton
                        variant="outlined"
                        color="inherit"
                        onClick={() => {
                          const old = [...comboForTypes.typeToPortions];
                          old.push(emptyTypeToPortion(index + 2));
                          setComboForTypes({
                            ...comboForTypes,
                            typeToPortions: old,
                          });
                        }}
                      >
                        +
                      </SubmitButton>
                    </>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export const Row = ({
  selectables,
  form,
  setform,
  remove,
  index,
}: {
  index: number;
  selectables: SelectablesInput[];
  remove: () => void;
  setform: (a: ComboOfferInput) => void;
  form: ComboOfferInput;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <GridRowTwoItems
            before={0}
            inbetween={0}
            firstlength={7}
            secondlength={5}
            firstchild={<Box sx={{ mt: 1 }}>{`Combo ${index + 1}`}</Box>}
            secondchild={
              <Box sx={{ mt: 1 }}>
                <TrashButton onClick={() => remove()} />
              </Box>
            }
          />
        </TableCell>
        <TableCell width="5%">
          <FormFieldInputAdornmentNumber
            input={"€"}
            value={String(form.price)}
            setValue={(a) => {
              setform({
                ...form,
                price: Number(a),
              });
            }}
          />
        </TableCell>
        <TableCell colSpan={2}>
          {form.dishes.map((dish, index1) => {
            const found = selectables.find((d) => d.dishid === dish.dishid);
            return (
              <GridRowTwoItems
                key={index1}
                before={0}
                inbetween={0}
                firstlength={10}
                secondlength={2}
                firstchild={
                  <FormAutoCompleteDishSelectable
                    key={index1}
                    label={`Round ${index1 + 1}:`}
                    value={{
                      img: null,
                      dishid: dish.dishid,
                      dishname: dish.name,
                      quantitiesSelected: [
                        { selected: true, portion: dish.portion },
                      ],
                    }}
                    options={selectables
                      .filter((s) => s.dishname !== "Test Dish")
                      .map((selectable) =>
                        mapSelectableToMySelectable(selectable)
                      )}
                    setValue={(a) => {
                      const old = [...form.dishes];
                      old[index1] = {
                        ...old[index1],
                        name: a.dishname,
                        dishid: a.dishid,
                        round: index1 + 1,
                        portion:
                          a.dishid !== form.dishes[index1].dishid
                            ? sortPortions(
                                a.quantitiesSelected
                                  .filter((i) => i.portion.name !== "Manual")
                                  .map((i) => i.portion)
                              )[0]
                            : old[index1].portion,
                      };
                      setform({
                        ...form,
                        dishes: old,
                      });
                    }}
                  />
                }
                secondchild={
                  <FormSelect
                    label="Size"
                    place={
                      found &&
                      found!.quantitiesSelected.filter(
                        (i) => i.portion.name !== "Manual"
                      ).length > 0
                        ? {
                            name: found!.quantitiesSelected.filter(
                              (i) => i.portion.name !== "Manual"
                            )[0].portion.name,
                            id: found!.quantitiesSelected.filter(
                              (i) => i.portion.name !== "Manual"
                            )[0].portion.name,
                          }
                        : undefined
                    }
                    options={
                      found
                        ? found!.quantitiesSelected
                            .filter((i) => i.portion.name !== "Manual")
                            .map((p) => ({
                              name: p.portion.name,
                              id: p.portion.name,
                            }))
                        : [
                            { name: "Small", id: "Small" },
                            { name: "Medium", id: "Medium" },
                            { name: "Large", id: "Large" },
                            { name: "Extra large", id: "Extra large" },
                          ]
                    }
                    value={form.dishes[index1].portion.name}
                    setValue={(a) => {
                      const old = [...form.dishes];
                      old[index1] = {
                        ...old[index1],
                        portion: {
                          name: a,
                          quantity:
                            found &&
                            found.quantitiesSelected.find(
                              (p) => p.portion.name === a
                            )
                              ? found.quantitiesSelected.find(
                                  (p) => p.portion.name === a
                                )!.portion.quantity
                              : small.quantity,
                        },
                      };
                      setform({
                        ...form,
                        dishes: old,
                      });
                    }}
                  />
                }
              />
            );
          })}
        </TableCell>
        <TableCell>
          <>
            <SubmitButton
              variant="outlined"
              color="inherit"
              onClick={() => {
                const old = [...form.dishes];
                old.push(emptyDishToPortionInput);
                setform({
                  ...form,
                  dishes: old,
                });
              }}
            >
              +
            </SubmitButton>
            <SubmitButton
              variant="outlined"
              color="inherit"
              onClick={() => {
                const old = [...form.dishes];
                old.splice(old.length - 1, 1);
                setform({
                  ...form,
                  dishes: old,
                });
              }}
            >
              -
            </SubmitButton>
          </>
        </TableCell>
      </TableRow>
    </>
  );
};
export const CombosForm = ({
  form,
  setForm,
  combosForTypes,
  setCombosForTypes,
}: {
  combosForTypes: CombosForTypes[];
  setCombosForTypes: (a: CombosForTypes[]) => void;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "16px",
          borderColor: "blue",
        }}
      >
        <Typography align="center" variant="h6">
          Add some combos for types and portions:
        </Typography>
      </Box>
      {combosForTypes.map((cft, i) => (
        <Table>
          <TableRow>
            <TableCell align="center" width="50%">
              Price and Supplements
            </TableCell>
            <TableCell align="center" width="50%">
              Types and Portions
            </TableCell>
          </TableRow>
          <ComboForTypes
            key={i}
            i={i}
            remove={() => {
              const old = [...combosForTypes];
              old.splice(i, 1);
              setCombosForTypes(old);
            }}
            form={form}
            comboForTypes={cft}
            setComboForTypes={(a) => {
              const old = [...combosForTypes];
              old[i] = a;
              setCombosForTypes(old);
            }}
          />
        </Table>
      ))}
      <SubmitButton
        variant="outlined"
        onClick={() => {
          const old = [...combosForTypes];
          old.push(emptyCombosForTypes);
          setCombosForTypes(old);
        }}
        color="inherit"
      >
        Add Another combo for type
      </SubmitButton>

      <Box
        sx={{
          borderRadius: "16px",
          borderColor: "blue",
        }}
      >
        <Typography align="center" variant="h6">
          Or add some combos individually:
        </Typography>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="15%">
                Delete All:
                <TrashButton
                  onClick={() =>
                    setForm({
                      ...form,
                      combos: [],
                    })
                  }
                />
              </TableCell>
              <TableCell width="5%">Total Price</TableCell>
              <TableCell width="50%" colSpan={2}>
                Dishes
              </TableCell>
              <TableCell>Add/Remove Dish</TableCell>
            </TableRow>
            {form.combos.map((combo, index) => (
              <Row
                index={index}
                selectables={form.courses.map((c) => c.selectables).flat()}
                remove={() => {
                  const old = [...form.combos];
                  old.splice(index, 1);
                  setForm({
                    ...form,
                    combos: old,
                  });
                }}
                key={index}
                form={combo}
                setform={(a) => {
                  const old = [...form.combos];
                  old[index] = a;
                  setForm({
                    ...form,
                    combos: old,
                  });
                }}
              />
            ))}
            <TableRow>
              <TableCell colSpan={3}>
                <SubmitButton
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    const old = [...form.combos];
                    old.length > 0
                      ? old.push(addFormForCombo(old[old.length - 1]))
                      : old.push(emptyFormForCombo);
                    setForm({
                      ...form,
                      combos: old,
                    });
                  }}
                >
                  Add another combo
                </SubmitButton>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </>
  );
};
