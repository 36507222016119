import {
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";

export const FormSelectMultiple = ({
  options,
  place,
  handleChange,
  value,
  label,
}: {
  place?: string[];
  value: string[];
  options: string[];
  handleChange: (e: SelectChangeEvent<string[]>) => void;
  label?: string;
}) => {
  return (
    <>
      {label && (
        <>
          <InputLabel>{label}</InputLabel>
        </>
      )}
      <Select
        style={{ borderRadius: 28 }}
        fullWidth
        // sx={{ height: 0.5 }}
        size="small"
        margin="dense"
        defaultValue={place}
        multiple
        value={value}
        onChange={(e) => handleChange(e)}
        renderValue={(selected) => selected.join(", ")}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
