import { Stack, Divider } from "@mui/material";
import React from "react";
import { GridRowOneItem } from "../../../../components/layouts/Grids";
import { CurrentMenu_currentMenu_courses } from "../../types/CurrentMenu";
import { MicroMacroField } from "../MicroMacroField/types";
import { PerCourse } from "./PerCourse";
import { AllergyChoices } from "../MicroMacroField";

interface CoursesProps {
  allergyChoices: AllergyChoices;
  options: string[];
  microMacroForm: MicroMacroField;
  courses: CurrentMenu_currentMenu_courses[];
}
export const ShowCourses: React.FC<CoursesProps> = ({
  courses,
  microMacroForm,
  options,
  allergyChoices,
}) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0.5}
      child={
        <>
          <Stack
            spacing={2}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {courses.map((course, index1) => (
              <PerCourse
                key={index1}
                options={options}
                microMacroForm={microMacroForm}
                course={course}
                allergyChoices={allergyChoices}
              />
            ))}
          </Stack>
        </>
      }
    />
  );
};
