export const returnStringsIfNotNullOrUndefined = (
  a: string[] | null | undefined
): string[] => (checkIfStringsNotNullOrUndefined(a) ? a! : [""]);

export const checkIfStringsNotNullOrUndefined = (
  a: string[] | null | undefined
): boolean => a !== undefined && a !== null;

export const returnStringIfNotNullOrUndefined = (
  a: string | null | undefined
): string => (checkIfStringNotNullOrUndefined(a) ? a! : "");

export const checkIfStringNotNullOrUndefined = (
  a: string | null | undefined
): boolean => a !== undefined && a !== null;
