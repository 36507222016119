import { MeasureInput, QuantityInput } from "../../globalTypes";
import {
  gramMeasureInput,
  kilogramMeasureInput,
  literMeasureInput,
  milliliterMeasureInput,
} from "../defaults/portions/PortionInput";

export const writeUnit = (a: MeasureInput): string => {
  let result;
  switch (true) {
    case a.label.toLowerCase() === "gram":
      result = "g";
      break;
    case a.label.toLowerCase() === "serving":
      result = "serv.";
      break;
    case a.label.toLowerCase() === "teaspoon":
      result = "tsp.";
      break;
    case a.label.toLowerCase() === "tablespoon":
      result = "tbsp.";
      break;
    case a.label.toLowerCase() === "cups":
      result = "cups";
      break;
    case a.label.toLowerCase() === "whole":
      result = "pcs.";
      break;
    case a.label.toLowerCase() === "kilogram":
      result = "kg";
      break;
    case a.label.toLowerCase() === "milliliter":
      result = "ml";
      break;
    case a.label.toLowerCase() === "liter":
      result = "l";
      break;
    default:
      result = a.label.toLowerCase().slice(0, 2);
      break;
  }
  return result;
};

export const minimizeUnit = (unit: MeasureInput): MeasureInput => {
  let result;
  switch (true) {
    case unit.label.toLowerCase() === "kilogram":
      result = gramMeasureInput();
      break;
    case unit.label.toLowerCase() === "liter":
      result = milliliterMeasureInput();
      break;
    default:
      result = unit;
      break;
  }
  return result;
};

export const scaleQuantity = (quantity: QuantityInput): QuantityInput => {
  let result;
  switch (true) {
    case quantity.unit.uri ===
      "http://www.edamam.com/ontologies/edamam.owl#Measure_gram" &&
      quantity.quantity > 1000:
      result = {
        quantity: quantity.quantity / 1000,
        unit: kilogramMeasureInput(),
      };
      break;
    case quantity.unit.uri ===
      "http://www.edamam.com/ontologies/edamam.owl#Measure_kilogram" &&
      quantity.quantity < 1:
      result = {
        quantity: quantity.quantity * 1000,
        unit: gramMeasureInput(),
      };
      break;
    case quantity.unit.uri ===
      "http://www.edamam.com/ontologies/edamam.owl#Measure_liter" &&
      quantity.quantity < 1:
      result = {
        quantity: quantity.quantity * 1000,
        unit: milliliterMeasureInput(),
      };
      break;
    case quantity.unit.uri ===
      "http://www.edamam.com/ontologies/edamam.owl#milliliter" &&
      quantity.quantity > 1000:
      result = {
        quantity: quantity.quantity / 1000,
        unit: literMeasureInput(),
      };
      break;
    default:
      result = {
        quantity: quantity.quantity,
        unit: quantity.unit,
      };
      break;
  }
  return result;
};

export const displayQuantity = (quantity: QuantityInput): string => {
  const scaled = scaleQuantity(quantity);
  return `${roundToZeroOr2(scaled.quantity)}${writeUnit(scaled.unit)}`;
};

export const roundToZeroOr2 = (a: number): number =>
  a % 1 != 0 ? Number(a.toFixed(2)) : Number(a.toFixed(0));
