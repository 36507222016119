import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  aestheticBackground,
  backgroundField,
  buttonColor,
} from "../../../../components/layouts/Colors";
import {
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import {
  NutritionInput,
  OptionInput,
  OptionsInput,
  PortionInput,
} from "../../../../globalTypes";
import { useLazyItemsForDishQuery } from "../../../chefsbase/dishes/api";
import {
  CurrentMenu_currentMenu_combos,
  CurrentMenu_currentMenu_combos_dishes,
  CurrentMenu_currentMenu_combos_dishes_dish,
  CurrentMenu_currentMenu_combos_dishes_portion,
  CurrentMenu_currentMenu_courses_selectables_selectables,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish_healthLabelInfo,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish_options,
  CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected,
} from "../../types/CurrentMenu";
import { NutritionToInput } from "../../../chefsbase/dishes/components/RowRecipe";
import { MicroMacroField, MinMax } from "../MicroMacroField/types";
import {
  emptyFoodResult,
  emptyRecipeForDish,
} from "../../../../components/defaults/RecipeForDish/EmptyDishComponents";
import { quantityToInput } from "../../createClientPage/forms/DishCard/Mappers";
import {
  DishItems,
  DishItems_dish_products_foods,
  DishItems_dish_recipes,
} from "../../../chefsbase/dishes/types/DishItems";
import {
  DisplayIngredients,
  DisplayNutritionInput,
  DisplayNutritionPortions,
  DisplayRecipes,
  writePortionName,
} from "../../../chefsbase/productsfromapi";
import { SubmitButton } from "../../../../components/Buttons";
import { Box2mt } from "../../../../components/Boxes";
import { Loader } from "../../../../components/Loading/Loader";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import { getFraction } from "../../../../components/utils/GetFraction";
import { sortPortionsWithNutrition } from "../../../chefsbase/dishes/dishDialog/DishContent";
import { zeronutrition } from "../../../chefsbase/dishes/components/EmptyVariables";
import { gramMeasure } from "../../../../components/defaults/portions/PortionInput";
import { createRange } from "../../../../components/utils/CreateRange";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  DishForSlides,
  MappedCombos,
  PortionWithNutrition,
  Rounds,
  SlideDishesProps,
} from "../../types/types";
import { AllergyChoices } from "../MicroMacroField";

export const Arrow = ({
  direction,
  clickFunction,
}: {
  direction: string;
  clickFunction: () => void;
}) => {
  const icon = direction === "left" ? <FaChevronLeft /> : <FaChevronRight />;

  return (
    <Box sx={{ mt: 25 }}>
      <IconButton style={{ border: 2 }} onClick={clickFunction}>
        {icon}
      </IconButton>
    </Box>
  );
};

export const getPicture = (name: string): string => {
  let result: string;
  switch (true) {
    case name === "Soup Bros. Focaccia" || name === "Create Your Focacccia":
      result = "/images/Focaccia.jpeg";
      break;
    case name === "Red Lentil Soup":
      result = "/images/rode_linzen.png";
      break;
    case name === "Broccoli Soup":
      result = "/images/broccoli_soep.png";
      break;
    case name === "Sweet Potato Soup":
      result = "/images/zoete_aardappel.png";
      break;
    case name === "Goulash Soup":
      result = "/images/goulash.jpeg";
      break;
    case name === "Gazpacho":
      result = "/images/tomatensoep.png";
      break;
    case name === "Mushroom Soup":
      result = "/images/mushroom.png";
      break;
    case name === "658f5a74-4150-3312-b8d8-13f0e843cb83":
      result = "/images/broccoli_soup.png";
      break;
    case name === "Tiramisu":
      result = "/images/tiramisu.png";
      break;
    case name === "Mangolicious ":
      result = "/images/mangolicious.png";
      break;
    case name === "Choco Caramel":
      result = "/images/choco_caramel.png";
      break;
    case name === "Tomato Treasure Bruschetta":
      result = "/images/Bruschetta.png";
      break;
    case name === "Sourdough Slice":
      result = "/images/zuurdesemplak.png";
      break;
    case name === "Sourdough Bread":
      result = "/images/zuurdesem.png";
      break;
    case name === "Kids Deal":
      result = "/images/Kids_Deal.jpg";
      break;
    case name === "Soup Bros. Herbbutter":
      result = "/images/butter.jpg";
      break;
    case name.includes("Berries"):
      result = "/images/berries_bowl.jpg";
      break;
    case name.includes("Dragons"):
      result = "/images/Dragon_kiss.jpg";
      break;
    case name === "Multigrain bread":
      result = "/images/multigraan.png";
      break;
    case name === "Glutenfree Bread":
      result = "/images/multigrain_sandwich.jpg";
      break;
    case name === "Glutenfree sandwich":
      result = "/images/glutenfree_sandwich_filled.png";
      break;
    case name === "Tropical Delight":
      result = "/images/tropical_delight.png";
      break;
    case name === "Green Detox Smoothie":
      result = "/images/green_detox.png";
      break;
    case name === "Recharge":
      result = "/images/recharge.png";
      break;
    case name === "Refresh":
      result = "/images/refresh.png";
      break;
    case name === "Create Your bowl" || name === "Rice bowl":
      result = "/images/Poke Bowl Maker.jpeg";
      break;
    case name === "Create Your Focaccia":
      result = "/images/Focaccia.jpeg";
      break;
    case name === "Banana Bread":
      result = "/images/Banana_Bread.jpeg";
      break;
    default:
      result = "/images/SB_logo.png";
      break;
  }
  return result;
};

export const withinRange = (number: number, therange: MinMax): boolean => {
  return number >= therange.min && number <= therange.max;
};
export const withNuts = [
  "Mangolicious",
  "Dragons Kiss Pitaya Smoothiebowl",
  "Tiramisu",
  "Choco Caramel",
].map((a) => a.toLocaleLowerCase());

// export const
export const withGluten = [
  "Soup Bros. Focaccia",
  "Tomato Treasure Bruschetta",
  "Sourdough Loaf",
  "Sourdough Slice",
  "Sourdough Bread",
  "Multigrain Bread",
].map((a) => a.toLocaleLowerCase());

export const passAllergies = (
  allergies: AllergyChoices,
  healthInfo: CurrentMenu_currentMenu_courses_selectables_selectables_dish_healthLabelInfo
): boolean => {
  //First Options:
  if (allergies.first.includes("Vegan") && !healthInfo.vegan) return false;
  if (allergies.first.includes("Meat-Free") && healthInfo.meat) return false;
  if (allergies.first.includes("Fish-Free") && healthInfo.fish) return false;

  //Second Options:
  if (allergies.second.includes("Crustaceans-Free") && healthInfo.crustacean)
    return false;
  if (allergies.second.includes("Dairy-Free") && healthInfo.dairy) return false;
  if (allergies.second.includes("Egg-Free") && healthInfo.egg) return false;
  if (allergies.second.includes("Gluten-Free") && healthInfo.gluten)
    return false;
  if (allergies.second.includes("Lupine-Free") && healthInfo.lupine)
    return false;
  if (allergies.second.includes("Milk-Free") && healthInfo.milk) return false;
  // if (!allergies.second.includes("Mollusk-Free") && healthInfo.mollusk) return false;
  if (allergies.second.includes("Mustard") && healthInfo.mustard) return false;
  if (allergies.second.includes("Nut-Free") && healthInfo.nut) return false;
  if (allergies.second.includes("Peanut-Free") && healthInfo.peanut)
    return false;
  if (allergies.second.includes("Pork-Free") && healthInfo.pork) return false;
  if (allergies.second.includes("Celery-Free") && healthInfo.selery)
    return false;
  if (allergies.second.includes("Sesame-Free") && healthInfo.sesame)
    return false;
  if (allergies.second.includes("Shellfish-Free") && healthInfo.shellfish)
    return false;
  if (allergies.second.includes("Soy-Free") && healthInfo.soy) return false;
  // if (!allergies.second.includes("Sulphur dioxide") && healthInfo.sulphide)
  //   return false;

  return true;
};

export const passOptions = (
  dishOptions: OptionsInput[],
  options: string[]
): boolean => {
  if (options.length === 0) return true;
  const passesAll = options.map((option) => {
    const found = dishOptions.find((d) => d.name === option);

    return (
      found !== undefined &&
      (found.option === OptionInput.YES ||
        found.option === OptionInput.OPTIONAL)
    );
  });
  if (passesAll.includes(false)) return false;
  return true;
};

export const passMicroMacroForm = (
  nutritionInput: NutritionInput,
  price: number,
  micromacroform: MicroMacroField
): boolean => {
  const kcal = withinRange(nutritionInput.kcal, micromacroform.kcal);
  const carbs = withinRange(nutritionInput.carbscarbs, micromacroform.carbs);
  const proteins = withinRange(
    nutritionInput.prottotal,
    micromacroform.prottotal
  );
  const fats = withinRange(nutritionInput.fatstotal, micromacroform.fats);
  const calories = withinRange(nutritionInput.kcal, micromacroform.kcal);
  const pricetrue = withinRange(price, micromacroform.price);
  return kcal && carbs && proteins && fats && calories && pricetrue;
};

export const emptyPortion: CurrentMenu_currentMenu_combos_dishes_portion = {
  __typename: "Portion",
  nutrition: zeronutrition,
  name: "",
  quantity: {
    __typename: "Quantity",
    quantity: 0,
    unit: gramMeasure,
  },
};
export const emptyDishIdName: CurrentMenu_currentMenu_combos_dishes_dish = {
  __typename: "NameToId",
  name: "",
  id: "",
};
export const emptyDishPortion: CurrentMenu_currentMenu_combos_dishes = {
  __typename: "DishToPortion",
  dish: emptyDishIdName,
  portion: emptyPortion,
  round: 1,
};

export const mapToOptions = (
  imgToId: { img: string[]; id: string }[],
  options: string[],
  dishes: CurrentMenu_currentMenu_combos_dishes[]
): DishForSlides[] => {
  const final: DishForSlides[] = [];
  dishes.forEach((option) => {
    const img =
      imgToId.find((i) => i.id === option.dish.id) !== undefined
        ? imgToId.find((i) => i.id === option.dish.id)!.img[0]
        : "/images/MCB_logo.png";
    if (final.filter((i) => i.id === option.dish.id).length === 0)
      final.push({
        img: img,
        name: option.dish.name,
        id: option.dish.id,
        options: options.map((optionhere) => ({
          name: optionhere,
          option: OptionInput.YES,
        })),
        portions: distinctItems(
          dishes
            .filter((d) => d.dish.id === option.dish.id)
            .map((f) => ({
              nutrition: NutritionToInput(f.portion.nutrition),
              name: f.portion.name,
              quantity: quantityToInput(f.portion.quantity),
              price: 0,
            }))
        ),
      });
  });
  return final;
};
export const distinctnumbers = (selectables: number[]): number[] => {
  const distinct: number[] = [];
  selectables.map((sel) => {
    if (!distinct.map((d) => d).includes(sel)) {
      distinct.push(sel);
    }
  });
  return distinct;
};
export const distinctItems = (
  selectables: PortionWithNutrition[]
): PortionWithNutrition[] => {
  const distinct: PortionWithNutrition[] = [];
  selectables.map((sel) => {
    if (!distinct.map((d) => d.name).includes(sel.name)) {
      distinct.push(sel);
    }
  });
  return distinct;
};

export const distinctStringArray = (strings: string[]): string[] => {
  const distinct: string[] = [];
  strings.map((sel) => {
    if (!distinct.map((d) => d).includes(sel)) {
      distinct.push(sel);
    }
  });
  return distinct;
};

export const distinctPortions = (
  selectables: PortionInput[]
): PortionInput[] => {
  const distinct: PortionInput[] = [];
  selectables.map((sel) => {
    if (!distinct.map((d) => d.name).includes(sel.name)) {
      distinct.push(sel);
    }
  });
  return distinct;
};

export const mapCombosForSlides = (
  imgToId: { img: string[]; id: string }[],
  options: string[],
  combos: CurrentMenu_currentMenu_combos[],
  maxRound: number
): MappedCombos[] => {
  const result: MappedCombos[] = createRange(1, maxRound + 1).map(
    (round, index) => ({
      round: round,
      options: mapToOptions(
        imgToId,
        options,
        combos.map((c) => c.dishes.filter((d) => d.round === round)).flat()
      ),
    })
  );
  return result;
};

export const mapCombosForSlides2 = (
  imgToId: { img: string[]; id: string }[],
  options: string[],
  combos: CurrentMenu_currentMenu_combos[]
): Rounds => {
  const firstOptions: CurrentMenu_currentMenu_combos_dishes[] = combos
    .map((c) => c.dishes.filter((d) => d.round === 1))
    .flat();

  const secondOptions: CurrentMenu_currentMenu_combos_dishes[] = combos
    .map((c) => c.dishes.filter((d) => d.round === 2))
    .flat();

  return {
    firstoptions: mapToOptions(imgToId, options, firstOptions),
    secondoptions: mapToOptions(imgToId, options, secondOptions),
  };
};

export const mapForSlides = (
  selectables: CurrentMenu_currentMenu_courses_selectables_selectables[]
): DishForSlides[] => {
  return selectables.map((selectable) => ({
    img:
      selectable.dish.img !== undefined && selectable.dish.img!.length > 0
        ? selectable.dish.img![0]
        : "/images/MCB_logo.png",
    name: selectable.dish.name,
    id: selectable.dish.id,
    options: selectable.dish.options.map((o) => ({
      name: o.name,
      option: o.option,
    })),
    portions: selectable.quantitiesSelected
      .filter((qSel) => qSel.selected)
      .map((qSel) => ({
        nutrition: NutritionToInput(qSel.portion.nutrition),
        name: qSel.portion.name,
        quantity: quantityToInput(qSel.portion.quantity),
        price: qSel.portion.price,
      }))
      .sort((a, b) => a.quantity.quantity - b.quantity.quantity),
  }));
};

export const DisplaySlide = ({
  content,
  micromacroform,
  options,
  portion,
  setPortion,
}: {
  portion: PortionWithNutrition;
  setPortion: (a: PortionWithNutrition) => void;
  options: string[];
  content: DishForSlides;
  micromacroform: MicroMacroField;
}) => {
  const [products, setproducts] = useState<DishItems_dish_products_foods[]>([
    emptyFoodResult,
  ]);
  const [recipes, setrecipes] =
    useState<DishItems_dish_recipes>(emptyRecipeForDish);

  const selectedOne = content.portions.length > 0;
  const [dishQuantity, setdishquantity] = useState<PortionInput>(
    selectedOne
      ? {
          price: content.portions[0].price,
          name: content.portions[0].name,
          quantity: content.portions[0].quantity,
        }
      : {
          price: 0,
          name: "No size avaiable",
          quantity: {
            quantity: 0,
            unit: {
              uri: "",
              label: "Serving",
              weight: 1,
            },
          },
        }
  );
  const [open, setopen] = useState(false);

  const { getItems, loading } = useLazyItemsForDishQuery({
    id: content.id,
    quantity: {
      quantity: dishQuantity.quantity.quantity,
      unit: {
        uri: dishQuantity.quantity.unit.uri,
        weight: dishQuantity.quantity.unit.weight,
        label: dishQuantity.quantity.unit.label,
      },
    },
    onCompleted: (result: DishItems) => {
      setrecipes(result.dish.recipes);
      setproducts(result.dish.products.foods);
    },
  });

  const image =
    content.img !== null && content.img !== "" && content.img !== undefined
      ? content.img
      : "/images/MCB_logo.png";

  return (
    <>
      <Card
        sx={{
          backgroundColor: aestheticBackground,
          borderRadius: 5,
          width: "30vh",
        }}
        onClick={() => {
          getItems();
          setopen(true);
        }}
      >
        <>
          <Typography
            variant="h6"
            align="center"
            fontFamily={"FairField Display, serif"}
          >
            {content.name}
          </Typography>
          <CardMedia
            height="250vw"
            width="100vw"
            component="img"
            image={image}
          />
          {/* <CardContent>
            <GridRowOneItem
              before={0}
              after={0}
              child={
                <Stack direction="row" spacing={1}>
                  <DisplayNutritionPortions
                    name={content.name}
                    options={options}
                    dishOptions={content.options}
                    portions={content.portions}
                    micromacroform={micromacroform}
                  />
                  <Stack direction="column" spacing={0.5}>
                    Size:
                    <Stack direction="column" spacing={0}>
                      {sortPortionsWithNutrition(
                        content.portions.map((p) => ({
                          name: p.name,
                          quantity: p.quantity,
                          price: p.price,
                          nutrition: p.nutrition,
                        }))
                      ).map((p, i) => (
                        <>
                          <Button
                            key={i}
                            sx={{ backgroundColor: buttonColor }}
                            onClick={() => setPortion(p)}
                            variant={
                              portion.name === p.name ? "contained" : "outlined"
                            }
                          >
                            {writePortionName(p.name)}
                          </Button>
                        </>
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              }
            />
          </CardContent> */}
          {/* <CardActions>
            <GridRowOneItem
              before={4}
              after={4}
              child={
                <SubmitButton
                  sx={{ borderRadius: 28, backgroundColor: buttonColor }}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    getItems();
                    setopen(true);
                  }}
                >
                  Open
                </SubmitButton>
              }
            />
          </CardActions> */}
        </>
      </Card>
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setopen(false)}
        sx={{ color: aestheticBackground }}
      >
        <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
          <Typography variant="h6" align="center">
            {content.name}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: aestheticBackground }}>
          <Stack spacing={0.5} direction="column" alignContent={"center"}>
            <Grid container spacing={2}>
              {/* {images.length === 1 ? ( */}
              <Grid item xs={12} sm={12} md={6}>
                <DisplayImage
                  height={100}
                  width={100}
                  img={
                    content.img !== null && content.img !== ""
                      ? content.img
                      : "/images/MCB_logo.png"
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="body1" align="left">
              {content.options.length > 0 &&
                content.options.map((i) => (
                  <>
                    {i.option === "YES" && (
                      <GridRowTwoItems
                        before={0}
                        inbetween={1}
                        firstlength={5.5}
                        secondlength={4}
                        firstchild={i.name}
                        secondchild={<Checkbox checked={true} />}
                      />
                    )}
                  </>
                ))}
            </Typography>
            <GridRowOneItem before={0} after={0} child={<Divider />} />
            {content.portions.length > 0 && (
              <GridRowThreeItems
                before={0}
                firstlength={3}
                secondlength={5}
                thirdlength={3}
                inbetweenfirsttwo={0.5}
                inbetweensecondtwo={0.5}
                firstchild={<Box2mt mt={1} childComp={<>Choose Size:</>} />}
                secondchild={
                  <Select
                    sx={{ height: 0.8, mt: 0, minWidth: 0.3 }}
                    size="small"
                    margin="dense"
                    fullWidth
                    value={dishQuantity.name}
                    name={dishQuantity.name}
                    onChange={(e) =>
                      setdishquantity({
                        name: content.portions.find(
                          (i) => i.name === e.target.value
                        )!.name,
                        quantity: content.portions.find(
                          (i) => i.name === e.target.value
                        )!.quantity,
                      })
                    }
                  >
                    {content.portions.length > 0 &&
                      content.portions.map((portion, index1) => (
                        <MenuItem key={index1} value={portion.name}>
                          {portion.name}
                        </MenuItem>
                      ))}
                  </Select>
                }
                thirdchild={
                  <>{`${"\n"} ${
                    content.portions.find(
                      (d) => d.name === dishQuantity.name
                    ) &&
                    content.portions
                      .find((d) => d.name === dishQuantity.name)!
                      .quantity.quantity.toFixed(0)
                  } ${
                    content.portions.find(
                      (d) => d.name === dishQuantity.name
                    ) &&
                    content.portions.find((d) => d.name === dishQuantity.name)!
                      .quantity.unit.label
                  }s`}</>
                }
              />
            )}
            <GridRowOneItem before={0} after={0} child={<Divider />} />
            <GridRowTwoItems
              before={0}
              firstlength={7}
              secondlength={4.5}
              inbetween={0.5}
              firstchild={
                <>
                  {loading && (
                    <>
                      Loading ingredients..
                      <Loader loading={loading} />
                    </>
                  )}
                  {products && (
                    <DisplayIngredients
                      products={products}
                      frac={getFraction(
                        content.portions[0].quantity,
                        dishQuantity.quantity
                      )}
                    />
                  )}
                  {recipes && (
                    <DisplayRecipes
                      recipes={recipes.recipes}
                      frac={getFraction(
                        content.portions[0].quantity,
                        dishQuantity.quantity
                      )}
                    />
                  )}
                </>
              }
              secondchild={
                <>
                  {content.portions.find(
                    (d) => d.quantity === dishQuantity.quantity
                  ) ? (
                    <DisplayNutritionInput
                      direction="column"
                      nutritionInput={
                        content.portions.find(
                          (d) => d.quantity === dishQuantity.quantity
                        )!.nutrition
                      }
                      frac={1}
                    />
                  ) : (
                    <DisplayNutritionInput
                      direction="column"
                      nutritionInput={content.portions[0].nutrition}
                      frac={1}
                    />
                  )}
                </>
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: aestheticBackground }}>
          <Button
            sx={{ borderRadius: 28, backgroundColor: aestheticBackground }}
            color="inherit"
            variant="outlined"
            onClick={() => setopen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const sortPortionsForDishForSlide = (portions: DishForSlides) => {
  portions.portions
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    })
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    });
};
export const SlideDishes: React.FC<SlideDishesProps> = ({
  input,
  micromacroform,
  options,
  index,
  setindex,
  indexToPortion,
  setPortion,
}) => {
  const content = input[index];
  const portion = indexToPortion.portion;
  const numSlides = input.length;

  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<
    "left" | "right" | "down" | "up" | undefined
  >("down");

  const onArrowClick = (
    direction: "left" | "right" | "down" | "up" | undefined
  ) => {
    const increment = direction === "left" ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;

    const oppDirection = direction === "left" ? "right" : "left";
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setindex(newIndex);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 500);
  };
  if (!content) return <></>;
  return (
    <>
      <GridRowThreeItems
        before={0}
        firstlength={1}
        inbetweenfirsttwo={0}
        secondlength={10}
        inbetweensecondtwo={0}
        thirdlength={1}
        firstchild={
          <Arrow direction="left" clickFunction={() => onArrowClick("left")} />
        }
        secondchild={
          <Slide in={slideIn} direction={slideDirection}>
            <div>
              <DisplaySlide
                setPortion={(a) => {
                  setPortion(a);
                }}
                portion={portion}
                options={options}
                content={content}
                micromacroform={micromacroform}
              />
            </div>
          </Slide>
        }
        thirdchild={
          <Arrow
            direction="right"
            clickFunction={() => onArrowClick("right")}
          />
        }
      />
    </>
  );
};

export const mapToDishOptions = (
  options: CurrentMenu_currentMenu_courses_selectables_selectables_dish_options[]
): OptionsInput[] => {
  return options.map((o) => ({
    name: o.name,
    option: o.option,
  }));
};

export const DisplayDishButton = ({
  dish,
  quantitiesSelected,
  microMacroForm,
  setopen,
  setrecipes,
  availablePortions,
  setproducts,
  allergyChoices,
}: {
  allergyChoices: AllergyChoices;
  availablePortions: CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected[];
  products: DishItems_dish_products_foods[];
  setproducts: (a: DishItems_dish_products_foods[]) => void;
  setrecipes: (a: DishItems_dish_recipes) => void;
  setopen: (a: boolean) => void;
  microMacroForm: MicroMacroField;
  quantitiesSelected: CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected[];
  dish: CurrentMenu_currentMenu_courses_selectables_selectables_dish;
}) => {
  const selectedOne = quantitiesSelected.filter((i) => i.selected).length > 0;
  const [dishQuantity, setdishquantity] = useState<PortionInput>(
    selectedOne && quantitiesSelected.filter((i) => i.selected)[0]
      ? {
          price: quantitiesSelected.filter((i) => i.selected)[0].portion.price,
          name: quantitiesSelected.filter((i) => i.selected)[0].portion.name,
          quantity: quantityToInput(
            quantitiesSelected.filter((i) => i.selected)[0].portion.quantity
          ),
        }
      : {
          price: 0,
          name: "No size avaiable",
          quantity: {
            quantity: 0,
            unit: {
              uri: "",
              label: "Serving",
              weight: 1,
            },
          },
        }
  );

  const { getItems, loading } = useLazyItemsForDishQuery({
    id: dish.id,
    quantity: {
      quantity: dishQuantity.quantity.quantity,
      unit: {
        uri: dishQuantity.quantity.unit.uri,
        weight: dishQuantity.quantity.unit.weight,
        label: dishQuantity.quantity.unit.label,
      },
    },
    onCompleted: (result: DishItems) => {
      setrecipes(result.dish.recipes);
      setproducts(result.dish.products.foods);
      setdishquantity(result.dish.products.dishPortionOutput);
    },
  });

  const imgToDisplay = dish.img?.[0] || "/images/MCB_logo.png";

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card
        onClick={() => {
          getItems();
          setopen(true);
        }}
        sx={{
          minWidth: "auto",
          maxWidth: "auto",
          height: "auto",
          backgroundColor: aestheticBackground,
          borderRadius: 10,
        }}
      >
        <CardMedia sx={{ height: 140 }} image={imgToDisplay} />
        <CardContent
          sx={{ maxHeight: "auto", maxWidth: "auto" }}
          style={{ margin: "0px" }}
        >
          <Box height={"60px"} sx={{ mt: 0.5 }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                fontFamily: "FairField Display, serif",
              }}
              align="center"
              gutterBottom
              variant="body2"
              component="div"
            >
              {dish.name}
            </Typography>
          </Box>
          <Grid container sx={{ height: 80 }}>
            {availablePortions.map((portion, index1) => {
              const scaled = portion.portion.nutrition;
              const passed = passMicroMacroForm(
                scaled,
                portion.portion.price,
                microMacroForm
              );
              const passed2 = passAllergies(
                allergyChoices,
                dish.healthLabelInfo
              );

              if (passed && passed2)
                return (
                  <>
                    <Grid item xs={index1 === 0 ? 0 : 1}></Grid>
                    <Grid item xs={4} key={index1}>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.secondary"
                        style={{
                          fontSize: "12px",
                          whiteSpace: "pre-line",
                          fontWeight: "bold",
                        }}
                      >
                        <u>{portion.portion.name}</u>
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.secondary"
                        style={{
                          fontFamily: "FairField Display, serif",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Stack direction="row" spacing={0}>
                          {/* <> €{portion.portion.price}</> */}

                          {`${portion.portion.nutrition.kcal.toFixed(0)} kcal`}
                        </Stack>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </>
                );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
