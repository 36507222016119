import { gql, useMutation } from "@apollo/client";
import { AddMenu, AddMenuVariables } from "./types/AddMenu";
import { UpdateMenu, UpdateMenuVariables } from "./types/UpdateMenu";

const AddMenuMutation = gql`
  mutation AddMenu(
    $input: MenuInput!
    $combos: [ComboOfferInput!]!
    $courses: [CourseInput!]!
  ) {
    addMenu(input: $input, combos: $combos, courses: $courses)
  }
`;

export const useAddMenuhMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addMenu, { loading, error }] = useMutation<AddMenu, AddMenuVariables>(
    AddMenuMutation,
    {
      onCompleted: () => onCompleted(),
    }
  );
  return {
    addMenu,
    loading,
    error,
  };
};

const UpdateMenuMutation = gql`
  mutation UpdateMenu(
    $id: String!
    $input: MenuInput!
    $combos: [ComboOfferInput!]!
    $courses: [CourseInput!]!
  ) {
    updateMenu(id: $id, input: $input, combos: $combos, courses: $courses)
  }
`;

export const useUpdateMenuMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updatMenu, { loading, error }] = useMutation<
    UpdateMenu,
    UpdateMenuVariables
  >(UpdateMenuMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    updatMenu,
    loading,
    error,
  };
};
