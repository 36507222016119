import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowFourItems,
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { MySelectablesInput } from "../types/MySelectablesInput";
import { AddDishVariables } from "../../../chefsbase/dishes/addDish/types/AddDish";
import { Button, Stack } from "@mui/material";
import {
  scaleNutrition,
  totalNutrition,
} from "../../components/courses/Functions";
import { getFraction } from "../../../../components/utils/GetFraction";
import { createRange } from "../../../../components/utils/CreateRange";
import { FormQuantity } from "../../../chefsbase/recipes/addrecipes/CreateYourRecipe";
import { PortionInput, PortionSelectedInput } from "../../../../globalTypes";
import {
  findMeasure,
  gramMeasure,
  measureInputOptions,
} from "../../../../components/defaults/portions/PortionInput";
import { measureToMeasureInput } from "./DishCard/Mappers";
import { SubmitButton } from "../../../../components/Buttons";

const OneBiggerThanLast = (a: PortionSelectedInput[]): PortionInput => {
  const length = a.length;
  const last = a[length - 1];
  if (last === null || last === undefined)
    return {
      name: "Small",
      quantity: {
        quantity: 100,
        unit: measureToMeasureInput(gramMeasure),
      },
      price: 1,
    };
  let name: string;
  switch (last.portion.name) {
    case "Small":
      name = "Medium";
      break;
    case "Medium":
      name = "Large";
      break;
    case "Large":
      name = "Extra Large";
      break;
    default:
      name = "Medium";
  }
  const result: PortionInput = {
    name: name,
    quantity: {
      quantity:
        last === null || last === undefined
          ? 100
          : last.portion.quantity.quantity + 100,
      unit:
        a.length > 0
          ? measureToMeasureInput(gramMeasure)
          : a[0].portion.quantity.unit,
    },
    price: last.portion.price ? last.portion.price + 1 : 1,
  };

  return result;
};

export const PortionsForm = ({
  form,
  dish,
  setDish,
  options,
}: {
  options: { name: string; id: string }[];
  form?: AddDishVariables;
  dish: MySelectablesInput;
  setDish: (a: MySelectablesInput) => void;
}) => {
  const setForms = (a: PortionSelectedInput[]) => {
    setDish({
      ...dish,
      quantitiesSelected: a,
    });
  };

  const pushOne = () => {
    const old = [...dish.quantitiesSelected];
    old.push({
      portion: OneBiggerThanLast(dish.quantitiesSelected),
      selected: true,
    });
    setForms(old);
  };

  const cutOne = () => {
    const old = [...dish.quantitiesSelected];
    old.splice(dish.quantitiesSelected.length - 1, 1);
    setForms(old);
  };

  return (
    <>
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <GridRowFourItems
            before={0}
            firstlength={2}
            inbetweenfirsttwo={0}
            secondlength={0.5}
            inbetweensecondtwo={0}
            thirdlength={2}
            inbetweenthirdtwo={0}
            fourthlength={1}
            firstchild={
              <SubmitButton
                fullWidth
                sx={{ width: 150, borderRadius: 28 }}
                color="primary"
                variant={"contained"}
                onClick={() => {
                  pushOne();
                }}
              >
                Add
              </SubmitButton>
            }
            secondchild={<>or</>}
            thirdchild={
              <SubmitButton
                fullWidth
                sx={{ width: 150, borderRadius: 28 }}
                color="primary"
                variant={"contained"}
                onClick={() => {
                  cutOne();
                }}
              >
                Remove
              </SubmitButton>
            }
            fourthchild={<>Portion</>}
          />
        }
      />
      <>
        {dish.quantitiesSelected
          .filter((sel) => sel.selected === true)
          .map(
            (i, index) =>
              index < dish.quantitiesSelected.length &&
              dish.quantitiesSelected[index] && (
                <PortionInputRow
                  options={options}
                  form={form}
                  setForms={setForms}
                  index={dish.quantitiesSelected.indexOf(i)}
                  dish={dish}
                />
              )
          )}
      </>
    </>
  );
};

export const PortionInputRow = ({
  form,
  setForms,
  index,
  dish,
  options,
}: {
  options: { name: string; id: string }[];
  form?: AddDishVariables;
  setForms: (a: PortionSelectedInput[]) => void;
  index: number;
  dish: MySelectablesInput;
}) => {
  return (
    <Stack
      justifyContent="flex-start"
      alignItems="stretch"
      direction="row"
      spacing={0.5}
    >
      <FormField
        value={dish.quantitiesSelected[index].portion.name}
        setValue={(name) => {
          const newSel = [...dish.quantitiesSelected];
          newSel[index] = {
            portion: {
              name: name,
              quantity: newSel[index].portion.quantity,
              price: newSel[index].portion.price,
            },
            selected: newSel[index].selected,
          };
          setForms(newSel);
        }}
      />
      <GridRowTwoItems
        before={0}
        inbetween={1}
        firstlength={5}
        secondlength={5}
        firstchild={
          <FormField
            required
            num={true}
            value={String(
              dish.quantitiesSelected[index].portion.quantity.quantity
            )}
            setValue={(name) => {
              const newSel = [...dish.quantitiesSelected];
              newSel[index] = {
                portion: {
                  price: newSel[index].portion.price,
                  name: newSel[index].portion.name,
                  quantity: {
                    quantity: Number(name),
                    unit: dish.quantitiesSelected[index].portion.quantity.unit,
                  },
                },
                selected: newSel[index].selected,
              };

              setForms(newSel);
            }}
          />
        }
        secondchild={
          <>
            <FormSelect
              mt={1}
              value={dish.quantitiesSelected[index].portion.quantity.unit.label}
              setValue={(name) => {
                const newSel = [...dish.quantitiesSelected];
                const replaced = newSel.map((i, index1) => ({
                  portion: {
                    price: newSel[index1].portion.price,
                    name: newSel[index1].portion.name,
                    quantity: {
                      quantity:
                        dish.quantitiesSelected[index1].portion.quantity
                          .quantity,
                      unit: findMeasure(name, undefined),
                    },
                  },
                  selected: newSel[index1].selected,
                }));
                setForms(replaced);
              }}
              options={options}
            />
          </>
        }
      />
      <FormFieldInputAdornmentNumber
        input="€"
        value={String(dish.quantitiesSelected[index].portion.price)}
        setValue={(name) => {
          const newSel = [...dish.quantitiesSelected];
          newSel[index] = {
            portion: {
              price: Number(name),
              name: newSel[index].portion.name,
              quantity: newSel[index].portion.quantity,
            },
            selected: newSel[index].selected,
          };
          setForms(newSel);
        }}
      />
      {form && (
        <>
          {scaleNutrition(
            getFraction(
              dish.quantitiesSelected[0].portion.quantity,
              dish.quantitiesSelected[index].portion.quantity
            ),
            totalNutrition(
              form.products.ingredients
                .map((p) => p.nutrition)
                .concat(form.recipes.ingredients.map((p) => p.nutrition))
            )
          ).kcal.toFixed(2)}{" "}
          kcal
        </>
      )}
    </Stack>
  );
};
