import * as FileSaver from "file-saver"; // Import FileSaver library
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { BoxTitle, MainBox } from "../../../components/Boxes";
import { SubmitButton, TrashButton } from "../../../components/Buttons";
import { FormField } from "../../../components/forms/singleForms/FormField";
import {
  ProcessingDialog,
  ErrorDialog,
} from "../../../components/Loading/Processing";
import {
  AccountInput,
  ComboOfferInput,
  ComposablesInput,
  CourseInput,
  IngredientToQuantityToSalePriceInput,
  KitchenType,
  SelectablesInput,
} from "../../../globalTypes";
import { useSetCurrentMenuMutation } from "../../clientbase/api";
import { DeleteDialog } from "../components/DeleteDialog";
import { useLazySearchDishesQuery } from "../dishes/api";
import { dishes, dishes_dishes } from "../dishes/types/dishes";
import { useClientMenusQuery, useLazyComposableQuery } from "./api";
import { MenuForGrids } from "./components/SampleTypes";
import {
  Clientmenus,
  Clientmenus_menus,
  Clientmenus_menus_combos,
  Clientmenus_menus_combos_dishes,
  Clientmenus_menus_courses,
  Clientmenus_menus_courses_composables,
  Clientmenus_menus_courses_composables_composableRounds_foods,
  Clientmenus_menus_courses_composables_composableRounds_recipes,
  Clientmenus_menus_courses_selectables_selectables,
  Clientmenus_menus_courses_selectables_selectables_dish,
} from "./types/Clientmenus";
import { useNavigate } from "react-router-dom";
import { useUpdateMenuMutation } from "./addMenu/api";
import { AddMenuVariables } from "./addMenu/types/AddMenu";
import { UpdateMenuVariables } from "./addMenu/types/UpdateMenu";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../components/layouts/Grids";
import { Composables, Composables_composables } from "./types/Composables";
import { GetClientPage } from "../../clientbase";
import { FormContent } from "../../clientbase/createClientPage";
import { toNutritionInput } from "../../clientbase/components/mappers/nutrition/ToNutritionInput";
import {
  measureToMeasureInput,
  quantityToInput,
} from "../../clientbase/createClientPage/forms/DishCard/Mappers";
import { Loader } from "../../../components/Loading/Loader";
import { NutritionToInput } from "../dishes/components/RowRecipe";
import axios from "axios";
import { VscRefresh, VscAdd } from "react-icons/vsc";
import { createRange } from "../../../components/utils/CreateRange";
import { healthlabelInfoToOutput } from "../dishes/utils/MapToInput";
import {
  emptyAccount,
  accountToInput,
} from "../../../components/layouts/Header";
import { useAccountQuery } from "../../api";

export const mapCombos = (
  combo: Clientmenus_menus_combos[]
): ComboOfferInput[] => {
  const mapped = combo.map((c) => {
    const sorteddishes: Clientmenus_menus_combos_dishes[] = [...c.dishes].sort(
      (a, b) => Number(a.round) - Number(b.round)
    );
    return {
      price: c.price,
      dishes: sorteddishes.map((dishtoportion) => ({
        round: dishtoportion.round,
        name: dishtoportion.dish.name,
        dishid: dishtoportion.dish.id,
        nutrition: NutritionToInput(dishtoportion.portion.nutrition),
        portion: {
          name: dishtoportion.portion.name,
          quantity: quantityToInput(dishtoportion.portion.quantity),
        },
      })),
    };
  });

  return mapped;
};
export const mapVarProdToIngredientInput = (
  a: Clientmenus_menus_courses_composables_composableRounds_foods[]
): IngredientToQuantityToSalePriceInput[] => {
  return a.map((a) => ({
    healthLabelInfo: healthlabelInfoToOutput(a.healthLabelInfo),
    measurementInfo: {
      serving:
        a.measurementInfo && a.measurementInfo.serving
          ? a.measurementInfo.serving
          : 0,
      cup:
        a.measurementInfo && a.measurementInfo.cup ? a.measurementInfo.cup : 0,
      teaspoon:
        a.measurementInfo && a.measurementInfo.teaspoon
          ? a.measurementInfo.teaspoon
          : 0,
      tablespoon:
        a.measurementInfo && a.measurementInfo.tablespoon
          ? a.measurementInfo.tablespoon
          : 0,
      whole:
        a.measurementInfo && a.measurementInfo.whole
          ? a.measurementInfo.whole
          : 0,
    },
    id: a.id,
    quantity: {
      quantity: a.quantity.quantity,
      unit: measureToMeasureInput(a.quantity.unit),
    },
    price: a.price,
    name: a.name,
    nutrition: {
      quantity: quantityToInput(a.nutrition.quantity),
      nutrition: toNutritionInput(a.nutrition.nutrition),
    },
    img: "",
  }));
};

export const mapVarRecToIngredientInput = (
  a: Clientmenus_menus_courses_composables_composableRounds_recipes[]
): IngredientToQuantityToSalePriceInput[] => {
  return a.map((a) => ({
    healthLabelInfo: healthlabelInfoToOutput(a.healthLabelInfo),
    id: a.id,
    quantity: {
      quantity: a.quantity.quantity,
      unit: measureToMeasureInput(a.quantity.unit),
    },
    price: a.price,
    name: a.name,
    nutrition: {
      quantity: quantityToInput(a.nutrition.quantity),
      nutrition: toNutritionInput(a.nutrition.nutrition),
    },
    img: "",
  }));
};
export const mapComposablestoInput = (
  composables: Clientmenus_menus_courses_composables[]
): ComposablesInput[] => {
  const mapped = composables.map((c) => ({
    type: c.type,
    img: c.img,
    name: c.name,
    composableRounds: c.composableRounds.map((cr, no) => ({
      number: cr.number,
      name: cr.name,
      foods: mapVarProdToIngredientInput(cr.foods),
      recipes: mapVarRecToIngredientInput(cr.recipes),
    })),
    basePrice: c.basePrice,
  }));
  return mapped;
};

export const mapSelectablestoInput = (
  selectables: Clientmenus_menus_courses_selectables_selectables[]
): SelectablesInput[] => {
  const mapped = selectables.map((c) => ({
    dishid: c.dish.id,
    dishname: c.dish.name,
    quantitiesSelected: c.quantitiesSelected.map((q) => ({
      portion: {
        name: q.portion.name,
        quantity: {
          quantity: q.portion.quantity.quantity,
          unit: measureToMeasureInput(q.portion.quantity.unit),
        },
        price: q.portion.price,
      },
      selected: q.selected,
    })),
    img: c.dish.img,
  }));
  return mapped;
};

export const mapMenu = (a: Clientmenus_menus[]): MenuForGrids[] => {
  const result = a.map((m) => ({
    id: m.id,
    name: m.name,
    numberPerType: [
      {
        number: 1,
        type: "Type",
      },
    ],
    courses: m.courses.map((c: Clientmenus_menus_courses) => ({
      name: c.name,
      composables: mapComposablestoInput(c.composables),
      selectables: mapSelectablestoInput(
        c.selectables
          .map((s) =>
            s.selectables.filter((sel) => sel.dish.name !== "Test Dish")
          )
          .flat()
      ),
    })),
    combos: mapCombos(m.combos),
  }));
  return result;
};

export const Menus = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [type, settype] = useState("");
  const [firstmenus, setfirstmenus] = useState<MenuForGrids[]>([]);
  const [filteredmenus, setfilteredmenus] = useState<MenuForGrids[]>([]);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { data, loading, refetch } = useClientMenusQuery({
    onCompleted: (result: Clientmenus) => {
      setfirstmenus(mapMenu(result.menus));
      setfilteredmenus(mapMenu(result.menus));
    },
  });

  const setNameFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredmenus.filter((i) =>
            i.name.toLowerCase().includes(a.toLowerCase())
          )
        : firstmenus;

    setfilteredmenus(n);
  };
  const setTypeFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredmenus.filter((i) => {
            const list = i.numberPerType.map((t) => t.type.toLowerCase());
            const search = a.toLowerCase();
            const result = list.filter((i) => i.includes(search));
            return result.length > 0;
          })
        : firstmenus;

    setfilteredmenus(n);
  };
  const [noAccount, setNoAccount] = useState<boolean>(false);

  const { data: dataAccount } = useAccountQuery({
    onCompleted: (result) => {
      if (
        result.account !== null &&
        result.account.accesstoken == result.account.restaurantName
      )
        setNoAccount(true);
    },
  });
  return (
    <MainBox
      childComp={
        noAccount ? (
          <>
            Please send a request to <br />
            <a href="mailto::mychefsbase@gmail.com">mychefsbase@gmail.com</a>
            to request for the use of MyChefsbase. Or sign up for the waiting{" "}
            <br />
            list on <a href="www.mychefsbase.com">www.mychefsbase.com</a>
          </>
        ) : (
          <>
            <BoxTitle title="Menus" />
            <div style={{ height: 300, width: "100%" }}>
              <Table>
                {!isMobile && (
                  <TopRow
                    isMobile={isMobile}
                    name={name}
                    type={type}
                    setNameFilter={(a: string) => setNameFilter(a)}
                    setTypeFilter={(a: string) => setTypeFilter(a)}
                    setname={(a: string) => setName(a)}
                    settype={(a: string) => settype(a)}
                  />
                )}
                {!isMobile && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <SubmitButton
                        variant="contained"
                        onClick={() => navigate("/addmenu", { replace: true })}
                      >
                        <VscAdd />
                        Menu
                      </SubmitButton>
                    </TableCell>
                    <TableCell colSpan={isMobile ? 2 : 4}>
                      <SubmitButton
                        variant="contained"
                        onClick={() => window.location.reload()}
                      >
                        <VscRefresh />
                        Refresh
                      </SubmitButton>
                    </TableCell>
                  </TableRow>
                )}
                {loading && <Loader loading={loading} />}
                {data && data.menus && (
                  <BodyRows
                    isMobile={isMobile}
                    refetch={refetch}
                    menus={data.menus}
                    filteredmenus={filteredmenus}
                    setfilteredmenus={(f: MenuForGrids[]) =>
                      setfilteredmenus(f)
                    }
                  />
                )}
              </Table>
            </div>
          </>
        )
      }
    />
  );
};

const TopRow = ({
  isMobile,
  name,
  type,
  setname,
  settype,
  setNameFilter,
  setTypeFilter,
}: {
  isMobile: boolean;
  name: string;
  type: string;
  setname: (a: string) => void;
  settype: (a: string) => void;
  setTypeFilter: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Search Menu:</TableCell>
          <TableCell>
            <FormField
              value={name}
              setValue={(a) => {
                setname(a);
                setNameFilter(a);
              }}
            />
          </TableCell>
          <TableCell>Search On Type:</TableCell>
          <TableCell>
            <FormField
              value={type}
              setValue={(a) => {
                settype(a);
                setTypeFilter(a);
              }}
            />
          </TableCell>
          <TableCell></TableCell>
          {!isMobile && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
    </>
  );
};

const BodyRows = ({
  filteredmenus,
  setfilteredmenus,
  menus,
  refetch,
  isMobile,
}: {
  isMobile: boolean;
  refetch: () => void;
  menus: Clientmenus_menus[];
  filteredmenus: MenuForGrids[];
  setfilteredmenus: (a: MenuForGrids[]) => void;
}) => {
  return (
    <>
      {filteredmenus.map((m, index) => (
        <>
          <MenuRow
            isMobile={isMobile}
            key={index}
            menu={menus[index]}
            m={m}
            refetch={refetch}
            remove={() => {
              const old = [...filteredmenus];
              old.splice(index, 1);
              setfilteredmenus(old);
            }}
          />
        </>
      ))}
    </>
  );
};

const MenuRow = ({
  menu,
  m,
  remove,
  refetch,
  isMobile,
}: {
  isMobile: boolean;
  refetch: () => void;
  menu: Clientmenus_menus;
  remove: () => void;
  m: MenuForGrids;
}) => {
  return (
    <>
      <MenuTopRow
        isMobile={isMobile}
        menu={menu}
        remove={() => remove}
        m={m}
        refetch={refetch}
      />
    </>
  );
};
export const mapToAddMenuVariables = (
  a: UpdateMenuVariables
): AddMenuVariables => {
  const courseInput: CourseInput[] = [];
  a.courses.forEach((c, index) => {
    const old = c.selectables;
    // old.push(emptySelectable);
    const entries: SelectablesInput[] = [];
    old.forEach((s) => {
      !entries.includes(s) && entries.push(s);
    });
    const entry: CourseInput = {
      ...c,
      selectables: entries,
    };
    courseInput.push(entry);
  });

  return {
    input: a.input,
    combos: a.combos,
    courses: courseInput,
  };
};

export const mapToUpdateMenuVariables = (
  id: string,
  a: AddMenuVariables
): UpdateMenuVariables => {
  const form = a;
  const old = form.courses;
  form.courses.forEach((c, index) => {
    const updated: ComposablesInput[] = c.composables.map((composable, i) => ({
      ...composable,
      composableRounds: composable.composableRounds.map((r, no) => ({
        number: r.number,
        name: r.name,
        foods: r.foods.filter((i) => i.name !== ""),
        recipes: r.recipes.filter((i) => i.name !== ""),
      })),
    }));
    old[index] = {
      composables: updated
        .filter((c) => c.name !== "Create Your Own")
        .map((comp) => ({
          ...comp,
          img: comp.img
            ? comp.img.filter((it) => it !== null && it !== undefined)
            : [""],
        })),
      name: old[index].name,
      selectables: c.selectables.filter((i) => i.dishname !== "Test Dish"),
    };
  });
  const resultingForm: UpdateMenuVariables = {
    id: id,
    input: form.input,
    combos: form.combos,
    courses:
      old.filter((i) => i.name != "").length > 0
        ? old.filter((i) => i.name != "")
        : form.courses,
  };

  return resultingForm;
};

interface ExcelRowForAllergene {
  dishname: string;
  egg: string;
  gluten: string;
  milk: string;
  mustard: string;
  sulphide: string;
  mollusk: string;
  fish: string;
  soy: string;
  sesame: string;
  selery: string;
  shellfish: string;
  peanut: string;
  nut: string;
}
type TypeToDishes = {
  type: string;
  dishes: Clientmenus_menus_courses_selectables_selectables_dish[];
};
export const menuGridToExcelRows = (
  name: string,
  a: Clientmenus_menus
): ExcelRowForAllergene[] => {
  const dishes = a.courses
    .map((c) => c.selectables)
    .flat()
    .map((sel) => sel.selectables)
    .flat()
    .map((s) => s.dish);

  return dishes.map((dish) => ({
    dishname: dish.name,
    egg: dish.healthLabelInfo.egg ? "X" : "",
    gluten: dish.healthLabelInfo.gluten ? "X" : "",
    milk: dish.healthLabelInfo.milk ? "X" : "",
    mustard: dish.healthLabelInfo.mustard ? "X" : "",
    sulphide: dish.healthLabelInfo.sulphide ? "X" : "",
    mollusk: dish.healthLabelInfo.mollusk ? "X" : "",
    fish: dish.healthLabelInfo.fish ? "X" : "",
    soy: dish.healthLabelInfo.soy ? "X" : "",
    sesame: dish.healthLabelInfo.sesame ? "X" : "",
    selery: dish.healthLabelInfo.selery ? "X" : "",
    shellfish: dish.healthLabelInfo.shellfish ? "X" : "",
    peanut: dish.healthLabelInfo.peanut ? "X" : "",
    nut: dish.healthLabelInfo.nut ? "X" : "",
  }));
};
const MenuTopRow = ({
  m,
  remove,
  menu,
  refetch,
  isMobile,
}: {
  isMobile: boolean;
  refetch: () => void;
  menu: Clientmenus_menus;
  remove: () => void;
  m: MenuForGrids;
}) => {
  const [address, setaddress] = useState("Staat en huisnummer");
  const [location, setlocation] = useState("Stad");
  const [name, setName] = useState("Allergenenlijst");

  const handleExport = async () => {
    const Excel = require("exceljs");
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet("AllergeneSheet");

    ws.getColumn(2).width = 25;
    createRange(3, 17).forEach((num) => {
      ws.getColumn(num).width = 16;
    });
    ws.getRow(10).height = 38;
    const headings = [
      "",
      "",
      "Bevat ei",
      "Bevat gluten",
      "Bevat lupine",
      "bevat melk",
      "bevat mosterd",
      "bevat zwavel- dioxide",
      "bevat week- dieren",
      "bevat vis",
      "bevat soja",
      "bevat sesam-zaad",
      "bevat selderij",
      "bevat schaal-dieren",
      "bevat pinda's",
      "bevat noten",
    ];

    const dataRows = menuGridToExcelRows(m.name, menu);
    const eggBuffer = await axios.get("/Egg.png", {
      responseType: "arraybuffer",
    });
    const imageEgg = wb.addImage({
      buffer: eggBuffer.data,
      extension: "png",
    });
    const glutenBuffer = await axios.get("/Gluten.png", {
      responseType: "arraybuffer",
    });
    const imageGluten = wb.addImage({
      buffer: glutenBuffer.data,
      extension: "png",
    });
    const lupineBuffer = await axios.get("/Lupine.png", {
      responseType: "arraybuffer",
    });
    const imageLupine = wb.addImage({
      buffer: lupineBuffer.data,
      extension: "png",
    });
    const milkBuffer = await axios.get("/Milk.png", {
      responseType: "arraybuffer",
    });
    const imageMilk = wb.addImage({
      buffer: milkBuffer.data,
      extension: "png",
    });
    const mustardBuffer = await axios.get("/Mustard.png", {
      responseType: "arraybuffer",
    });
    const imageMustard = wb.addImage({
      buffer: mustardBuffer.data,
      extension: "png",
    });
    const sulphideBuffer = await axios.get("/Sulphide.png", {
      responseType: "arraybuffer",
    });
    const imageSulphide = wb.addImage({
      buffer: sulphideBuffer.data,
      extension: "png",
    });
    const molluskBuffer = await axios.get("/mollusk.png", {
      responseType: "arraybuffer",
    });
    const imagemollusk = wb.addImage({
      buffer: molluskBuffer.data,
      extension: "png",
    });
    const fishBuffer = await axios.get("/Fish.png", {
      responseType: "arraybuffer",
    });
    const imageFish = wb.addImage({
      buffer: fishBuffer.data,
      extension: "png",
    });
    const soyBuffer = await axios.get("/Soy.png", {
      responseType: "arraybuffer",
    });
    const imageSoy = wb.addImage({
      buffer: soyBuffer.data,
      extension: "png",
    });
    const sesameBuffer = await axios.get("/Sesame.png", {
      responseType: "arraybuffer",
    });
    const imageSesame = wb.addImage({
      buffer: sesameBuffer.data,
      extension: "png",
    });
    const seleryBuffer = await axios.get("/Selery.png", {
      responseType: "arraybuffer",
    });
    const imageSelery = wb.addImage({
      buffer: seleryBuffer.data,
      extension: "png",
    });
    const shellFishBuffer = await axios.get("/Shellfish.png", {
      responseType: "arraybuffer",
    });
    const imageShellfish = wb.addImage({
      buffer: shellFishBuffer.data,
      extension: "png",
    });
    const peanutBuffer = await axios.get("/Peanut.png", {
      responseType: "arraybuffer",
    });
    const imagePeanut = wb.addImage({
      buffer: peanutBuffer.data,
      extension: "png",
    });
    const nutBuffer = await axios.get("/Nut.png", {
      responseType: "arraybuffer",
    });
    const imageNut = wb.addImage({
      buffer: nutBuffer.data,
      extension: "png",
    });

    ws.getCell("B1").font = {
      name: "Calibri",
      family: 4,
      size: 24,
      // underline: "double",
      bold: true,
    };
    ws.getCell("B9").font = {
      name: "Calibri",
      family: 4,
      size: 24,
      // underline: "double",
      bold: true,
    };
    ws.getCell("B6").font = {
      name: "Calibri",
      bold: true,
    };

    ws.getCell("B11").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    ws.getCell("B1").value = new String("Allergenen invullijst");
    ws.getCell("B3").value = new String("Locatie:");
    ws.getCell("B4").value = new String("Adres:");
    ws.getCell("B6").value = new String(
      "Let op: vermeld de naam van de glutenbevattende granen en/of de naam van de noten."
    );
    ws.getCell("C3").value = new String(location);
    ws.getCell("C4").value = new String(address);
    ws.mergeCells("C3:P3");
    ws.mergeCells("C4:P4");
    ws.getCell("B9").value = new String("Gerechten");
    ws.mergeCells("B9:B10");
    ws.getRow(10).values = headings;
    ws.addImage(imageEgg, `C7:C9`);
    ws.addImage(imageGluten, `D7:D9`);
    ws.addImage(imageLupine, `E7:E9`);
    ws.addImage(imageMilk, `F7:F9`);
    ws.addImage(imageMustard, `G7:G9`);
    ws.addImage(imageSulphide, `H7:H9`);
    ws.addImage(imagemollusk, `I7:I9`);
    ws.addImage(imageFish, `J7:J9`);
    ws.addImage(imageSoy, `K7:K9`);
    ws.addImage(imageSesame, `L7:L9`);
    ws.addImage(imageSelery, `M7:M9`);
    ws.addImage(imageShellfish, `N7:N9`);
    ws.addImage(imagePeanut, `O7:O9`);
    ws.addImage(imageNut, `P7:P9`);

    dataRows.forEach((row, rowIndex) => {
      ws.getRow(rowIndex + 11).values = [
        "",
        row.dishname,
        row.egg ? "X" : "",
        row.gluten ? "X" : "",
        "",
        row.milk ? "X" : "",
        row.mustard ? "X" : "",
        row.sulphide ? "X" : "",
        row.mollusk ? "X" : "",
        row.fish ? "X" : "",
        row.soy ? "X" : "",
        row.sesame ? "X" : "",
        row.selery ? "X" : "",
        row.shellfish ? "X" : "",
        row.peanut ? "X" : "",
        // row.milk ? "X" : "",
        row.nut ? "X" : "",
      ];
    });

    wb.xlsx
      .writeBuffer()
      .then((buffer: ArrayBuffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, `${name}.xlsx`);
      })
      .catch((error: any) => console.log("Error writing excel export", error));
  };
  const [openDownload, setopenDownload] = useState(false);
  const [form, setForm] = useState<UpdateMenuVariables>(mapMenuGridToForm(m));
  const [openupdate, setopenupdate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [openCurrent, setOpenCurrent] = useState(false);

  const { updatMenu, loading, error } = useUpdateMenuMutation({
    onCompleted: () => {
      setopenupdate(false);
    },
  });
  const [dishes, setdishes] = useState<dishes_dishes[]>([]);
  const [composables, setcomposables] = useState<Composables_composables[]>([]);

  const { searchdishes, loading: loadingDishes } = useLazySearchDishesQuery({
    onCompleted: (result: dishes) => setdishes(result.dishes),
  });
  const { getcomposables, loading: loadingcomposables } =
    useLazyComposableQuery({
      onCompleted: (result: Composables) => setcomposables(result.composables),
    });

  if (loading)
    <ProcessingDialog loading={loading} title="Your menu is updating.." />;
  if (error) <ErrorDialog />;

  if (isMobile)
    return (
      <>
        <TableRow key={m.id}>
          <TableCell colSpan={3}>
            <SubmitButton
              fullWidth
              onClick={() => {
                setOpenView(true);
                searchdishes();
                getcomposables();
              }}
              variant="contained"
            >
              {menu.name}
            </SubmitButton>
          </TableCell>
        </TableRow>
        <MenuDialog menu={menu} setOpenView={setOpenView} openView={openView} />
      </>
    );
  return (
    <>
      <TableRow key={m.id}>
        <TableCell>
          <SubmitButton
            variant="contained"
            onClick={() => {
              setOpenView(true);
              searchdishes();
              getcomposables();
            }}
          >
            View
          </SubmitButton>
        </TableCell>
        <TableCell>{menu.name}</TableCell>
        <TableCell>
          <SubmitButton
            variant="contained"
            onClick={() => {
              setopenupdate(true);
              searchdishes();
              getcomposables();
            }}
          >
            Update
          </SubmitButton>
        </TableCell>
        <TableCell>
          <SubmitButton
            variant={menu.current === 1 ? "contained" : "outlined"}
            color={menu.current === 1 ? "primary" : "inherit"}
            onClick={() => {
              setOpenCurrent(true);
            }}
          >
            Current Menu
          </SubmitButton>
        </TableCell>
        <TableCell>
          <TrashButton
            onClick={() => {
              setopenDelete(true);
            }}
          />
        </TableCell>
        <TableCell>
          <SubmitButton
            onClick={() => setopenDownload(true)}
            variant="contained"
          >
            HACCP Allergene List
          </SubmitButton>
        </TableCell>
      </TableRow>
      {loadingDishes ||
        (loadingcomposables && (
          <Loader loading={loadingDishes || loadingcomposables} />
        ))}
      <DeleteDialog
        open={openDelete}
        onClose={() => setopenDelete(false)}
        name={form.input.name}
        id={m.id}
        kitchenType={KitchenType.Menu}
        onCompleted={() => {
          remove();
        }}
      />
      <SetCurrentMenuDialog
        id={m.id}
        refetch={refetch}
        open={openCurrent}
        setOpen={setOpenCurrent}
      />
      <Dialog open={openDownload} onClose={() => setopenDownload(false)}>
        <DialogTitle>Download Haccp List</DialogTitle>
        <DialogContent>
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <FormField
                label="File Name"
                value={name}
                setValue={(a) => setName(a)}
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <FormField
                label="Location"
                value={location}
                setValue={(a) => setlocation(a)}
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <FormField
                label="Address"
                value={address}
                setValue={(a) => setaddress(a)}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <SubmitButton variant="contained" onClick={handleExport}>
            Download
          </SubmitButton>
          <SubmitButton
            variant="outlined"
            color="inherit"
            onClick={() => setopenDownload(false)}
          >
            Cancel
          </SubmitButton>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen open={openupdate} onClose={() => setopenupdate(false)}>
        <DialogTitle>Update </DialogTitle>
        <DialogContent>
          <FormContent
            loadingUpdateMenu={loading}
            dishes={dishes}
            setdishes={setdishes}
            menuForm={mapToAddMenuVariables(form)}
            mutate={(a) => {
              updatMenu({
                variables: GetOriginalMenuItems(
                  menu,
                  mapToUpdateMenuVariables(menu.id, a)
                ),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setopenupdate(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <MenuDialog menu={menu} setOpenView={setOpenView} openView={openView} />
    </>
  );
};
const MenuDialog = ({
  openView,
  setOpenView,
  menu,
}: {
  menu: Clientmenus_menus;
  openView: boolean;
  setOpenView: (b: boolean) => void;
}) => {
  return (
    <Dialog fullScreen open={openView} onClose={() => setOpenView(false)}>
      <DialogTitle>{menu.name}</DialogTitle>
      <DialogContent>
        <GetClientPage menu={menu} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpenView(false)}>
          Close
        </Button>
      </DialogActions>{" "}
    </Dialog>
  );
};
export const SetCurrentMenuDialog = ({
  open,
  setOpen,
  id,
  refetch,
}: {
  refetch: () => void;
  id: string;
  open: boolean;
  setOpen: (a: boolean) => void;
}) => {
  const navigate = useNavigate();

  const { setCurrentMenu } = useSetCurrentMenuMutation({
    onCompleted: () => {
      navigate("/menus", { replace: true });
    },
  });
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Are you sure you want to set this menu as current, so that your
        customers will view it?
      </DialogTitle>
      <DialogActions>
        <GridRowTwoItems
          before={3}
          inbetween={3}
          firstlength={3}
          secondlength={3}
          firstchild={
            <Button
              variant="contained"
              onClick={() => {
                setCurrentMenu({
                  variables: {
                    id: id,
                  },
                });
                refetch();
                setOpen(false);
              }}
            >
              Yes
            </Button>
          }
          secondchild={
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export const GetOriginalMenuItems = (
  a: Clientmenus_menus,
  b: UpdateMenuVariables
): UpdateMenuVariables => {
  const id = a.id;
  const input = {
    ...b.input,
    img: "",
  };
  const combos: ComboOfferInput[] = b.combos;

  const courses: CourseInput[] = b.courses;

  return {
    id: id,
    input: input,
    combos: combos,
    courses: courses,
  };
};

export const UpdateDialog = ({
  open,
  onClose,
  name,
  updateItem,
}: {
  updateItem: () => void;
  open: boolean;
  onClose: () => void;
  name: string;
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>Are you sure to update {name}?</DialogTitle>
      <DialogActions>
        <GridRowTwoItems
          before={2}
          firstlength={4}
          secondlength={4}
          inbetween={2}
          firstchild={
            <SubmitButton
              variant="outlined"
              onClick={() => {
                updateItem();
                onClose();
              }}
            >
              Yes
            </SubmitButton>
          }
          secondchild={
            <SubmitButton variant="outlined" onClick={() => onClose()}>
              No
            </SubmitButton>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

const mapMenuGridToForm = (m: MenuForGrids): UpdateMenuVariables => {
  return {
    id: m.id,
    input: {
      name: m.name,
      img: "",
    },
    combos: m.combos,
    courses: m.courses,
  };
};
