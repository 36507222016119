import Axios from "axios";
import { Product } from "../../screens/chefsbase/products/types/Product";
import {
  gramMeasure,
  gramMeasureInput,
  mlMeasureInput,
  small,
} from "../defaults/portions/PortionInput";
import { FoodConfig } from "./Config";
import { QuantityToProduct } from "../../screens/clientbase/createClientPage/types/QuantityToProduct";
import { Clientmenus_menus_courses_composables_composableRounds_foods_quantity_unit } from "../../screens/chefsbase/menus/types/Clientmenus";
import {
  MeasureInput,
  QuantityToNameIdAndNutritionInput,
} from "../../globalTypes";
import { EdamamToNutritionInput } from "../../screens/clientbase/createClientPage/forms/DishCard/Mappers";

import { MeasurementInfo } from "../../screens/clientbase/types/types";

export type ProductToMeasures = {
  measures: MeasureInput[];
  food: Product;
};

export const getData = async (
  a: string,
  submit: boolean,
  setproduct: (a: QuantityToProduct) => void,
  setproducts: (a: QuantityToProduct[]) => void,
  product?: QuantityToProduct
) => {
  if (a !== "") {
    const url = `https://api.edamam.com/api/food-database/v2/parser?app_id=${FoodConfig.params.app_id}&app_key=${FoodConfig.params.app_key}&ingr=${a}`;

    const result = await Axios.get(url);

    result &&
      result.data &&
      result.data.hints &&
      setproducts(
        result.data.hints.map((h: any) => {
          const a: MeasureInput | undefined =
            h.measures &&
            h.measures.find(
              (measure: MeasureInput) => measure.label === "Milliliter"
            );
          return {
            quantity: {
              quantity: 0,
              unit: a ? mlMeasureInput(a.weight) : gramMeasureInput(1),
            },
            product: h,
          };
        })
      );
  }
};

export const getData2 = async (
  a: string[],
  setproducts: (a: QuantityToNameIdAndNutritionInput[]) => void
) => {
  const list: QuantityToNameIdAndNutritionInput[] = [];

  for (const string of a) {
    const url = `https://api.edamam.com/api/food-database/v2/parser?app_id=${FoodConfig.params.app_id}&app_key=${FoodConfig.params.app_key}&ingr=${string}`;
    const result = await Axios.get(url);
    const product = result?.data?.hints[0];
    const measures = result?.data?.hints[0].measures;
    const serving = measures.find((m: any) => m.label === "Serving");
    const cup = measures.find((m: any) => m.label === "Cup");
    const whole = measures.find((m: any) => m.label === "Whole");
    const teaspoon = measures.find((m: any) => m.label === "Teaspoon");
    const tablespoon = measures.find((m: any) => m.label === "Tablespoon");
    const measurementInfo: MeasurementInfo = {
      serving: serving?.weight,
      cup: cup?.weight,
      whole: whole?.weight,
      teaspoon: teaspoon?.weight,
      tablespoon: tablespoon?.weight,
    };
    const quantityToProduct: QuantityToProduct = {
      quantity: {
        quantity: 100,
        unit: gramMeasureInput(1),
      },
      product: product,
    };

    const final: QuantityToNameIdAndNutritionInput = {
      measurementInfo: measurementInfo,
      quantity: quantityToProduct.quantity,
      name: quantityToProduct.product.food.label,
      id: quantityToProduct.product.food.foodId,
      img: quantityToProduct.product.food.image
        ? quantityToProduct.product.food.image
        : "",
      nutrition: EdamamToNutritionInput(
        quantityToProduct.product.food.nutrients
      ),
    };

    list.push(final);
  }
  setproducts(list);
};

export const getMeasureFrac = (
  amount: number,
  label: string,
  measures: (
    | Clientmenus_menus_courses_composables_composableRounds_foods_quantity_unit
    | MeasureInput
  )[]
) => {
  const grams = getGramsOfLabel(label, measures);

  return (amount / 100) * (grams / 100);
};

const getGramsOfLabel = (
  label: string,
  measures: (
    | Clientmenus_menus_courses_composables_composableRounds_foods_quantity_unit
    | MeasureInput
  )[]
) => {
  if (label === "Gram") {
    return 100;
  }
  const gramsOfItem = measures.find((m) => m.label === label);
  const grams = measures.find((m) => m.label === "Gram");
  const milliliters = measures.find((m) => m.label === "Milliliter");

  const result = gramsOfItem
    ? gramsOfItem.weight
    : grams
    ? grams.weight
    : milliliters
    ? milliliters.weight
    : 1;
  return result;
};
