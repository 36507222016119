export type MinMax = {
  min: number;
  max: number;
};
export type MicroMacroField = {
  kcal: MinMax;
  prottotal: MinMax;
  carbs: MinMax;
  fats: MinMax;
  price: MinMax;
};

export enum MinOrMax {
  MIN,
  MAX,
}