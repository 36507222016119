import {
  Card,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
import { emptySelectable } from "../../../../components/defaults/selectables/SelectableInput";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import {
  ImageButton,
  ImageSrc,
  ImageBackdrop,
  ImageMarked,
  Image,
} from "../../../../components/image/ImageButton";
import { MySelectablesInput } from "../types/MySelectablesInput";
import { TrashButton } from "../../../../components/Buttons";
import { small } from "../../../../components/defaults/portions/PortionInput";
import {
  backgroundContainer,
  backgroundField,
} from "../../../../components/layouts/Colors";
import { GridRowOneItem } from "../../../../components/layouts/Grids";
import { sortPortions } from "../../../chefsbase/dishes/dishDialog/DishContent";
import { getPicture } from "../../components/courses/DisplayDishButton";

export const DishButton2 = ({
  setopen,
  form,
  setForm,
  index,
  selectable,
  i,
  setselectable,
}: {
  setselectable: (a: MySelectablesInput) => void;
  i: number;
  selectable: MySelectablesInput;
  index: number;
  setopen: (bool: boolean) => void;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card
        sx={{
          height: "65vh",
          width: "35vh",
          backgroundColor: backgroundContainer,
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={selectable.img?.[0] || "/images/DishBackground.webp"}
        />
        <CardContent sx={{ height: "200px" }} style={{ margin: "0px" }}>
          <Box height={"60px"} sx={{ mt: 2 }}>
            <Typography
              style={{ fontWeight: "bold" }}
              align="center"
              gutterBottom
              variant="body2"
              component="div"
            >
              {selectable.dishname}
            </Typography>
          </Box>
          <Grid container>
            {sortPortions(
              selectable.quantitiesSelected
                .filter((q) => q.selected)
                .map((q) => q.portion)
            ).map((portion, index1) => {
              return (
                <>
                  <Grid item xs={index1 === 0 ? 0 : 1}></Grid>
                  <Grid item xs={3} key={index1}>
                    <Typography
                      align="center"
                      variant="body2"
                      color="text.secondary"
                      style={{
                        fontSize: "12px",
                        whiteSpace: "pre-line",
                        fontWeight: "bold",
                      }}
                    >
                      <u>{portion.name}</u>
                    </Typography>
                    <Typography
                      align="center"
                      variant="body2"
                      color="text.secondary"
                    >
                      <> €{portion.price}</>
                      <br />
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </>
              );
            })}
          </Grid>
        </CardContent>
        <CardActions>
          <GridRowOneItem
            before={4}
            after={4}
            child={
              <Button
                sx={{ background: backgroundField, borderRadius: 28 }}
                // size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setopen(true);
                }}
              >
                Change
              </Button>
            }
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

export const DishButton = ({
  setopen,
  form,
  setForm,
  index,
  selectable,
  i,
  setselectable,
}: {
  setselectable: (a: MySelectablesInput) => void;
  i: number;
  selectable: MySelectablesInput;
  index: number;
  setopen: (bool: boolean) => void;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const width = 1000 / form.courses[index].selectables.length;
  const image = {
    url:
      selectable.img && selectable.img[0]
        ? selectable.img[0]
        : "/images/DishBackground.webp",
    title:
      selectable.dishname !== "Test Dish" ? selectable.dishname : "Add a Dish",
    width: `${width}%`,
  };
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", width: width }}>
      <ImageButton
        focusRipple
        key={image.title}
        style={{
          width: image.width,
        }}
        onClick={() => {
          const old = [...form.courses[index].selectables];
          if (!old.map((i) => i.dishname).includes(selectable.dishname))
            old.push(emptySelectable);
          const oldcourses = [...form.courses];
          oldcourses[index] = {
            composables: oldcourses[index].composables,
            name: oldcourses[index].name,
            selectables: old,
          };
          setForm({
            input: form.input,
            combos: form.combos,
            courses: oldcourses,
          });
          setopen(true);
        }}
      >
        <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {image.title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>
      {selectable.dishname !== "Test Dish" && (
        <TrashButton
          onClick={() => {
            setselectable({
              img: [""],
              dishid: "",
              dishname: "Test Dish",
              quantitiesSelected: [
                {
                  portion: small,
                  selected: true,
                },
              ],
            });
            const old = [...form.courses[index].selectables];
            old.splice(i, 1);
            const courses = [...form.courses];
            courses[index] = {
              ...courses[index],
              selectables: old,
            };
            setForm({
              input: form.input,
              combos: form.combos,
              courses: courses,
            });
          }}
        />
      )}
    </Box>
  );
};
