import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  TableCell,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { VscCheck, VscEdit, VscTrash } from "react-icons/vsc";
import { SubmitButton } from "../../Buttons";
import { GridRowThreeItems, GridRowTwoItems } from "../../layouts/Grids";
import { QuantityInput } from "../../../globalTypes";
import { FormSelect } from "../singleForms/FormSelect";
import {
  findMeasure,
  measureInputOptions,
} from "../../defaults/portions/PortionInput";
import { displayQuantity } from "../../utils/DisplayQuantity";
import {
  OneIngredientWide,
  OneIngredientWideTable,
  OneRecipeTable,
} from "../../../screens/clientbase/createClientPage/forms/IngredientForProductRow";
import { UpdateDishVariables } from "../../../screens/chefsbase/dishes/addDish/types/UpdateDish";
import {
  EdamamToNutritionInput,
  mapToProduct,
  mapToRecipe,
  mapToRecipeInput,
} from "../../../screens/clientbase/createClientPage/forms/DishCard/Mappers";
import { QuantityToProduct } from "../../../screens/clientbase/createClientPage/types/QuantityToProduct";
import { scaleNutrition } from "../../../screens/clientbase/components/courses/Functions";
import { Loader } from "../../Loading/Loader";
import { QuantityToRecipe } from "../../../screens/clientbase/types/types";
import { getFraction } from "../../utils/GetFraction";

export const TextOrQuantityFull = ({
  value,
  setValue,
  setopenmeasurementchange,
}: {
  setopenmeasurementchange?: (a: boolean) => void;
  value: QuantityInput;
  setValue: (a: QuantityInput) => void;
}) => {
  const [open, setOpen] = useState(false);
  if (open)
    return (
      <>
        <GridRowTwoItems
          before={0}
          inbetween={0}
          firstlength={10}
          secondlength={2}
          firstchild={
            <Stack spacing={0} direction="column">
              <TextField
                type="number"
                // sx={{ height: 1 }}
                size="small"
                margin="dense"
                onChange={(e) =>
                  setValue({
                    ...value,
                    quantity: Number(e.target.value),
                  })
                }
                value={String(value.quantity.toFixed(2))}
                autoFocus
              />
              <FormSelect
                options={measureInputOptions(value.unit.label).map((a) => ({
                  name: a.label,
                  id: a.uri,
                }))}
                value={value.unit.label}
                setValue={(a) => {
                  setValue({
                    ...value,
                    unit: findMeasure(a, 0),
                  });
                }}
              />
            </Stack>
          }
          secondchild={
            <SubmitButton
              sx={{ mt: 2, borderRadius: 16 }}
              variant="contained"
              onClick={() => setOpen(!open)}
            >
              <VscCheck />
            </SubmitButton>
          }
        />
        {setopenmeasurementchange && (
          <SubmitButton
            onClick={() => {
              setopenmeasurementchange(true);
            }}
            variant="contained"
          >
            Change Unit
          </SubmitButton>
        )}
      </>
    );
  else
    return (
      <>
        <span>{displayQuantity(value)}</span>
        <VscEdit style={{ cursor: "pointer" }} onClick={() => setOpen(!open)} />
      </>
    );
};

interface OneIngredientOrRecipeWide {
  recipes: QuantityToRecipe[];
  loadingRecipes: boolean;
  ingredient: boolean;
  index: number;
  form: UpdateDishVariables;
  setForm: (a: UpdateDishVariables) => void;
  deleteIngredient: () => void;
}

export const TextOrSelectProduct: React.FC<OneIngredientOrRecipeWide> = ({
  form,
  loadingRecipes,
  setForm,
  index,
  ingredient,
  recipes,
  deleteIngredient,
}) => {
  const [open, setOpen] = useState(
    ingredient && form.products.ingredients[index]
      ? form.products.ingredients[index].name === "" ||
          form.products.ingredients[index].quantity.quantity === 0
      : (form.recipes.ingredients[index] &&
          form.recipes.ingredients[index].name === "") ||
          form.recipes.ingredients[index].quantity.quantity === 0
  );
  if (open)
    return (
      <GridRowTwoItems
        firstlength={10}
        secondlength={1}
        before={0}
        inbetween={1}
        firstchild={
          ingredient ? (
            <OneIngredientWideTable
              key={index}
              deleteItem={() => {
                const old = [...form.products.ingredients];
                old.splice(index, 1);
                setForm({
                  ...form,
                  products: {
                    ...form.products,
                    ingredients: old,
                  },
                });
              }}
              product={mapToProduct(form.products.ingredients[index])}
              setproduct={(a: QuantityToProduct) => {
                const oldForForm = [...form.products.ingredients];
                oldForForm[index] = {
                  measurementInfo: a.product.measurementInfo,
                  quantity: a.quantity,
                  name: a.product.food.label,
                  nutrition: scaleNutrition(
                    1,
                    EdamamToNutritionInput(a.product.food.nutrients)
                  ),
                  id: a.product.food.foodId,
                };
                setForm({
                  ...form,
                  products: {
                    ...form.products,
                    ingredients: oldForForm,
                  },
                });
              }}
              setprice={(a: number) => {}}
            />
          ) : (
            <>
              {loadingRecipes ? (
                <CircularProgress />
              ) : (
                <OneRecipeTable
                  key={index}
                  recipes={recipes}
                  deleteItem={() => {
                    const oldForForm = [...form.recipes.ingredients];
                    oldForForm.splice(index, 1);
                    setForm({
                      ...form,
                      recipes: {
                        ...form.recipes,
                        ingredients: oldForForm,
                      },
                    });
                  }}
                  recipe={mapToRecipe(form.recipes.ingredients[index])}
                  setrecipe={(a: QuantityToRecipe) => {
                    const nutrition =
                      isNaN(a.recipe.nutrition.nutrition.kcal) &&
                      recipes.find((r) => r.recipe.id === a.recipe.id)
                        ? recipes.find((r) => r.recipe.id === a.recipe.id)!
                            .recipe.nutrition
                        : a.recipe.nutrition;
                    const oldForForm = [...form.recipes.ingredients];
                    const mapped = mapToRecipeInput(a);
                    oldForForm[index] = {
                      name: mapped.name,
                      id: mapped.id,
                      quantity: mapped.quantity,
                      img: mapped.img,
                      measurementInfo: null,
                      healthlabelInfo: mapped.healthlabelInfo,
                      nutrition: scaleNutrition(
                        getFraction(nutrition.quantity, a.quantity),
                        nutrition.nutrition
                      ),
                    };
                    setForm({
                      ...form,
                      recipes: {
                        ...form.recipes,
                        ingredients: oldForForm,
                      },
                    });
                  }}
                />
              )}
            </>
          )
        }
        secondchild={
          <Box>
            <SubmitButton
              sx={{ mt: 2, borderRadius: 16 }}
              variant="contained"
              onClick={() => setOpen(!open)}
            >
              <VscCheck />
            </SubmitButton>
          </Box>
        }
      />
    );
  else
    return (
      <Stack direction="row" spacing={0.5}>
        <Box width={"200vh"}>
          {ingredient
            ? form.products.ingredients[index].name
            : form.recipes.ingredients[index].name}
        </Box>
        <Box width={"200vh"}>
          {displayQuantity(
            ingredient
              ? form.products.ingredients[index].quantity
              : form.recipes.ingredients[index].quantity
          )}
        </Box>
        <Box width={"200vh"}>
          <VscEdit
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(!open)}
          />
        </Box>
        <Box width={"200vh"}>
          <VscTrash
            style={{ cursor: "pointer" }}
            onClick={() => {
              {
                if (ingredient) {
                  deleteIngredient();
                } else {
                  const old = [...form.recipes.ingredients];
                  old.splice(index, 1);
                  setForm({
                    ...form,
                    recipes: {
                      ...form.recipes,
                      ingredients: old,
                    },
                  });
                }
              }
            }}
          />
        </Box>
      </Stack>
    );
};

export const TextOrField = ({
  pw,
  num,
  value,
  setValue,
  mult,
}: {
  pw?: boolean;
  mult?: boolean;
  num?: boolean;
  value: string;
  setValue: (a: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  if (open)
    return (
      <GridRowTwoItems
        before={0}
        inbetween={0}
        firstlength={10}
        secondlength={2}
        firstchild={
          <TextField
            multiline={mult ? true : false}
            type={num ? "number" : pw ? "password" : "normal"}
            sx={{ height: 1 }}
            size="small"
            margin="dense"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
          />
        }
        secondchild={
          <SubmitButton
            color="inherit"
            sx={{ mt: 2, borderRadius: 16 }}
            variant="outlined"
            onClick={() => setOpen(!open)}
          >
            <VscCheck />
          </SubmitButton>
        }
      />
    );
  else
    return (
      <>
        <span>{pw ? "***" : value}</span>
        <VscEdit style={{ cursor: "pointer" }} onClick={() => setOpen(!open)} />
      </>
    );
};

export const TextOrQuantity = ({
  u,
  value,
  setValue,
}: {
  u: string;
  value: string;
  setValue: (a: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  if (open)
    return (
      <GridRowTwoItems
        before={0}
        inbetween={0}
        firstlength={10}
        secondlength={2}
        firstchild={
          <TextField
            sx={{ height: 1 }}
            size="small"
            margin="dense"
            required
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
          />
        }
        secondchild={
          <SubmitButton
            sx={{ mt: 2, borderRadius: 16 }}
            variant="outlined"
            onClick={() => setOpen(!open)}
          >
            <VscCheck />
          </SubmitButton>
        }
      />
    );
  else
    return (
      <>
        {`${value} ${u}`}
        <VscEdit style={{ cursor: "pointer" }} onClick={() => setOpen(!open)} />
      </>
    );
};

export const TextOrFieldEuro = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (a: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  if (open)
    return (
      <GridRowTwoItems
        before={0}
        inbetween={0}
        firstlength={10}
        secondlength={2}
        firstchild={
          <TextField
            InputProps={{
              inputMode: "numeric",
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            sx={{ height: 1 }}
            size="small"
            margin="dense"
            required
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
          />
        }
        secondchild={
          <SubmitButton
            sx={{ mt: 2, borderRadius: 16 }}
            variant="outlined"
            onClick={() => setOpen(!open)}
          >
            <VscCheck />
          </SubmitButton>
        }
      />
    );
  else
    return (
      <>
        {`€${Number(value).toFixed(2)}`}
        <VscEdit style={{ cursor: "pointer" }} onClick={() => setOpen(!open)} />
      </>
    );
};
