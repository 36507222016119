import { gql, useMutation } from "@apollo/client";
import { useSimpleQuery } from "../../../utils/apollo";
import { PriceForIngredient } from "./types/PriceForIngredient";
import {
  WritePriceForIngredient,
  WritePriceForIngredientVariables,
} from "./types/WritePriceForIngredient";

const PriceForIngredientQuery = gql`
  query PriceForIngredient($id: String!) {
    priceForIngredient(id: $id) {
      ingredientid
      ingredientname
      priceperdate {
        price
        supplierid
        suppliername
        date
        article
        quantity {
          quantity
          unit {
            label
            uri
          }
        }
      }
    }
  }
`;

const WritePriceForFoodMutation = gql`
  mutation WritePriceForIngredient($input: IngredientToPriceInput!) {
    writePriceForIngredient(input: $input)
  }
`;
export const usePriceForIngredientQuery = ({
  id,
  onCompleted,
}: {
  id: string;
  onCompleted: (result: PriceForIngredient) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(
    PriceForIngredientQuery,
    {
      variables: {
        id: id,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { loading, data, error, refetch };
};

export const useWritePriceForFoodMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addFood, { loading, error }] = useMutation<
    WritePriceForIngredient,
    WritePriceForIngredientVariables
  >(WritePriceForFoodMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    addFood,
    loading,
    error,
  };
};
