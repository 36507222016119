import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { GridRowOneItem } from "../../../../components/layouts/Grids";
import { createRange } from "../../../../components/utils/CreateRange";
import { dishes_dishes } from "../../../chefsbase/dishes/types/dishes";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import { DishForCourse } from "./DishForCourseForm";
import {
  Image,
  ImageBackdrop,
  ImageButton,
  ImageMarked,
  ImageSrc,
} from "../../../../components/image/ImageButton";
import { AddDishDialog } from "./AddDishDialog";
import { emptyAddDishVariables } from "../../../../components/defaults/AddDishForm/EmptyAddDishVariables";
import { useState } from "react";
import { NewDishDialog } from "./NewDishDialog";
import { useLazyRecipesQuery } from "../../../chefsbase/recipesAndIngredients/api";
import { recipeResultToQuantityToRecipe } from "../../components/mappers/RecipeToQuantityToRecipes";
import { recipes } from "../../../chefsbase/recipesAndIngredients/types/recipes";
import { measureInputOptions } from "../../../../components/defaults/portions/PortionInput";
import { ProcessingDialog } from "../../../../components/Loading/Processing";
import {
  backgroundContainer,
  backgroundField,
} from "../../../../components/layouts/Colors";
import { QuantityToRecipe } from "../../types/types";

export const CoursesForm = ({
  form,
  setForm,
  numberofcourses,
  dishes,
  loadingDishes,
  refetch,
}: {
  refetch: () => void;
  dishes: dishes_dishes[];
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  numberofcourses: number;
  loadingDishes: boolean;
}) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0.5}
      child={
        <>
          <Stack spacing={2}>
            {createRange(0, numberofcourses).map((index) => {
              return (
                <>
                  <FormField
                    label="Course"
                    value={form.courses[index] ? form.courses[index].name : ""}
                    setValue={(a: string) => {
                      const courses = [...form.courses];
                      courses[index] = {
                        name: a,
                        selectables: form.courses[index].selectables,
                        composables: form.courses[index].composables,
                      };
                      setForm({
                        ...form,
                        courses: courses,
                      });
                    }}
                  />
                  <Grid container spacing={1}>
                    {form.courses[index].selectables
                      .filter((sel) => sel.dishname !== "Test Dish")
                      .map((sel, i) => {
                        return (
                          <DishForCourse
                            sel={sel}
                            loadingDishes={loadingDishes}
                            dishes={dishes}
                            i={i}
                            index={index}
                            form={form}
                            setForm={(a) => setForm(a)}
                          />
                        );
                      })}

                    <AddableDishForCourse
                      refetch={refetch}
                      loadingDishes={loadingDishes}
                      dishes={dishes}
                      index={index}
                      form={form}
                      setForm={setForm}
                    />
                  </Grid>
                </>
              );
            })}
          </Stack>
        </>
      }
    />
  );
};

export const AddableDishForCourse = ({
  form,
  refetch,
  setForm,
  index,
  dishes,
  loadingDishes,
}: {
  refetch: () => void;
  loadingDishes: boolean;
  dishes: dishes_dishes[];
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const [openIndex, setopenIndex] = useState(0);
  const [openAddDish, setopenAddDish] = useState(false);
  const [openCreate, setopenCreate] = useState(false);
  const [newdish, setnewdish] = useState(emptyAddDishVariables);
  const [options, setoptions] = useState(
    // newdish.portions[0].quantity.unit.label
    measureInputOptions().map((i) => ({
      name: i.label,
      id: i.uri,
    }))
  );
  return (
    <>
      <Grid xs={3.5} sm={3.5} md={3}>
        <AddDishButton
          onclick={() => {
            setopenIndex(index);
            setopenAddDish(true);
          }}
        />
      </Grid>
      <AddDishDialog
        refetch={refetch}
        loadingDishes={loadingDishes}
        dishes={dishes}
        open={openAddDish}
        setopen={setopenAddDish}
        onSubmit={(addedDish) => {
          const old = [...form.courses[openIndex].selectables];
          old.push(addedDish);
          const oldcourses = [...form.courses];
          oldcourses[openIndex] = {
            ...oldcourses[openIndex],
            selectables: old,
          };
          setForm({
            ...form,
            courses: oldcourses,
          });
          setopenAddDish(false);
        }}
      />
    </>
  );
};

const AddDishButton = ({ onclick }: { onclick: () => void }) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card
        sx={{
          cursor: "pointer",
          height: "65vh",
          width: "35vh",
          backgroundColor: backgroundContainer,
        }}
      >
        <CardHeader></CardHeader>
        <CardContent>
          <GridRowOneItem
            before={1}
            after={0.5}
            child={
              <Typography
                style={{ fontFamily: "FairField Display, serif" }}
                align="center"
              >
                Add another one
              </Typography>
            }
          />
        </CardContent>
        <CardActionArea>
          <GridRowOneItem
            before={1}
            after={0.5}
            child={
              <Button
                sx={{
                  background: backgroundField,
                  borderRadius: 28,
                  width: "30vh",
                }}
                // size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  onclick();
                }}
              >
                Choose from your dishes
              </Button>
            }
          />
        </CardActionArea>
        <Box sx={{ mt: 25 }}>
          <GridRowOneItem
            before={1}
            after={0.5}
            child={
              <Button
                sx={{
                  background: backgroundField,
                  borderRadius: 28,
                  width: "30vh",
                }}
                // size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open("https://www.mychefsbase.com/adddish", "_blank");
                }}
              >
                Create a new Dish
              </Button>
            }
          />
        </Box>
      </Card>
    </Grid>
  );
};
