import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Divider,
  Grid,
  DialogActions,
  TextField,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { VscCheck, VscEdit } from "react-icons/vsc";
import { AddPageBox } from "../../../../components/Boxes";
import { SubmitButton } from "../../../../components/Buttons";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowTwoItems,
  GridRowThreeItems,
  GridRowOneItem,
  GridRowFourItems,
  GridRowFiveItems,
} from "../../../../components/layouts/Grids";
import { H6, H6Left } from "../../../../components/TextTypes";
import { getFraction } from "../../../../components/utils/GetFraction";
import { writeText } from "../../../../components/utils/WriteText";
import {
  scaleNutrition,
  totalNutrition,
} from "../../../clientbase/components/courses/Functions";
import { UpdateRecipeVariables } from "../addrecipes/types/UpdateRecipe";
import { recipes_recipes } from "../../recipesAndIngredients/types/recipes";
import { toNutritionInput } from "../../../clientbase/components/mappers/nutrition/ToNutritionInput";
import {
  EditRecipesAndIngredients,
  allergiesToHealthLabelInfo,
  writeHealthlabelInfo,
} from "../../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import {
  DishIngredientsInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import { ImageSection } from "../../../clientbase/createClientPage/forms/DishCard/ImageSection";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { readFileAsDataURL } from "../../../clientbase/components/imgReader/ReadFileAsDataUrl";
import {
  findMeasure,
  measureInputOptions,
} from "../../../../components/defaults/portions/PortionInput";
import { AllergyForm } from "../../../../components/edamam/GetNutrition";
import { healthlabelInfoToOutput } from "../../dishes/utils/MapToInput";
import {
  ToQuantityToNameIdAndNutritionInput,
  ToQuantityToNameIdAndNutritionInputProd,
} from "../components/Mapping";
import { emptyRecipeForm } from "../../../../components/defaults/UpdateRecipeForm/UpdateRecipeForm";
import { quantityToInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { ExtraRecipeDialog } from "../../dishes/dishDialog/DishContent";
import { useLazydIngredientsForRecipeQuery } from "../api";
import { Recipe } from "../types/Recipe";
import { buttonColor } from "../../../../components/layouts/Colors";

export const RecipeDialog = ({
  form,
  mutate,
  setForm,
  open,
  setOpen,
  filteredrecipes,
  isMobile,
}: {
  isMobile: boolean;
  filteredrecipes: recipes_recipes[];
  open: boolean;
  setOpen: (a: boolean) => void;
  form: UpdateRecipeVariables;
  setForm: (a: UpdateRecipeVariables) => void;
  mutate: (options?: any) => void;
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>(form.input.img);
  const [allergies, setallergies] = useState<AllergyForm>({
    food: [],
    recipe: form.recipes.ingredients.map((i) => ({
      id: i.id,
      healthLabelInfo: i.healthlabelInfo,
    })),
  });
  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);

        setForm({
          ...form,
          input: {
            ...form.input,
            img: urls,
          },
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };
  const [edit, setEdit] = useState(false);
  const [quantityView, setQuantityView] = useState(form.input.quantity);
  const frac = getFraction(form.input.quantity, quantityView);
  const recipes = filteredrecipes.map((i) => ({
    quantity: {
      quantity: i.quantity.quantity,
      unit: {
        label: i.quantity.unit.label,
        weight: i.quantity.unit.weight,
        uri: i.quantity.unit.uri,
      },
    },
    recipe: {
      healthLabelInfo: healthlabelInfoToOutput(i.healthLabelInfo),
      name: i.name,
      id: i.id,
      nutrition: {
        quantity: {
          quantity: i.quantity.quantity,
          unit: i.quantity.unit,
        },
        nutrition: toNutritionInput(i.nutrition.nutrition),
      },
    },
  }));
  if (isMobile)
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogActions>
          <SubmitButton onClick={() => setOpen(false)} variant="contained">
            Close
          </SubmitButton>
        </DialogActions>
        <DialogTitle>
          <GridRowOneItem
            before={1}
            after={0.5}
            child={
              <>
                {`${form.input.name}`} <br /> {`(${form.input.type})`}
              </>
            }
          />
        </DialogTitle>
        <DialogContent>
          {
            <>
              <GridRowOneItem
                before={0.5}
                after={0.5}
                child={
                  <AddPageBox
                    childComp={
                      <>
                        <GridRowOneItem
                          before={0}
                          after={0}
                          child={
                            <>
                              <Box
                                sx={{
                                  mt: 2,
                                  backgroundColor: buttonColor,
                                  borderRadius: 10,
                                  width: "38vh",
                                }}
                              >
                                <GridRowTwoItems
                                  before={0}
                                  firstlength={6}
                                  secondlength={5}
                                  inbetween={0.5}
                                  firstchild={<H6 title="Ingredients:" />}
                                  secondchild={
                                    form.products &&
                                    form.products.ingredients.length > 0 && (
                                      <H6Left
                                        title={`${scaleNutrition(
                                          frac,
                                          totalNutrition(
                                            form.products.ingredients.map(
                                              (i) => {
                                                const fracForIngredient =
                                                  i.quantity.unit.label.toLowerCase() ===
                                                  "gram"
                                                    ? i.quantity.quantity / 100
                                                    : 1; //Temporary. NutritionInput here should contain quantity

                                                return scaleNutrition(
                                                  fracForIngredient,
                                                  i.nutrition
                                                );
                                              }
                                            )
                                          )
                                        ).kcal.toFixed(2)} kcal`}
                                      />
                                    )
                                  }
                                />
                              </Box>
                              <Box
                                sx={{
                                  mt: 0,
                                  width: "38vh",
                                }}
                              >
                                {form.products.ingredients.map((i) => {
                                  const fracForIngredient =
                                    i.quantity.unit.label.toLowerCase() ===
                                    "gram"
                                      ? i.quantity.quantity / 100
                                      : 1; //Temporary. NutritionInput here should contain quantity
                                  return (
                                    <Typography
                                      style={{
                                        fontFamily: "FairField Display, serif",
                                      }}
                                    >
                                      <GridRowFiveItems
                                        before={0.5}
                                        firstlength={3.5}
                                        secondlength={1.5}
                                        thirdlength={2.5}
                                        fourthlength={2}
                                        fifthlength={0.5}
                                        inbetweenfirsttwo={0}
                                        inbetweensecondtwo={0}
                                        inbetweenthirdtwo={0}
                                        inbetweenfourthtwo={0}
                                        firstchild={<Box>{i.name}</Box>}
                                        secondchild={(
                                          i.quantity.quantity * frac
                                        ).toFixed(2)}
                                        thirdchild={i.quantity.unit.label}
                                        fourthchild={`${(
                                          i.nutrition.kcal *
                                          frac *
                                          fracForIngredient
                                        ).toFixed(2)}`}
                                        fifthchild="kcal"
                                      />
                                    </Typography>
                                  );
                                })}

                                {form.recipes.ingredients.map((i) => (
                                  <RecipeRow r={i} frac={frac} />
                                ))}
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  backgroundColor: buttonColor,
                                  borderRadius: 10,
                                  width: "38vh",
                                }}
                              >
                                <H6 title="Method" />
                              </Box>
                              <Box
                                sx={{
                                  width: "38vh",
                                }}
                              >
                                {writeText(form.input.method)}
                              </Box>
                              <Box
                                sx={{
                                  mt: 2,
                                  backgroundColor: buttonColor,
                                  borderRadius: 10,
                                  width: "38vh",
                                }}
                              >
                                <H6 title="Allergies" />
                              </Box>
                              <Box
                                sx={{
                                  width: "38vh",
                                }}
                              >
                                {writeHealthlabelInfo(
                                  form.healthLabels!,
                                  isMobile
                                )}
                              </Box>

                              <Box
                                sx={{
                                  mt: 2,
                                  backgroundColor: buttonColor,
                                  borderRadius: 10,
                                  width: "38vh",
                                }}
                              >
                                <H6 title="Images:" />
                              </Box>
                              <Box
                                sx={{
                                  width: "38vh",
                                }}
                              >
                                <Stack direction="row">
                                  {form.input.img.map((i) => (
                                    <Box>
                                      <DisplayImage
                                        key={i}
                                        img={i}
                                        width={100}
                                        height={100}
                                      />
                                    </Box>
                                  ))}
                                </Stack>
                              </Box>
                            </>
                          }
                        />
                      </>
                    }
                  />
                }
              />
            </>
          }
        </DialogContent>
      </Dialog>
    );
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <GridRowTwoItems
          firstchild={
            <>
              {!edit ? (
                <GridRowThreeItems
                  before={1}
                  inbetweenfirsttwo={0.5}
                  inbetweensecondtwo={0.5}
                  firstchild={`${form.input.name} (${form.input.type})`}
                  secondchild={
                    <FormField
                      num={true}
                      value={String(quantityView.quantity)}
                      setValue={(a: string) => {
                        setQuantityView({
                          quantity: Number(a),
                          unit: quantityView.unit,
                        });
                      }}
                    />
                  }
                  thirdchild={quantityView.unit.label}
                  firstlength={5}
                  secondlength={2.5}
                  thirdlength={2.5}
                />
              ) : (
                <GridRowThreeItems
                  before={1}
                  inbetweenfirsttwo={0.5}
                  inbetweensecondtwo={0.5}
                  firstlength={5}
                  secondlength={2.5}
                  thirdlength={2.5}
                  firstchild={
                    <GridRowTwoItems
                      firstchild={
                        <FormField
                          value={form.input.name}
                          setValue={(a: string) =>
                            setForm({
                              ...form,
                              input: {
                                ...form.input,
                                name: a,
                              },
                            })
                          }
                        />
                      }
                      secondchild={
                        <FormField
                          value={form.input.type ? form.input.type : ""}
                          setValue={(a: string) =>
                            setForm({
                              ...form,
                              input: {
                                ...form.input,
                                type: a,
                              },
                            })
                          }
                        />
                      }
                      firstlength={5.5}
                      secondlength={5.5}
                      before={0}
                      inbetween={1}
                    />
                  }
                  secondchild={
                    <FormField
                      value={String(form.input.quantity.quantity)}
                      num={true}
                      setValue={(a: string) =>
                        setForm({
                          ...form,
                          recipes: {
                            ...form.recipes,
                            dishPortion: {
                              ...form.recipes.dishPortion,
                              quantity: {
                                quantity: Number(a),
                                unit: form.input.quantity.unit,
                              },
                            },
                          },
                          products: {
                            ...form.products,
                            dishPortion: {
                              ...form.products.dishPortion,
                              quantity: {
                                quantity: Number(a),
                                unit: form.input.quantity.unit,
                              },
                            },
                          },
                          input: {
                            ...form.input,
                            quantity: {
                              quantity: Number(a),
                              unit: form.input.quantity.unit,
                            },
                          },
                        })
                      }
                    />
                  }
                  thirdchild={
                    <>
                      <FormSelect
                        value={form.input.quantity.unit.label}
                        options={measureInputOptions().map((q) => ({
                          id: q.uri,
                          name: q.label,
                        }))}
                        setValue={(a: string) =>
                          setForm({
                            ...form,
                            input: {
                              ...form.input,
                              quantity: {
                                ...form.input.quantity,
                                unit: findMeasure(a, undefined),
                              },
                            },
                          })
                        }
                      />
                    </>
                  }
                />
              )}
            </>
          }
          secondchild={
            <>
              {edit ? (
                <SubmitButton
                  variant="contained"
                  onClick={() => {
                    mutate({
                      variables: toUpdateRecipeForm(form),
                    });
                    setEdit(!edit);
                  }}
                >
                  <VscCheck />
                </SubmitButton>
              ) : (
                <SubmitButton
                  variant="contained"
                  onClick={() => setEdit(!edit)}
                >
                  <VscEdit />
                </SubmitButton>
              )}
            </>
          }
          firstlength={10}
          secondlength={2}
          before={0}
          inbetween={0}
        />
      </DialogTitle>
      <DialogContent>
        {edit ? (
          <>
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <AddPageBox
                  childComp={
                    <>
                      <Grid xs={12} sx={{ backgroundColor: buttonColor }}>
                        <H6 title="Allergies:" />
                      </Grid>
                      <GridRowOneItem
                        before={0.5}
                        after={0.5}
                        child={writeHealthlabelInfo(form.healthLabels!)}
                      />

                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={<Divider />}
                      />
                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={
                          <Table>
                            <TableHead>
                              <TableCell
                                colSpan={3}
                                sx={{
                                  backgroundColor: buttonColor,
                                }}
                              >
                                <H6 title="Ingredients" />
                              </TableCell>
                              <TableCell
                                colSpan={3}
                                sx={{
                                  backgroundColor: buttonColor,
                                }}
                              ></TableCell>
                            </TableHead>
                            {
                              <EditRecipesAndIngredients
                                allergies={allergies}
                                setallergies={setallergies}
                                recipes={recipes}
                                ingredientsFromForm={form.products}
                                setIngredientsFromForm={(
                                  a: DishIngredientsInput
                                ) =>
                                  setForm({
                                    ...form,
                                    healthLabels:
                                      allergiesToHealthLabelInfo(allergies),
                                    products: a,
                                  })
                                }
                                recipesFromForm={form.recipes}
                                setRecipesFromForm={(a: DishIngredientsInput) =>
                                  setForm({
                                    ...form,
                                    recipes: a,
                                    healthLabels:
                                      allergiesToHealthLabelInfo(allergies),
                                  })
                                }
                              />
                            }
                          </Table>
                        }
                      />
                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={<Divider />}
                      />

                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: buttonColor,
                          borderRadius: 10,
                          width: "100vh",
                        }}
                      >
                        <H6 title="Method" />
                      </Box>
                      <FormField
                        value={form.input.method}
                        mult={true}
                        setValue={(a: string) =>
                          setForm({
                            ...form,
                            input: { ...form.input, method: a },
                          })
                        }
                      />
                      <ImageSection
                        handleChangeFile={(a) => handleChangeFile(a)}
                        imageUrls={imageUrls}
                        urls={returnStringsIfNotNullOrUndefined(form.input.img)}
                        setNoPic={() => {
                          setImageUrls([""]);
                          setForm({
                            ...form,
                            input: {
                              ...form.input,
                              img: [""],
                            },
                          });
                        }}
                      />
                    </>
                  }
                />
              }
            />
          </>
        ) : (
          <>
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <AddPageBox
                  childComp={
                    <>
                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={
                          <Box
                            sx={{
                              mt: 2,
                              borderRadius: 10,
                              width: "100vh",
                            }}
                          >
                            <Box
                              sx={{
                                mt: 2,
                                backgroundColor: buttonColor,
                                borderRadius: 10,
                                width: "100vh",
                              }}
                            >
                              <GridRowTwoItems
                                before={0}
                                firstlength={6}
                                secondlength={5}
                                inbetween={0.5}
                                firstchild={<H6 title="Ingredients:" />}
                                secondchild={
                                  form.products &&
                                  form.products.ingredients.length > 0 && (
                                    <H6Left
                                      title={`${scaleNutrition(
                                        frac,
                                        totalNutrition(
                                          form.products.ingredients.map((i) => {
                                            const fracForIngredient =
                                              i.quantity.unit.label.toLowerCase() ===
                                              "gram"
                                                ? i.quantity.quantity / 100
                                                : 1; //Temporary. NutritionInput here should contain quantity

                                            return scaleNutrition(
                                              fracForIngredient,
                                              i.nutrition
                                            );
                                          })
                                        )
                                      ).kcal.toFixed(2)} kcal`}
                                    />
                                  )
                                }
                              />
                            </Box>
                            <Box
                              sx={{
                                mt: 0,
                                width: "100vh",
                              }}
                            >
                              {form.products.ingredients.map((i) => {
                                const fracForIngredient =
                                  i.quantity.unit.label.toLowerCase() === "gram"
                                    ? i.quantity.quantity / 100
                                    : 1; //Temporary. NutritionInput here should contain quantity
                                return (
                                  <Typography
                                    style={{
                                      fontFamily: "FairField Display, serif",
                                    }}
                                  >
                                    <GridRowFiveItems
                                      before={0.5}
                                      firstlength={3.5}
                                      secondlength={1.5}
                                      thirdlength={2.5}
                                      fourthlength={2}
                                      fifthlength={0.5}
                                      inbetweenfirsttwo={0}
                                      inbetweensecondtwo={0}
                                      inbetweenthirdtwo={0}
                                      inbetweenfourthtwo={0}
                                      firstchild={<Box>{i.name}</Box>}
                                      secondchild={(
                                        i.quantity.quantity * frac
                                      ).toFixed(2)}
                                      thirdchild={i.quantity.unit.label}
                                      fourthchild={`${(
                                        i.nutrition.kcal *
                                        frac *
                                        fracForIngredient
                                      ).toFixed(2)}`}
                                      fifthchild="kcal"
                                    />
                                  </Typography>
                                );
                              })}
                              {form.recipes.ingredients.map((i) => (
                                <RecipeRow r={i} frac={frac} />
                              ))}
                            </Box>
                          </Box>
                        }
                      />
                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={<Divider />}
                      />
                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: buttonColor,
                          borderRadius: 10,
                          width: "100vh",
                        }}
                      >
                        <H6 title="Method" />
                      </Box>

                      <GridRowOneItem
                        before={0}
                        after={0}
                        child={<Divider />}
                      />
                      <Box
                        sx={{
                          width: "100vh",
                        }}
                      >
                        {writeText(form.input.method)}
                      </Box>
                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: buttonColor,
                          borderRadius: 10,
                          width: "100vh",
                        }}
                      >
                        <H6 title="Allergies" />
                      </Box>
                      <Box
                        sx={{
                          width: "100vh",
                        }}
                      >
                        {writeHealthlabelInfo(form.healthLabels!)}
                      </Box>
                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: buttonColor,
                          borderRadius: 10,
                          width: "100vh",
                        }}
                      >
                        <H6 title="Images" />
                      </Box>
                      <Box
                        sx={{
                          width: "100vh",
                        }}
                      >
                        {form.input.img.map((i) => (
                          <DisplayImage
                            key={i}
                            img={i}
                            width={100 / form.input.img.length}
                            height={100 / form.input.img.length}
                          />
                        ))}
                      </Box>
                    </>
                  }
                />
              }
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SubmitButton
          variant="contained"
          onClick={() => {
            setEdit(false);
            setOpen(false);
          }}
        >
          Close
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export const toUpdateRecipeForm = (form: UpdateRecipeVariables) => {
  return {
    ...form,
    recipes: {
      ...form.recipes,
      ingredients: form.recipes.ingredients.filter((r) => r.id !== ""),
    },
    products: {
      ...form.products,
      ingredients: form.products.ingredients.filter((r) => r.id !== ""),
    },
  };
};

export const RecipeRow = ({
  r,
  frac,
}: {
  r: QuantityToNameIdAndNutritionInput;
  frac: number;
}) => {
  const [openRecipe, setopenRecipe] = useState(false);
  const [updateRecipeForm, setRecipeForm] = useState<UpdateRecipeVariables>({
    ...emptyRecipeForm,
    input: {
      ...emptyRecipeForm.input,
      name: r.name,
      quantity: r.quantity,
    },
  });
  const { getIngredientsForRecipe, loading } =
    useLazydIngredientsForRecipeQuery({
      id: r.id,
      quantity: r.quantity,
      onCompleted: (result: Recipe) =>
        setRecipeForm({
          ...updateRecipeForm,
          products: {
            dishPortion: {
              name: result.recipe.products.dishPortionOutput.name,
              quantity: quantityToInput(
                result.recipe.products.dishPortionOutput.quantity
              ),
            },
            ingredients: ToQuantityToNameIdAndNutritionInputProd(
              result.recipe.products && result.recipe.products.foods
                ? result.recipe.products.foods
                : []
            ),
          },
          recipes: {
            dishPortion: {
              name: result.recipe.recipes.dishPortion.name,
              quantity: quantityToInput(
                result.recipe.recipes.dishPortion.quantity
              ),
            },
            ingredients: ToQuantityToNameIdAndNutritionInput(
              result.recipe.recipes && result.recipe.recipes.recipes
            ),
          },
        }),
    });
  return (
    <>
      <ExtraRecipeDialog
        updateRecipeForm={updateRecipeForm}
        setopen={setopenRecipe}
        open={openRecipe}
        loading={loading}
      />

      <Typography
        style={{
          fontFamily: "FairField Display, serif",
        }}
      >
        <GridRowFiveItems
          before={0.5}
          firstlength={3.5}
          secondlength={1.5}
          thirdlength={2.5}
          fourthlength={2}
          fifthlength={0.5}
          inbetweenfirsttwo={0}
          inbetweensecondtwo={0}
          inbetweenthirdtwo={0}
          inbetweenfourthtwo={0}
          firstchild={<Box>{r.name}</Box>}
          secondchild={(r.quantity.quantity * frac).toFixed(2)}
          thirdchild={r.quantity.unit.label}
          fourthchild={`${(r.nutrition.kcal * frac).toFixed(2)}`}
          fifthchild="kcal"
        />
      </Typography>
    </>
  );
};
