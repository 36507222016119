import * as React from "react";
import styled from "@emotion/styled";
import JoinWaitingList from "../components/homescreen/components/JoinWaitingList";

export const Component3 = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY - 1500, // Adjust the 700 value to scroll down by a different amount
      behavior: "smooth",
    });
  };

  const handleHomeClick = () => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth",
    });
  };

  const handleContactClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: "smooth",
    });
  };
  const handleEmailClick = () => {
    window.location.href = "mailto:mychefsbase@gmail.com";
  };
  return (
    <Div>
      <Div2>A Price To Suit Everyone</Div2>
      <Div3>
        Interested in our products?
        <br />
        Contact us, benefit from a free trial period, and enjoy a monthly low
        price
      </Div3>
      <Div4>$</Div4>
      <JoinWaitingList />
      {/* <Div5>Request Now</Div5> */}
      <Div6>
        <Div7>
          <Div8>MyChefsbase</Div8>
          <Div8>+31 6 21 94 64 91</Div8>
          <Div8 onClick={handleEmailClick}>mychefsbase@gmail.com</Div8>
          <Div8>Boschstraat 88A02, 6211AZ Maastricht</Div8>
          <Div8>C.o.c no: 91667089</Div8>
          <Div8>©2023 MyChefsbase</Div8>
          <Div14 />
          <Div15>
            <Div16 onClick={handleHomeClick}>Home</Div16>
            <Div17 onClick={handleScroll}>How it Works</Div17>
            <Div18 onClick={handleContactClick}>Contact</Div18>
          </Div15>
        </Div7>
      </Div6>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  padding: 0px 0px 0;
`;

const Div2 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 25px;
  font: 36px/133% Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Div3 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 0px;
  width: 600px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  color: var(--Colour-Main-Blue-700, #222f65);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  letter-spacing: 1px;
  margin-top: 0px;
  font: 50px/132% Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 0px;
    font-size: 40px;
  }
`;

const Div6 = styled.div`
  background-color: #faf4ec;
  align-self: stretch;
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 6px 60px 39px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 10px;
    padding: 0 20px;
  }
`;

const Div7 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1110px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div8 = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  color: var(--Colour-Main-Grey-400, #939ea4);
  font-feature-settings: "clig" off, "liga" off;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div9 = styled.div`
  color: var(--Colour-Main-Grey-400, #939ea4);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 7px;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div11 = styled.div`
  color: var(--Colour-Main-Grey-400, #939ea4);
  font-feature-settings: "clig" off, "liga" off;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 14px/171% Roboto, sans-serif;
`;

const Div12 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  border-radius: 2px;
  background-color: var(--Colour-Main-Blue-800, #111b47);
  color: var(--colour-secondary-white-100-general, #fff);
  text-align: center;
  justify-content: center;
  padding: 9px 17px;
  font: 12px/150% Roboto, sans-serif;
`;

const Div13 = styled.div`
  color: var(--Colour-Main-Grey-400, #939ea4);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 24px;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div14 = styled.div`
  background-color: #cdd1d4;
  margin-top: 8px;
  height: 1px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div15 = styled.div`
  display: flex;
  margin-top: 27px;
  width: 244px;
  max-width: 100%;
  gap: 20px;
  font-size: 14px;
  color: var(--Colour-Main-Blue-300, #929ecc);
  font-weight: 400;
  white-space: nowrap;
  line-height: 171%;
  justify-content: space-between;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div16 = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
`;

const Div17 = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
`;

const Div18 = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
`;
