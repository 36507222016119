import { gql, useMutation } from "@apollo/client";
import { useSimpleQuery } from "../utils/apollo";
import { account } from "./types/account";
import { updateAccount, updateAccountVariables } from "./types/updateAccount";

export const accountQuery = gql`
  query account {
    account {
      email
      password
      id
      accesstoken
      restaurantName
      description
      location
      fullName
      mcbpackage
    }
  }
`;

export const accountMutation = gql`
  mutation updateAccount($input: AccountInput!) {
    updateAccount(input: $input)
  }
`;

export const useAccountQuery = ({
  onCompleted,
}: {
  onCompleted: (account: account) => void;
}) => {
  const { loading, data, refetch, error } = useSimpleQuery(accountQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, refetch, error };
};

export const useUpdateAccountMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updateAccount, { loading, error }] = useMutation<
    updateAccount,
    updateAccountVariables
  >(accountMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    updateAccount,
    loading,
    error,
  };
};
