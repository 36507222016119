import { Box } from "@mui/material";
import { SubmitButton } from "../../../../../components/Buttons";
import { H5 } from "../../../../../components/TextTypes";
import { GridRowOneItem } from "../../../../../components/layouts/Grids";
import { AddMenuVariables } from "../../../../chefsbase/menus/addMenu/types/AddMenu";
import CircularProgress from "@mui/material/CircularProgress";

export const FormHeader = ({
  loading,
  mutate,
  form,
}: {
  loading?: boolean;
  form: AddMenuVariables;
  mutate: (form: AddMenuVariables) => void;
}) => {
  return (
    <Box sx={{ mt: 5 }}>
      <GridRowOneItem
        before={0}
        after={0}
        child={<H5 title="Create Your Menu" />}
      />
      <GridRowOneItem
        before={3}
        after={3}
        child={
          <SubmitButton
            disabled={checkForDisabled(form)}
            fullWidth
            variant="contained"
            onClick={() =>
              // console.log(form)
              mutate(form)
            }
          >
            {loading && <CircularProgress />}
            Save Menu
          </SubmitButton>
        }
      />
    </Box>
  );
};

const checkForDisabled = (a: AddMenuVariables): boolean => {
  return false;
  if (a.input.name === "") return true;
  if (a.courses.map((c) => c.name).includes("")) return true;
  return false;
};
