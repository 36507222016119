import { recipes } from "../../../chefsbase/recipesAndIngredients/types/recipes";
import {
  measureToMeasureInput,
  quantityToInput,
} from "../../createClientPage/forms/DishCard/Mappers";
import { toNutritionInput } from "./nutrition/ToNutritionInput";
import { QuantityToRecipe } from "../../types/types";
import { healthlabelInfoToOutput } from "../../../chefsbase/dishes/utils/MapToInput";

export const recipeResultToQuantityToRecipe = (
  result: recipes
): QuantityToRecipe[] => {
  return result.recipes.map((r) => ({
    recipe: {
      healthLabelInfo: healthlabelInfoToOutput(r.healthLabelInfo),
      id: r.id,
      name: r.name,
      nutrition: {
        quantity: quantityToInput(r.nutrition.quantity),
        nutrition: toNutritionInput(r.nutrition.nutrition),
      },
    },
    quantity: {
      quantity: r.quantity.quantity,
      unit: measureToMeasureInput(r.quantity.unit),
    },
  }));
};
