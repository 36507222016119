import React from "react";
import { FormFieldInputAdornmentNumber } from "../../../../components/forms/singleForms/FormField";
import { MinOrMax, MicroMacroField } from "./types";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";

export const kcalOptions = [
  0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
  1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 4000,
  5000,
].map((a) => ({
  name: String(a),
  id: String(a),
}));

export const priceOptions = [
  0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
  1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 4000,
  5000,
].map((a) => ({
  name: String(a / 100),
  id: String(a / 100),
}));

export const microOptions = [
  0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000,
  1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 4000,
  5000,
].map((a) => ({
  name: String(a / 10),
  id: String(a / 10),
}));

export const MicroMacroFormField = ({
  minOrMax,
  micro,
  microMacroForm,
  setValue,
}: {
  minOrMax: MinOrMax;
  micro: string;
  microMacroForm: MicroMacroField;
  setValue: (a: MicroMacroField) => void;
}) => {
  var result;
  switch (micro) {
    case "Kcal":
      result = (
        <FormSelect
          fw
          options={kcalOptions}
          label={minOrMax === MinOrMax.MIN ? `${micro}` : undefined}
          value={
            minOrMax === MinOrMax.MIN
              ? String(microMacroForm.kcal.min)
              : String(microMacroForm.kcal.max)
          }
          setValue={(a) => {
            setValue({
              kcal: {
                min:
                  minOrMax === MinOrMax.MIN
                    ? Number(a)
                    : microMacroForm.kcal.min,
                max:
                  minOrMax === MinOrMax.MAX
                    ? Number(a)
                    : microMacroForm.kcal.max,
              },
              prottotal: {
                min: microMacroForm.prottotal.min,
                max: microMacroForm.prottotal.max,
              },
              carbs: {
                min: microMacroForm.carbs.min,
                max: microMacroForm.carbs.max,
              },
              fats: {
                min: microMacroForm.fats.min,
                max: microMacroForm.fats.max,
              },
              price: {
                min: microMacroForm.price.min,
                max: microMacroForm.price.max,
              },
            });
          }}
        />
      );
      break;
    case "Prot":
      result = (
        <FormSelect
          fw
          options={microOptions}
          label={minOrMax === MinOrMax.MIN ? `${micro}` : undefined}
          value={
            minOrMax === MinOrMax.MIN
              ? String(microMacroForm.prottotal.min)
              : String(microMacroForm.prottotal.max)
          }
          setValue={(a) => {
            setValue({
              prottotal: {
                min:
                  minOrMax === MinOrMax.MIN
                    ? Number(a)
                    : microMacroForm.prottotal.min,
                max:
                  minOrMax === MinOrMax.MAX
                    ? Number(a)
                    : microMacroForm.prottotal.max,
              },
              kcal: {
                min: microMacroForm.kcal.min,
                max: microMacroForm.kcal.max,
              },
              carbs: {
                min: microMacroForm.carbs.min,
                max: microMacroForm.carbs.max,
              },
              fats: {
                min: microMacroForm.fats.min,
                max: microMacroForm.fats.max,
              },
              price: {
                min: microMacroForm.price.min,
                max: microMacroForm.price.max,
              },
            });
          }}
        />
      );
      break;
    case "Carbs":
      result = (
        <FormSelect
          fw
          options={microOptions}
          label={minOrMax === MinOrMax.MIN ? `${micro}` : undefined}
          value={
            minOrMax === MinOrMax.MIN
              ? String(microMacroForm.carbs.min)
              : String(microMacroForm.carbs.max)
          }
          setValue={(a) => {
            setValue({
              carbs: {
                min:
                  minOrMax === MinOrMax.MIN
                    ? Number(a)
                    : microMacroForm.carbs.min,
                max:
                  minOrMax === MinOrMax.MAX
                    ? Number(a)
                    : microMacroForm.carbs.max,
              },
              kcal: {
                min: microMacroForm.kcal.min,
                max: microMacroForm.kcal.max,
              },
              prottotal: {
                min: microMacroForm.prottotal.min,
                max: microMacroForm.prottotal.max,
              },
              fats: {
                min: microMacroForm.fats.min,
                max: microMacroForm.fats.max,
              },
              price: {
                min: microMacroForm.price.min,
                max: microMacroForm.price.max,
              },
            });
          }}
        />
      );
      break;
    case "Fat":
      result = (
        <FormSelect
          fw
          options={microOptions}
          label={minOrMax === MinOrMax.MIN ? `${micro}` : undefined}
          value={
            minOrMax === MinOrMax.MIN
              ? String(microMacroForm.carbs.min)
              : String(microMacroForm.carbs.max)
          }
          setValue={(a) => {
            setValue({
              fats: {
                min:
                  minOrMax === MinOrMax.MIN
                    ? Number(a)
                    : microMacroForm.fats.min,
                max:
                  minOrMax === MinOrMax.MAX
                    ? Number(a)
                    : microMacroForm.fats.max,
              },
              kcal: {
                min: microMacroForm.kcal.min,
                max: microMacroForm.kcal.max,
              },
              prottotal: {
                min: microMacroForm.prottotal.min,
                max: microMacroForm.prottotal.max,
              },
              carbs: {
                min: microMacroForm.carbs.min,
                max: microMacroForm.carbs.max,
              },
              price: {
                min: microMacroForm.price.min,
                max: microMacroForm.price.max,
              },
            });
          }}
        />
      );
      break;
    default:
      result = (
        <FormSelect
          fw
          options={priceOptions}
          label={minOrMax === MinOrMax.MIN ? `${micro}` : undefined}
          value={
            minOrMax === MinOrMax.MIN
              ? String(microMacroForm.price.min)
              : String(microMacroForm.price.max)
          }
          setValue={(a) => {
            setValue({
              price: {
                min:
                  minOrMax === MinOrMax.MIN
                    ? Number(a)
                    : microMacroForm.price.min,
                max:
                  minOrMax === MinOrMax.MAX
                    ? Number(a)
                    : microMacroForm.price.max,
              },
              prottotal: {
                min: microMacroForm.prottotal.min,
                max: microMacroForm.prottotal.max,
              },
              carbs: {
                min: microMacroForm.carbs.min,
                max: microMacroForm.carbs.max,
              },
              fats: {
                min: microMacroForm.fats.min,
                max: microMacroForm.fats.max,
              },
              kcal: {
                min: microMacroForm.kcal.min,
                max: microMacroForm.kcal.max,
              },
            });
          }}
        />
      );
  }

  return result;
};
