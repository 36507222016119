import {
  NutritionInput,
  OptionsInput,
  QuantityInput,
} from "../../../globalTypes";
import { MicroMacroField } from "../components/MicroMacroField/types";
import { NametoIdToNutrition } from "../../../components/types/types";

export type Dish = {
  name: string;
  id: string;
  type: string;
};
export type PortionWithNutrition = {
  nutrition: NutritionInput;
  name: string;
  quantity: QuantityInput;
  price?: number;
};
export type DishToPortion = {
  dish: Dish;
  portion: PortionWithNutrition;
};
export type DishForSlides = {
  name: string;
  img: string | null;
  id: string;
  portions: PortionWithNutrition[];
  options: OptionsInput[];
};
export type MappedCombos = {
  round: number;
  options: DishForSlides[];
};
export type Rounds = {
  firstoptions: DishForSlides[];
  secondoptions: DishForSlides[];
};

export interface SlideDishesProps {
  setPortion: (a: PortionWithNutrition) => void;
  indexToPortion: IndexToPortion;
  input: DishForSlides[];
  micromacroform: MicroMacroField;
  options: string[];
  index: number;
  setindex: (a: number) => void;
}

export type IndexToPortion = {
  index: number;
  portion: PortionWithNutrition;
};
export type DishToIndexToPortion = {
  index: number;
  portion: PortionWithNutrition;
  dish: DishForSlides;
};
export type QuantityToRecipe = {
  quantity: QuantityInput;
  recipe: NametoIdToNutrition;
};
export type MeasurementInfo = {
  serving?: number;
  cup?: number;
  whole?: number;
  teaspoon?: number;
  tablespoon?: number;
};

export enum BaseOrVar {
  BASE,
  VAR,
}
