type Config = {
    endpoint: string;
  };
  
  if (process.env.REACT_APP_API_URL == null) {
    throw new Error(
      "You must set the environmental variable REACT_APP_API_URL in the form http://url.com/graphql"
    );
  }
  
  const url: string = process.env.REACT_APP_API_URL;
  
  const setup: Config = {
    endpoint: `${url}`,
  };
  
  export default setup;
  
  export const REACT_APP_AUTH0_DOMAIN = "dev-4qxzf6zv.us.auth0.com";
  export const REACT_APP_AUTH0_CLIENT_ID = "KgdyUhOE3qMfm4sTo3YElw5QxRisHLzu";
  
  export const REACT_APP_AUTH0_AUDIENCE = "mychefsbase";
  export const REACT_APP_API_SERVER_URL = "http://localhost:3000";
  