import { Divider, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { H5 } from "../../../../components/TextTypes";
import { NametoId } from "../../../../components/types/types";
import { UnitOptions } from "../../../../components/utils/UnitFunctions";
import { QuantityInput } from "../../../../globalTypes";
import { RecipeInfoForm } from "./types";
import { findMeasure } from "../../../../components/defaults/portions/PortionInput";

import { removeDuplicateOptions } from "../../../../utils/removeDuplicateOptions";

export const CreateYourRecipe = ({
  info,
  setInfo,
}: {
  info: RecipeInfoForm;
  setInfo: (a: RecipeInfoForm) => void;
}) => {
  const [amount, setamount] = useState(String(info.quantity.quantity));

  return (
    <>
      <GridRowOneItem
        before={0}
        after={0}
        child={<H5 title="Create Your Recipe" />}
      />
      <GridRowOneItem before={0} after={0} child={<Divider />} />
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <FormField
            label="Name"
            value={info.name}
            setValue={(name) => {
              setInfo({
                ...info,
                name: name,
              });
            }}
          />
        }
      />
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <FormField
            label="Type"
            value={info.type}
            setValue={(type) => {
              setInfo({
                ...info,
                type: type,
              });
            }}
          />
        }
      />
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <FormQuantity
            amount={amount}
            setamount={setamount}
            label={"Quantity"}
            options={UnitOptions}
            value={info.quantity}
            setValue={(a: QuantityInput) => {
              setInfo({
                ...info,
                quantity: a,
              });
            }}
          />
        }
      />
    </>
  );
};

const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

export const stringToNumber = (a: string): number => {
  return Number(a);
};
export const FormQuantity = ({
  w,
  label,
  value,
  setValue,
  options,
  error,
  amount,
  setamount,
}: {
  w?: boolean;
  amount: string;
  setamount: (a: string) => void;
  error?: boolean;
  label?: string;
  value: QuantityInput;
  options: NametoId[];
  setValue: (value: QuantityInput) => void;
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setamount(event.target.value);
    setValue({
      quantity: stringToNumber(event.target.value),
      unit: value.unit,
    });
  };
  return (
    <GridRowTwoItems
      before={0}
      inbetween={w ? 0 : 1}
      firstlength={w ? 10 : 5}
      secondlength={w ? 2 : 5}
      firstchild={
        <FormField
          required={error}
          label={label ? "Quantity" : undefined}
          num={true}
          value={amount}
          setValue={(name) => {
            setamount(name);
            setValue({
              quantity: stringToNumber(name),
              unit: value.unit,
            });
          }}
          onChange={handleInputChange} // Handle input change
        />
      }
      secondchild={
        <FormSelect
          error={error}
          label={label ? "Unit" : undefined}
          mt={1}
          value={value.unit.label}
          setValue={(name) =>
            setValue({
              quantity: value.quantity,
              unit: findMeasure(name, undefined),
            })
          }
          options={removeDuplicateOptions(options)}
        />
      }
    />
  );
};
