import { InputLabel, InputAdornment, TextField } from "@mui/material";
import React from "react";

export interface TextFormProps {
  value: string;
  setValue: (e: string) => void;
  required?: boolean;
  multiline?: boolean;
  number?: boolean;
  password?: boolean;
  enterStar?: boolean;
  place?: string;
  label?: string;
}

export const MyTextField: React.FC<TextFormProps> = ({
  value,
  number,
  setValue,
  label,
  place,
  password,
  multiline,
  required,
  enterStar,
}) => {
  return (
    <>
      {label && <InputLabel variant="standard">{label}</InputLabel>}
      <TextField
        placeholder={place}
        error={required && !number && value === ""}
        sx={{ height: 0.5 }}
        size="small"
        margin="dense"
        required
        fullWidth
        multiline={multiline && multiline === true ? true : false}
        type={password && password === true ? "password" : "normal"}
        inputProps={
          enterStar
            ? {
                onKeyDown: (event) => {
                  if (event.key === "Backspace") {
                    const cursorIndex = event.currentTarget.selectionStart;
                    cursorIndex
                      ? setValue(
                          value.slice(0, cursorIndex - 1) +
                            value.slice(cursorIndex)
                        )
                      : setValue(value.slice(0, -1));
                  }
                },
                onKeyPress: (event) => {
                  if (event.key === "Enter") {
                    setValue(value + "\n* ");
                  } else setValue(value + event.key);
                },
              }
            : {}
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          console.log(Number.isNaN(Number(e.target.value)));
          //Make condition that the e.target.value is not a NaN
          if (number && !Number.isNaN(Number(e.target.value)))
            setValue(e.target.value);
          // if (!Number.isNaN(Number(e.target.value))) setValue(e.target.value);
          if (!enterStar && !number) setValue(e.target.value);
        }}
        value={value}
        autoFocus
      />
    </>
  );
};

export const FormField = ({
  value,
  num,
  setValue,
  label,
  place,
  password,
  mult,
  required,
  onChange,
}: {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  num?: boolean;
  mult?: boolean;
  password?: boolean;
  place?: string;
  value: string;
  setValue: (value: string) => void;
  label?: string;
}) => {
  return (
    <>
      {label && <InputLabel variant="standard">{label}</InputLabel>}
      <TextField
        error={
          (required && value === "") || (required && num && value === String(0))
        }
        helperText={
          (required && value === "") ||
          (required && num && value === String(0) && "Fill in")
        }
        sx={{ height: 0.5 }}
        size="small"
        margin="dense"
        required
        fullWidth
        multiline={mult && mult === true ? true : false}
        type={
          password && password === true
            ? "password"
            : num && num === true
            ? "number"
            : "normal"
        }
        inputProps={num ? { min: 0 } : {}}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange ? onChange(e) : setValue(e.target.value)
        }
        value={value}
        autoFocus
      />
    </>
  );
};

export const FormFieldInputAdornmentNumber = ({
  value,
  setValue,
  label,
  input,
  w,
}: {
  w?: string;
  value: string;
  setValue: (value: string) => void;
  label?: string;
  input: string;
}) => {
  return (
    <>
      {label && <InputLabel variant="standard">{label}</InputLabel>}
      <TextField
        inputProps={{ min: 0 }}
        InputProps={{
          inputMode: "numeric",
          startAdornment: (
            <InputAdornment position="start">{input}</InputAdornment>
          ),
          // endAdornment: (
          //   <InputAdornment position="end">{String(value)}</InputAdornment>
          // ),
        }}
        sx={{ height: 0.5, textAlign: "right", minWidth: w ? w : "20vh" }}
        size="small"
        type="number"
        margin="dense"
        onChange={(e) => setValue(e.target.value)}
        value={String(value)}
        autoFocus
      />
    </>
  );
};
