import React from "react";
import { MicroMacroFormField } from "./MicroMacroField";
import { MicroMacroField, MinOrMax } from "./types";
import { Grid, Stack } from "@mui/material";

export const MicroMacroFieldRow = ({
  micro,
  microMacroForm,
  setmicromacroform,
}: {
  micro: string;
  microMacroForm: MicroMacroField;
  setmicromacroform: (a: MicroMacroField) => void;
}) => {
  return (
    <Stack direction="column" alignItems={"center"}>
      <MicroMacroFormField
        minOrMax={MinOrMax.MIN}
        micro={micro}
        microMacroForm={microMacroForm}
        setValue={(a) => setmicromacroform(a)}
      />
      <>to</>
      <MicroMacroFormField
        minOrMax={MinOrMax.MAX}
        micro={micro}
        microMacroForm={microMacroForm}
        setValue={(a) => setmicromacroform(a)}
      />
    </Stack>
  );
};
