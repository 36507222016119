import * as React from "react";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";

const StepTwo = () => {
  return (
    <Div11>
      <Div12>2. An analysis of your recipes is provided</Div12>
      <Div13>
        The software analyses your dishes and provides you with:
        <br />
        The amount of each ingredient needed for different portion sizes
        <br />
        An analysis of nutritional information, such as calories, carbs, fats,
        and much more.
        <br />
        An analysis of possible allergies
      </Div13>
    </Div11>
  );
};

const StepFour = () => {
  const handleOpenDemo = () => {
    window.open("https://www.mychefsbase.com/cleanfoods", "_blank");
  };
  return (
    <Div21>
      <Div22>4. The MCB-Smart Menu for your customers</Div22>
      <Div23>
        Let customers scan a QR-Code,
        <br />
        leading them to your smart-menu on their phone, which enables them to:
        <br />
        Choose dishes based on their diet, allergies and nutrient preferences{" "}
        <br />
        Lets them swipe between dish combinations and see how the nutrition
        changes
        <br />
        Lets them create their own meal, while monitoring nutritional info
      </Div23>
      <Div24 onClick={handleOpenDemo}>Open Demo</Div24>
    </Div21>
  );
};
export const Component2 = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Div>
      <Div2>How it works</Div2>
      <Div8>
        <Div10>
          <Column4>
            <Div5>
              <Div6>1. Account owner uploads recipes, dishes and menus</Div6>
              <Div7>
                In the dashboard the account-owner uploads its recipes, dishes
                and menus.
                <br />
                This can be done through an Excel file or through the account
                itself.
                <br />
                Also, we provide help with this process, as you can fully
                outsource this step to our team.{" "}
              </Div7>
            </Div5>
          </Column4>
          <Column3>
            <Img loading="lazy" srcSet="/digital_chef.png" />
          </Column3>
        </Div10>
        <Div15>
          <Div10>
            <Column3>
              {isMobile ? (
                <StepTwo />
              ) : (
                <Img4 loading="lazy" srcSet="/analysis.png" />
              )}
            </Column3>
            <Column4>
              {!isMobile ? (
                <StepTwo />
              ) : (
                <Img4 loading="lazy" srcSet="/analysis.png" />
              )}
            </Column4>
          </Div10>
        </Div15>
        <Div14>
          <Div15>
            <Column6>
              <Div16>
                <Div17>
                  3. Enable your kitchen team to access the recipes on their
                  phone or tablet
                </Div17>
                <Div18>
                  Employees of the kitchen team can log in on their phone and
                  see an overview of the recipes, including:
                  <br />
                  Required ingredients per portion size
                  <br />
                  Instructions on how to make the dish
                  <br />
                  Images
                </Div18>
              </Div16>
            </Column6>
            <Column5>
              <Img4 loading="lazy" srcSet="/employees_phone.png" />
            </Column5>
          </Div15>
        </Div14>
        <Div19>
          <Div20>
            <Column7>
              {isMobile ? (
                <StepFour />
              ) : (
                <Img4 loading="lazy" srcSet="/digital_menu.png" />
              )}
            </Column7>
            <Column8>
              {!isMobile ? (
                <StepFour />
              ) : (
                <Img4 loading="lazy" srcSet="/digital_menu.png" />
              )}
            </Column8>
          </Div20>
        </Div19>
      </Div8>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  background-color: #faf4ec;
`;

const Div2 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/133% Roboto, sans-serif;
`;

const Div3 = styled.div`
  align-self: stretch;
  margin-top: 36px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Div4 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 69%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div5 = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div6 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div7 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 19px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img = styled.img`
  aspect-ratio: 0.85;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  margin-top: -3px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div8 = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  max-width: 1042px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 5px;
  }
`;

const Div9 = styled.div`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 39%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 0.7;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 61%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div11 = styled.div`
  display: flex;
  margin-top: 37px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div12 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div13 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 15px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div14 = styled.div`
  margin-top: 94px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div15 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 0.64;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div16 = styled.div`
  display: flex;
  margin-top: 9px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div17 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div18 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 27px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div19 = styled.div`
  margin-top: 36px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div20 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 41%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.23;
  object-fit: auto;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 59%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div21 = styled.div`
  display: flex;
  margin-top: 9px;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div22 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin: 18px 27px 0 0;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
  }
`;

const Div24 = styled.div`
  border-radius: 25px;
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  background-color: #ebce9d; /* Updated background color */
  color: black;
  align-self: start;
  margin-top: 21px;
  text-align: center;
  justify-content: center;
  padding: 10px 39px;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;
