import {
  gramMeasure,
  mlMeasure,
} from "../../../../components/defaults/portions/PortionInput";
import { measureToMeasureInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";

export const standard = {
  quantity: 100.0,
  unit: measureToMeasureInput(gramMeasure),
};

export const standardMl = {
  quantity: 100.0,
  unit: measureToMeasureInput(mlMeasure),
};
