import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import { useAuth0 } from "@auth0/auth0-react";
import emailjs from "emailjs-com";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { NavigateOptions, To, useNavigate } from "react-router";
import { SubmitButton } from "../Buttons";
import { VscAccount, VscAdd } from "react-icons/vsc";
import { useRef, useState } from "react";
import {
  useGetAllFoods,
  usePgAdminMutation,
} from "../../screens/chefsbase/foods/api";
import { getData, getData2 } from "../edamam/GetData";
import { QuantityToProduct } from "../../screens/clientbase/createClientPage/types/QuantityToProduct";
import { emptyProduct } from "../defaults/products/QuantityToProduct";
import {
  AccountInput,
  QuantityToNameIdAndNutritionInput,
} from "../../globalTypes";
import { TextOrField } from "../forms/formOrEdit/FormFieldEdit";
import { useAccountQuery, useUpdateAccountMutation } from "../../screens/api";
import { account_account } from "../../screens/types/account";
import { GridContainer, GridRowOneItem, GridRowTwoItems } from "./Grids";
import { Loader } from "../Loading/Loader";
import { file } from "@babel/types";
import { readFileAsDataURL } from "../../screens/clientbase/components/imgReader/ReadFileAsDataUrl";
import { FormField } from "../forms/singleForms/FormField";

const tabsA = (
  navigate: (to: To, options?: NavigateOptions | undefined) => void
) => [
  {
    label: "Menus",
    value: 1,
    onClick: () => navigate("/menus", { replace: true }),
  },
  {
    label: "Dishes",
    value: 2,
    onClick: () => navigate("/dishes", { replace: true }),
  },
  // {
  //   label: "My Creatables",
  //   value: 3,
  //   onClick: () => navigate("/creatables", { replace: true }),
  // },
  {
    label: "Recipes",
    value: 4,
    onClick: () => navigate("/recipes", { replace: true }),
  },
  {
    label: "Food Production",
    value: 5,
    onClick: () => navigate("/production", { replace: true }),
  },
  {
    label: "Menu Production",
    value: 6,
    onClick: () => navigate("/inventory", { replace: true }),
  },
];

const tabsB = (
  navigate: (to: To, options?: NavigateOptions | undefined) => void
) => [
  {
    label: "Menus",
    value: 1,
    onClick: () => navigate("/menus", { replace: true }),
  },
  {
    label: "Dishes",
    value: 2,
    onClick: () => navigate("/dishes", { replace: true }),
  },
  // {
  //   label: "My Creatables",
  //   value: 3,
  //   onClick: () => navigate("/creatables", { replace: true }),
  // },
  {
    label: "Recipes",
    value: 4,
    onClick: () => navigate("/recipes", { replace: true }),
  },
];
export const Header = () => {
  const { logout } = useAuth0();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [openAccount, setOpenAccount] = useState(false);
  const [account, setaccount] = useState<AccountInput>(emptyAccount);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { data, loading, error } = useAccountQuery({
    onCompleted: (result) => {
      if (result.account !== null) setaccount(accountToInput(result.account));
      if (
        result.account !== null &&
        result.account.accesstoken == result.account.restaurantName
      )
        setOpenAccount(true);
    },
  });

  const mcbPackage = data && data.account ? data.account.mcbpackage : "A";

  return (
    <React.Fragment>
      <AppBar
        component="div"
        color="secondary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Box
              component="img"
              src="/images/MCB_logo.png"
              sx={{ height: isMobile ? "45px" : "60px", width: "auto" }}
            />
            <Typography
              color="inherit"
              variant="h5"
              component="h1"
              style={{
                fontFamily: "Playfair Display, serif",
                fontSize: isMobile ? 24 : 32,
              }}
            >
              MyChefsbase
            </Typography>
            <SubmitButton
              variant="contained"
              onClick={(e) =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            >
              Log <br />
              Out
            </SubmitButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <AppBar
        color="secondary"
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        {account.accesstoken !== account.restaurantName && (
          <Tabs
            color="secondary"
            value={value}
            textColor="secondary"
            onChange={(e, newValue) => setValue(newValue as number)}
          >
            <>
              {!isMobile && <CreateButton navigate={navigate} />}
              {mcbPackage === "B" || isMobile
                ? tabsB(navigate).map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      onClick={tab.onClick}
                    />
                  ))
                : tabsA(navigate).map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      onClick={tab.onClick}
                    />
                  ))}
              {isMobile && <CreateButtonForPhone navigate={navigate} />}
            </>
          </Tabs>
        )}
      </AppBar>
      {/* <AccountDialog
        open={openAccount}
        onClose={() => setOpenAccount(false)}
        setaccount={setaccount}
        account={account}
      /> */}
    </React.Fragment>
  );
};

export const accountToInput = (a: account_account): AccountInput => {
  return {
    id: a.id,
    email: a.email,
    password: a.password,
    accesstoken: a.accesstoken,
    restaurantName: a.restaurantName,
    description: a.description,
    location: a.location,
    fullName: a.fullName,
  };
};
export const emptyAccount: AccountInput = {
  id: "",
  email: "",
  password: "",
  accesstoken: "",
  restaurantName: "",
  description: "",
  location: "",
  fullName: "",
};
export interface AccountDialogProps {
  onClose: () => void;
  open: boolean;
  account: AccountInput;
  setaccount: (a: AccountInput) => void;
}

export const AccountDialog: React.FC<AccountDialogProps> = ({
  open,
  onClose,
  account,
  setaccount,
}) => {
  const [done, setDone] = useState(false);

  const { updateAccount, loading: loadingAccount } = useUpdateAccountMutation({
    onCompleted: () => {
      setDone(true);
    },
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {account.accesstoken == account.restaurantName ? (
          <>
            Congrats! your trial period has started. <br />
            Please start by updating your account info
          </>
        ) : (
          <>Account</>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={7}
            firstchild={<Box sx={{ mt: 1 }}>Email:</Box>}
            secondchild={
              <TextOrField
                value={
                  account.email === account.accesstoken
                    ? "Email"
                    : account.email
                    ? account.email
                    : ""
                }
                setValue={(a) =>
                  setaccount({
                    ...account,
                    email: a,
                  })
                }
              />
            }
          />
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={4}
            firstchild={<Box sx={{ mt: 1 }}>Password:</Box>}
            secondchild={
              <TextOrField
                pw={true}
                value={account.password}
                setValue={(a) =>
                  setaccount({
                    ...account,
                    password: a,
                  })
                }
              />
            }
          />
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={4}
            firstchild={<Box sx={{ mt: 1 }}>Restaurant:</Box>}
            secondchild={
              <TextOrField
                value={
                  account.restaurantName === account.accesstoken
                    ? "Restaurant"
                    : account.restaurantName
                    ? account.restaurantName
                    : ""
                }
                setValue={(a) =>
                  setaccount({
                    ...account,
                    restaurantName: a,
                  })
                }
              />
            }
          />
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={4}
            firstchild={<Box sx={{ mt: 1 }}>Owner:</Box>}
            secondchild={
              <TextOrField
                value={
                  account.fullName === account.accesstoken
                    ? "Name"
                    : account.fullName
                    ? account.fullName
                    : ""
                }
                setValue={(a) =>
                  setaccount({
                    ...account,
                    fullName: a,
                  })
                }
              />
            }
          />
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={4}
            firstchild={<Box sx={{ mt: 1 }}>Location:</Box>}
            secondchild={
              <TextOrField
                value={
                  account.location === account.accesstoken
                    ? "Location"
                    : account.location
                    ? account.location
                    : ""
                }
                setValue={(a) =>
                  setaccount({
                    ...account,
                    location: a,
                  })
                }
              />
            }
          />
          <GridRowTwoItems
            before={0.5}
            inbetween={1}
            firstlength={3}
            secondlength={4}
            firstchild={<Box sx={{ mt: 1 }}>Description of restaurant:</Box>}
            secondchild={
              <TextOrField
                mult={true}
                value={
                  account.description === account.accesstoken
                    ? "Description"
                    : account.description
                    ? account.description
                    : ""
                }
                setValue={(a) =>
                  setaccount({
                    ...account,
                    description: a,
                  })
                }
              />
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {loadingAccount && <Loader loading={loadingAccount} />}
        {!done && account.accesstoken !== account.restaurantName && (
          <SubmitButton
            variant="contained"
            onClick={() => {
              updateAccount({
                variables: { input: account },
              });
              setDone(true);
            }}
          >
            Update
          </SubmitButton>
        )}
        {!loadingAccount && (
          <SubmitButton
            variant="outlined"
            color="inherit"
            disabled={
              account.accesstoken == account.restaurantName ||
              account.accesstoken == account.email
            }
            onClick={() => {
              setDone(false);
              onClose();
            }}
          >
            {done ? "Done" : "Close"}
          </SubmitButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CreateButtonForPhone = ({
  navigate,
}: {
  navigate: (to: To, options?: NavigateOptions | undefined) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <SubmitButton color="inherit" onClick={handleClick} variant="text">
        <IconButton color="inherit">
          <VscAdd />
        </IconButton>
      </SubmitButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/adddish", { replace: true });
          }}
        >
          Dish
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/addrecipe", { replace: true });
          }}
        >
          Recipe
        </MenuItem>
      </Menu>
    </div>
  );
};

const CreateButton = ({
  navigate,
}: {
  navigate: (to: To, options?: NavigateOptions | undefined) => void;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openReport, setOpenReport] = useState(false);

  return (
    <div>
      <SubmitButton color="inherit" onClick={handleClick} variant="text">
        <IconButton color="inherit">
          <VscAdd />
        </IconButton>
      </SubmitButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/addmenu", { replace: true });
          }}
        >
          Menu
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/adddish", { replace: true });
          }}
        >
          Dish
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/addrecipe", { replace: true });
          }}
        >
          Recipe
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenInvoice(true);
          }}
        >
          Upload Invoice
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenReport(true);
          }}
        >
          Upload Sales Report
        </MenuItem>
      </Menu>
      <EmailInvoiceDialog
        open={openInvoice}
        onClose={() => setOpenInvoice(false)}
      />
      <EmailReportDialog
        open={openReport}
        onClose={() => setOpenReport(false)}
      />
      {/* <UploadInvoiceDialog
        open={openInvoice}
        onClose={() => setOpenInvoice(false)}
      /> */}
    </div>
  );
};

interface UploadInvoiceProps {
  open: boolean;
  onClose: () => void;
}

export const UploadInvoiceDialog: React.FC<UploadInvoiceProps> = ({
  open,
  onClose,
}) => {
  const [email, setEmail] = useState<string>("");
  const [pdfFile, setPdfFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPdfFile(event.target.files[0]);
    }
  };
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const f = true;
    if (f) {
      e.preventDefault();

      emailjs
        .sendForm(
          "service_7mojydn",
          "template_4pvudlf",
          e.currentTarget,
          "ASiCPigOW_8pD-YDa"
        )
        .then(
          (result) => {
            window.location.reload();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload invoices</DialogTitle>
      <DialogContent>
        <form className="contact-form" onSubmit={sendEmail}>
          <input type="hidden" name="contact_number" />
          <TextField
            label="Supplier"
            type="text"
            name="supplier_name"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            type="email"
            name="email_address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Message"
            name="html_message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
          <input type="submit" value="Send" />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EmailInvoiceDialog: React.FC<UploadInvoiceProps> = ({
  open,
  onClose,
}) => {
  const [supplier, setSupplier] = useState("");
  const handleUploadInvoice = () => {
    const subject = encodeURIComponent(`Invoice Upload for ${supplier}`);
    const body = encodeURIComponent(
      `Please attach the invoice you received from: ${supplier}.`
    );
    window.location.href = `mailto:mychefsbase@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Please send us the invoice through email</DialogTitle>
      <DialogContent>
        <FormField
          label="Supplier"
          value={supplier}
          setValue={(a) => setSupplier(a)}
        />
        <SubmitButton
          disabled={supplier === ""}
          color="inherit"
          variant="contained"
          onClick={() => {
            handleUploadInvoice();
            onClose();
          }}
        >
          Send invoice through email
        </SubmitButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EmailReportDialog: React.FC<UploadInvoiceProps> = ({
  open,
  onClose,
}) => {
  const handleUploadInvoice = () => {
    const subject = encodeURIComponent(`Revenue Report`);
    const body = encodeURIComponent(`Please attach the revenue report.`);
    window.location.href = `mailto:mychefsbase@gmail.com?subject=${subject}&body=${body}`;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Please send us the invoice through email</DialogTitle>
      <DialogContent>
        <SubmitButton
          color="inherit"
          variant="contained"
          onClick={() => {
            handleUploadInvoice();
            onClose();
          }}
        >
          Send revenue report through email
        </SubmitButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
