import {
  Table,
  TableRow,
  TableCell,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { read, utils, writeFile } from "xlsx";
import { type } from "@testing-library/user-event/dist/type";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import { Loader } from "../../../components/Loading/Loader";
import {
  AmountPerQuantityInput,
  DishToQuantityInput,
  IngredientToQuantityToSalePriceInput,
  KitchenType,
  MeasureInput,
  QuantityInput,
} from "../../../globalTypes";
import { DeletesDialog } from "../components/DeleteDialog";
import { dishes, dishes_dishes } from "../dishes/types/dishes";
import { FormAutoCompleteDishSelectable } from "../../../components/forms/singleForms/FormSelect";
import { MySelectablesInput } from "../../clientbase/createClientPage/types/MySelectablesInput";
import { useState } from "react";
import {
  emptyMySelectable,
  emptySelectable,
} from "../../../components/defaults/selectables/SelectableInput";
import { useLazyItemsForDishQuery, useSearchDishesQuery } from "../dishes/api";
import { mapDishToSelectable } from "../../clientbase/components/mappers/selectable/MapToSelectables";
import { FormQuantity } from "../recipes/addrecipes/CreateYourRecipe";
import {
  gramMeasure,
  gramMeasureInput,
  measureInputOptions,
  mlMeasureInput,
  small,
} from "../../../components/defaults/portions/PortionInput";
import { sortPortions } from "../dishes/dishDialog/DishContent";
import { standard } from "../dishes/components/StandardWeight";
import { VscTrash } from "react-icons/vsc";
import {
  AmountPerQuantityToProduct,
  QuantityToNameIdAndNutritionInputWithPrice,
} from "../dishes";
import { zeroNutritionInput } from "../dishes/components/EmptyVariables";
import {
  displayQuantity,
  minimizeUnit,
  roundToZeroOr2,
  scaleQuantity,
  writeUnit,
} from "../../../components/utils/DisplayQuantity";
import { useLazyOrderOverviewQuery } from "./api";
import {
  orderOverview_orderOverview,
  orderOverview_orderOverview_products,
  orderOverview_orderOverview_recipes,
} from "./types/orderOverview";
import {
  getFraction,
  subtractAmounts,
} from "../../../components/utils/GetFraction";
import { FormField } from "../../../components/forms/singleForms/FormField";
import { quantityToInput } from "../../clientbase/createClientPage/forms/DishCard/Mappers";
import { replaceCommasInString } from "../../../components/utils/WriteText";
import { OrderDialog, OrderRowForm } from "../inventory";

export const choosePortionAmount = (a: number): number => {
  let result = a;
  switch (true) {
    case a < 5:
      result = 5;
      break;
    case a < 10:
      result = 10;
      break;
    case a < 500:
      result = 500;
      break;
    case a < 1000:
      result = 1000;
      break;
    case a < 5000:
      result = 5000;
      break;
    default:
      result = Number(a.toFixed(0));
  }
  return result;
};
export interface ExcelRow {
  lijstnaam: string;
  sorteervolgorde: string;
  artikelnummer: string;
  portioneringGewicht: string;
  verpakkingscode: string;
  voorkeursAantal: string;
  empty1: string;
  empty2: string;
  productinformatie: string;
  productgroepcode: string;
  productgroepnaam: string;
  portioneringOmschrijving: string;
  verpakkingOmschrijving: string;
}
export const rowFormsToExcelRows = (name: string, a: RowForm[]): ExcelRow[] => {
  return a.map((rowForm, index) => rowFormToExcelRow(index + 1, name, rowForm));
};

export const rowFormToExcelRow = (
  index: number,
  name: string,
  a: RowForm
): ExcelRow => {
  const random = Math.ceil(Math.random() * 19);
  return {
    lijstnaam: name,
    sorteervolgorde: String(index),
    artikelnummer: `-`,
    portioneringGewicht: `${choosePortionAmount(a.needed.quantity)} ${
      a.needed.unit.label
    }`,
    verpakkingscode: `-`,
    voorkeursAantal: String(random),
    empty1: "",
    empty2: "",
    productinformatie: `${a.name} per ${choosePortionAmount(
      a.needed.quantity
    )} ${a.needed.unit.label}`,
    productgroepcode: `-`,
    productgroepnaam: `-`,
    portioneringOmschrijving: `${choosePortionAmount(a.needed.quantity)} ${
      a.needed.unit.label
    }`,
    verpakkingOmschrijving: "-",
  };
};
export const Production = () => {
  const [dishes, setdishes] = useState<dishes_dishes[]>([]);
  const { data, loading: loadingDishes } = useSearchDishesQuery({
    onCompleted: (result: dishes) => setdishes(result.dishes),
  });
  const [rowForms, setRowForms] = useState<RowForm[]>([]);
  const [openIngredients, setOpenIngredients] = useState(false);
  const [recipes, setRecipes] = useState<orderOverview_orderOverview_recipes[]>(
    []
  );
  const [dishToQuantityList, setDishToQuantityList] = useState<
    DishToQuantityInput[]
  >([emptyDishToQuantity]);

  const { getOrderOverview, loading } = useLazyOrderOverviewQuery({
    onCompleted: (quantityToFood) => {
      setRecipes(quantityToFood.orderOverview.recipes);
      setRowForms(
        quantityToFood.orderOverview.products.map((order) => {
          return {
            id: order.food.id,
            name: order.food.name,
            needed: quantityToInput(order.quantity),
            inventory: {
              quantity: 0,
              unit: quantityToInput(order.quantity).unit,
            },
            toOrder: quantityToInput(order.quantity),
            ingredientprice: order.food.price
              ? order.food.price.amountPerQuantity.amount *
                getFraction(
                  quantityToInput(order.food.price.amountPerQuantity.quantity),
                  scaleQuantity(quantityToInput(order.quantity))
                )
              : 0,
            originalPrice: order.food.price
              ? {
                  amount: order.food.price!.amountPerQuantity.amount,
                  quantity: quantityToInput(
                    order.food.price!.amountPerQuantity.quantity
                  ),
                }
              : {
                  amount: 0,
                  quantity: quantityToInput(order.quantity),
                },
          };
        })
      );
    },
    quantityToDishes: dishToQuantityList,
  });
  const [value, setValue] = useState(0);
  const [openBestellijst, setOpenBestellijst] = useState(false);
  const [formForOrder, setFormForOrder] = useState<OrderRowForm[]>();
  const [openDone, setOpenDone] = useState(false);

  return (
    <MainBox
      childComp={
        <>
          <BoxTitle title="Time to make some dishes!" />
          <div style={{ height: 300, width: "100%" }}>
            <Table>
              <DishRow
                loading={loadingDishes}
                dishes={dishes.map((d) => mapDishToSelectable(d))}
                dishToQuantityList={dishToQuantityList}
                setDishToQuantityList={setDishToQuantityList}
              />
              <TableRow>
                <TableCell>
                  <SubmitButton
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      getOrderOverview();
                      setOpenIngredients(true);
                    }}
                  >
                    Get all that is needed
                  </SubmitButton>
                </TableCell>
              </TableRow>
              <Button
                variant={value === 0 ? "contained" : "outlined"}
                onClick={() => setValue(0)}
              >
                Ingredients
              </Button>
              <Button
                variant={value === 1 ? "contained" : "outlined"}
                onClick={() => setValue(1)}
              >
                Recipes
              </Button>
              {openIngredients && value === 1 ? (
                <Table>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <BoxTitle title="Recipes:" />
                    </TableCell>
                  </TableRow>
                  {loading ? (
                    <TableRow>
                      <TableCell>Loading Order Overview</TableCell>
                      <CircularProgress />
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>Recipe</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>In Inventory</TableCell>
                      <TableCell>To Make</TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    (recipes.length > 0 ? (
                      recipes.map((recipe, recipeIndex) => (
                        <RecipeRow key={recipeIndex} recipe={recipe} />
                      ))
                    ) : (
                      <TableRow>No Recipes Found</TableRow>
                    ))}
                </Table>
              ) : (
                <Table>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <BoxTitle title="Ingredients:" />
                    </TableCell>
                  </TableRow>

                  {loading ? (
                    <TableRow>
                      <TableCell>Loading Order Overview</TableCell>
                      <CircularProgress />
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>Ingredient</TableCell>
                      <TableCell>Price per</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>In Inventory</TableCell>
                      <TableCell>To Order</TableCell>
                      <TableCell>
                        <b>
                          Total €
                          {rowForms.length > 0 &&
                            roundToZeroOr2(
                              rowForms
                                .map((rowForm) => rowForm.ingredientprice)
                                .reduce((sum, p) => sum + p)
                            )}
                        </b>
                      </TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    (rowForms.length > 0 ? (
                      rowForms.map((rowForm, ingredientIndex) => (
                        <Row
                          key={ingredientIndex}
                          index={ingredientIndex}
                          rowForm={rowForm}
                          setRowForm={(a) => {
                            const old = [...rowForms];
                            old[ingredientIndex] = a;
                            setRowForms(old);
                          }}
                        />
                      ))
                    ) : (
                      <TableRow>No Ingredients Found</TableRow>
                    ))}
                </Table>
              )}
              {rowForms.length > 0 && (
                <TableRow>
                  <TableCell>
                    <SubmitButton
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        setFormForOrder(
                          rowForms
                            .filter((r) => r.toOrder.quantity > 0)
                            .map((f) => ({
                              food: { name: f.name, id: f.id },
                              quantity: f.toOrder,
                              units: 1,
                              pricePerUnit: f.ingredientprice,
                            }))
                        );
                        setOpenBestellijst(true);
                      }}
                    >
                      Download Order
                    </SubmitButton>
                  </TableCell>
                </TableRow>
              )}
            </Table>
            {formForOrder && formForOrder.length > 0 && (
              <OrderDialog
                setOpenDone={setOpenDone}
                open={openBestellijst}
                onClose={() => setOpenBestellijst(false)}
                form={formForOrder}
              />
            )}
            <Dialog open={openDone} onClose={() => setOpenDone(false)}>
              <DialogTitle>Find the orderlist in your Downloads</DialogTitle>
              <DialogActions>
                <SubmitButton
                  onClick={() => setOpenDone(false)}
                  variant="contained"
                >
                  Great!
                </SubmitButton>
              </DialogActions>
            </Dialog>
          </div>
        </>
      }
    />
  );
};
export type RowForm = {
  name: string;
  id: string;
  needed: QuantityInput;
  inventory: QuantityInput;
  toOrder: QuantityInput;
  ingredientprice: number;
  originalPrice: AmountPerQuantityInput;
};

export const RecipeRow = ({
  recipe,
}: {
  recipe: orderOverview_orderOverview_recipes;
}) => {
  const [inventory, setinventory] = useState(0);
  console.log(recipe.name);
  console.log(recipe.quantity);

  return (
    <TableRow>
      <TableCell>{recipe.name}</TableCell>
      <TableCell>{displayQuantity(quantityToInput(recipe.quantity))}</TableCell>
      <TableCell>
        <FormField
          num={true}
          value={String(inventory)}
          setValue={(a) => setinventory(Number(a))}
        />
      </TableCell>
      <TableCell>
        {displayQuantity({
          quantity:
            scaleQuantity(quantityToInput(recipe.quantity)).quantity -
            inventory,
          unit:
            recipe.name === "Soup Bros Bouillon"
              ? mlMeasureInput(0)
              : scaleQuantity(quantityToInput(recipe.quantity)).unit,
        })}
      </TableCell>
    </TableRow>
  );
};
export const Row = ({
  index,
  rowForm,
  setRowForm,
}: {
  setRowForm: (a: RowForm) => void;
  rowForm: RowForm;
  index: number;
}) => {
  return (
    <TableRow key={index}>
      <TableCell>{rowForm.name}</TableCell>
      <TableCell>
        {`€(${roundToZeroOr2(rowForm.originalPrice.amount)} / ${writeUnit(
          rowForm.originalPrice.quantity.unit
        )})`}
      </TableCell>
      <TableCell>{displayQuantity(rowForm.needed)}</TableCell>
      <TableCell>
        {
          <FormField
            num={true}
            value={String(rowForm.inventory.quantity)}
            setValue={(e) => {
              setRowForm({
                ...rowForm,
                inventory: {
                  quantity: Number(replaceCommasInString(e)),
                  unit: scaleQuantity(rowForm.needed).unit,
                },
                toOrder: {
                  unit: minimizeUnit(rowForm.needed.unit),
                  quantity: Math.max(
                    subtractAmounts(rowForm.needed, {
                      quantity: Number(replaceCommasInString(e)),
                      unit: scaleQuantity(rowForm.needed).unit,
                    }).quantity,
                    0
                  ),
                },
                ingredientprice:
                  rowForm.originalPrice.amount *
                  getFraction(rowForm.originalPrice.quantity, {
                    unit: minimizeUnit(rowForm.needed.unit),
                    quantity: Math.max(
                      subtractAmounts(rowForm.needed, {
                        quantity: Number(replaceCommasInString(e)),
                        unit: scaleQuantity(rowForm.needed).unit,
                      }).quantity,
                      0
                    ),
                  }),
              });
            }}
          />
        }
      </TableCell>
      <TableCell>{displayQuantity(rowForm.toOrder)}</TableCell>
      <TableCell>€{roundToZeroOr2(rowForm.ingredientprice)}</TableCell>
    </TableRow>
  );
};
interface DishRowComponents {
  i?: number;
  d?: DishToQuantityInput;
  dishes: MySelectablesInput[];
  dishToQuantityList: DishToQuantityInput[];
  setDishToQuantityList: (a: DishToQuantityInput[]) => void;
  loading: boolean;
}

export const emptyDishToQuantity: DishToQuantityInput = {
  id: emptyMySelectable.dishid,
  name: emptyMySelectable.dishname,
  quantity: standard,
};
export const RowHere: React.FC<DishRowComponents> = ({
  dishes,
  d,
  dishToQuantityList,
  setDishToQuantityList,
  loading,
  i,
}) => {
  const dish = d!;
  const index = i!;
  const [amount, setamount] = useState(String(dish.quantity.quantity));
  return (
    <TableRow key={index}>
      <TableCell>
        {loading && (
          <>
            Loading dishes...
            <Loader loading={loading} />
          </>
        )}
        <FormAutoCompleteDishSelectable
          value={{
            img: [""],
            dishid: dish.id,
            dishname: dish.name,
            quantitiesSelected: [
              {
                selected: false,
                portion: { name: "Manual", quantity: dish.quantity },
              },
            ],
          }}
          options={dishes}
          setValue={(a) => {
            const old = [...dishToQuantityList];
            old[index] = {
              id: a.dishid,
              name: a.dishname,
              quantity: sortPortions(
                a.quantitiesSelected.map((q) => q.portion)
              )[0]
                ? sortPortions(a.quantitiesSelected.map((q) => q.portion))[0]
                    .quantity
                : standard,
            };
            setDishToQuantityList(old);
          }}
        />
      </TableCell>
      <TableCell>
        <FormQuantity
          amount={amount}
          setamount={setamount}
          value={dish.quantity}
          options={(dish.quantity.unit
            ? measureInputOptions(dish.quantity.unit.label)
            : measureInputOptions()
          ).map((t) => ({
            name: t.label,
            id: t.uri,
          }))}
          setValue={(a) => {
            const old = [...dishToQuantityList];
            old[index] = {
              ...old[index],
              quantity: a,
            };
            setDishToQuantityList(old);
          }}
        />
      </TableCell>
      <TableCell>
        <VscTrash
          style={{ cursor: "pointer" }}
          onClick={() => {
            const old = [...dishToQuantityList];
            old.splice(index, 1);
            setDishToQuantityList(old);
          }}
        />
      </TableCell>
    </TableRow>
  );
};
const DishRow: React.FC<DishRowComponents> = ({
  dishes,
  dishToQuantityList,
  setDishToQuantityList,
  loading,
}) => {
  return (
    <Table>
      {dishToQuantityList.map((dish, index) => (
        <RowHere
          dishToQuantityList={dishToQuantityList}
          dishes={dishes}
          i={index}
          loading={loading}
          d={dish}
          setDishToQuantityList={setDishToQuantityList}
        />
      ))}
      <TableRow>
        <SubmitButton
          variant="contained"
          onClick={() => {
            const old = [...dishToQuantityList];
            old.push(emptyDishToQuantity);
            setDishToQuantityList(old);
          }}
        >
          {" "}
          Another One{" "}
        </SubmitButton>
      </TableRow>
    </Table>
  );
};
