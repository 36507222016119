import styled from "@emotion/styled";

export const Component1 = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 500, // Adjust the 500 value to scroll down by a different amount
      behavior: "smooth",
    });
  };
  return (
    <Div>
      <Div2>The digital help for you as a chef !</Div2>
      <Div8>
        <Div10>
          <Column4>
            <Div5>
              <DivMainText>
                Keep your menu and recipes online, while MyChefsbase will do the
                work through analysis.
                <li>Have your team access the updated recipes.</li>
                <li>
                  Have your customers read nutritional and allergy information.
                </li>
                <li>
                  Have all detailed information online in a dashboard for
                  financial or strategic decisions.
                </li>
                <br />
                Meet MyChefsbase, the tool to bring chefs back to the kitchen
                and takes over all hustles that come with owning a restaurant or
                catering
                <br />
                <DivLearnMore onClick={handleScroll}>Learn More</DivLearnMore>
              </DivMainText>
            </Div5>
          </Column4>
          <Column3>
            <Img loading="lazy" srcSet="/MyChefsbaseProcess.png" />
          </Column3>
        </Div10>
      </Div8>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  background-color: #faf4ec;
`;

const Div2 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/133% Roboto, sans-serif;
`;

const Div3 = styled.div`
  align-self: stretch;
  margin-top: 36px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Div4 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 69%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div5 = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div6 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div7 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 19px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img = styled.img`
  aspect-ratio: 0.85;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  margin-top: -3px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div8 = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  max-width: 1042px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 5px;
  }
`;

const Div9 = styled.div`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div10 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const Img2 = styled.img`
  aspect-ratio: 0.7;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 61%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div11 = styled.div`
  display: flex;
  margin-top: 37px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div12 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div13 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 15px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div14 = styled.div`
  margin-top: 94px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div15 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 0.64;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div16 = styled.div`
  display: flex;
  margin-top: 9px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div17 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 500 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div18 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 27px;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div19 = styled.div`
  margin-top: 36px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div20 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 41%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.23;
  object-fit: auto;
  object-position: center;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 59%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div21 = styled.div`
  display: flex;
  margin-top: 9px;
  flex-grow: 1;
  flex-direction: column;
  font-weight: 500;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div22 = styled.div`
  color: var(--Colour-Main-Blue-900, #091133);
  font-feature-settings: "clig" off, "liga" off;
  font: 36px/48px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  color: var(--Colour-Main-Blue-400, #6f7cb2);
  font-feature-settings: "clig" off, "liga" off;
  margin: 18px 27px 0 0;
  font: 400 16px/26px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
  }
`;

const Div24 = styled.div`
  border-radius: 25px;
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  background-color: #ebce9d; /* Updated background color */
  color: black;
  align-self: start;
  margin-top: 21px;
  text-align: center;
  justify-content: center;
  padding: 10px 39px;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

// export const Component1 = () => {
// const handleScroll = () => {
//   window.scrollTo({
//     top: window.scrollY + 500, // Adjust the 500 value to scroll down by a different amount
//     behavior: "smooth",
//   });
// };
//   return (
//     <div>
//       <Div>
//         <Column2>
//           <Div3>
//             <Img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets/TEMP/445544af12eec052a9825810dba854362485e87060914ce460a741d455f75e33?apiKey=abf6b92ded7a4266896fab5a5d3c0257&"
//             />
//             <Div4>
//               <ColumnsWrapper>
//                 <Column3>
//                   <Div6>
//                     <Div7>The digital help for you as a chef !</Div7>
//                     <Div8>
//                       Keep your menu and recipes online, while MyChefsbase will
//                       do the work through analysis.
//                       <li>Have your team access the updated recipes.</li>
//                       <li>
//                         Have your customers read nutritional and allergy
//                         information.
//                       </li>
//                       <li>
//                         Have all detailed information online in a dashboard for
//                         financial or strategic decisions.
//                       </li>
//                       <br />
//                       Meet MyChefsbase, the tool to bring chefs back to the
//                       kitchen and takes over all hustles that come with owning a
//                       restaurant or catering
//                       <br />
//                       <br />
//                       <br />
//                     </Div8>
//                     <Div2 onClick={handleScroll}>Learn More</Div2>
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                     <br />
//                   </Div6>
//                 </Column3>
//                 <Column4>
//                   <Img2 loading="lazy" srcSet="/MyChefsbaseProcess.png" />
//                 </Column4>
//               </ColumnsWrapper>
//             </Div4>
//           </Div3>
//         </Column2>
//       </Div>
//     </div>
//   );
// };

// const ColumnsWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   @media (max-width: 991px) {
//     flex-direction: column;
//   }
// `;

// const Div = styled.div`
//   gap: 50px;
//   display: flex;
//   background-color: #faf4ec;
//   @media (max-width: 1400px) {
//     flex-direction: column;
//     align-items: stretch;
//     gap: 0px;
//   }
// `;

const DivLearnMore = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  font-feature-settings: "clig" off, "liga" off;
  border-radius: 25px;
  background-color: #ebce9d; /* Updated background color */
  color: black;
  border-width: 2px;
  align-self: center;
  margin-top: 0px;
  text-align: center;
  justify-content: center;
  padding: 13px 55px;
  font: 16px/162.5% Roboto, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

// const Column2 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 100%;
//   margin-left: 20px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Div3 = styled.div`
//   display: flex;
//   flex-direction: column;
//   fill: var(--Colour-Main-Blue-100, #e7ecff);
//   overflow: hidden;
//   position: relative;
//   min-height: 420px;
//   flex-grow: 1;
//   align-items: start;
//   justify-content: top;
//   padding: 0px 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const Img = styled.img`
//   position: absolute;
//   inset: 0;
//   height: 100%;
//   width: 100%;
//   object-fit: cover;
//   object-position: center;
// `;

// const Div4 = styled.div`
//   position: relative;
//   margin-top: 116px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const Column3 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 40%;
//   margin-left: 80px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Div6 = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-self: stretch;
//   color: var(--Colour-Main-Blue-900, #091133);
//   font-weight: 500;
//   margin: auto 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const Div7 = styled.div`
//   font-feature-settings: "clig" off, "liga" off;
//   letter-spacing: 1px;
//   font: 40px/44px Roboto, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     font-size: 40px;
//     line-height: 59px;
//   }
// `;

const DivMainText = styled.div`
  color: black;
  font-feature-settings: "clig" off, "liga" off;
  margin-top: 0px;
  font: 400 18px/30px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

// const Div9 = styled.div`
//   font-feature-settings: "clig" off, "liga" off;
//   border-radius: 2px;
//   border-color: rgba(9, 17, 51, 1);
//   border-style: solid;
//   border-width: 2px;
//   align-self: center;
//   margin-top: px;
//   text-align: center;
//   justify-content: center;
//   padding: 13px 55px;
//   font: 16px/162.5% Roboto, sans-serif;
//   @media (max-width: 991px) {
//     margin-top: 40px;
//     padding: 0 20px;
//   }
// `;

// const Column4 = styled.div`
//   display: flex;
//   flex-direction: column;
//   line-height: normal;
//   width: 40%;
//   margin-left: 20px;
//   @media (max-width: 991px) {
//     width: 100%;
//   }
// `;

// const Img2 = styled.img`
//   aspect-ratio: 0.75;
//   object-fit: auto;
//   object-position: center;
//   width: 100%;
//   flex-grow: 1;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;
