import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { VscArrowDown, VscArrowUp } from "react-icons/vsc";
import { CurrentMenu_currentMenu } from "../../types/CurrentMenu";

export const TabsForMenu = ({
  data,
  value,
  setValue,
}: {
  data?: CurrentMenu_currentMenu | null;
  value: number;
  setValue: (a: number) => void;
}) => {
  return (
    <GridRowOneItem
      before={0.8}
      after={0.5}
      child={
        <Box>
          {data && data.courses.flatMap((i) => i.selectables).length > 0 && (
            <Box onClick={() => (value === 1 ? setValue(0) : setValue(1))}>
              <GridRowTwoItems
                before={0}
                firstlength={10}
                secondlength={1}
                inbetween={1}
                firstchild={
                  <Typography
                    align="left"
                    variant="h6"
                    component="div"
                    style={{
                      fontFamily: "Playfair Display, serif",
                      fontWeight: value === 1 ? "bold" : "normal",
                    }}
                  >
                    1. Courses
                  </Typography>
                }
                secondchild={
                  <Box sx={{ mt: 1 }}>
                    {value === 1 ? (
                      <VscArrowUp
                        style={{
                          cursor: "pointer",
                          height: 20,
                          width: 20,
                        }}
                        onClick={() => setValue(0)}
                      />
                    ) : (
                      <VscArrowDown
                        style={{ cursor: "pointer", height: 20, width: 20 }}
                        onClick={() => setValue(1)}
                      />
                    )}
                  </Box>
                }
              />
              <Divider />
            </Box>
          )}
          {data && data.combos.length > 0 && (
            <Box onClick={() => (value === 2 ? setValue(0) : setValue(2))}>
              <GridRowTwoItems
                before={0}
                firstlength={10}
                secondlength={1}
                inbetween={1}
                firstchild={
                  <Typography
                    align="left"
                    variant="h6"
                    component="div"
                    style={{
                      fontFamily: "Playfair Display, serif",
                      fontWeight: value === 2 ? "bold" : "normal",
                    }}
                  >
                    2. Combos
                  </Typography>
                }
                secondchild={
                  <Box>
                    {value === 2 ? (
                      <VscArrowUp
                        style={{ cursor: "pointer", height: 20, width: 20 }}
                      />
                    ) : (
                      <VscArrowDown
                        style={{ cursor: "pointer", height: 20, width: 20 }}
                      />
                    )}
                  </Box>
                }
              />
              <Divider />
            </Box>
          )}
          {data && data.courses.flatMap((i) => i.composables).length > 0 && (
            <Box onClick={() => (value === 3 ? setValue(0) : setValue(3))}>
              <GridRowTwoItems
                before={0}
                firstlength={10}
                secondlength={1}
                inbetween={1}
                firstchild={
                  <Typography
                    align="left"
                    variant="h6"
                    component="div"
                    style={{
                      fontFamily: "Playfair Display, serif",
                      fontWeight: value === 3 ? "bold" : "normal",
                    }}
                  >
                    {data && data.combos.length > 0 ? "3" : "2"}. Create
                  </Typography>
                }
                secondchild={
                  <Box>
                    {value === 3 ? (
                      <VscArrowUp
                        style={{ cursor: "pointer", height: 20, width: 20 }}
                      />
                    ) : (
                      <VscArrowDown
                        style={{ cursor: "pointer", height: 20, width: 20 }}
                      />
                    )}
                  </Box>
                }
              />
              <Divider />
            </Box>
          )}
        </Box>
      }
    />
  );
};

export const TabsForMenuForUpdate = ({
  data,
  value,
  setValue,
}: {
  data?: CurrentMenu_currentMenu | null;
  value: number;
  setValue: (a: number) => void;
}) => {
  return (
    <GridRowOneItem
      before={0.8}
      after={0.5}
      child={
        <Box sx={{ cursor: "pointer" }}>
          <Box onClick={() => (value === 1 ? setValue(0) : setValue(1))}>
            <GridRowTwoItems
              before={0}
              firstlength={10}
              secondlength={1}
              inbetween={1}
              firstchild={
                <Typography
                  align="left"
                  variant="h6"
                  component="div"
                  style={{
                    fontFamily: "Playfair Display, serif",
                    fontWeight: value === 1 ? "bold" : "normal",
                  }}
                >
                  1. Courses
                </Typography>
              }
              secondchild={
                <Box sx={{ mt: 1 }}>
                  {value === 1 ? (
                    <VscArrowUp
                      style={{
                        cursor: "pointer",
                        height: 20,
                        width: 20,
                      }}
                      onClick={() => setValue(0)}
                    />
                  ) : (
                    <VscArrowDown
                      style={{ cursor: "pointer", height: 20, width: 20 }}
                      onClick={() => setValue(1)}
                    />
                  )}
                </Box>
              }
            />
            <Divider />
          </Box>

          <Box onClick={() => (value === 2 ? setValue(0) : setValue(2))}>
            <GridRowTwoItems
              before={0}
              firstlength={10}
              secondlength={1}
              inbetween={1}
              firstchild={
                <Typography
                  align="left"
                  variant="h6"
                  component="div"
                  style={{
                    fontFamily: "Playfair Display, serif",
                    fontWeight: value === 2 ? "bold" : "normal",
                  }}
                >
                  2. Combos
                </Typography>
              }
              secondchild={
                <Box>
                  {value === 2 ? (
                    <VscArrowUp
                      style={{ cursor: "pointer", height: 20, width: 20 }}
                    />
                  ) : (
                    <VscArrowDown
                      style={{ cursor: "pointer", height: 20, width: 20 }}
                    />
                  )}
                </Box>
              }
            />
            <Divider />
          </Box>
          <Box onClick={() => (value === 3 ? setValue(0) : setValue(3))}>
            <GridRowTwoItems
              before={0}
              firstlength={10}
              secondlength={1}
              inbetween={1}
              firstchild={
                <Typography
                  align="left"
                  variant="h6"
                  component="div"
                  style={{
                    fontFamily: "Playfair Display, serif",
                    fontWeight: value === 3 ? "bold" : "normal",
                  }}
                >
                  3. Create
                </Typography>
              }
              secondchild={
                <Box>
                  {value === 3 ? (
                    <VscArrowUp
                      style={{ cursor: "pointer", height: 20, width: 20 }}
                    />
                  ) : (
                    <VscArrowDown
                      style={{ cursor: "pointer", height: 20, width: 20 }}
                    />
                  )}
                </Box>
              }
            />
            <Divider />
          </Box>
        </Box>
      }
    />
  );
};
