import { gql, useLazyQuery } from "@apollo/client";
import { DishToQuantityInput } from "../../../globalTypes";
import { orderOverview } from "./types/orderOverview";

export const orderOverviewQuery = gql`
  query orderOverview($quantityToDishes: [DishToQuantityInput!]!) {
    orderOverview(quantityToDishes: $quantityToDishes) {
      recipes {
        name
        id
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
      }
      products {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        food {
          id
          name
          price {
            amountPerQuantity {
              amount
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useLazyOrderOverviewQuery = ({
  onCompleted,
  quantityToDishes,
}: {
  onCompleted: (dishes: orderOverview) => void;
  quantityToDishes: DishToQuantityInput[];
}) => {
  const [getOrderOverview, { loading, data }] = useLazyQuery(
    orderOverviewQuery,
    {
      onCompleted: (result) => {
        onCompleted(result);
      },
      variables: {
        quantityToDishes: quantityToDishes,
      },
    }
  );
  return { loading, data, getOrderOverview };
};
