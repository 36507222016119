import { Fade, LinearProgress } from "@mui/material";
import React from "react";

export const Loader = ({ loading }: {loading: boolean}) => (
    <div style={{ height: "100%", width: "100%" }}>
      {loading && (
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "600ms" : "0ms",
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
      )}
    </div>
  );