import { Stack } from "@mui/material";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { GridRowOneItem } from "../../../../components/layouts/Grids";
import { createRange } from "../../../../components/utils/CreateRange";
import { dishes_dishes } from "../../../chefsbase/dishes/types/dishes";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import {
  AddableComposableForCourse,
  ComposableForCourse,
} from "./ComposableForCourse";

export const ComposablesForm = ({
  form,
  setForm,
  numberofcourses,
  dishes,
}: {
  dishes: dishes_dishes[];
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  numberofcourses: number;
}) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0.5}
      child={
        <>
          <Stack spacing={2}>
            {createRange(0, numberofcourses).map((index) => (
              <>
                <FormField
                  label="Course"
                  value={form.courses[index] ? form.courses[index].name : ""}
                  setValue={(a: string) => {
                    const courses = [...form.courses];
                    courses[index] = {
                      name: a,
                      selectables: form.courses[index].selectables,
                      composables: form.courses[index].composables,
                    };
                    setForm({
                      input: form.input,
                      courses: courses,
                      combos: form.combos,
                    });
                  }}
                />
                <Stack direction="row" spacing={0.1}>
                  {form.courses[index].composables
                    .filter((c) => c.name !== "")
                    .map((sel, i) => (
                      <ComposableForCourse
                        i={i}
                        index={index}
                        form={form}
                        setForm={(a) => setForm(a)}
                      />
                    ))}
                  <AddableComposableForCourse
                    index={index}
                    form={form}
                    setForm={(a) => setForm(a)}
                  />
                </Stack>
              </>
            ))}
          </Stack>
        </>
      }
    />
  );
};
