import { small } from "../../../../components/defaults/portions/PortionInput";
import { distinctstringsOneArray } from "../../../../components/utils/DistinctStrings";
import { getFraction } from "../../../../components/utils/GetFraction";
import {
  mapIngredientToQuantityToSalePriceInput,
  mapToIngredientToQuantityToSalePriceInput,
  mapToIngredientToQuantityToSalePriceInputBase,
  mapToIngredientToQuantityToSalePriceInputForRecipe,
} from "../../../../components/utils/Mappers";
import {
  AmountPerQuantityInput,
  ComposablesInput,
  HealthLabelInfo,
  OptionInput,
  OptionsInput,
  PortionInput,
  QuantityInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import {
  totalNutrition,
  scaleNutrition,
} from "../../../clientbase/components/courses/Functions";
import { measureToMeasureInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { Composables_composables } from "../../menus/types/Composables";
import { AddDishVariables } from "../addDish/types/AddDish";
import { UpdateDishVariables } from "../addDish/types/UpdateDish";
import { zeroNutritionInput } from "../components/EmptyVariables";
import { Dish_dish_products_foods } from "../types/Dish";
import {
  dishes_dishes_options,
  dishes_dishes,
  dishes_dishes_hoursWorked,
  dishes_dishes_portions,
  dishes_dishes_healthLabelInfo,
} from "../types/dishes";

export const toHoursForm = (
  a: dishes_dishes_hoursWorked
): AmountPerQuantityInput => {
  return {
    amount: a.amount,
    quantity: {
      quantity: a.quantity.quantity,
      unit: measureToMeasureInput(a.quantity.unit),
    },
  };
};

export const toPortionsInput = (
  a: dishes_dishes_portions[]
): PortionInput[] => {
  const portionNames = distinctstringsOneArray(a.map((p) => p.name));
  const portions = portionNames.map((name) => {
    const i = a.find((f) => f.name === name)!;
    return {
      name: i.name,
      quantity: {
        quantity: i.quantity.quantity,
        unit: measureToMeasureInput(i.quantity.unit),
      },
      price: i.price,
    };
  });

  return portions;
};
export const toOptionsForm = (
  options: dishes_dishes_options[]
): OptionsInput[] => {
  return options.length > 0
    ? options.map((o, i) => ({
        name: o.name,
        option: o.option,
      }))
    : [
        {
          name: "Vegetarian",
          option: OptionInput.NO,
        },
      ];
};
export const toComposableForm = (
  d: Composables_composables
): ComposablesInput => {
  return {
    name: d.name,
    type: d.type,
    img: d.img,
    composableRounds: d.composableRounds.map((i) => ({
      ...i,
      number: i.number,
      foods: i.foods.map((a) => mapToIngredientToQuantityToSalePriceInput(a)),
      recipes: i.recipes.map((a) =>
        mapToIngredientToQuantityToSalePriceInputForRecipe(a)
      ),
    })),
    basePrice: d.basePrice,
  };
};

export const healthlabelInfoToOutput = (
  input: dishes_dishes_healthLabelInfo
): HealthLabelInfo => {
  return {
    lupine: input.lupine,
    meat: input.meat,
    nut: input.nut,
    peanut: input.peanut,
    shellfish: input.shellfish,
    selery: input.selery,
    sesame: input.sesame,
    soy: input.soy,
    fish: input.fish,
    crustacean: input.crustacean,
    sulphide: input.sulphide,
    mustard: input.mustard,
    dairy: input.dairy,
    gluten: input.gluten,
    egg: input.egg,
    milk: input.milk,
    pork: input.pork,
    mollusk: input.mollusk,
  };
};

export const toDishForm = (
  d: dishes_dishes,
  manualPortion:
    | {
        quantity: QuantityInput;
        __typename: "Portion";
        price: number;
        name: string;
      }
    | undefined,
  recipes: QuantityToNameIdAndNutritionInput[],
  products: QuantityToNameIdAndNutritionInput[]
): UpdateDishVariables => {
  const dishPortion = manualPortion
    ? manualPortion
    : {
        name: small.name,
        quantity: {
          quantity: small.quantity.quantity,
          unit: d.hoursWorked
            ? measureToMeasureInput(d.hoursWorked.quantity.unit)
            : small.quantity.unit,
        },
        price: small.price,
      };
  return {
    id: d.id,
    healthLabels: healthlabelInfoToOutput(d.healthLabelInfo),
    products: {
      dishPortion: dishPortion,
      ingredients: products,
    },
    options: toOptionsForm(d.options),
    hours: toHoursForm(d.hoursWorked),
    portions: toPortionsInput(d.portions),
    input: {
      name: d.name,
      type: d.type,
      urls: d.img,
      method: d.method,
    },
    recipes: {
      dishPortion: dishPortion,
      ingredients: recipes,
    },
  };
};

export const ToQuantityToNameIdAndNutritionInputProd = (
  a: Dish_dish_products_foods[]
): QuantityToNameIdAndNutritionInput[] => {
  const result = a.map((i) => ({
    name: i.food.id,
    id: i.food.id,
    quantity: i.quantity,
    img: i.food.img,
    nutrition: totalNutrition(
      a.map((p) =>
        scaleNutrition(
          getFraction(p.food.nutrition.quantity, p.quantity),
          p.food.nutrition.nutrition
        )
      )
    ),
  }));
  return result;
};
