import { ComposablesInput } from "../../../globalTypes";
import { emptyComposableRound } from "../../../screens/clientbase/createClientPage/forms/ComposableDishDialog";
import { small } from "../portions/PortionInput";

export const emptyComposable: ComposablesInput = {
  img: [""],
  name: "Create Your Own",
  type: "Test Type",
  composableRounds: [emptyComposableRound(1)],
  basePrice: 1,
};
