import { LineChart } from "@mui/x-charts/LineChart";
import {
  Checkbox,
  Table,
  TableHead,
  TableCell,
  Divider,
  Dialog,
  TableRow,
  Typography,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  Slide,
  Card,
  CardMedia,
} from "@mui/material";
import React, { PureComponent, useState } from "react";
import { AddPageBox } from "../../../../components/Boxes";
import { Loader } from "../../../../components/Loading/Loader";
import { H6Left, H6 } from "../../../../components/TextTypes";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import {
  GridRowFiveItems,
  GridRowFourItems,
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import {
  getFraction,
  getFractionWithServing,
} from "../../../../components/utils/GetFraction";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { writeText } from "../../../../components/utils/WriteText";
import {
  AmountPerQuantityInput,
  IngredientToPriceInput,
  MeasureInput,
  PortionInput,
  QuantityInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import { readFileAsDataURL } from "../../../clientbase/components/imgReader/ReadFileAsDataUrl";
import { ImageSection } from "../../../clientbase/createClientPage/forms/DishCard/ImageSection";
import {
  allergiesToHealthLabelInfo,
  dishHealthLabels,
  foundToHealthLabelInfo,
  recipeHealthLabels,
} from "../../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import { DishDialogOptions } from "../addDish";
import { Methods } from "../addDish/Method";
import { UpdateDishVariables } from "../addDish/types/UpdateDish";
import {
  displayQuantity,
  roundToZeroOr2,
  scaleQuantity,
} from "../../../../components/utils/DisplayQuantity";
import { standard, standardMl } from "../components/StandardWeight";
import { totalKcal, totalPrice } from "../components/TotalKcalForDish";
import {
  Recipe,
  Recipe_recipe_products_foods_food_measurementInfo,
} from "../../recipes/types/Recipe";
import { AmountPerQuantityToProduct } from "..";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import {
  TextOrQuantityFull,
  TextOrSelectProduct,
} from "../../../../components/forms/formOrEdit/FormFieldEdit";
import { ExtraInfoSection } from "../../../clientbase/createClientPage/forms/DishCard/ExtraInfoSection";
import {
  emptyQuantityToNameIdAndNutritionInput,
  fullyEmptyQuantityToNameIdAndNutritionInput,
} from "../../../../components/defaults/baseProd/IngredientToQuantityToSalePriceInput";
import { VscAdd, VscEdit } from "react-icons/vsc";
import { CurrentMenu_currentMenu_combos_dishes } from "../../../clientbase/types/CurrentMenu";
import { SubmitButton } from "../../../../components/Buttons";
import {
  PortionWithNutrition,
  QuantityToRecipe,
} from "../../../clientbase/types/types";
import { FormFieldInputAdornmentNumber } from "../../../../components/forms/singleForms/FormField";
import { FormQuantity } from "../../recipes/addrecipes/CreateYourRecipe";
import { formMeasureInputOptions } from "../../../../components/defaults/portions/PortionInput";
import {
  AllergyForm,
  IdToHealthLabel,
  emptyAllergyForm,
  getHealthLabels,
} from "../../../../components/edamam/GetNutrition";
import {
  PriceForIngredient,
  PriceForIngredient_priceForIngredient,
} from "../../ingredienttoprice/types/PriceForIngredient";
import { usePriceForIngredientQuery } from "../../ingredienttoprice/api";
import {
  Arrow,
  DisplaySlide,
  distinctStringArray,
} from "../../../clientbase/components/courses/DisplayDishButton";
import { UpdateRecipeVariables } from "../../recipes/addrecipes/types/UpdateRecipe";
import { emptyRecipeForDish } from "../../../../components/defaults/RecipeForDish/EmptyDishComponents";
import { emptyRecipeForm } from "../../../../components/defaults/UpdateRecipeForm/UpdateRecipeForm";
import { useLazydIngredientsForRecipeQuery } from "../../recipes/api";
import { resultToRecipesInput } from "../../../clientbase/components/mappers/DishResultToAddDishVariables";
import {
  measureToMeasureInput,
  quantityToInput,
} from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import {
  ToQuantityToNameIdAndNutritionInput,
  ToQuantityToNameIdAndNutritionInputProd,
} from "../../recipes/components/Mapping";
import { RecipeRow } from "../../recipes/recipeDialog";
import { MethodSection } from "../../../clientbase/createClientPage/forms/DishCard/MethodSection";
import {
  aestheticBackground,
  backgroundContainer,
  buttonColor,
} from "../../../../components/layouts/Colors";

export const sortDishes = (dishes: CurrentMenu_currentMenu_combos_dishes[]) =>
  dishes.sort((a, b) => {
    return a.round - b.round;
  });
export const sortPortionsWithNutrition = (portions: PortionWithNutrition[]) =>
  portions
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    })
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    });
export const sortPortions = (portions: PortionInput[]) =>
  portions
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    })
    .sort((a, b) => {
      if (a.name === "Manual" && b.name !== "Manual") {
        return -1;
      } else if (a.name !== "Manual" && b.name === "Manual") return 1;
      else return a.quantity.quantity - b.quantity.quantity;
    });

export const hourlyRate = 10;

export const MobileDishSlider = ({ urls }: { urls: string[] }) => {
  const [index, setindex] = useState(0);
  const content = urls[index];
  const numSlides = urls.length;

  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] = useState<
    "left" | "right" | "down" | "up" | undefined
  >("down");

  const onArrowClick = (
    direction: "left" | "right" | "down" | "up" | undefined
  ) => {
    const increment = direction === "left" ? -1 : 1;
    const newIndex = (index + increment + numSlides) % numSlides;

    const oppDirection = direction === "left" ? "right" : "left";
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
      setindex(newIndex);
      setSlideDirection(oppDirection);
      setSlideIn(true);
    }, 500);
  };
  if (!content) return <></>;
  return (
    <>
      <GridRowThreeItems
        before={0}
        firstlength={1}
        inbetweenfirsttwo={0}
        secondlength={10}
        inbetweensecondtwo={0}
        thirdlength={1}
        firstchild={
          <Arrow direction="left" clickFunction={() => onArrowClick("left")} />
        }
        secondchild={
          <Slide in={slideIn} direction={slideDirection}>
            <div>
              <DisplaySlideForMobile url={content} height={100} width={100} />
            </div>
          </Slide>
        }
        thirdchild={
          <Arrow
            direction="right"
            clickFunction={() => onArrowClick("right")}
          />
        }
      />
    </>
  );
};

export const DisplaySlideForMobile = ({
  url,
  height,
  width,
}: {
  url: string;
  height: number;
  width: number;
}) => {
  return (
    <>
      <Box
        alignSelf={"center"}
        component="img"
        src={url ? url : ""}
        sx={{ height: `${height}%`, width: `${width}%`, borderRadius: 10 }}
      />
    </>
  );
};

const PortionWritten = ({
  productToPrice,
  form,
  p,
  dishdialogoptions,
  costs,
}: {
  costs: boolean;
  form: UpdateDishVariables;
  p: PortionInput;
  productToPrice: AmountPerQuantityToProduct[] | undefined;
  dishdialogoptions: DishDialogOptions;
}) => {
  const fr = getFraction(form.products.dishPortion.quantity, p.quantity);

  const labour =
    form.hours && form.hours.amount
      ? hourlyRate *
        roundToZeroOr2(
          form.hours.amount * getFraction(form.hours?.quantity, p.quantity)
        )
      : 0;

  const procurement = totalPrice(form, productToPrice) * fr;
  const price = p.price ? p.price : 0;
  const margin = price - labour - procurement;
  return (
    <Box sx={{ width: "100vh", mt: 0 }}>
      <H6Left title={p.name} />
      <Typography variant="body1">
        {displayQuantity({
          quantity: p.quantity.quantity,
          unit: p.quantity.unit,
        })}
      </Typography>
      <br />
      {((form.products && form.products.ingredients.length > 0) ||
        (form.recipes && form.recipes.ingredients.length > 0)) &&
        dishdialogoptions.calories && (
          <>{`${roundToZeroOr2(totalKcal(form, fr))} kcal`}</>
        )}
      <br />
      {(form.products || form.recipes) &&
        (form.products.ingredients.length > 0 ||
          form.recipes.ingredients.length > 0) &&
        costs && (
          <>
            <GridRowOneItem
              before={0}
              after={0}
              child={
                <b>{`Margin: €${p.price ? roundToZeroOr2(margin) : "-"} (${
                  p.price ? ((margin / price) * 100).toFixed(2) : "-"
                }%)`}</b>
              }
            />
            <GridRowOneItem
              before={0}
              after={0}
              child={`Sale Price: €${p.price ? roundToZeroOr2(price) : "-"}`}
            />
            <GridRowOneItem
              before={0}
              after={0}
              child={`Labor Costs: €${roundToZeroOr2(labour)}`}
            />
            <GridRowOneItem
              before={0}
              after={0}
              child={`Ingredient Costs: €${roundToZeroOr2(procurement)}`}
            />
          </>
        )}
    </Box>
  );
};
export const DishContent = ({
  loading,
  loadingRecipes,
  recipes,
  edit,
  form,
  setForm,
  dishdialogoptions,
  setdishdialogoptions,
  productToPrice,
  costs,
  setopenmeasurementchange,
  isMobile,
}: {
  isMobile: boolean;
  setopenmeasurementchange: (a: boolean) => void;
  costs: boolean;
  loadingRecipes: boolean;
  productToPrice: AmountPerQuantityToProduct[] | undefined;
  loading: boolean;
  recipes: QuantityToRecipe[];
  edit: boolean;
  dishdialogoptions: DishDialogOptions;
  setdishdialogoptions: (a: DishDialogOptions) => void;
  form: UpdateDishVariables;
  setForm: (a: UpdateDishVariables) => void;
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>(
    returnStringsIfNotNullOrUndefined(form.input.urls)
  );
  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);

        setForm({
          ...form,
          input: {
            ...form.input,
            urls: urls,
          },
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };

  const data = form.portions
    .filter((p) => p.name !== "Manual")
    .sort((a, b) => (a.price ?? 0) - (b.price ?? 0))
    .map((p, index) => ({
      name: p.name,
      sale: p.price ? p.price : 0,
      costs: Number(
        (productToPrice && productToPrice.length > 0
          ? totalPrice(form, productToPrice) *
            getFraction(form.products.dishPortion.quantity, p.quantity)
          : 1
        ).toFixed(2)
      ),
      labour: Number((p.price ? p.price * 0.35 : 2).toFixed(2)),
    }));
  if (isMobile)
    return (
      <Stack direction="column" spacing={1}>
        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <Stack direction={"row"}>
              {form.input.urls && form.input.urls.length > 0 ? (
                <MobileDishSlider urls={form.input.urls} />
              ) : (
                <Box sx={{ height: "100%", width: "100%", borderRadius: 10 }}>
                  "No Picture loaded yet.."
                </Box>
              )}
            </Stack>
          }
        />
        <>
          <Box sx={{ mt: 2, backgroundColor: buttonColor, borderRadius: 10 }}>
            <GridRowOneItem
              before={0.5}
              after={1}
              child={<H6Left title="Instructions:" />}
            />
          </Box>
          <Typography
            style={{ fontFamily: "FairField Display, serif", fontSize: "16px" }}
          >
            {writeText(form.input.method)}
          </Typography>
        </>
        <Box sx={{ mt: 2, backgroundColor: buttonColor, borderRadius: 10 }}>
          <GridRowTwoItems
            before={0}
            inbetween={0}
            firstlength={3}
            secondlength={8}
            firstchild={<></>}
            secondchild={
              form.portions.length === 1 ? (
                <GridRowOneItem
                  before={3}
                  after={3}
                  child={displayQuantity(form.portions[0].quantity)}
                />
              ) : form.portions.length === 2 ? (
                <GridRowTwoItems
                  before={2}
                  inbetween={1}
                  firstchild={displayQuantity(form.portions[0].quantity)}
                  secondchild={displayQuantity(form.portions[1].quantity)}
                  secondlength={4}
                  firstlength={4}
                />
              ) : form.portions.length === 3 ? (
                <GridRowThreeItems
                  before={1}
                  inbetweenfirsttwo={1}
                  inbetweensecondtwo={1}
                  firstchild={displayQuantity(form.portions[0].quantity)}
                  secondchild={displayQuantity(form.portions[1].quantity)}
                  thirdchild={displayQuantity(form.portions[2].quantity)}
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                />
              ) : (
                <GridRowFourItems
                  before={0.5}
                  inbetweenfirsttwo={0.5}
                  inbetweensecondtwo={0.5}
                  firstchild={displayQuantity(form.portions[0].quantity)}
                  secondchild={displayQuantity(form.portions[1].quantity)}
                  thirdchild={displayQuantity(form.portions[2].quantity)}
                  fourthchild={displayQuantity(form.portions[3].quantity)}
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                  fourthlength={2.5}
                  inbetweenthirdtwo={0.5}
                />
              )
            }
          />
        </Box>
        {loading && <Loader loading={loading} />}
        {form.products.ingredients.length > 0 &&
          form.products.ingredients.map((r, ind) => {
            return (
              <Row
                isMobile={isMobile}
                loadingRecipes={false}
                recipes={recipes}
                productIndex={ind}
                form={form}
                setForm={setForm}
                edit={false}
                ingredient={true}
                portions={form.portions}
                key={ind}
                product={r}
                productToPrice={productToPrice}
                dishdialogoptions={{
                  calories: false,
                  costs: false,
                }}
              />
            );
          })}
        {form.recipes.ingredients.length > 0 &&
          form.recipes.ingredients.map((r, ind) => (
            <Row
              isMobile={isMobile}
              loadingRecipes={loadingRecipes}
              recipes={recipes}
              productIndex={ind}
              form={form}
              setForm={setForm}
              edit={false}
              ingredient={false}
              portions={form.portions}
              key={ind}
              product={r}
              productToPrice={productToPrice}
              dishdialogoptions={{
                calories: false,
                costs: false,
              }}
            />
          ))}
      </Stack>
    );
  return (
    <>
      {costs ? (
        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <>
              <ComposedChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis label="€" type="number" domain={[0, "dataMax + 3"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="sale" stroke="#ff7300" />
                <Bar dataKey="costs" stackId="a" fill="#82ca9d" />
                <Bar dataKey="labour" stackId="a" fill="#4C6A66" />
              </ComposedChart>
            </>
          }
        />
      ) : (
        <GridRowOneItem
          before={1}
          after={0.5}
          child={
            <>
              {edit ? (
                <ImageSection
                  handleChangeFile={handleChangeFile}
                  imageUrls={imageUrls}
                  urls={returnStringsIfNotNullOrUndefined(form.input.urls)}
                  setNoPic={() =>
                    setForm({ ...form, input: { ...form.input, urls: [""] } })
                  }
                />
              ) : (
                <>
                  {form.input.urls && form.input.urls.length > 0 ? (
                    form.input.urls.map((url, ii) => (
                      <>
                        {ii <= 3 && (
                          <DisplayImage img={url} height={75} width={25} />
                        )}
                      </>
                    ))
                  ) : (
                    <Box sx={{ height: "75%", width: "30%", borderRadius: 10 }}>
                      "No Picture loaded yet.."
                    </Box>
                  )}
                </>
              )}
            </>
          }
        />
      )}
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <AddPageBox
            mt={0}
            w={"90wh"}
            childComp={
              <>
                <ExtraInfoSection
                  form={form}
                  setForm={setForm}
                  edit={edit}
                  isMobile={isMobile}
                />
                <GridRowOneItem before={0.5} after={0.5} child={<Divider />} />
                <GridRowOneItem
                  before={0.5}
                  after={0.5}
                  child={
                    <>
                      <Box
                        sx={{
                          mt: 2,
                          backgroundColor: buttonColor,
                          borderRadius: 10,
                        }}
                      >
                        <GridRowTwoItems
                          before={0.5}
                          inbetween={0}
                          firstlength={3}
                          secondlength={8}
                          firstchild={
                            <Box>
                              <GridRowTwoItems
                                before={0.5}
                                inbetween={0.5}
                                firstlength={8}
                                firstchild={
                                  <Box sx={{ mt: 1, mb: 1 }}>Show Kcal</Box>
                                }
                                secondchild={
                                  <Checkbox
                                    color="secondary"
                                    value={dishdialogoptions.calories}
                                    onChange={(e) =>
                                      setdishdialogoptions({
                                        calories: e.target.checked,
                                        costs: dishdialogoptions.costs,
                                      })
                                    }
                                  />
                                }
                                secondlength={3}
                              />
                              {costs && <>(€{hourlyRate} p/hour)</>}
                              <H6Left title="Products" />
                            </Box>
                          }
                          secondchild={
                            !edit ? (
                              <GridRowTwoItems
                                before={0}
                                inbetween={0}
                                firstlength={0}
                                secondlength={12}
                                firstchild={<></>}
                                secondchild={
                                  form.portions.length === 1 ? (
                                    <GridRowOneItem
                                      before={3}
                                      after={3}
                                      child={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[0]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                    />
                                  ) : form.portions.length === 2 ? (
                                    <GridRowTwoItems
                                      before={2}
                                      inbetween={1}
                                      firstchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[0]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[1]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondlength={4}
                                      firstlength={4}
                                    />
                                  ) : form.portions.length === 3 ? (
                                    <GridRowThreeItems
                                      before={1}
                                      inbetweenfirsttwo={1}
                                      inbetweensecondtwo={1}
                                      firstchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[0]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[1]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      thirdchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[2]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondlength={2.5}
                                      firstlength={2.5}
                                      thirdlength={2.5}
                                    />
                                  ) : (
                                    <GridRowFourItems
                                      before={0.5}
                                      inbetweenfirsttwo={0.5}
                                      inbetweensecondtwo={0.5}
                                      firstchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[0]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[1]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      thirdchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[2]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      fourthchild={
                                        <PortionWritten
                                          costs={costs}
                                          form={form}
                                          p={form.portions[3]}
                                          productToPrice={productToPrice}
                                          dishdialogoptions={dishdialogoptions}
                                        />
                                      }
                                      secondlength={2.5}
                                      firstlength={2.5}
                                      thirdlength={2.5}
                                      fourthlength={2.5}
                                      inbetweenthirdtwo={0.5}
                                    />
                                  )
                                }
                              />
                            ) : (
                              <Box sx={{ width: "100vh" }}>
                                <Typography variant="h6">
                                  <TextOrQuantityFull
                                    setopenmeasurementchange={
                                      setopenmeasurementchange
                                    }
                                    value={form.products.dishPortion.quantity}
                                    setValue={(a) => {
                                      const oldQuantities = [...form.portions];
                                      const oldManual = oldQuantities.find(
                                        (q) => q.name === "Manual"
                                      );
                                      const indexOfOldManual =
                                        oldManual &&
                                        oldQuantities.indexOf(oldManual);
                                      if (indexOfOldManual !== undefined) {
                                        oldQuantities[indexOfOldManual] = {
                                          name: "Manual",
                                          quantity: a,
                                        };
                                      }
                                      setForm({
                                        ...form,
                                        portions: oldQuantities,
                                        products: {
                                          ...form.products,
                                          dishPortion: {
                                            ...form.products.dishPortion,
                                            quantity: a,
                                          },
                                        },
                                        recipes: {
                                          ...form.recipes,
                                          dishPortion: {
                                            ...form.recipes.dishPortion,
                                            quantity: a,
                                          },
                                        },
                                      });
                                    }}
                                  />
                                </Typography>
                              </Box>
                            )
                          }
                        />
                      </Box>
                      {loading && <Loader loading={loading} />}
                      {
                        <>
                          {form.products.ingredients.length > 0 &&
                            form.products.ingredients.map((r, ind) => {
                              return (
                                <Row
                                  isMobile={isMobile}
                                  loadingRecipes={false}
                                  recipes={recipes}
                                  productIndex={ind}
                                  form={form}
                                  setForm={setForm}
                                  edit={edit}
                                  ingredient={true}
                                  portions={form.portions}
                                  key={ind}
                                  product={r}
                                  productToPrice={productToPrice}
                                  dishdialogoptions={{
                                    calories: dishdialogoptions.calories,
                                    costs: costs,
                                  }}
                                />
                              );
                            })}
                          {form.recipes.ingredients.length > 0 &&
                            form.recipes.ingredients.map((r, ind) => (
                              <Row
                                isMobile={isMobile}
                                loadingRecipes={loadingRecipes}
                                recipes={recipes}
                                productIndex={ind}
                                form={form}
                                setForm={setForm}
                                edit={edit}
                                ingredient={false}
                                portions={form.portions}
                                key={ind}
                                product={r}
                                productToPrice={productToPrice}
                                dishdialogoptions={{
                                  calories: dishdialogoptions.calories,
                                  costs: costs,
                                }}
                              />
                            ))}
                          {edit && (
                            <TableRow>
                              <TableCell>
                                <Button
                                  sx={{ borderRadius: 28 }}
                                  color="inherit"
                                  variant="outlined"
                                  onClick={() => {
                                    const old = form.products.ingredients;
                                    old.push(
                                      fullyEmptyQuantityToNameIdAndNutritionInput
                                    );
                                    const variables = {
                                      ...form,
                                      products: {
                                        ...form.products,
                                        ingredients: old,
                                      },
                                    };
                                    setForm(variables);
                                  }}
                                >
                                  <VscAdd /> Ingredient
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  sx={{ borderRadius: 28 }}
                                  color="inherit"
                                  variant="outlined"
                                  onClick={() => {
                                    const old = form.recipes.ingredients;
                                    old.push(
                                      emptyQuantityToNameIdAndNutritionInput
                                    );
                                    const variables = {
                                      ...form,
                                      recipes: {
                                        ...form.recipes,
                                        ingredients: old,
                                      },
                                    };
                                    setForm(variables);
                                  }}
                                >
                                  <VscAdd /> Recipe
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      }
                      <Box>
                        <Box
                          sx={{
                            mt: 2,
                            backgroundColor: buttonColor,
                            borderRadius: 10,
                          }}
                        >
                          <GridRowOneItem
                            before={0.5}
                            after={1}
                            child={<H6Left title="Instructions:" />}
                          />
                        </Box>
                        <GridRowOneItem
                          before={0.5}
                          after={0.5}
                          child={
                            <>
                              {!edit ? (
                                <>{writeText(form.input.method)}</>
                              ) : (
                                <>
                                  <Methods
                                    method={form.input.method}
                                    setMethod={(a) => {
                                      setForm({
                                        ...form,
                                        input: {
                                          ...form.input,
                                          method: a,
                                        },
                                      });
                                    }}
                                  />
                                </>
                              )}
                            </>
                          }
                        />
                      </Box>
                    </>
                  }
                />
              </>
            }
          />
        }
      />
    </>
  );
};

interface RowComponent {
  isMobile: boolean;
  loadingRecipes: boolean;
  recipes: QuantityToRecipe[];
  form: UpdateDishVariables;
  setForm: (a: UpdateDishVariables) => void;
  edit: boolean;
  ingredient: boolean;
  productToPrice: AmountPerQuantityToProduct[] | undefined;
  portions: PortionInput[];
  dishdialogoptions: DishDialogOptions;
  product: QuantityToNameIdAndNutritionInput;
  productIndex: number;
}

const Row: React.FC<RowComponent> = ({
  edit,
  product,
  dishdialogoptions,
  portions,
  productToPrice,
  ingredient,
  form,
  setForm,
  productIndex,
  recipes,
  loadingRecipes,
  isMobile,
}) => {
  const r = product;
  const measurementInfo:
    | Recipe_recipe_products_foods_food_measurementInfo
    | null
    | undefined = {
    __typename: "MeasurementInfo",
    serving: r.measurementInfo?.serving ? r.measurementInfo.serving : 0.0,
    cup: r.measurementInfo?.cup ? r.measurementInfo.cup : 0.0,
    tablespoon: r.measurementInfo?.tablespoon
      ? r.measurementInfo.tablespoon
      : 0.0,
    teaspoon: r.measurementInfo?.teaspoon ? r.measurementInfo.teaspoon : 0.0,
    whole: r.measurementInfo?.whole ? r.measurementInfo.whole : 0.0,
  };
  const foundProductToPrice =
    productToPrice && productToPrice.find((f) => f.product.id === r.id);

  const foundprice = foundProductToPrice
    ? foundProductToPrice.amountPerQuantity
    : { amount: 0, quantity: product.quantity };
  const [allergies, setallergies] = useState<AllergyForm>({
    food: [],
    recipe: form.recipes.ingredients.map((i) => ({
      id: i.id,
      healthLabelInfo: i.healthlabelInfo,
    })),
  });
  const [foodid, setfoodid] = useState("");
  const [openfood, setopenfood] = useState(false);
  const [openEditPrice, setOpenEditPrice] = useState(false);

  const [openRecipe, setopenRecipe] = useState(false);
  const [updateRecipeForm, setRecipeForm] = useState<UpdateRecipeVariables>({
    ...emptyRecipeForm,
    input: {
      ...emptyRecipeForm.input,
      name: r.name,
      quantity: r.quantity,
    },
  });
  const { getIngredientsForRecipe, loading } =
    useLazydIngredientsForRecipeQuery({
      id: r.id,
      quantity: r.quantity,
      onCompleted: (result: Recipe) =>
        setRecipeForm({
          ...updateRecipeForm,
          products: {
            dishPortion: {
              name: result.recipe.products.dishPortionOutput.name,
              quantity: quantityToInput(
                result.recipe.products.dishPortionOutput.quantity
              ),
            },
            ingredients: ToQuantityToNameIdAndNutritionInputProd(
              result.recipe.products && result.recipe.products.foods
                ? result.recipe.products.foods
                : []
            ),
          },
          recipes: {
            dishPortion: {
              name: result.recipe.recipes.dishPortion.name,
              quantity: quantityToInput(
                result.recipe.recipes.dishPortion.quantity
              ),
            },
            ingredients: ToQuantityToNameIdAndNutritionInput(
              result.recipe.recipes && result.recipe.recipes.recipes
            ),
          },
        }),
    });

  const getText = (portion: PortionInput) => {
    const fracPortion = getFractionWithServing(
      measurementInfo,
      form.products.dishPortion.quantity,
      portion.quantity
    );

    const fracN = ingredient
      ? getFractionWithServing(
          measurementInfo,
          product.quantity.unit.label.toLowerCase() === "milliliter"
            ? standardMl
            : standard,
          {
            quantity: product.quantity.quantity * fracPortion,
            unit: product.quantity.unit,
          }
        )
      : 1;

    const scaledQuantity = scaleQuantity({
      quantity: product.quantity.quantity * fracPortion,
      unit: product.quantity.unit,
    });

    const finalprice =
      getFractionWithServing(
        measurementInfo,
        foundprice.quantity,
        scaledQuantity
      ) * foundprice.amount;

    return {
      quantity: displayQuantity({
        quantity: product.quantity.quantity * fracPortion,
        unit: product.quantity.unit,
      }),
      kcal: `${roundToZeroOr2(
        ingredient
          ? product.nutrition.kcal * fracN
          : product.nutrition.kcal * fracPortion
      )} 
              kcal`,
      price: `€${isNaN(finalprice) ? "-" : roundToZeroOr2(finalprice)} `,
    };
  };
  return (
    <>
      <ExtraRecipeDialog
        updateRecipeForm={updateRecipeForm}
        setopen={setopenRecipe}
        open={openRecipe}
        loading={loading}
      />
      <Box sx={{ mt: 1, borderRadius: 10 }}>
        {isMobile ? (
          <GridRowTwoItems
            before={0.5}
            inbetween={0}
            firstlength={3}
            secondlength={8}
            firstchild={
              <>
                {!ingredient ? (
                  <Box
                    sx={{ mr: 2 }}
                    onClick={() => {
                      getIngredientsForRecipe();
                      setopenRecipe(true);
                    }}
                  >
                    <Typography variant="body2">{product.name}</Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body2">{product.name}</Typography>
                  </Box>
                )}
              </>
            }
            secondchild={
              sortPortions(portions).length === 1 ? (
                <GridRowOneItem
                  before={3}
                  after={3}
                  child={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                />
              ) : sortPortions(portions).length === 2 ? (
                <GridRowTwoItems
                  before={2}
                  inbetween={1}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={4}
                  firstlength={4}
                />
              ) : sortPortions(portions).length === 3 ? (
                <GridRowThreeItems
                  before={1}
                  inbetweenfirsttwo={1}
                  inbetweensecondtwo={1}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  thirdchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[2]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[2]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[2]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                />
              ) : (
                <GridRowFourItems
                  before={0.5}
                  inbetweenfirsttwo={0.5}
                  inbetweensecondtwo={0.5}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  thirdchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[2]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[2]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[2]).price}</>
                      )}
                    </Typography>
                  }
                  fourthchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[3]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[3]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[3]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                  fourthlength={2.5}
                  inbetweenthirdtwo={0.5}
                />
              )
            }
          />
        ) : (
          <GridRowThreeItems
            before={0}
            firstlength={1}
            inbetweenfirsttwo={0}
            inbetweensecondtwo={0}
            secondlength={edit ? 9 : 2.5}
            thirdlength={edit ? 1 : 7.5}
            firstchild={
              <Box>
                <Button
                  style={{
                    height: "25%",
                    borderRadius: 28,
                    color: "inherit",
                    borderColor: "inherit",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setfoodid(product.id);
                    setopenfood(true);
                  }}
                >
                  $
                </Button>
              </Box>
            }
            secondchild={
              edit ? (
                <TextOrSelectProduct
                  deleteIngredient={() => {
                    const old = [...form.products.ingredients];
                    old.splice(productIndex, 1);

                    setForm({
                      ...form,
                      products: {
                        ...form.products,
                        ingredients: old,
                      },
                    });
                  }}
                  loadingRecipes={loadingRecipes}
                  recipes={recipes}
                  ingredient={ingredient}
                  index={productIndex}
                  form={form}
                  setForm={(f) => {
                    const index = [...form.products.ingredients]
                      .map((p) => p.id)
                      .indexOf(product.id);
                    const old = [...form.products.ingredients];
                    old[index] = [...f.products.ingredients][index];
                    setallergies({
                      food: ingredient ? [] : allergies.food,
                      recipe: allergies.recipe,
                    });
                    setallergies({
                      food: ingredient ? [] : allergies.food,
                      recipe: allergies.recipe,
                    });
                    ingredient &&
                      f.products.ingredients.length > 0 &&
                      getHealthLabels(
                        f.products.ingredients.map((i) => i.id),
                        allergies.food.filter((a) =>
                          f.products.ingredients
                            .map((i) => i.id)
                            .includes(a.foodid)
                        ),
                        (a) => {
                          const old = [...f.products.ingredients];
                          const updated = old.map((i) => {
                            const found = a.find(
                              (item) => item.foodid === i.id
                            );
                            const toreturn =
                              found !== undefined
                                ? {
                                    ...i,
                                    healthlabelInfo: foundToHealthLabelInfo(
                                      found!
                                    ),
                                  }
                                : i;

                            return toreturn;
                          });
                          const newAllergies: AllergyForm = {
                            food: a,
                            recipe: allergies.recipe,
                          };
                          setallergies(newAllergies);

                          setForm({
                            ...f,
                            healthLabels: allergiesToHealthLabelInfo(allergies),
                            products: {
                              ...form.products,
                              ingredients: updated,
                            },
                          });
                        }
                      );
                    !ingredient &&
                      setForm({
                        ...f,
                        healthLabels: dishHealthLabels(
                          f.products.ingredients,
                          recipeHealthLabels(f.recipes.ingredients)
                        ),
                      });
                  }}
                />
              ) : (
                <>
                  {!ingredient ? (
                    <Box
                      onClick={() => {
                        getIngredientsForRecipe();
                        setopenRecipe(true);
                      }}
                    >
                      {product.name}
                    </Box>
                  ) : (
                    <Box>{product.name}</Box>
                  )}
                </>
              )
            }
            thirdchild={
              edit ? (
                <></>
              ) : sortPortions(portions).length === 1 ? (
                <GridRowOneItem
                  before={3}
                  after={3}
                  child={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                />
              ) : sortPortions(portions).length === 2 ? (
                <GridRowTwoItems
                  before={2}
                  inbetween={1}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={4}
                  firstlength={4}
                />
              ) : sortPortions(portions).length === 3 ? (
                <GridRowThreeItems
                  before={1}
                  inbetweenfirsttwo={1}
                  inbetweensecondtwo={1}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  thirdchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[2]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[2]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[2]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                />
              ) : (
                <GridRowFourItems
                  before={0.5}
                  inbetweenfirsttwo={0.5}
                  inbetweensecondtwo={0.5}
                  firstchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[0]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[0]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[0]).price}</>
                      )}
                    </Typography>
                  }
                  secondchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[1]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[1]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[1]).price}</>
                      )}
                    </Typography>
                  }
                  thirdchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[2]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[2]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[2]).price}</>
                      )}
                    </Typography>
                  }
                  fourthchild={
                    <Typography variant="body2">
                      {getText(sortPortions(portions)[3]).quantity}
                      <br />
                      {dishdialogoptions.calories &&
                        getText(sortPortions(portions)[3]).kcal}

                      <br />
                      {dishdialogoptions.costs && ingredient && (
                        <>{getText(sortPortions(portions)[3]).price}</>
                      )}
                    </Typography>
                  }
                  secondlength={2.5}
                  firstlength={2.5}
                  thirdlength={2.5}
                  fourthlength={2.5}
                  inbetweenthirdtwo={0.5}
                />
              )
            }
          />
        )}
      </Box>
      <GridRowOneItem before={0.5} after={0.5} child={<Divider />} />

      <ChangePriceForProductDialog
        setopen={setOpenEditPrice}
        open={openEditPrice}
        product={product}
        foundprice={foundprice.amount}
      />
      <PriceChartFood foodid={foodid} open={openfood} setOpen={setopenfood} />
    </>
  );
};

interface PriceChartFoodProps {
  foodid: string;
  open: boolean;
  setOpen: (bool: boolean) => void;
}
export const PriceChartFood: React.FC<PriceChartFoodProps> = ({
  foodid,
  open,
  setOpen,
}) => {
  const [result, setresult] = useState<PriceForIngredient_priceForIngredient>({
    __typename: "IngredientToPrice",
    ingredientid: foodid,
    ingredientname: "Unknown",
    priceperdate: [],
  });

  const { loading } = usePriceForIngredientQuery({
    id: foodid,
    onCompleted: (result: PriceForIngredient) =>
      result &&
      result.priceForIngredient &&
      setresult(result.priceForIngredient),
  });

  const distinctSuppliers = distinctStringArray(
    result.priceperdate.map((p) => p.suppliername)
  );

  const supplierToData = distinctSuppliers.map((s) => ({
    supplier: s,
    prices: result.priceperdate.filter((p) => p.suppliername === s),
  }));
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
          Prices for{" "}
          {result.priceperdate.length > 0 ? result.ingredientname : "item"}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: aestheticBackground }}>
          {!loading && (
            <LineChart
              width={500}
              height={300}
              series={supplierToData.map((std, index3) => ({
                data: std.prices.map((p) => p.price),
                label: `Price from ${
                  supplierToData.length > 0
                    ? supplierToData[index3].supplier
                    : ""
                }`,
                yAxisKey: "rightAxisId",
              }))}
              xAxis={supplierToData.map((std, index3) => ({
                scaleType: "point",
                data: std.prices.map((p) => p.date),
              }))}
              yAxis={[{ id: "leftAxisId" }, { id: "rightAxisId" }]}
              rightAxis="rightAxisId"
            />
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: aestheticBackground }}>
          <SubmitButton
            style={{ color: "inherit", backgroundColor: buttonColor }}
            onClick={() => setOpen(false)}
          >
            Close
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface ChangePriceForProductDialogProps {
  setopen: (a: boolean) => void;
  open: boolean;
  product: QuantityToNameIdAndNutritionInput;
  foundprice: number;
}
export const ChangePriceForProductDialog: React.FC<
  ChangePriceForProductDialogProps
> = ({ open, setopen, product, foundprice }) => {
  const [amount, setamount] = useState(String(product.quantity.quantity));

  return (
    <Dialog open={open} onClose={() => setopen(false)}>
      <DialogTitle>{`Change price for ${product.name}`}</DialogTitle>
      <DialogContent>
        <GridRowTwoItems
          before={0.5}
          firstlength={4}
          secondlength={5}
          inbetween={1}
          firstchild={
            <FormFieldInputAdornmentNumber
              label="Price"
              value={String(foundprice)}
              setValue={(a) => {}}
              input={`€`}
            />
          }
          secondchild={
            <FormQuantity
              amount={amount}
              setamount={setamount}
              options={formMeasureInputOptions(product.quantity.unit.label)}
              value={product.quantity}
              setValue={(a) => {}}
              label="Per"
            />
          }
        />
      </DialogContent>
      <DialogActions sx={{ backgroundColor: aestheticBackground }}>
        <GridRowTwoItems
          before={0.5}
          firstlength={4}
          secondlength={5}
          inbetween={1}
          firstchild={
            <SubmitButton
              variant="contained"
              sx={{ backgroundColor: buttonColor }}
            >
              Done
            </SubmitButton>
          }
          secondchild={
            <SubmitButton
              sx={{ backgroundColor: buttonColor }}
              variant="contained"
              onClick={() => setopen(false)}
            >
              Close
            </SubmitButton>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export const ExtraRecipeDialog = ({
  open,
  setopen,
  updateRecipeForm,
  loading,
}: {
  loading: boolean;
  open: boolean;
  setopen: (b: boolean) => void;
  updateRecipeForm: UpdateRecipeVariables;
}) => {
  return (
    <Dialog open={open} onClose={() => setopen(false)}>
      <DialogTitle>
        <Typography
          style={{
            fontFamily: "PlayFair Display, serif",
            whiteSpace: "nowrap",
          }}
        >
          {`${updateRecipeForm.input.name} 
        (${updateRecipeForm.input.quantity.quantity}
        ${updateRecipeForm.input.quantity.unit.label})`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading && <CircularProgress />}
        <Table>
          {updateRecipeForm.products.ingredients.length > 0 && (
            <>
              <TableHead>Ingredients:</TableHead>
              {updateRecipeForm.products.ingredients.map((ingred, index) => (
                <TableRow>
                  <TableCell>{ingred.name}</TableCell>
                  <TableCell>{ingred.quantity.quantity}</TableCell>
                  <TableCell>{ingred.quantity.unit.label}</TableCell>
                </TableRow>
              ))}
            </>
          )}
          {updateRecipeForm.recipes.ingredients.length > 0 && (
            <>
              <TableHead>Recipes:</TableHead>
              {updateRecipeForm.recipes.ingredients.map((ingred, index) => (
                <RecipeRow r={ingred} frac={1} />
              ))}
            </>
          )}
        </Table>
        <DialogActions>
          <SubmitButton
            variant="contained"
            color="inherit"
            onClick={() => setopen(false)}
          >
            Close
          </SubmitButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
