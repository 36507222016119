import {
  getFraction,
  getFractionWithServing,
} from "../../../../components/utils/GetFraction";
import {
  MeasurementInfoInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import { scaleNutrition } from "../../../clientbase/components/courses/Functions";
import { toNutritionInput } from "../../../clientbase/components/mappers/nutrition/ToNutritionInput";
import { measureToMeasureInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { healthlabelInfoToOutput } from "../../dishes/utils/MapToInput";
import { recipes_recipes } from "../../recipesAndIngredients/types/recipes";
import { RecipeForm } from "../addrecipes/types";
import {
  Recipe_recipe_products_foods,
  Recipe_recipe_products_foods_food_measurementInfo,
  Recipe_recipe_recipes_recipes,
} from "../types/Recipe";

export const toRecipeForm = (r: recipes_recipes): RecipeForm => {
  return {
    info: {
      name: r.name,
      type: r.type ? r.type : "",
      quantity: {
        quantity: r.quantity.quantity,
        unit: measureToMeasureInput(r.quantity.unit),
      },
      img: r.img,
    },
    method: r.method,
    nutrition: r.nutrition.nutrition,
    products: [],
    recipes: [],
    healthLabels: healthlabelInfoToOutput(r.healthLabelInfo),
  };
};

export const ToQuantityToNameIdAndNutritionInputProd = (
  a: Recipe_recipe_products_foods[]
): QuantityToNameIdAndNutritionInput[] => {
  return a.map((i) => {
    const measurements: Recipe_recipe_products_foods_food_measurementInfo = {
      __typename: "MeasurementInfo",
      serving: i.food.measurementInfo?.serving
        ? i.food.measurementInfo.serving
        : 0.0,
      cup: i.food.measurementInfo?.cup ? i.food.measurementInfo.cup : 0.0,
      tablespoon: i.food.measurementInfo?.tablespoon
        ? i.food.measurementInfo.tablespoon
        : 0.0,
      teaspoon: i.food.measurementInfo?.teaspoon
        ? i.food.measurementInfo.teaspoon
        : 0.0,
      whole: i.food.measurementInfo?.whole ? i.food.measurementInfo.whole : 0.0,
    };

    const frac = i.quantity.unit.label === i.food.nutrition.quantity.unit.label ? 1 : getFractionWithServing(
      measurements,
      i.food.nutrition.quantity,
      i.quantity
    );

    return {
      measurementInfo: measurementInfoToInput(measurements),
      name: i.food.name,
      id: i.food.id,
      quantity: {
        quantity: i.quantity.quantity,
        unit: measureToMeasureInput(i.quantity.unit),
      },
      nutrition: scaleNutrition(frac, i.food.nutrition.nutrition),
    };
  });
};

export const measurementInfoToInput = (
  a: Recipe_recipe_products_foods_food_measurementInfo
): MeasurementInfoInput => ({
  serving: a.serving,
  cup: a.cup,
  teaspoon: a.teaspoon,
  tablespoon: a.tablespoon,
  whole: a.whole,
});

export const ToQuantityToNameIdAndNutritionInput = (
  a: Recipe_recipe_recipes_recipes[]
): QuantityToNameIdAndNutritionInput[] => {
  return a.map((i) => {
    return {
      name: i.name,
      id: i.id,
      quantity: {
        quantity: i.quantity.quantity,
        unit: measureToMeasureInput(i.quantity.unit),
      },
      nutrition: toNutritionInput(i.nutrition.nutrition),
    };
  });
};
