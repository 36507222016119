import {
  PortionSelectedInput,
  SelectablesInput,
} from "../../../../../globalTypes";
import { AddDishVariables } from "../../../../chefsbase/dishes/addDish/types/AddDish";
import {
  dishes_dishes_portions,
  dishes_dishes,
} from "../../../../chefsbase/dishes/types/dishes";
import { measureToMeasureInput } from "../../../createClientPage/forms/DishCard/Mappers";
import { MySelectablesInput } from "../../../createClientPage/types/MySelectablesInput";

export const mapOptionsToQuantitiesSelected = (
  a: dishes_dishes_portions[]
): PortionSelectedInput[] =>
  a.map((o) => ({
    portion: {
      name: o.name,
      quantity: {
        quantity: o.quantity.quantity,
        unit: measureToMeasureInput(o.quantity.unit),
      },
      price: o.price,
    },
    selected: o.name !== "Manual" ? true : false,
  }));

export const mapDishToSelectable = (a: dishes_dishes): MySelectablesInput => ({
  img: a.img,
  dishid: a.id,
  dishname: a.name,
  quantitiesSelected: mapOptionsToQuantitiesSelected(a.portions),
});

export const mapAddDishToSelectable = (
  a: AddDishVariables
): MySelectablesInput => ({
  img: a.input.urls ? a.input.urls : [""],
  dishid: a.input.name,
  dishname: a.input.name,
  quantitiesSelected: a.portions.map((i) => ({
    selected: i.name !== "Manual" ? true : false,
    portion: i,
  })),
});

export const mapMySelectableToSelectable = (
  a: MySelectablesInput
): SelectablesInput => ({
  img: a.img,
  dishid: a.dishid,
  dishname: a.dishname,
  quantitiesSelected: a.quantitiesSelected,
});

export const mapSelectableToMySelectable = (
  a: SelectablesInput
): MySelectablesInput => ({
  img: a.img,
  dishid: a.dishid,
  dishname: a.dishname,
  quantitiesSelected: a.quantitiesSelected,
});
