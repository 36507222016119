import { zerohealthlabelinfo } from "../../../screens/chefsbase/dishes/components/EmptyVariables";
import { UpdateRecipeVariables } from "../../../screens/chefsbase/recipes/addrecipes/types/UpdateRecipe";
import { small } from "../portions/PortionInput";

export const emptyRecipeForm: UpdateRecipeVariables = {
  id: "",
  input: {
    name: "",
    type: "",
    method: "",
    quantity: small.quantity,
    img: [],
  },
  recipes: {
    dishPortion: small,
    ingredients: [],
  },
  products: {
    dishPortion: small,
    ingredients: [],
  },
  healthLabels: zerohealthlabelinfo,
};
