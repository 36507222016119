import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React from "react";
import { SubmitButton } from "../../../components/Buttons";
import { GridRowTwoItems } from "../../../components/layouts/Grids";
import { KitchenType } from "../../../globalTypes";
import { useDeleteItemMutation, useDeletesItemMutation } from "../api";
import { Loader } from "../../../components/Loading/Loader";

export const DeleteDialog = ({
  open,
  onClose,
  name,
  id,
  kitchenType,
  onCompleted,
}: {
  onCompleted: () => void;
  open: boolean;
  onClose: () => void;
  name: string;
  id: string;
  kitchenType: KitchenType;
}) => {
  const { deleteItem, loading: loadingDelete } = useDeleteItemMutation({
    id: id,
    kitchenType: kitchenType,
    onCompleted: () => onCompleted(),
  });

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>Are you sure to delete {name}?</DialogTitle>
      {loadingDelete && (
        <DialogContent>
          <>
            Deleting...
            <Loader loading={loadingDelete} />
          </>
        </DialogContent>
      )}
      <DialogActions>
        <GridRowTwoItems
          before={2}
          firstlength={4}
          secondlength={4}
          inbetween={2}
          firstchild={
            <SubmitButton
              variant="contained"
              onClick={() => {
                deleteItem();
                onClose();
              }}
            >
              Yes
            </SubmitButton>
          }
          secondchild={
            <SubmitButton variant="contained" onClick={() => onClose()}>
              No
            </SubmitButton>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export const DeletesDialog = ({
  open,
  onClose,
  ids,
  kitchenType,
  onCompleted,
}: {
  onCompleted: () => void;
  open: boolean;
  onClose: () => void;
  ids: string[];
  kitchenType: KitchenType;
}) => {
  const { deleteItems, loading: loadingDelete } = useDeletesItemMutation({
    ids: ids,
    kitchenType: kitchenType,
    onCompleted: () => onCompleted(),
  });

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>Are you sure to delete all?</DialogTitle>
      <DialogActions>
        <GridRowTwoItems
          before={2}
          firstlength={4}
          secondlength={4}
          inbetween={2}
          firstchild={
            <SubmitButton variant="contained" onClick={() => deleteItems()}>
              Yes
            </SubmitButton>
          }
          secondchild={
            <SubmitButton variant="contained" onClick={() => onClose()}>
              No
            </SubmitButton>
          }
        />
      </DialogActions>
    </Dialog>
  );
};
