import { gql, useMutation } from "@apollo/client";
import { AddProduct, AddProductVariables } from "./types/AddProduct";

const AddProductMutation = gql`
  mutation AddProduct($input: ProductInput!) {
    addProduct(input: $input)
  }
`;

export const useAddProductMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addProduct, { loading, error }] = useMutation<
    AddProduct,
    AddProductVariables
  >(AddProductMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    addProduct,
    loading,
    error,
  };
};
