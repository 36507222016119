import { Box, Stack, TableCell } from "@mui/material";
import React, { useState } from "react";
import { VscEdit, VscCheck } from "react-icons/vsc";
import { SubmitButton } from "../../../../components/Buttons";
import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowFourItems,
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { DishInfoForm } from "../addDish";
import {
  findMeasure,
  gramMeasureInput,
  kilogramMeasureInput,
  literMeasureInput,
  milliliterMeasureInput,
  servingMeasureInput,
  unitMeasureInput,
  wholeMeasureInput,
} from "../../../../components/defaults/portions/PortionInput";
import { AmountPerQuantityInput, QuantityInput } from "../../../../globalTypes";
import { FormQuantity } from "../../recipes/addrecipes/CreateYourRecipe";

export const HoursWorked = ({
  info,
  setInfo,
}: {
  setInfo: (a: DishInfoForm) => void;
  info: DishInfoForm;
}) => {
  return (
    <>
      <GridRowFourItems
        before={1.5}
        inbetweenfirsttwo={1}
        inbetweensecondtwo={0.5}
        inbetweenthirdtwo={0.5}
        firstlength={2}
        secondlength={2}
        thirdlength={2}
        fourthlength={0}
        firstchild={
          <FormField
            label="Hours Worked"
            value={String(info.hoursWorked.amount)}
            setValue={(a: string) => {
              setInfo({
                name: info.name,
                urls: info.urls,
                type: info.type,
                portions: info.portions,
                options: info.options,
                hoursWorked: {
                  amount: Number(a),
                  quantity: info.hoursWorked.quantity,
                },
              });
            }}
          />
        }
        secondchild={
          <FormField
            num={true}
            label="For amount:"
            value={String(info.hoursWorked.quantity.quantity)}
            setValue={(a: string) => {
              setInfo({
                name: info.name,
                type: info.type,
                portions: info.portions,
                options: info.options,
                urls: info.urls,
                hoursWorked: {
                  amount: info.hoursWorked.amount,
                  quantity: {
                    quantity: Number(a),
                    unit: info.hoursWorked.quantity.unit,
                  },
                },
              });
            }}
          />
        }
        thirdchild={
          <FormSelect
            label="Unit"
            options={[
              gramMeasureInput(),
              kilogramMeasureInput(),
              literMeasureInput(),
              milliliterMeasureInput(),
              wholeMeasureInput(),
              unitMeasureInput(),
              servingMeasureInput(),
            ].map((i) => ({
              name: String(i),
              id: String(i),
            }))}
            value={info.hoursWorked.quantity.unit.label}
            setValue={(a: string) => {
              setInfo({
                name: info.name,
                type: info.type,
                portions: info.portions,
                options: info.options,
                urls: info.urls,
                hoursWorked: {
                  amount: info.hoursWorked.amount,
                  quantity: {
                    quantity: info.hoursWorked.quantity.quantity,
                    unit: findMeasure(
                      info.portions[0].quantity.unit.label,
                      Number(a)
                    ),
                  },
                },
              });
            }}
          />
        }
        fourthchild={<></>}
      />
    </>
  );
};

export const HoursCell = ({
  editHours,
  setOpenEditHours,
  setInfo,
  info,
}: {
  info: DishInfoForm;
  setInfo: (a: DishInfoForm) => void;
  editHours: boolean;
  setOpenEditHours: (a: boolean) => void;
}) => {
  return (
    <TableCell>
      <>
        {editHours === false ? (
          <>
            {`${info.hoursWorked.amount.toFixed(
              2
            )} hours for ${info.hoursWorked.quantity.quantity.toFixed(2)} ${
              info.hoursWorked.quantity.unit
            } `}
            <VscEdit
              style={{ cursor: "pointer" }}
              onClick={() => setOpenEditHours(!editHours)}
            />
          </>
        ) : (
          <GridRowTwoItems
            firstchild={
              <SubmitButton
                variant="outlined"
                onClick={() => setOpenEditHours(!editHours)}
              >
                <VscCheck />
              </SubmitButton>
            }
            secondchild={
              <HoursWorked
                setInfo={(a: DishInfoForm) => setInfo(a)}
                info={info}
              />
            }
            firstlength={1}
            secondlength={11}
            before={0}
            inbetween={0}
          />
        )}
      </>
    </TableCell>
  );
};

export const HoursWorked1 = ({
  info,
  setInfo,
  defaultQ,
  hourlyWage,
  sethourlyWage,
}: {
  hourlyWage?: number;
  sethourlyWage?: (a: number) => void;
  defaultQ?: QuantityInput;
  setInfo: (a: AmountPerQuantityInput) => void;
  info: AmountPerQuantityInput;
}) => {
  const [amount, setamount] = useState(String(info.quantity.quantity));

  return (
    <>
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={3}
        secondlength={8}
        firstchild={
          <FormField
            num={true}
            label="Hours Worked"
            value={String(info.amount)}
            setValue={(a: string) => {
              setInfo({
                amount: Number(a),
                quantity: info.quantity,
              });
            }}
          />
        }
        secondchild={
          <Stack alignItems="flex-end" direction="row">
            <Box sx={{ mb: 2, mr: 2 }}>For:</Box>
            <FormQuantity
              amount={amount}
              setamount={setamount}
              label="For amount:"
              value={info.quantity}
              setValue={(a: QuantityInput) => {
                setInfo({
                  amount: info.amount,
                  quantity: a,
                });
              }}
              options={[
                gramMeasureInput(),
                kilogramMeasureInput(),
                literMeasureInput(),
                milliliterMeasureInput(),
                wholeMeasureInput(),
                unitMeasureInput(),
                servingMeasureInput(),
              ].map((i) => ({
                name: String(i.label),
                id: String(i.uri),
              }))}
            />
          </Stack>
        }
      />
      {hourlyWage && sethourlyWage && (
        <GridRowTwoItems
          before={0.5}
          inbetween={0.5}
          firstlength={4}
          secondlength={2}
          firstchild={<Box sx={{ mb: 1, mr: 2 }}>Salary per hour:</Box>}
          secondchild={
            <FormFieldInputAdornmentNumber
              input="€"
              value={String(hourlyWage)}
              setValue={(a) => sethourlyWage(Number(a))}
            />
          }
        />
      )}
    </>
  );
};
