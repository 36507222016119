import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DisplayImage } from "../../components/image/DisplayImage";
import { useNavigate } from "react-router-dom";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MuiToolbar from "@mui/material/Toolbar";
import { GridRowThreeItems } from "../../components/layouts/Grids";

export const LogInBox = () => {
  const navigate = useNavigate();
  const { user, loginWithRedirect } = useAuth0();
  return (
    <Box
      style={{ cursor: "pointer" }}
      component="main"
      sx={{
        mt: 5,
        width: 500,
        height: 500,
        alignItems: "center",
      }}
    >
      <div
        onClick={() => {
          // navigate('/menus', {replace: true})
          user ? navigate("/menus", { replace: true }) : loginWithRedirect();
        }}
      >
        <DisplayImage img="/images/MCB_logo.png" height={100} width={100} />
      </div>
    </Box>
  );
};

export const SignIn = () => {
  return (
    <Container component="main">
      <Box
        sx={{
          width: 800,
          height: 700,
          marginLeft: 10,
          marginRight: 8,
          marginTop: 8,
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: 1,
          borderRadius: "16px",
          borderColor: "blue",
        }}
      >
        <Typography
          sx={{ mt: 2, mb: 0 }}
          component="h1"
          variant="h5"
          align="center"
        >
          {/* {user ? "You are now logged in!. Click again to proceed" :  */}
          <>
            Welcome to My Chefsbase <br />
            Click on the Logo to continue
          </>
          {/* } */}
        </Typography>
      </Box>
    </Container>
  );
};
