import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Stack,
  Grid,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useState } from "react";
import { VscCheck, VscChromeClose } from "react-icons/vsc";
import { Box2mt } from "../../../../components/Boxes";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import {
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { getFraction } from "../../../../components/utils/GetFraction";
import { NutritionInput, OptionInput } from "../../../../globalTypes";
import {
  DisplayIngredients,
  DisplayNutritionInput,
  DisplayRecipes,
} from "../../../chefsbase/productsfromapi";
import {
  CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish_healthLabelInfo,
} from "../../types/CurrentMenu";
import {
  DishItems_dish_recipes,
  DishItems_dish_products_foods,
} from "../../../chefsbase/dishes/types/DishItems";
import { Loader } from "../../../../components/Loading/Loader";
import { toNutritionInput } from "../mappers/nutrition/ToNutritionInput";
import { MobileDishSlider } from "../../../chefsbase/dishes/dishDialog/DishContent";
import { aestheticBackground } from "../../../../components/layouts/Colors";

export const DialogForDishForCourse = ({
  dish,
  open,
  setopen,
  products,
  availablePortions,
  totalNutr,
  recipes,
  loading,
}: {
  loading: boolean;
  recipes: DishItems_dish_recipes;
  totalNutr: NutritionInput;
  availablePortions: CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected[];
  open: boolean;
  setopen: (open: boolean) => void;
  dish: CurrentMenu_currentMenu_courses_selectables_selectables_dish;
  products: DishItems_dish_products_foods[];
}) => {
  const [dishQuantity, setdishquantity] = useState({
    name: availablePortions[0].portion.name,
    quantity: availablePortions[0].portion.quantity,
  });
  const images =
    dish.img && dish.img.length > 0
      ? dish.img
      : ["/images/DishBackground.webp"];
  const portion = availablePortions.find(
    (d) => d.portion.name === dishQuantity.name
  );

  return (
    <Dialog
      scroll="paper"
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => setopen(false)}
      sx={{ color: aestheticBackground }}
    >
      <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
        <GridRowTwoItems
          before={0}
          firstlength={10}
          secondlength={2}
          inbetween={0}
          firstchild={
            <Typography
              variant="h5"
              align="center"
              style={{
                fontWeight: "bold",
                fontFamily: "FairField Display, serif",
              }}
            >
              {dish.name}
            </Typography>
          }
          secondchild={
            <Button
              sx={{ borderRadius: 28, backgroundColor: aestheticBackground }}
              color="inherit"
              variant="contained"
              onClick={() => setopen(false)}
            >
              X
            </Button>
          }
        />
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: aestheticBackground }}>
        <Stack spacing={0.5} direction="column" alignContent={"center"}>
          <Grid container spacing={2}>
            {images.length === 1 ? (
              <Grid item xs={12} sm={12} md={6}>
                <DisplayImage height={100} width={100} img={images[0]} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <MobileDishSlider urls={images} />
                </Grid>
              </>
            )}
          </Grid>
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={<DisplayHealthInfoForDish info={dish.healthLabelInfo} />}
          />
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" align="left"></Typography>
          </Stack>
          {/* <GridRowOneItem before={0} after={0} child={<Divider />} /> */}
          {availablePortions && availablePortions.length > 0 && (
            <GridRowTwoItems
              before={0}
              firstlength={6}
              secondlength={3}
              inbetween={0.5}
              firstchild={
                <Select
                  sx={{ height: 0.5, mt: 0, minWidth: 0.3, borderRadius: 5 }}
                  size="small"
                  margin="dense"
                  fullWidth
                  value={dishQuantity.name}
                  name={dishQuantity.name}
                  onChange={(e) =>
                    setdishquantity({
                      name: availablePortions.find(
                        (i) => i.portion.name === e.target.value
                      )!.portion.name,
                      quantity: availablePortions.find(
                        (i) => i.portion.name === e.target.value
                      )!.portion.quantity,
                    })
                  }
                >
                  {availablePortions &&
                    availablePortions.length > 0 &&
                    availablePortions.map((portion, index1) => (
                      <MenuItem key={index1} value={portion.portion.name}>
                        {portion.portion.name}
                      </MenuItem>
                    ))}
                </Select>
              }
              secondchild={
                <Stack direction={"column"} spacing={0}>
                  {/* <Stack direction="row" spacing={0.5}>
                    <div>{`€${
                      portion ? portion.portion.price.toFixed(2) : ""
                    }`}</div>
                    <div>{` for `}</div>
                  </Stack> */}
                  <Stack direction={"row"} spacing={0.5}>
                    <div>{`${
                      portion
                        ? portion.portion.quantity.quantity.toFixed(0)
                        : ""
                    } `}</div>
                    <div>{`${
                      portion ? portion.portion.quantity.unit.label : ""
                    }(s)`}</div>
                  </Stack>
                </Stack>
              }
            />
          )}
          {/* <GridRowOneItem before={0} after={0} child={<Divider />} /> */}
          <GridRowTwoItems
            before={0}
            firstlength={8}
            secondlength={4}
            inbetween={0}
            firstchild={
              <>
                {loading && (
                  <>
                    Loading ingredients..
                    <Loader loading={loading} />
                  </>
                )}
                {products && (
                  <DisplayIngredients
                    products={products}
                    frac={getFraction(
                      dish.portions[0].quantity,
                      dishQuantity.quantity
                    )}
                  />
                )}
                {recipes && (
                  <DisplayRecipes
                    recipes={recipes.recipes}
                    frac={getFraction(
                      dish.portions[0].quantity,
                      dishQuantity.quantity
                    )}
                  />
                )}
              </>
            }
            secondchild={
              <>
                {availablePortions.find(
                  (d) => d.portion.quantity === dishQuantity.quantity
                ) ? (
                  <DisplayNutritionInput
                    direction="column"
                    nutritionInput={toNutritionInput(
                      availablePortions.find(
                        (d) => d.portion.quantity === dishQuantity.quantity
                      )!.portion.nutrition
                    )}
                    frac={1}
                  />
                ) : (
                  <DisplayNutritionInput
                    direction="column"
                    nutritionInput={toNutritionInput(
                      availablePortions[0].portion.nutrition
                    )}
                    frac={1}
                  />
                )}
              </>
            }
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export const DisplayHealthInfoForDish = ({
  info,
}: {
  info: CurrentMenu_currentMenu_courses_selectables_selectables_dish_healthLabelInfo;
}) => {
  const pics = [
    !info.meat && !info.fish && !info.shellfish && !info.mollusk
      ? "/Vegetarian.png"
      : "",
    !info.meat &&
    !info.fish &&
    !info.shellfish &&
    !info.mollusk &&
    !info.milk &&
    !info.egg &&
    !info.dairy &&
    info.vegan
      ? "/Vegan.png"
      : "",
    info.egg ? "/Egg.png" : "",
    info.fish ? "/Fish.png" : "",
    info.peanut ? "/Peanut.png" : "",
    info.sesame ? "/Sesame.png" : "",
    info.nut ? "/Nut.png" : "",
    // info.meat ? "/Meat.png" : "",
    info.selery ? "/Selery.png" : "",
    info.soy ? "/Soy.png" : "",
    info.gluten ? "/Gluten.png" : "",
    info.milk ? "/Milk.png" : "",
    info.shellfish ? "/Shellfish.png" : "",
    info.mollusk ? "/mollusk.png" : "",
    info.mustard ? "/Mustard.png" : "",
  ].filter((i) => i !== "");
  const texts = [
    info.egg ? "Egg" : "",
    info.fish ? "Fish" : "",
    info.peanut ? "Peanut" : "",
    info.nut ? "Nut" : "",
    // info.meat ? "Meat" : "",
    info.selery ? "Selery" : "",
    info.soy ? "Soy" : "",
    info.gluten ? "Gluten" : "",
    info.milk ? "Milk" : "",
    info.shellfish ? "Shellfish" : "",
    info.mollusk ? "Mollusk" : "",
    info.mustard ? "Mustard" : "",
  ].filter((i) => i !== "");
  const [displaytext, setText] = useState(false);
  return (
    <Stack direction="row" spacing={0.5} onClick={() => setText(!displaytext)}>
      {displaytext
        ? texts.map((p, i) => <b>p</b>)
        : pics.map((p, i) => (
            <DisplayImage key={i} height={10} width={10} img={p} />
          ))}
    </Stack>
  );
};
