import { Grid } from "@mui/material";
import React, { useState } from "react";
import { AddPageBox } from "../../../../components/Boxes";
import { SubmitButton } from "../../../../components/Buttons";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { ProcessingDialog } from "../../../../components/Loading/Processing";
import { H6 } from "../../../../components/TextTypes";
import { useSearchDishesQuery } from "../../dishes/api";
import { dishes, dishes_dishes } from "../../dishes/types/dishes";
import { useRecipesQuery } from "../../recipesAndIngredients/api";
import { useAddMenuhMutation } from "./api";
import { AddComposables } from "./components/AddComposable";
import { AddSelectables } from "./components/AddSelectables";
import { emptyMenuForm } from "../../../../components/defaults/menu/Menu";
import { AddMenuVariables } from "./types/AddMenu";
import { small } from "../../../../components/defaults/portions/PortionInput";
import {
  mapToAddMenuVariables,
  GetOriginalMenuItems,
  mapToUpdateMenuVariables,
} from "..";
import { useSetCurrentMenuMutation } from "../../../clientbase/api";
import { FormContent } from "../../../clientbase/createClientPage";
import { useComposableQuery } from "../api";
import { Composables_composables, Composables } from "../types/Composables";
import { UpdateMenuVariables } from "./types/UpdateMenu";
import { Loader } from "../../../../components/Loading/Loader";
import { mapToMenuInput } from "../../../clientbase/components/mappers/MapToMenuInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddPage } from "../../../../PageFrames";
import { buttonColor } from "../../../../components/layouts/Colors";

export const AddMenu = withAuthenticationRequired(() => {
  const [form, setForm] = useState<UpdateMenuVariables>({
    ...emptyMenuForm,
    id: "",
  });

  const { addMenu, loading } = useAddMenuhMutation({
    onCompleted: () => {
      setForm({
        ...emptyMenuForm,
        id: "",
      });
    },
  });
  const [dishes, setdishes] = useState<dishes_dishes[]>([]);
  const [composables, setcomposables] = useState<Composables_composables[]>([]);

  const { loading: loadingDishes } = useSearchDishesQuery({
    onCompleted: (result: dishes) => setdishes(result.dishes),
  });
  const { loading: loadingcomposables } = useComposableQuery({
    onCompleted: (result: Composables) => setcomposables(result.composables),
  });
  if (loading)
    return ProcessingDialog({
      loading: loading,
      title: "Updating your Menu. This might take a while..",
    });
  return (
    <>
      <AddPage
        childComp={
          <>
            {loadingcomposables || loadingDishes ? (
              <>
                Hold on While we fetch your dishes to choose from <br />
                <Loader loading={loadingcomposables || loadingDishes} />
              </>
            ) : (
              <FormContent
                dishes={dishes}
                setdishes={setdishes}
                menuForm={mapToAddMenuVariables(form)}
                mutate={(a) =>
                  addMenu({
                    variables: mapToMenuInput(a),
                  })
                }
              />
            )}
          </>
        }
      />
    </>
  );
});

export const MenuFormContent = ({
  form,
  setForm,
  mutate,
}: {
  mutate: (options?: any) => void;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const { data, loading } = useSearchDishesQuery({
    onCompleted: (a: dishes) => {},
    page: 0,
  });

  const { loading: loadingRecipes, data: dataRecipes } = useRecipesQuery({
    onCompleted: (result) => {},
    page: 0,
  });

  if (loading || !data || !dataRecipes || loadingRecipes)
    return ProcessingDialog({ loading: loading, title: "loading dishes" });

  const dishes: dishes_dishes[] = data.dishes;
  return (
    <>
      <GridRowOneItem
        before={4}
        after={4}
        child={
          <SubmitButton
            sx={{ mt: 2, borderRadius: 28 }}
            fullWidth
            variant="contained"
            onClick={
              () => {}
              // mutate({
              //   variables: {
              //     input: {
              //       name: form.info.name,
              //     },
              //     selectables: form.selectables.map((s) => ({
              //       type: s.type,
              //       selectablesInput: s.selectablesInput.map((t) => ({
              //         dishid: t.dishid,
              //         dishname: t.dishname,
              //         quantitiesSelected: t.quantitiesSelected,
              //       })),
              //     })),
              //     composables: form.composables,
              //   },
              // })
            }
          >
            Submit
          </SubmitButton>
        }
      />
      <GridRowOneItem
        before={2}
        after={2}
        child={
          <AddPageBox
            childComp={
              <FormField
                label="Name Your Menu"
                value={form.input.name}
                setValue={
                  (a: string) => {}
                  // setForm({
                  //   info: { name: a },
                  //   composables: form.composables,
                  //   selectables: form.selectables,
                  // })
                }
              />
            }
          />
        }
      />
      <GridRowTwoItems
        before={0.5}
        firstlength={4}
        inbetween={0.5}
        secondlength={6.5}
        firstchild={
          <>
            <Grid container sx={{ backgroundColor: buttonColor }}>
              <Grid xs={12}>
                <H6 title="Selectables" />
              </Grid>
            </Grid>
            {/* <AddSelectables
              dishes={dishes}
              form={form}
              setForm={(a) => setForm(a)}
            /> */}
          </>
        }
        secondchild={
          <>
            <Grid container sx={{ backgroundColor: buttonColor }}>
              <Grid xs={12}>
                <H6 title="Composables" />
              </Grid>
            </Grid>
            {/* <AddComposables
              form={form}
              setForm={(a) => setForm(a)}
              dataIngredients={dataIngredients}
              dataRecipes={dataRecipes}
              dataProducts={dataProducts}
            /> */}
          </>
        }
      />
    </>
  );
};
