import { gql, useLazyQuery } from "@apollo/client";
import { QuantityInput } from "../../../globalTypes";
import { Recipe } from "./types/Recipe";
import { useSimpleQuery } from "../../../utils/apollo";

const ingredientsForDishQuery = gql`
  query Recipe($id: String!, $quantity: QuantityInput!) {
    recipe(id: $id) {
      recipes(quantity: $quantity) {
        dishPortion {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        nutrition {
          kcal
          carbscarbs
          carbssugar
          fatstotal
          prottotal
          starch
          fibres
          cholesterol
          natrium
          saturedfat
        }
        recipes {
          name
          id
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          nutrition {
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
            nutrition {
              kcal
              saturedfat
              carbscarbs
              carbssugar
              fatstotal
              prottotal
              starch
              fibres
              cholesterol
              natrium
            }
          }
        }
      }
      products(quantity: $quantity) {
        dishPortionOutput {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        foods {
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          food {
            measurementInfo {
              serving
              cup
              tablespoon
              whole
              teaspoon
            }
            id
            name
            img
            nutrition {
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                kcal
                carbscarbs
                carbssugar
                fatstotal
                prottotal
                starch
                fibres
                cholesterol
                natrium
                saturedfat
              }
            }
          }
        }
      }
    }
  }
`;

export const useIngredientsForRecipeQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (recipes: Recipe) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const { data, loading } = useSimpleQuery(ingredientsForDishQuery, {
    variables: {
      id: id,
      quantity: quantity,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { data, loading };
};

export const useLazydIngredientsForRecipeQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (recipes: Recipe) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const [getIngredientsForRecipe, { data, refetch, loading }] = useLazyQuery(
    ingredientsForDishQuery,
    {
      variables: {
        id: id,
        quantity: quantity,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { getIngredientsForRecipe, data, refetch, loading };
};
