import { QuantityInput, PortionInput } from "../../globalTypes";

export function writeText(text: string) {
  return text.split("\n").map((str) => <p>{str}</p>);
}

export const replaceCommas = (amount: number): number => {
  const word = String(amount);
  const comma = /,/gi;
  return Number(word.replace(comma, "."));
};

export const replaceCommasInString = (amount: string): string => {
  const comma = /,/gi;
  return amount.replace(comma, ".");
};

export const replaceCommasInQuantity = (
  amount: QuantityInput
): QuantityInput => {
  return {
    quantity: replaceCommas(amount.quantity),
    unit: amount.unit,
  };
};

export const replaceCommasInPortion = (portion: PortionInput): PortionInput => {
  return {
    name: portion.name,
    price: portion.price ? replaceCommas(portion.price) : 0,
    quantity: replaceCommasInQuantity(portion.quantity),
  };
};

export const replaceSingleQuote = (a: string): string => {
  const word = String(a);
  const comma = /'/gi;
  return String(word.replace(comma, ""));
};
