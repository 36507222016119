import { Box } from "@mui/system";
import React from "react";

export const DisplayImage = ({
  img,
  height,
  width,
}: {
  img: string | null;
  height: number;
  width: number;
}) => {
  return (
    <Box
      alignSelf={"center"}
      component="img"
      src={img ? img : ""}
      sx={{ height: `${height}%`, width: `${width}%`, borderRadius: 10 }}
    />
  );
};
