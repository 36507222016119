import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useSimpleQuery } from "../../../utils/apollo";
import {
  addInventoryForMenu,
  addInventoryForMenuVariables,
} from "./types/addInventoryForMenu";
import { inventoryForMenu } from "./types/inventoryForMenu";

export const inventoryformenuquery = gql`
  query inventoryForMenu($id: String!) {
    inventoryForMenu(id: $id) {
      recipes {
        name
        id
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
      }
      products {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        food {
          id
          name
          price {
            amountPerQuantity {
              amount
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
            }
          }
        }
      }
    }
  }
`;

const addInventoryMutation = gql`
  mutation addInventoryForMenu(
    $id: String!
    $recipes: [QuantityToNameToIdInput!]!
    $foods: [QuantityToNameToIdInput!]!
  ) {
    addInventoryForMenu(id: $id, recipes: $recipes, foods: $foods)
  }
`;

export const useInventoryformenuquery = ({
  onCompleted,
  id,
}: {
  onCompleted: (dishes: inventoryForMenu) => void;
  id: string;
}) => {
  const [inventoryQuery, { loading, data }] = useLazyQuery(
    inventoryformenuquery,
    {
      variables: {
        id: id,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { loading, data, inventoryQuery };
};

export const useAddInventoryMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addInventoryForMenu, { loading, error }] = useMutation<
    addInventoryForMenu,
    addInventoryForMenuVariables
  >(addInventoryMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    addInventoryForMenu,
    loading,
    error,
  };
};
