import { Material, MeasureInput } from "../../globalTypes";
import { Dish_dish_recipes_dishPortion_quantity_unit } from "../../screens/chefsbase/dishes/types/Dish";
import {
  existinglabels,
  measureInputOptions,
} from "../defaults/portions/PortionInput";
import { NametoId } from "../types/types";

export const UnitsFor = (a: MeasureInput): NametoId[] => {
  return [
    {
      name: a.label,
      id: a.uri,
    },
  ];
};
export const UnitOptions = measureInputOptions()
  .filter((s) =>
    existinglabels.map((i) => i.toLowerCase()).includes(s.label.toLowerCase())
  )
  .map((x) => ({ name: x.label, id: x.uri }));

export const getUnitsForUnitTemp = (u: string): string[] => {
  var result;
  switch (u) {
    case "milligram":
      result = ["gram"];
      break;
    case "gram":
      result = ["gram"];
      break;
    case "kg":
      result = ["gram"];
      break;
    case "milliliter":
      result = ["milliliter"];
      break;
    case "liter":
      result = ["milliliter"];
      break;
    case "person(en)":
      result = ["person(en)"];
      break;
    case "eetlepel(s)":
      result = ["eetlepel(s)"];
      break;
    case "theelepel(s)":
      result = ["theelepel(s)"];
      break;
    default:
      result = ["stuk(s)"];
  }

  return result;
};

export const toMaterial = (a: MeasureInput) => {
  let result;

  return Material.SOLID;
};

export const getUnits = (u: Dish_dish_recipes_dishPortion_quantity_unit) => {
  return [u];
};
