import {
  Table,
  TableRow,
  TableCell,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from "@mui/material";
import {
  backgroundContainer,
  backgroundField,
} from "../../../components/layouts/Colors";
import { read, utils, writeFile } from "xlsx";
import { type } from "@testing-library/user-event/dist/type";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton, TrashButton } from "../../../components/Buttons";
import { Loader } from "../../../components/Loading/Loader";
import {
  AmountPerQuantityInput,
  DishToQuantityInput,
  IngredientToQuantityToSalePriceInput,
  KitchenType,
  MeasureInput,
  QuantityInput,
  QuantityToNameToIdInput,
} from "../../../globalTypes";
import { DeletesDialog } from "../components/DeleteDialog";
import { dishes, dishes_dishes } from "../dishes/types/dishes";
import {
  FormAutoCompleteDishSelectable,
  FormSelect,
} from "../../../components/forms/singleForms/FormSelect";
import { MySelectablesInput } from "../../clientbase/createClientPage/types/MySelectablesInput";
import { useState } from "react";
import {
  emptyMySelectable,
  emptySelectable,
} from "../../../components/defaults/selectables/SelectableInput";
import { useLazyItemsForDishQuery, useSearchDishesQuery } from "../dishes/api";
import { mapDishToSelectable } from "../../clientbase/components/mappers/selectable/MapToSelectables";
import { FormQuantity } from "../recipes/addrecipes/CreateYourRecipe";
import {
  formMeasureInputOptions,
  gramMeasure,
  gramMeasureInput,
  measureInputOptions,
  small,
} from "../../../components/defaults/portions/PortionInput";
import { sortPortions } from "../dishes/dishDialog/DishContent";
import { standard } from "../dishes/components/StandardWeight";
import { VscAdd, VscCheck, VscEdit, VscTrash } from "react-icons/vsc";
import {
  AmountPerQuantityToProduct,
  QuantityToNameIdAndNutritionInputWithPrice,
} from "../dishes";
import { zeroNutritionInput } from "../dishes/components/EmptyVariables";
import {
  displayQuantity,
  minimizeUnit,
  roundToZeroOr2,
  scaleQuantity,
  writeUnit,
} from "../../../components/utils/DisplayQuantity";
import {
  getFraction,
  subtractAmounts,
} from "../../../components/utils/GetFraction";
import { FormField } from "../../../components/forms/singleForms/FormField";
import { quantityToInput } from "../../clientbase/createClientPage/forms/DishCard/Mappers";
import { replaceCommasInString } from "../../../components/utils/WriteText";
import {
  ExcelRow,
  RecipeRow,
  Row,
  RowForm,
  choosePortionAmount,
  emptyDishToQuantity,
  rowFormsToExcelRows,
} from "../production";
import {
  orderOverviewQuery,
  useLazyOrderOverviewQuery,
} from "../production/api";
import {
  orderOverview,
  orderOverview_orderOverview_recipes,
} from "../production/types/orderOverview";
import { useClientMenusQuery, useQuickClientMenusQuery } from "../menus/api";
import { Clientmenus, Clientmenus_menus } from "../menus/types/Clientmenus";
import { QuickMenus, QuickMenus_menus } from "../menus/types/QuickMenus";
import { addInventoryForMenuVariables } from "./types/addInventoryForMenu";
import { useAddInventoryMutation, useInventoryformenuquery } from "./api";
import {
  inventoryForMenu,
  inventoryForMenu_inventoryForMenu_products,
  inventoryForMenu_inventoryForMenu_products_food,
  inventoryForMenu_inventoryForMenu_recipes,
} from "./types/inventoryForMenu";
import {
  TextOrQuantity,
  TextOrField,
} from "../../../components/forms/formOrEdit/FormFieldEdit";
import {
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../components/layouts/Grids";
import {
  QuantityToProduct,
  Product,
} from "../../clientbase/createClientPage/types/QuantityToProduct";
import { NametoId } from "../../../components/types/types";
import {
  NametoIdToQuantityInput,
  OneNameToIdWide,
} from "../../clientbase/createClientPage/forms/IngredientForProductRow";

export const rowsToExcelRows = (
  name: string,
  a: OrderRowForm[]
): ExcelRow[] => {
  return a.map((rowForm, index) => rowToExcelRow(index + 1, name, rowForm));
};

export const rowToExcelRow = (
  index: number,
  name: string,
  a: OrderRowForm
): ExcelRow => {
  const random = Math.ceil(Math.random() * 19);
  return {
    lijstnaam: name,
    sorteervolgorde: String(index),
    artikelnummer: String(index),
    portioneringGewicht: `${choosePortionAmount(a.quantity.quantity)} ${
      a.quantity.unit.label
    }`,
    verpakkingscode: "",
    voorkeursAantal: String(random),
    empty1: "",
    empty2: "",
    productinformatie: `${a.food.name} per ${choosePortionAmount(
      a.quantity.quantity
    )} ${a.quantity.unit.label}`,
    productgroepcode: "",
    productgroepnaam: "",
    portioneringOmschrijving: `${choosePortionAmount(a.quantity.quantity)} ${
      a.quantity.unit.label
    }`,
    verpakkingOmschrijving: "",
  };
};

export const dishToQuantityFormForMenu = (
  menu: QuickMenus_menus
): DishToQuantityInput[] => {
  const selectables = menu.courses.map((c) => c.selectables).flat();
  const result: DishToQuantityInput[] = selectables
    .map((sel) => sel.selectables)
    .flat()
    .map((s) => s.dish)
    .map((d) => ({
      id: d.id,
      name: d.name,
      quantity: d.portions[0]
        ? quantityToInput(d.portions[0].quantity)
        : small.quantity,
    }));

  return result;
};
type FormHere = {
  id: string;
  recipes: RowForm[];
  foods: RowForm[];
};

export const toAddInventoryVariables = (
  a: FormHere
): addInventoryForMenuVariables => {
  return {
    id: a.id,
    recipes: a.recipes.map((r) => ({
      name: r.name,
      id: r.id,
      quantityInput: r.needed,
    })),
    foods: a.foods.map((r) => ({
      name: r.name,
      id: r.id,
      quantityInput: r.needed,
    })),
  };
};
export type OrderRowForm = {
  food: NametoId;
  quantity: QuantityInput;
  units: number;
  pricePerUnit: number;
};

interface TextOrProductProps {
  product: NametoId;
  quantity: QuantityInput;
  setproduct: (a: NametoIdToQuantityInput) => void;
}
export const TextOrProduct: React.FC<TextOrProductProps> = ({
  product,
  quantity,
  setproduct,
}) => {
  const [edit, setedit] = useState(
    product.name === "Add a new Product" ? true : false
  );

  return (
    <>
      {!edit ? (
        <GridRowThreeItems
          before={0.5}
          firstlength={4}
          secondlength={5}
          thirdlength={1}
          inbetweenfirsttwo={0.5}
          inbetweensecondtwo={0.5}
          firstchild={product.name}
          secondchild={displayQuantity(quantity)}
          thirdchild={
            <VscEdit
              onClick={() => setedit(true)}
              style={{ cursor: "pointer" }}
            />
          }
        />
      ) : (
        <OneNameToIdWide
          product={{ quantity: quantity, nameToId: product }}
          setproduct={(a) => setproduct(a)}
          stopEdit={() => setedit(false)}
        />
      )}
    </>
  );
};
const ItemRowForDialog = ({
  ingredient,
  setingredient,
  onDelete,
}: {
  setingredient: (a: OrderRowForm) => void;
  ingredient: OrderRowForm;
  onDelete: () => void;
}) => {
  return (
    <TableRow>
      <TableCell>
        <TextOrProduct
          quantity={ingredient.quantity}
          product={ingredient.food}
          setproduct={(a) => {
            setingredient({
              ...ingredient,
              quantity: a.quantity,
              food: a.nameToId,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <TextOrField
          value={String(ingredient.units)}
          setValue={(a) => {
            setingredient({
              ...ingredient,
              units: Number(a),
            });
          }}
        />
      </TableCell>
      <TableCell>
        {`${ingredient.pricePerUnit.toFixed(2)} EU per stuk`}
      </TableCell>
      <TableCell>
        <b>{`${(ingredient.pricePerUnit * ingredient.units).toFixed(2)} EU`}</b>
      </TableCell>
      <TableCell>
        <TrashButton onClick={onDelete} />
      </TableCell>
    </TableRow>
  );
};

export const handleExportOrderList = (name: string, form: OrderRowForm[]) => {
  const headings = [
    [
      "Lijstnaam",
      "Sorteervolgorde",
      "Artikelnummer",
      "Portionering gewicht",
      "Verpakkingscode",
      "Voorkeurs-aantal",
      "",
      "",
      "Productinformatie",
      "Productgroepcode",
      "Productgroepnaam",
      "Portionering omschrijving",
      "Verpakking omschrijving",
    ],
  ];

  const dataRows = rowsToExcelRows(name, form);

  // Construct CSV content
  const csvContent = [headings[0].join(";")]; // Header row
  dataRows.forEach((row) => {
    const rowData = Object.values(row).join(";");
    csvContent.push(rowData);
  });

  // Create a Blob with the CSV content
  const csvBlob = new Blob([csvContent.join("\n")], {
    type: "text/csv;charset=utf-8",
  });

  // Trigger file download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(csvBlob);
  link.download = `${name}.csv`;
  link.click();
};

export const OrderDialog = ({
  form,
  open,
  onClose,
  setOpenDone,
}: {
  setOpenDone: (a: boolean) => void;
  open: boolean;
  onClose: () => void;
  form: OrderRowForm[];
}) => {
  const [name, setName] = useState("Bestellijst");
  const [orderForm, setOrderForm] = useState<OrderRowForm[]>(form);

  return (
    <Dialog fullScreen open={open} onClose={onClose} fullWidth>
      <DialogTitle>Orderoverview</DialogTitle>
      <DialogContent>
        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <FormField
              label="Name of orderlist"
              setValue={(a: string) => setName(a)}
              value={name}
            />
          }
        />
        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product and amount</TableCell>
                    <TableCell>#items</TableCell>
                    <TableCell>Price Per Item</TableCell>
                    <TableCell>Total Price</TableCell>
                  </TableRow>
                </TableHead>
                {orderForm.map((food, foodIndex) => (
                  <ItemRowForDialog
                    key={foodIndex}
                    ingredient={food}
                    setingredient={(a) => {
                      const old = [...orderForm];
                      old[foodIndex] = a;
                      setOrderForm(old);
                    }}
                    onDelete={() => {
                      const old = [...orderForm];
                      old.splice(foodIndex, 1);
                      setOrderForm(old);
                    }}
                  />
                ))}
              </Table>
            </>
          }
        />

        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <SubmitButton
              variant="outlined"
              color="inherit"
              onClick={() => {
                const old = [...orderForm];
                old.push({
                  food: {
                    name: "Add a new Product",
                    id: "Add a new Product",
                  },
                  quantity: small.quantity,
                  units: 1,
                  pricePerUnit: 0,
                });
                setOrderForm(old);
              }}
            >
              + Add another product
            </SubmitButton>
          }
        />
      </DialogContent>
      <DialogActions>
        <SubmitButton
          variant="contained"
          onClick={() => {
            handleExportOrderList(name, form);
            onClose();
            setOpenDone(true);
          }}
        >
          Order
        </SubmitButton>
        <SubmitButton variant="outlined" color="inherit" onClick={onClose}>
          Return
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
export const ManageInventory = () => {
  const [menus, setmenus] = useState<QuickMenus_menus[]>([]);
  const [menu, setmenu] = useState<QuickMenus_menus>({
    __typename: "McbMenu",
    id: "",
    name: "",
    courses: [],
  });

  const { data, loading: loadingMenus } = useQuickClientMenusQuery({
    onCompleted: (result: QuickMenus) => {
      setmenus(result.menus);
      if (result.menus.length > 0) {
        setmenu(result.menus[0]);
      }
    },
  });
  const [openIngredients, setOpenIngredients] = useState(false);
  const mapper = (
    a: inventoryForMenu_inventoryForMenu_recipes[]
  ): RowForm[] => {
    return a.map((i) => ({
      id: i.id,
      name: i.name,
      needed: quantityToInput(i.quantity),
      inventory: {
        quantity: i.quantity.quantity / 2,
        unit: i.quantity.unit,
      },
      toOrder: quantityToInput(i.quantity),
      ingredientprice: 10,
      originalPrice: {
        amount: 10,
        quantity: quantityToInput(i.quantity),
      },
    }));
  };
  const mapperProducts = (
    a: inventoryForMenu_inventoryForMenu_products[]
  ): RowForm[] => {
    return a.map((i) => ({
      id: i.food.id,
      name: i.food.name,
      needed: quantityToInput(i.quantity),
      inventory: {
        quantity: i.quantity.quantity / 2,
        unit: i.quantity.unit,
      },
      toOrder: {
        quantity: i.quantity.quantity / 2,
        unit: i.quantity.unit,
      },
      ingredientprice: i.food.price
        ? i.food.price!.amountPerQuantity.amount
        : 10,
      originalPrice: {
        amount: i.food.price ? i.food.price!.amountPerQuantity.amount : 10,
        quantity: quantityToInput(i.quantity),
      },
    }));
  };

  const [done, setdone] = useState(false);
  const [form, setForm] = useState<FormHere>({
    id: menu.id,
    recipes: [],
    foods: [],
  });

  const { addInventoryForMenu, loading: loadingUpdate } =
    useAddInventoryMutation({
      onCompleted: () => {
        setdone(true);
      },
    });

  const { inventoryQuery, loading } = useInventoryformenuquery({
    id: menu.id,
    onCompleted: (result: inventoryForMenu) => {
      setForm({
        id: menu.id,
        recipes: mapper(result.inventoryForMenu.recipes),
        foods: mapperProducts(result.inventoryForMenu.products),
      });
    },
  });
  const [value, setValue] = useState(0);
  const [openBestellijst, setOpenBestellijst] = useState(false);
  const [formForOrder, setFormForOrder] = useState<OrderRowForm[]>();
  const [openDone, setOpenDone] = useState(false);
  return (
    <MainBox
      childComp={
        <>
          <BoxTitle title="Inventory Management!" />
          <div style={{ height: 300, width: "100%" }}>
            <Table>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {loadingMenus ? (
                    <Loader loading={loadingMenus} />
                  ) : (
                    <FormSelect
                      fw
                      label="Choose menu to get inventory for"
                      options={menus.map((a) => ({ id: a.id, name: a.name }))}
                      value={menu ? menu.name : ""}
                      setValue={(a) => {
                        const found = menus.find((m) => m.name === a);
                        if (found) {
                          setmenu(found);
                        }
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <SubmitButton
                    variant="contained"
                    onClick={() => {
                      inventoryQuery();
                      setOpenIngredients(true);
                    }}
                  >
                    Get all that is needed
                  </SubmitButton>
                </TableCell>
                <TableCell>
                  <SubmitButton
                    disabled={
                      form.recipes.length === 0 && form.foods.length === 0
                    }
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      addInventoryForMenu({
                        variables: toAddInventoryVariables(form),
                      })
                    }
                  >
                    Update Invenotry Required?
                  </SubmitButton>
                </TableCell>
                <TableCell>
                  <SubmitButton
                    disabled={
                      form.recipes.length === 0 && form.foods.length === 0
                    }
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setFormForOrder(
                        form.foods
                          .filter((f) => f.needed.quantity > 0)
                          .map((f) => ({
                            food: { name: f.name, id: f.id },
                            quantity: f.toOrder,
                            units: 1,
                            pricePerUnit: f.ingredientprice,
                          }))
                      );
                      setOpenBestellijst(true);
                    }}
                  >
                    Download Order
                  </SubmitButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button
                    variant={value === 1 ? "contained" : "outlined"}
                    onClick={() => setValue(1)}
                  >
                    Ingredients
                  </Button>
                  <Button
                    variant={value === 0 ? "contained" : "outlined"}
                    onClick={() => setValue(0)}
                  >
                    Recipes
                  </Button>
                </TableCell>
                <TableCell colSpan={3}>
                  <BoxTitle title="Recipes to make and Ingredients to Order:" />
                </TableCell>
              </TableRow>
              {openIngredients ? (
                <>
                  {loading ? (
                    <TableRow>
                      <TableCell>Loading Recipe Overview</TableCell>
                      <CircularProgress />
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Inventory required:</TableCell>
                      <TableCell>In Stock:</TableCell>
                      <TableCell>To Make:</TableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    value === 0 &&
                    (form.recipes.length > 0 ? (
                      form.recipes.map((recipe, recipeIndex) => (
                        <ItemRow
                          key={recipeIndex}
                          recipe={recipe}
                          setrecipe={(a) => {
                            const old = [...form.recipes];
                            old[recipeIndex] = a;
                            setForm({
                              ...form,
                              recipes: old,
                            });
                          }}
                        />
                      ))
                    ) : (
                      <TableRow></TableRow>
                    ))}
                  {loading && value === 1 ? (
                    <TableRow>
                      <TableCell>Loading Order Overview</TableCell>
                      <CircularProgress />
                    </TableRow>
                  ) : (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell>Inventory required:</TableCell>
                          <TableCell>In Stock:</TableCell>
                          <TableCell>To Order:</TableCell>
                        </TableRow>
                      </TableHead>
                    </>
                  )}
                  {!loading &&
                    value === 1 &&
                    (form.foods.length > 0 ? (
                      form.foods.map((ingredient, ingredientIndex) => (
                        <ItemRow
                          key={ingredientIndex}
                          recipe={ingredient}
                          setrecipe={(a) => {
                            const old = [...form.foods];
                            old[ingredientIndex] = a;
                            setForm({
                              ...form,
                              foods: old,
                            });
                          }}
                        />
                      ))
                    ) : (
                      <TableRow></TableRow>
                    ))}
                </>
              ) : (
                <></>
              )}
            </Table>
            <Dialog open={done} onClose={() => setdone(false)}>
              Done
            </Dialog>
            {formForOrder && formForOrder.length > 0 && (
              <OrderDialog
                setOpenDone={setOpenDone}
                open={openBestellijst}
                onClose={() => setOpenBestellijst(false)}
                form={formForOrder}
              />
            )}
          </div>
        </>
      }
    />
  );
};

export const FormQuantityOrEdit = ({
  label,
  value,
  setValue,
  options,
  error,
  amount,
  setamount,
}: {
  amount: string;
  setamount: (a: string) => void;
  error?: boolean;
  label?: string;
  value: QuantityInput;
  options: NametoId[];
  setValue: (value: QuantityInput) => void;
}) => {
  const [edit, setedit] = useState(false);
  return (
    <>
      {!edit ? (
        <GridRowTwoItems
          before={0.5}
          firstlength={9}
          secondlength={2}
          inbetween={0.5}
          firstchild={displayQuantity(value)}
          secondchild={
            <VscEdit
              style={{ cursor: "pointer" }}
              onClick={() => setedit(true)}
            />
          }
        />
      ) : (
        <GridRowTwoItems
          before={0.5}
          firstlength={9}
          secondlength={2}
          inbetween={0.5}
          firstchild={
            <FormQuantity
              amount={amount}
              setamount={setamount}
              options={options}
              value={value}
              setValue={setValue}
            />
          }
          secondchild={
            <VscCheck
              style={{ cursor: "pointer" }}
              onClick={() => setedit(false)}
            />
          }
        />
      )}
    </>
  );
};
const ItemRow = ({
  recipe,
  setrecipe,
}: {
  setrecipe: (a: RowForm) => void;
  recipe: RowForm;
}) => {
  const [amount, setamount] = useState(String(recipe.needed.quantity));
  const [amount2, setamount2] = useState(String(recipe.inventory.quantity));

  return (
    <TableRow>
      <TableCell>{recipe.name}</TableCell>
      <TableCell>
        <FormQuantityOrEdit
          amount={amount}
          setamount={setamount}
          options={formMeasureInputOptions(recipe.needed.unit.label)}
          value={recipe.needed}
          setValue={(a) => {
            setrecipe({
              ...recipe,
              needed: a,
              toOrder: {
                unit: recipe.toOrder.unit,
                quantity: a.quantity - recipe.inventory.quantity,
              },
            });
          }}
        />
      </TableCell>
      <TableCell>
        <FormQuantity
          options={formMeasureInputOptions(recipe.inventory.unit.label)}
          amount={amount2}
          setamount={setamount2}
          value={recipe.inventory}
          setValue={(a) =>
            setrecipe({
              ...recipe,
              inventory: a,
              toOrder: {
                unit: recipe.toOrder.unit,
                quantity: recipe.needed.quantity - a.quantity,
              },
            })
          }
        />
      </TableCell>
      <TableCell>{displayQuantity(recipe.toOrder)}</TableCell>
    </TableRow>
  );
};
