export const FoodConfig = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    app_id: "ef2483f5",
    app_key: "f5a92edd5859ca4e82a2004e59b2a227",
  },
};

export const RecipeConfig = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    app_id: "1af31c15",
    app_key: "a502a6a0c41e47ac17e68f7c4b83d7e4",
  },
};
