import {
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../../components/layouts/Grids";
import { SubmitButton } from "../../../../../components/Buttons";
import { theme } from "../../../../../theme";
import {
  aestheticBackground,
  backgroundBeige,
} from "../../../../../components/layouts/Colors";

export default function JoinWaitingList() {
  return <SignUpButton />;
}

interface FormProps {
  activeStep: number;
  form: Form;
  setform: (f: Form) => void;
  sendForm: () => void;
}

const Quest: React.FC<FormProps> = ({
  activeStep,
  form,
  setform,
  sendForm,
}) => {
  let result;
  switch (true) {
    case activeStep === 0:
      result = (
        <MainInfo
          email={form.email}
          setEmail={(e: string) => setform({ ...form, email: e })}
          website={form.website}
          setWebsite={(e: string) => setform({ ...form, website: e })}
          restaurant={form.restaurant}
          setRestaurant={(e: string) => setform({ ...form, restaurant: e })}
          address={form.address}
          setAddress={(e: string) => setform({ ...form, address: e })}
        />
      );
      break;
    case activeStep === 1:
      result = (
        <Quest1
          name="turnover"
          title={"New employees"}
          subtitle={"I am losing time and money on new employees"}
          options={[
            "I want an easy screen to show them how to work",
            "I want to make sure they do not make recipes wrongly",
            "I want less food waste due to errors in the kitchen",
          ]}
          turnover={form.turnover}
          setturnover={(t) => setform({ ...form, turnover: t })}
        />
      );
      break;
    case activeStep === 2:
      result = (
        <Quest1
          name="estimate"
          title={"Estimating Ingredients"}
          subtitle={"I don't know how much is needed for my menu tomorrow:"}
          options={[
            "I want to see how much I need from each ingredient when I produce in bulk",
            "I want to see how much I need from each ingredient for my current menu",
            "I want to see how much of each recipe needs to be ready at the start of the day",
          ]}
          turnover={form.estimating}
          setturnover={(t) => setform({ ...form, estimating: t })}
        />
      );
      break;
    case activeStep === 3:
      result = (
        <Quest1
          name="order"
          title={"Order Ingredients"}
          subtitle={"I have trouble ordering the correct amount of ingredients"}
          options={[
            "I want automatic orders at the end of my working day",
            "I want automatic calculations of what I need for the next day",
            "I want to base my purchases on prices",
          ]}
          turnover={form.ordering}
          setturnover={(t) => setform({ ...form, ordering: t })}
        />
      );
      break;
    case activeStep === 4:
      result = (
        <Quest1
          name="costs"
          title={"Costs & Margins"}
          subtitle={
            "I don't know about my dishes costs and margins changing over time"
          }
          options={[
            "I want to track ingredient prices change over time",
            "I want an overview of all margins of my dishes",
            "I will base my next menu on margins of dishes",
          ]}
          turnover={form.costs}
          setturnover={(t) => setform({ ...form, costs: t })}
        />
      );
      break;
    case activeStep === 5:
      result = (
        <Quest1
          name="haccp"
          title={"Hygiene Inspections"}
          subtitle={"I have trouble being prepared for hygiene inspections"}
          options={[
            "I need automatic allergy lists for my menus",
            "I need an overview of temperature requirements for my recipes",
            "I need all documents to be ready for an inspection",
          ]}
          turnover={form.haccp}
          setturnover={(t) => setform({ ...form, haccp: t })}
        />
      );
      break;
    case activeStep === 6:
      result = (
        <Quest1
          name="nutrition"
          title={"Nutrition Information"}
          subtitle={
            "I don't have answers to customers asking about nutritional values"
          }
          options={[
            "I need automatic calculations of macros",
            "I need a digital menu to show all macros to customers",
            "I want to provide customers with options tailored to their nutritional wishes",
          ]}
          turnover={form.nutrition}
          setturnover={(t) => setform({ ...form, nutrition: t })}
        />
      );
      break;
    default:
      result = (
        <>
          <TextField
            label="Anything else you would like to add to your request?"
            name={form.message}
            multiline
            onChange={(e) => setform({ ...form, html_message: e.target.value })}
            rows={3}
            fullWidth
            margin="normal"
          />
          <GridRowOneItem
            before={4}
            after={4}
            child={
              <SubmitButton onClick={sendForm} variant="contained">
                Sign Up Now
              </SubmitButton>
            }
          />
        </>
      );
      return result;
  }
  return <Box sx={{ minHeight: "30vh" }}>{result}</Box>;
};

const UploadRecipesText = ({ isMediaQuery }: { isMediaQuery: boolean }) => {
  if (isMediaQuery)
    return (
      <Stack direction={"column"} spacing={1}>
        <>
          Easy forms and pages that let you easily write upload all information
          about your recipes and Menus. <br />
          Also possible to outsource this process to MyChefsbase.
        </>
        <Box
          component="img"
          src="/adddishform.png"
          alt="buoy"
          sx={{ width: 300 }} // Set width to 50% on mobile screens
        />
      </Stack>
    );
  return (
    <GridRowTwoItems
      before={0}
      firstlength={5}
      inbetween={0}
      secondlength={5}
      firstchild={
        <>
          Easy forms and pages that let you easily write upload all information
          about your recipes and Menus. <br />
          Also possible to outsource this process to MyChefsbase.
        </>
      }
      secondchild={
        <Box
          component="img"
          src="/adddishform.png"
          alt="buoy"
          sx={{ width: 400 }} // Set width to 50% on mobile screens
        />
      }
    />
  );
};

const emptyForm: Form = {
  email: "",
  website: "",
  address: "",
  restaurant: "",
  message: "",
  waste: "",
  turnover: [],
  estimating: [],
  ordering: [],
  costs: [],
  haccp: [],
  nutrition: [],
  html_message: "",
};
interface Form {
  email: string;
  website: string;
  address: string;
  restaurant: string;
  message: string;
  waste: string;
  turnover: string[];
  estimating: string[];
  ordering: string[];
  costs: string[];
  haccp: string[];
  nutrition: string[];
  html_message: string;
}

const SignUpButton = () => {
  const [open, setopen] = useState(false);
  const [form, setform] = useState<Form>(emptyForm);
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendEmail = () => {
    const f = true;
    const formData: Record<string, unknown> = {
      email_address: form.email,
      website_address: form.website,
      restaurant_name: form.restaurant,
      restaurant_address: form.address,
      turnover: JSON.stringify(form.turnover),
      estimating: JSON.stringify(form.estimating),
      ordering: JSON.stringify(form.ordering),
      waste: JSON.stringify(form.waste),
      haccp: JSON.stringify(form.haccp),
      nutrition: JSON.stringify(form.nutrition),
      html_message: JSON.stringify(form.html_message),
    };
    if (f) {
      emailjs
        .send(
          "service_7mojydn",
          "template_4pvudlf",
          formData,
          "ASiCPigOW_8pD-YDa"
        )
        .then(
          (result) => {
            window.location.reload();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <>
      <SubmitButton
        sx={{ backgroundColor: backgroundBeige, borderRadius: 25 }}
        variant="contained"
        onClick={() => setopen(true)}
      >
        Sign Up for the Waiting List
      </SubmitButton>
      <Dialog
        open={open}
        onClose={() => setopen(false)}
        maxWidth="md" // Adjust the maxWidth as needed
        sx={{
          "& .MuiPaper-root": {
            width: "500px", // Set a fixed width for the dialog
            height: "600px", // Set a fixed height for the dialog
          },
        }}
      >
        <DialogTitle>
          Please be so kind to answer the following questions:
        </DialogTitle>
        <DialogContent>
          <form className="contact-form" onSubmit={sendEmail}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                sx={{ mr: 1, borderRadius: 28 }}
              >
                Return
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                disabled={activeStep === 7}
                variant="contained"
                sx={{ mr: 1, borderRadius: 28 }}
                onClick={() =>
                  activeStep !== 7 ? handleNext() : setopen(false)
                }
              >
                {"Next"}
              </Button>
            </Box>
            {
              <React.Fragment>
                <Typography
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <Quest
                    activeStep={activeStep}
                    form={form}
                    setform={setform}
                    sendForm={() => {
                      sendEmail();
                      setopen(false);
                    }}
                  />
                </Typography>
              </React.Fragment>
            }
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton variant="contained" onClick={() => setopen(false)}>
            Close
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MainInfo = ({
  email,
  setEmail,
  website,
  setWebsite,
  restaurant,
  setRestaurant,
  address,
  setAddress,
}: {
  email: string | string[];
  setEmail: (a: string) => void;
  website: string | string[];
  setWebsite: (a: string) => void;
  restaurant: string | string[];
  setRestaurant: (a: string) => void;
  address: string | string[];
  setAddress: (a: string) => void;
}) => {
  return (
    <>
      <TextField
        label="Email"
        type="email"
        name="email_address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Website"
        name="website_address"
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Restaurant"
        name="restaurant_name"
        value={restaurant}
        onChange={(e) => setRestaurant(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Restaurant Address"
        name="restaurant_address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        margin="normal"
      />
    </>
  );
};

const Quest1 = ({
  turnover,
  setturnover,
  options,
  title,
  subtitle,
  name,
}: {
  name: string;
  title: string;
  subtitle: string;
  options: string[];
  turnover: string | string[];
  setturnover: (a: string[]) => void;
}) => {
  return (
    <div>
      <div>
        <b>{title}</b> <br />
        {subtitle}
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={turnover.includes(options[0])}
              onChange={(e) => {
                if (e.target.checked && !turnover.includes(options[0])) {
                  const old = [...turnover];
                  old.push(options[0]);
                  setturnover(old);
                }
                if (!e.target.checked && turnover.includes(options[0])) {
                  const old = [...turnover];
                  const index = old.indexOf(options[0]);
                  old.splice(index, 1);
                  setturnover(old);
                }
              }}
              color="primary"
            />
          }
          label={options[0]}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={turnover.includes(options[1])}
              onChange={(e) => {
                if (e.target.checked && !turnover.includes(options[1])) {
                  const old = [...turnover];
                  old.push(options[1]);
                  setturnover(old);
                }
                if (!e.target.checked && turnover.includes(options[1])) {
                  const old = [...turnover];
                  const index = old.indexOf(options[1]);
                  old.splice(index, 1);
                  setturnover(old);
                }
              }}
              color="primary"
            />
          }
          label={options[1]}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={turnover.includes(options[2])}
              onChange={(e) => {
                if (e.target.checked && !turnover.includes(options[2])) {
                  const old = [...turnover];
                  old.push(options[2]);
                  setturnover(old);
                }
                if (!e.target.checked && turnover.includes(options[2])) {
                  const old = [...turnover];
                  const index = old.indexOf(options[2]);
                  old.splice(index, 1);
                  setturnover(old);
                }
              }}
              color="primary"
            />
          }
          label={options[2]}
        />
      </div>
    </div>
  );
};
