import { ComposablesInput, SelectablesInput } from "../../../globalTypes";
import { emptyComposable } from "../composables/ComposablesInput";
import { emptySelectable } from "../selectables/SelectableInput";
export type Course = {
  name: string;
  selectables: SelectablesInput[];
  composables: ComposablesInput[];
};
export const emptyCourse: Course = {
  name: "",
  selectables: [emptySelectable],
  composables: [emptyComposable],
};
