import { useState } from "react";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import {
  AddComposablesDishButton,
  ComposablesDishButton,
} from "./ComposablesDishButton";
import {
  AddComposableDishDialog,
  ComposableDishDialog,
  ComposableDishDialogUpdate,
} from "./ComposableDishDialog";
import { UpdateMenuVariables } from "../../../chefsbase/menus/addMenu/types/UpdateMenu";
import { VscEdit } from "react-icons/vsc";
import { emptyComposable } from "../../../../components/defaults/composables/ComposablesInput";

export const ComposableForCourse = ({
  form,
  setForm,
  index,
  i,
}: {
  i: number;
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const [open, setopen] = useState(false);
  return (
    <>
      <ComposablesDishButton
        composable={form.courses[index].composables[i]}
        setopen={(bool) => setopen(bool)}
        deleteItem={() => {
          const old = [...form.courses[index].composables];
          old.splice(i, 1);
          const courses = [...form.courses];
          courses[index] = {
            ...courses[index],
            composables: old,
          };
          setForm({
            ...form,
            courses: courses,
          });
        }}
      />

      <ComposableDishDialog
        open={open}
        i={i}
        setopen={setopen}
        index={index}
        setForm={(a) => {
          setForm(a);
        }}
        form={form}
      />
    </>
  );
};

export const AddableComposableForCourse = ({
  form,
  setForm,
  index,
}: {
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const [open, setopen] = useState(false);
  return (
    <>
      <AddComposablesDishButton setopen={(bool) => setopen(bool)} />

      <AddComposableDishDialog
        open={open}
        setopen={setopen}
        index={index}
        setForm={(a) => {
          setForm(a);
        }}
        form={form}
      />
    </>
  );
};

export const ComposableForCourseUpdate = ({
  form,
  setForm,
  index,
  i,
}: {
  i: number;
  index: number;
  form: UpdateMenuVariables;
  setForm: (a: UpdateMenuVariables) => void;
}) => {
  const [open, setopen] = useState(false);

  return (
    <>
      {form.courses[index].composables[i].name}
      <VscEdit onClick={() => setopen(true)} />
      <ComposableDishDialogUpdate
        open={open}
        i={i}
        setopen={setopen}
        index={index}
        setForm={(a) => {
          setForm(a);
        }}
        form={form}
      />
    </>
  );
};
