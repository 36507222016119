import { gramMeasure } from "../../../../components/defaults/portions/PortionInput";
import { HealthLabelInfo, NutritionInput } from "../../../../globalTypes";
import { Dish } from "../../../clientbase/types/types";
import { recipes_recipes_nutrition_nutrition } from "../../recipesAndIngredients/types/recipes";
import { dishes_dishes, dishes_dishes_healthLabelInfo } from "../types/dishes";

export const zeronutrition: recipes_recipes_nutrition_nutrition = {
  __typename: "Nutrition",
  kcal: 0,
  prottotal: 0,
  carbscarbs: 0,
  carbssugar: 0,
  fatstotal: 0,
  starch: 0,
  natrium: 0,
  cholesterol: 0,
  saturedfat: 0,
  fibres: 0,
};
export const zeroNutritionInput: NutritionInput = {
  kcal: 0,
  prottotal: 0,
  carbscarbs: 0,
  carbssugar: 0,
  fatstotal: 0,
  starch: 0,
  natrium: 0,
  cholesterol: 0,
  saturedfat: 0,
  fibres: 0,
};
export const emptyhealthlabelinfo: HealthLabelInfo = {
  vegan: false,
  lupine: false,
  meat: false,
  nut: false,
  peanut: false,
  shellfish: false,
  selery: false,
  sesame: false,
  soy: false,
  fish: false,
  crustacean: false,
  sulphide: false,
  mustard: false,
  dairy: false,
  gluten: false,
  egg: false,
  milk: false,
  pork: false,
  mollusk: false,
};

export const mapHealthLabelInfo = (
  info: dishes_dishes_healthLabelInfo
): HealthLabelInfo => {
  return {
    vegan: info.vegan,
    lupine: info.lupine,
    meat: info.meat,
    nut: info.nut,
    peanut: info.peanut,
    shellfish: info.shellfish,
    selery: info.selery,
    sesame: info.sesame,
    soy: info.soy,
    fish: info.fish,
    crustacean: info.crustacean,
    sulphide: info.sulphide,
    mustard: info.mustard,
    dairy: info.dairy,
    gluten: info.gluten,
    egg: info.egg,
    milk: info.milk,
    pork: info.pork,
    mollusk: info.mollusk,
  };
};
export const zerohealthlabelinfo: dishes_dishes_healthLabelInfo = {
  __typename: "HealthLabelInfoOutput",
  vegan: false,
  lupine: false,
  meat: false,
  nut: false,
  peanut: false,
  shellfish: false,
  selery: false,
  sesame: false,
  soy: false,
  fish: false,
  crustacean: false,
  sulphide: false,
  mustard: false,
  dairy: false,
  gluten: false,
  egg: false,
  milk: false,
  pork: false,
  mollusk: false,
};
export const zerodish: dishes_dishes = {
  __typename: "Dish",
  id: "Default",
  name: "Default",
  type: "Default",
  img: [],
  portions: [],
  options: [],
  method: "Default",
  healthLabelInfo: zerohealthlabelinfo,
  hoursWorked: {
    __typename: "AmountPerQuantity",
    amount: 10,
    quantity: {
      __typename: "Quantity",
      quantity: 500,
      unit: gramMeasure,
    },
  },
};
