import { gql, useMutation } from "@apollo/client";
import {
  writeTinderRecipe,
  writeTinderRecipeVariables,
} from "./types/writeTinderRecipe";

const AddTinderRecipeMutation = gql`
  mutation writeTinderRecipe($input: RecipeForTinderInput!) {
    writeTinderRecipe(input: $input)
  }
`;

export const useAddTinderRecipeMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [writeTinderRecipe, { loading, error }] = useMutation<
    writeTinderRecipe,
    writeTinderRecipeVariables
  >(AddTinderRecipeMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    writeTinderRecipe,
    loading,
    error,
  };
};
