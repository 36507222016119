import { SelectablesInput } from "../../../globalTypes";
import { MySelectablesInput } from "../../../screens/clientbase/createClientPage/types/MySelectablesInput";
import {
  smallSelected,
  mediumSelected,
  largeSelected,
} from "../portions/PortionInput";

export const emptySelectable: SelectablesInput = {
  dishid: "ece13bb0-9d88-318b-b211-0082a89d1645",
  dishname: "Test Dish",
  quantitiesSelected: [smallSelected, mediumSelected, largeSelected],
};

export const emptyMySelectable: MySelectablesInput = {
  img: [""],
  dishid: "ece13bb0-9d88-318b-b211-0082a89d1645",
  dishname: "Test Dish",
  quantitiesSelected: [smallSelected, mediumSelected, largeSelected],
};

export const addNewMySelectable: MySelectablesInput = {
  img: [""],
  dishid: "+ Add New",
  dishname: "+ Add New",
  quantitiesSelected: [smallSelected, mediumSelected, largeSelected],
};