import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { VscAdd, VscRefresh, VscTrash } from "react-icons/vsc";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import { TextOrField } from "../../../components/forms/formOrEdit/FormFieldEdit";
import { FormField } from "../../../components/forms/singleForms/FormField";
import { KitchenType } from "../../../globalTypes";
import { DeleteDialog } from "../components/DeleteDialog";
import { useRecipesQuery } from "../recipesAndIngredients/api";
import {
  recipes,
  recipes_recipes,
} from "../recipesAndIngredients/types/recipes";
import { RecipeForm } from "./addrecipes/types";
import { useLazydIngredientsForRecipeQuery } from "./api";
import {
  toRecipeForm,
  ToQuantityToNameIdAndNutritionInputProd,
  ToQuantityToNameIdAndNutritionInput,
} from "./components/Mapping";
import { RecipeDialog } from "./recipeDialog";
import { Recipe } from "./types/Recipe";
import { UpdateRecipeVariables } from "./addrecipes/types/UpdateRecipe";
import { useUpdateRecipeMutation } from "./addrecipes/api";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { measureToMeasureInput } from "../../clientbase/createClientPage/forms/DishCard/Mappers";
import { gramMeasureInput } from "../../../components/defaults/portions/PortionInput";
import { healthlabelInfoToOutput } from "../dishes/utils/MapToInput";
import { Loader } from "../../../components/Loading/Loader";
import { useAccountQuery } from "../../api";

export const Recipes = () => {
  const [name, setName] = useState("");
  const [type, settype] = useState("");
  const [filteredrecipes, setrecipes] = useState<recipes_recipes[]>([]);

  const { data, loading } = useRecipesQuery({
    page: 0,
    onCompleted: (result: recipes) => setrecipes(result.recipes),
  });

  const recipes: recipes_recipes[] = data ? data.recipes : [];

  const navigate = useNavigate();
  const setNameFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredrecipes.filter((i) =>
            i.name.toLowerCase().includes(a.toLowerCase())
          )
        : recipes;

    setrecipes(n);
  };
  const setTypeFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredrecipes.filter(
            (i) => true
            //   i.type.toLowerCase().includes(a.toLowerCase())
          )
        : recipes;

    setrecipes(n);
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [noAccount, setNoAccount] = useState<boolean>(false);

  const { data: dataAccount } = useAccountQuery({
    onCompleted: (result) => {
      if (
        result.account !== null &&
        result.account.accesstoken == result.account.restaurantName
      )
        setNoAccount(true);
    },
  });
  return (
    <MainBox
      childComp={
        noAccount ? (
          <>
            Please send a request to <br />
            <a href="mailto::mychefsbase@gmail.com">mychefsbase@gmail.com</a>
            to request for the use of MyChefsbase. Or sign up for the waiting{" "}
            <br />
            list on <a href="www.mychefsbase.com">www.mychefsbase.com</a>
          </>
        ) : (
          <>
            <BoxTitle title="Recipes" />
            <div style={{ height: 300, width: "100%" }}>
              <Table>
                {!isMobile && (
                  <TopRow
                    navigate={navigate}
                    name={name}
                    type={type}
                    setNameFilter={(a: string) => setNameFilter(a)}
                    setTypeFilter={(a: string) => setTypeFilter(a)}
                    setname={(a: string) => setName(a)}
                    settype={(a: string) => settype(a)}
                  />
                )}
                {loading && <Loader loading={loading} />}
                <BodyRows
                  isMobile={isMobile}
                  filteredrecipes={filteredrecipes}
                  setfilteredrecipes={(f: recipes_recipes[]) => setrecipes(f)}
                />
              </Table>
            </div>
          </>
        )
      }
    />
  );
};

const TopRow = ({
  name,
  type,
  setname,
  settype,
  setNameFilter,
  setTypeFilter,
  navigate,
}: {
  navigate: NavigateFunction;
  name: string;
  type: string;
  setname: (a: string) => void;
  settype: (a: string) => void;
  setTypeFilter: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Search Recipe:</TableCell>
          <TableCell>
            <FormField
              value={name}
              setValue={(a) => {
                setname(a);
                setNameFilter(a);
              }}
            />
          </TableCell>
          <TableCell>Search On Type:</TableCell>
          <TableCell>
            <FormField
              value={type}
              setValue={(a) => {
                settype(a);
                setTypeFilter(a);
              }}
            />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableRow>
        <TableCell colSpan={2}>
          <SubmitButton
            variant="contained"
            onClick={() => navigate("/addrecipe", { replace: true })}
          >
            <VscAdd />
            Recipe
          </SubmitButton>
        </TableCell>
        <TableCell colSpan={2}>
          <SubmitButton
            variant="contained"
            onClick={() => window.location.reload()}
          >
            <VscRefresh />
            Refresh
          </SubmitButton>
        </TableCell>
      </TableRow>
    </>
  );
};

const BodyRows = ({
  filteredrecipes,
  setfilteredrecipes,
  isMobile,
}: {
  isMobile: boolean;
  filteredrecipes: recipes_recipes[];
  setfilteredrecipes: (a: recipes_recipes[]) => void;
}) => {
  return (
    <>
      {filteredrecipes.map((r, index) => (
        <>
          <RecipeRow
            isMobile={isMobile}
            key={r.id}
            filteredrecipes={filteredrecipes}
            remove={() => {
              const newSelected = [...filteredrecipes];
              newSelected.splice(index, 1);
              setfilteredrecipes(newSelected);
            }}
            r={r}
          />
        </>
      ))}
    </>
  );
};

const toUpdateRecipeForm = (r: recipes_recipes): UpdateRecipeVariables => {
  return {
    healthLabels: healthlabelInfoToOutput(r.healthLabelInfo),
    id: r.id,
    input: {
      img: r.img,
      name: r.name,
      type: r.type,
      method: r.method,
      quantity: {
        quantity: r.quantity.quantity,
        unit: measureToMeasureInput(r.quantity.unit),
      },
    },
    recipes: {
      dishPortion: {
        name: "Manual",
        quantity: {
          quantity: r.quantity.quantity,
          unit: measureToMeasureInput(r.quantity.unit),
        },
      },
      ingredients: [],
    },
    products: {
      dishPortion: {
        name: "Manual",
        quantity: {
          quantity: r.quantity.quantity,
          unit: measureToMeasureInput(r.quantity.unit),
        },
      },
      ingredients: [],
    },
  };
};

const RecipeRow = ({
  r,
  remove,
  filteredrecipes,
  isMobile,
}: {
  isMobile: boolean;
  filteredrecipes: recipes_recipes[];
  remove: () => void;
  r: recipes_recipes;
}) => {
  //Buttons

  const [updated, setupdated] = useState(false);
  const { updateRecipe, loading } = useUpdateRecipeMutation({
    onCompleted: () => {
      setupdated(true);
    },
  });
  const [openRecipe, setOpenRecipe] = useState(false);
  const [recipeForm, setRecipeForm] = useState<UpdateRecipeVariables>(
    toUpdateRecipeForm(r)
  );
  const { getIngredientsForRecipe, data: ingredientsForRecipe } =
    useLazydIngredientsForRecipeQuery({
      id: r.id,
      quantity: {
        quantity: r.quantity.quantity,
        unit: measureToMeasureInput(r.quantity.unit),
      },
      onCompleted: (result: Recipe) =>
        setRecipeForm({
          ...recipeForm,
          products: {
            ...recipeForm.products,
            ingredients: ToQuantityToNameIdAndNutritionInputProd(
              result.recipe.products && result.recipe.products?.foods
                ? result.recipe.products.foods
                : []
            ),
          },
          recipes: {
            ...recipeForm.recipes,
            ingredients: ToQuantityToNameIdAndNutritionInput(
              result.recipe.recipes && result.recipe.recipes.recipes
            ),
          },
        }),
    });

  return (
    <>
      <RecipeTopRow
        isMobile={isMobile}
        id={r.id}
        form={toRecipeForm(r)}
        setopen={(a: boolean) => {
          getIngredientsForRecipe();
          setOpenRecipe(a);
        }}
        remove={() => remove()}
      />
      {recipeForm.products && (
        <RecipeDialog
          isMobile={isMobile}
          filteredrecipes={filteredrecipes}
          open={openRecipe}
          setOpen={() => setOpenRecipe(false)}
          form={recipeForm}
          setForm={(a: UpdateRecipeVariables) => setRecipeForm(a)}
          mutate={(options?: any) => {
            updateRecipe(options);
          }}
        />
      )}
      <Dialog open={updated} onClose={() => setupdated(false)}>
        <DialogTitle>{`${recipeForm.input.name} has been updated!`}</DialogTitle>
        <DialogActions>
          <SubmitButton
            variant="contained"
            onClick={() => {
              setupdated(false);
              setOpenRecipe(false);
            }}
          >
            Great!
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
const RecipeTopRow = ({
  form,
  setopen,
  remove,
  id,
  isMobile,
}: {
  isMobile: boolean;
  id: string;
  remove: () => void;
  form: RecipeForm;
  setopen: (a: boolean) => void;
}) => {
  const [openDelete, setopenDelete] = useState(false);
  if (isMobile)
    return (
      <>
        <TableRow>
          <TableCell>
            <SubmitButton
              fullWidth
              variant="contained"
              onClick={() => {
                setopen(true);
              }}
            >
              {form.info.name}
            </SubmitButton>
          </TableCell>
        </TableRow>
      </>
    );
  return (
    <>
      <TableRow>
        <TableCell>
          <SubmitButton
            variant="contained"
            onClick={() => {
              setopen(true);
            }}
          >
            Open
          </SubmitButton>
        </TableCell>
        <TableCell>{form.info.name}</TableCell>
        <TableCell>{form.info.type}</TableCell>
        <TableCell>
          <SubmitButton variant="contained" onClick={() => setopenDelete(true)}>
            <VscTrash />
          </SubmitButton>
        </TableCell>
      </TableRow>
      <DeleteDialog
        open={openDelete}
        onClose={() => setopenDelete(false)}
        name={form.info.name}
        id={id}
        kitchenType={KitchenType.Recipe}
        onCompleted={() => {
          remove();
        }}
      />
    </>
  );
};
