import { Grid } from "@mui/material";

export interface ParentCompProps {
  childComp?: React.ReactNode;
}

export interface ParentCompPropsFiveItems {
  firstchild: React.ReactNode;
  secondchild: React.ReactNode;
  thirdchild: React.ReactNode;
  fourthchild: React.ReactNode;
  fifthchild: React.ReactNode;
  firstlength: number;
  secondlength: number;
  thirdlength: number;
  fourthlength: number;
  fifthlength: number;
  before: number;
  inbetweenfirsttwo: number;
  inbetweensecondtwo: number;
  inbetweenthirdtwo: number;
  inbetweenfourthtwo: number;
}

export interface ParentCompPropsSixItems {
  firstchild: React.ReactNode;
  secondchild: React.ReactNode;
  thirdchild: React.ReactNode;
  fourthchild: React.ReactNode;
  fifthchild: React.ReactNode;
  sixthchild: React.ReactNode;
  firstlength: number;
  secondlength: number;
  thirdlength: number;
  fourthlength: number;
  fifthlength: number;
  sixthlength: number;
  before: number;
  inbetweenfirsttwo: number;
  inbetweensecondtwo: number;
  inbetweenthirdtwo: number;
  inbetweenfourthtwo: number;
  inbetweenfifthtwo: number;
}

export interface ParentCompPropsThreeItems {
  firstchild: React.ReactNode;
  secondchild: React.ReactNode;
  thirdchild: React.ReactNode;
  firstlength: number;
  secondlength: number;
  thirdlength: number;
  before: number;
  inbetweenfirsttwo: number;
  inbetweensecondtwo: number;
}

export interface ParentCompPropsFourItems {
  firstchild: React.ReactNode;
  secondchild: React.ReactNode;
  thirdchild: React.ReactNode;
  fourthchild: React.ReactNode;
  firstlength: number;
  secondlength: number;
  thirdlength: number;
  fourthlength: number;
  before: number;
  inbetweenfirsttwo: number;
  inbetweensecondtwo: number;
  inbetweenthirdtwo: number;
}

export interface ParentCompPropsTwoItems {
  firstchild: React.ReactNode;
  secondchild: React.ReactNode;
  firstlength: number;
  secondlength: number;
  before: number;
  inbetween: number;
}

export interface ParentCompPropsOneItem {
  child?: React.ReactNode;
  before: number;
  after: number;
}

export interface ParentCompPropsOne {
  child: React.ReactNode;
}

export const GridContainer: React.FC<ParentCompPropsOne> = (props) => {
  const { child } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="stretch"
      xs={12}
    >
      {child}
    </Grid>
  );
};

export const GridRowOneItem: React.FC<ParentCompPropsOneItem> = (props) => {
  const { child, before, after } = props;
  return (
    <Grid container>
      <Grid item xs={before}></Grid>
      <Grid item xs={12 - after - before}>
        {child}
      </Grid>
      <Grid item xs={after}></Grid>
    </Grid>
  );
};

export const GridRowTwoItems: React.FC<ParentCompPropsTwoItems> = (props) => {
  const {
    firstchild,
    secondchild,
    firstlength,
    secondlength,
    before,
    inbetween,
  } = props;
  return (
    <Grid container>
      <Grid item xs={before}></Grid>
      <Grid item xs={firstlength}>
        {firstchild}
      </Grid>
      <Grid item xs={inbetween}></Grid>
      <Grid item xs={secondlength}>
        {secondchild}
      </Grid>
      <Grid
        item
        xs={12 - before - firstlength - secondlength - inbetween}
      ></Grid>
    </Grid>
  );
};

export const GridRowThreeItems: React.FC<ParentCompPropsThreeItems> = (
  props
) => {
  const {
    firstchild,
    secondchild,
    thirdchild,
    firstlength,
    secondlength,
    thirdlength,
    before,
    inbetweenfirsttwo,
    inbetweensecondtwo,
  } = props;
  return (
    <Grid
      container
      // sx={{ backgroundColor: "#7DADC5" }}
    >
      <Grid item xs={before}></Grid>
      <Grid item xs={firstlength}>
        {firstchild}
      </Grid>
      <Grid item xs={inbetweenfirsttwo}></Grid>
      <Grid item xs={secondlength}>
        {secondchild}
      </Grid>
      <Grid item xs={inbetweensecondtwo}></Grid>
      <Grid item xs={thirdlength}>
        {thirdchild}
      </Grid>
      <Grid
        item
        xs={
          12 -
          before -
          firstlength -
          secondlength -
          thirdlength -
          inbetweenfirsttwo -
          inbetweensecondtwo
        }
      ></Grid>
    </Grid>
  );
};

export const GridRowFourItems: React.FC<ParentCompPropsFourItems> = (props) => {
  const {
    firstchild,
    secondchild,
    thirdchild,
    fourthchild,
    firstlength,
    secondlength,
    thirdlength,
    fourthlength,
    before,
    inbetweenfirsttwo,
    inbetweensecondtwo,
    inbetweenthirdtwo,
  } = props;
  return (
    <Grid container>
      <Grid item xs={before}></Grid>
      <Grid item xs={firstlength}>
        {firstchild}
      </Grid>
      <Grid item xs={inbetweenfirsttwo}></Grid>
      <Grid item xs={secondlength}>
        {secondchild}
      </Grid>
      <Grid item xs={inbetweensecondtwo}></Grid>
      <Grid item xs={thirdlength}>
        {thirdchild}
      </Grid>
      <Grid item xs={inbetweenthirdtwo}></Grid>
      <Grid item xs={fourthlength}>
        {fourthchild}
      </Grid>
      <Grid
        item
        xs={
          12 -
          before -
          firstlength -
          secondlength -
          thirdlength -
          fourthlength -
          inbetweenfirsttwo -
          inbetweensecondtwo -
          inbetweenthirdtwo
        }
      ></Grid>
    </Grid>
  );
};

export const GridRowFiveItems: React.FC<ParentCompPropsFiveItems> = (props) => {
  const {
    firstchild,
    secondchild,
    thirdchild,
    fourthchild,
    fifthchild,
    firstlength,
    secondlength,
    thirdlength,
    fourthlength,
    fifthlength,
    before,
    inbetweenfirsttwo,
    inbetweensecondtwo,
    inbetweenthirdtwo,
    inbetweenfourthtwo,
  } = props;
  return (
    <Grid container>
      <Grid item xs={before}></Grid>
      <Grid item xs={firstlength}>
        {firstchild}
      </Grid>
      <Grid item xs={inbetweenfirsttwo}></Grid>
      <Grid item xs={secondlength}>
        {secondchild}
      </Grid>
      <Grid item xs={inbetweensecondtwo}></Grid>
      <Grid item xs={thirdlength}>
        {thirdchild}
      </Grid>
      <Grid item xs={inbetweenthirdtwo}></Grid>
      <Grid item xs={fourthlength}>
        {fourthchild}
      </Grid>
      <Grid item xs={inbetweenfourthtwo}></Grid>
      <Grid item xs={fifthlength}>
        {fifthchild}
      </Grid>
      <Grid
        item
        xs={
          12 -
          before -
          firstlength -
          secondlength -
          thirdlength -
          fourthlength -
          fifthlength -
          inbetweenfirsttwo -
          inbetweensecondtwo -
          inbetweenthirdtwo -
          inbetweenfourthtwo
        }
      ></Grid>
    </Grid>
  );
};

export const GridRowSixItems: React.FC<ParentCompPropsSixItems> = (props) => {
  const {
    firstchild,
    secondchild,
    thirdchild,
    fourthchild,
    fifthchild,
    sixthchild,
    firstlength,
    secondlength,
    thirdlength,
    fourthlength,
    fifthlength,
    sixthlength,
    before,
    inbetweenfirsttwo,
    inbetweensecondtwo,
    inbetweenthirdtwo,
    inbetweenfourthtwo,
    inbetweenfifthtwo,
  } = props;
  return (
    <Grid container>
      <Grid item xs={before}></Grid>
      <Grid item xs={firstlength}>
        {firstchild}
      </Grid>
      <Grid item xs={inbetweenfirsttwo}></Grid>
      <Grid item xs={secondlength}>
        {secondchild}
      </Grid>
      <Grid item xs={inbetweensecondtwo}></Grid>
      <Grid item xs={thirdlength}>
        {thirdchild}
      </Grid>
      <Grid item xs={inbetweenthirdtwo}></Grid>
      <Grid item xs={fourthlength}>
        {fourthchild}
      </Grid>
      <Grid item xs={inbetweenfourthtwo}></Grid>
      <Grid item xs={fifthlength}>
        {fifthchild}
      </Grid>
      <Grid item xs={inbetweenfifthtwo}></Grid>
      <Grid item xs={sixthlength}>
        {sixthchild}
      </Grid>
      <Grid
        item
        xs={
          12 -
          before -
          firstlength -
          secondlength -
          thirdlength -
          fourthlength -
          fifthlength -
          sixthlength -
          inbetweenfirsttwo -
          inbetweensecondtwo -
          inbetweenthirdtwo -
          inbetweenfourthtwo -
          inbetweenfifthtwo
        }
      ></Grid>
    </Grid>
  );
};
