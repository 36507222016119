import { useState } from "react";
import { AddDishVariables } from "../../../chefsbase/dishes/addDish/types/AddDish";
import { dishes_dishes } from "../../../chefsbase/dishes/types/dishes";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import { MySelectablesInput } from "../types/MySelectablesInput";
import { ChangeDishDialog } from "./AddDishDialog";
import { DishButton, DishButton2 } from "./DishButton";
import { emptyAddDishVariables } from "../../../../components/defaults/AddDishForm/EmptyAddDishVariables";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { SelectablesInput } from "../../../../globalTypes";
import { Grid } from "@mui/material";
import { measureInputOptions } from "../../../../components/defaults/portions/PortionInput";
import { TrashButton } from "../../../../components/Buttons";

export const DishForCourse = ({
  form,
  setForm,
  index,
  i,
  dishes,
  loadingDishes,
  sel,
}: {
  sel: SelectablesInput;
  loadingDishes: boolean;
  dishes: dishes_dishes[];
  i: number;
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
}) => {
  const [dish, setdish] = useState<MySelectablesInput>({
    img: sel.img,
    dishid: sel.dishid,
    dishname: sel.dishname,
    quantitiesSelected: sel.quantitiesSelected,
  });
  const [openChangeDish, setOpenChangeDish] = useState(false);
  const indexOfSel = form.courses[index].selectables.indexOf(
    form.courses[index].selectables.find((o) => o.dishname === sel.dishname)!
  );

  return (
    <>
      <Grid xs={3.5} sm={3.5} md={3}>
        <DishButton2
          setselectable={setdish}
          selectable={dish}
          setopen={(bool) => setOpenChangeDish(bool)}
          index={index}
          i={indexOfSel}
          form={form}
          setForm={(a) => setForm(a)}
        />
        <TrashButton
          onClick={() => {
            const oldSel = [...form.courses[index].selectables];
            oldSel.splice(i, 1);
            const oldCourses = [...form.courses];
            oldCourses[index] = {
              ...oldCourses[index],
              selectables: oldSel,
            };
            setForm({
              ...form,
              courses: oldCourses,
            });
          }}
        />
      </Grid>
      <ChangeDishDialog
        options={
          dish.quantitiesSelected.length > 0
            ? measureInputOptions(
                dish.quantitiesSelected[0].portion.quantity.unit.label
              ).map((a) => ({ name: a.label, id: a.uri }))
            : measureInputOptions().map((a) => ({ name: a.label, id: a.uri }))
        }
        dish={dish}
        loadingDishes={loadingDishes}
        setDish={(a) => {
          setdish(a);
        }}
        onSubmit={(a) => {
          const oldcourses = [...form.courses];
          const old = [...form.courses[index].selectables];
          const indexOfSel = old.indexOf(
            old.find((o) => o.dishname === a.dishname)!
          );
          old[indexOfSel] = a;
          oldcourses[index] = {
            ...form.courses[index],
            selectables: old,
          };
          setForm({
            ...form,
            courses: oldcourses,
          });
          setOpenChangeDish(false);
        }}
        dishes={dishes}
        open={openChangeDish}
        setopen={setOpenChangeDish}
      />
    </>
  );
};
