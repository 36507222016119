import { Button } from "@mui/material";
import React from "react";
import { VscTrash } from "react-icons/vsc";

export const SubmitButton = (props: React.ComponentProps<typeof Button>) => {
  return <Button sx={{ borderRadius: 28 }} {...props} />;
};

export const TrashButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    sx={{ borderRadius: 28 }}
    color="primary"
    variant="contained"
    onClick={onClick}
  >
    <VscTrash />
  </Button>
);
