import React from "react";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import {
  GridContainer,
  GridRowOneItem,
} from "../../../../components/layouts/Grids";
import { Divider } from "@mui/material";

export const Methods = ({
  method,
  setMethod,
}: {
  method: string;
  setMethod: (a: string) => void;
}) => {
  return (
    <>
      <GridContainer
        child={
          <>
            <GridRowOneItem before={0.5} after={0.5} child={<Divider />} />
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <FormField
                  mult={true}
                  value={method}
                  setValue={(name) => {
                    setMethod(name);
                  }}
                />
              }
            />
          </>
        }
      />
    </>
  );
};
