import { Typography } from "@mui/material";
import { GridRowTwoItems } from "../../../../components/layouts/Grids";
import { AddDishVariables } from "../../../chefsbase/dishes/addDish/types/AddDish";
import { UpdateDishQuantityForRecipes } from "./DishCard/UpdateDishQuantityForRecipes";
import { RecipeForm } from "../../../chefsbase/recipes/addrecipes/types";
import { info } from "console";
import { useState } from "react";
import { UnitOptions } from "../../../../components/utils/UnitFunctions";
import { QuantityInput } from "../../../../globalTypes";
import { FormQuantity } from "../../../chefsbase/recipes/addrecipes/CreateYourRecipe";

export const RecipesAndIngredientsHeader = ({
  dishForm,
  setDishForm,
  options,
  fontWeight,
}: {
  fontWeight?: number;
  options: { name: string; id: string }[];
  dishForm: AddDishVariables;
  setDishForm: (dishForm: AddDishVariables) => void;
}) => {
  return (
    <GridRowTwoItems
      before={0.5}
      inbetween={0}
      firstlength={5.5}
      secondlength={6}
      secondchild={
        <UpdateDishQuantityForRecipes
          options={options}
          dishForm={dishForm}
          setDishForm={setDishForm}
        />
      }
      firstchild={
        <>
          <Typography
            fontWeight={fontWeight ? fontWeight : 400}
            variant="body1"
            color="text.secondary"
          >
            {`With the following ingredients,`}
          </Typography>
          <Typography
            fontWeight={fontWeight ? fontWeight : 400}
            variant="body1"
            color="text.secondary"
          >
            {`I can make this amount of ${dishForm.input.name}:`}
          </Typography>
        </>
      }
    />
  );
};

export const RecipesAndIngredientsHeaderForRecipe = ({
  form,
  setForm,
  options,
  fontWeight,
}: {
  fontWeight?: number;
  options: { name: string; id: string }[];
  form: RecipeForm;
  setForm: (dishForm: RecipeForm) => void;
}) => {
  const [amount, setamount] = useState(String(form.info.quantity.quantity));
  return (
    <GridRowTwoItems
      before={0.5}
      inbetween={0}
      firstlength={5.5}
      secondlength={6}
      secondchild={
        <FormQuantity
          amount={amount}
          setamount={setamount}
          label={"Quantity"}
          options={UnitOptions}
          value={form.info.quantity}
          setValue={(a: QuantityInput) => {
            setForm({
              ...form,
              info: {
                ...form.info,
                quantity: a,
              },
            });
          }}
        />
      }
      firstchild={
        <>
          <Typography
            fontWeight={fontWeight ? fontWeight : 400}
            variant="body1"
            color="text.secondary"
          >
            {`With the following ingredients,`}
          </Typography>
          <Typography
            fontWeight={fontWeight ? fontWeight : 400}
            variant="body1"
            color="text.secondary"
          >
            {`I can make this amount of ${form.info.name}:`}
          </Typography>
        </>
      }
    />
  );
};
