import { small } from "../../../../components/defaults/portions/PortionInput";
import {
  replaceSingleQuote,
  replaceCommasInPortion,
  replaceCommasInQuantity,
} from "../../../../components/utils/WriteText";
import { OptionInput } from "../../../../globalTypes";
import { AddDishVariables } from "../addDish/types/AddDish";
import { zerohealthlabelinfo } from "./EmptyVariables";

export const toInput = (
  numberofportions: number,
  form: AddDishVariables
): AddDishVariables => {
  const healthLabels = form.healthLabels ? form.healthLabels : zerohealthlabelinfo
  return {
    healthLabels: healthLabels,
    input: {
      name: replaceSingleQuote(form.input.name),
      type: replaceSingleQuote(form.input.type),
      method: replaceSingleQuote(form.input.method),
      urls: form.input.urls,
    },
    portions: form.portions
      .filter((p, index) => index < numberofportions)
      .map((p) => replaceCommasInPortion(p)),
    options: form.options
      ? form.options.map((o) => ({
          name: replaceSingleQuote(o.name),
          option: o.option,
        }))
      : [{ name: "Vegan", option: OptionInput.NO }],
    hours: {
      amount: form.hours ? form.hours.amount : 0.0,
      quantity: replaceCommasInQuantity(
        form.hours ? form.hours.quantity : small.quantity
      ),
    },
    recipes: {
      dishPortion: replaceCommasInPortion(form.recipes.dishPortion),
      ingredients: form.recipes
        ? form.recipes.ingredients.filter((i) => i.id !== "" && i.id)
        : [],
    },
    products: {
      dishPortion: replaceCommasInPortion(form.products.dishPortion),
      ingredients: form.products
        ? form.products.ingredients.filter((i) => i.id !== "" && i.id)
        : [],
    },
  };
};
