import {
  Divider,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { SubmitButton } from "../../../../components/Buttons";
import Axios from "axios";
import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../components/forms/singleForms/FormField";
import {
  GridRowOneItem,
  GridRowThreeItems,
} from "../../../../components/layouts/Grids";
import { ProcessingDialog } from "../../../../components/Loading/Processing";
import { H5, H6 } from "../../../../components/TextTypes";
import {
  toMaterial,
  UnitOptions,
} from "../../../../components/utils/UnitFunctions";
import { Material, QuantityInput } from "../../../../globalTypes";
import { zeronutrition } from "../../dishes/components/EmptyVariables";
import { FormQuantity } from "../../recipes/addrecipes/CreateYourRecipe";
import { useAddProductMutation } from "./api";
import { AddProductVariables } from "./types/AddProduct";
import { small } from "../../../../components/defaults/portions/PortionInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddPage } from "../../../../PageFrames";

export type ProductForm = {
  input: AddProductVariables;
  id: string;
};

export const emptyProductForm: ProductForm = {
  id: "24c822fb-f1bc-38bf-95a1-43062f5f639d",
  input: {
    input: {
      name: "New Product",
      category: "Category",
      material: Material.SOLID,
      brand: "Brand",
      quantityPerUnit: small.quantity,
      quantityTeaSpoon: small.quantity,
      quantityEatSpoon: small.quantity,
      price: {
        amount: 1.0,
        quantity: small.quantity,
      },
      nutrition: {
        nutrition: zeronutrition,
        quantity: small.quantity,
      },
    },
  },
};

const appKey = "f5a92edd5859ca4e82a2004e59b2a227";
const appId = "ef2483f5";
const recipeid = "1af31c15";
const recipekey = "a502a6a0c41e47ac17e68f7c4b83d7e4";
type Rec = {
  recipe: { calories: number };
};
type T = {
  food: { label: string };
};
export const FatSecret = () => {
  const [query, setQuery] = useState("b");
  const [recipes, setRecipes] = useState<T[]>([]);
  const [alert, setAlert] = useState("");
  const url2 = `https://api.edamam.com/api/food-database/v2/parser?app_id=${appId}&app_key=${appKey}`;
  const url = `https://api.edamam.com/search?q=chicken&$app_id=${recipeid}&app_key=${recipekey}`;

  const getData = async () => {
    if (query !== "") {
      const result = await Axios.get(url2);
      result &&
        result.data &&
        result.data.hints &&
        setRecipes(result.data.hints);
      setQuery("");
      setAlert("");
    } else {
      setAlert("Please fill the form");
    }
  };

  return (
    <div className="App">
      <h1>Food Searching App</h1>
      <SubmitButton variant="outlined" onClick={getData}>
        Click
      </SubmitButton>

      {/* <div className="recipes">{recipes !== [] && recipes}</div> */}
    </div>
  );
};

export const AddProduct = withAuthenticationRequired(() => {
  const [form, setForm] = useState(emptyProductForm);

  const { addProduct, loading } = useAddProductMutation({
    onCompleted: () => {},
  });

  if (loading)
    return <ProcessingDialog loading={loading} title="Adding your Product" />;

  return (
    <>
      <AddPage
        childComp={
          <>
            <ProductFormContent
              form={form.input}
              setForm={(a: AddProductVariables) =>
                setForm({
                  id: form.id,
                  input: a,
                })
              }
              mutate={(variables?: any) => addProduct(variables)}
            />
          </>
        }
      />
    </>
  );
});

export const ProductFormContent = ({
  form,
  mutate,
  setForm,
}: {
  form: AddProductVariables;
  setForm: (a: AddProductVariables) => void;
  mutate: (options?: any) => void;
}) => {
  const [amount, setamount] = useState(
    String(form.input.quantityPerUnit.quantity)
  );
  return (
    <Box sx={{ mt: 4 }}>
      <GridRowOneItem
        before={4}
        after={4}
        child={
          <SubmitButton
            sx={{ mt: 2, borderRadius: 28 }}
            fullWidth
            variant="contained"
            onClick={() =>
              mutate({
                variables: form,
              })
            }
          >
            Submit
          </SubmitButton>
        }
      />
      <Box sx={{ mt: 4 }}>
        <GridRowOneItem
          before={1}
          after={1}
          child={
            <>
              <GridRowOneItem
                before={0}
                after={0}
                child={<H5 title="Create Your Product" />}
              />
              <GridRowOneItem before={0} after={0} child={<Divider />} />

              <GridRowThreeItems
                before={0}
                inbetweenfirsttwo={0.5}
                inbetweensecondtwo={0.5}
                firstlength={3}
                secondlength={3.5}
                thirdlength={2.5}
                firstchild={
                  <FormField
                    label="Name"
                    value={form.input.name}
                    setValue={(name) => {
                      setForm({
                        input: {
                          name: name,
                          category: form.input.category,
                          material: form.input.material,
                          brand: form.input.brand,
                          quantityPerUnit: form.input.quantityPerUnit,
                          quantityTeaSpoon: form.input.quantityTeaSpoon,
                          quantityEatSpoon: form.input.quantityEatSpoon,
                          price: form.input.price,
                          nutrition: form.input.nutrition,
                        },
                      });
                    }}
                  />
                }
                secondchild={
                  <FormField
                    label="Brand"
                    value={form.input.brand}
                    setValue={(name) => {
                      setForm({
                        input: {
                          name: name,
                          category: form.input.category,
                          material: form.input.material,
                          brand: name,
                          quantityPerUnit: form.input.quantityPerUnit,
                          quantityTeaSpoon: form.input.quantityTeaSpoon,
                          quantityEatSpoon: form.input.quantityEatSpoon,
                          price: form.input.price,
                          nutrition: form.input.nutrition,
                        },
                      });
                    }}
                  />
                }
                thirdchild={
                  <FormField
                    label="Category"
                    value={form.input.category}
                    setValue={(cat) => {
                      setForm({
                        input: {
                          name: form.input.name,
                          category: cat,
                          material: form.input.material,
                          brand: form.input.brand,
                          quantityPerUnit: form.input.quantityPerUnit,
                          quantityTeaSpoon: form.input.quantityTeaSpoon,
                          quantityEatSpoon: form.input.quantityEatSpoon,
                          price: form.input.price,
                          nutrition: form.input.nutrition,
                        },
                      });
                    }}
                  />
                }
              />

              <GridRowThreeItems
                before={0}
                inbetweenfirsttwo={0.5}
                inbetweensecondtwo={0.5}
                firstlength={3}
                secondlength={0.5}
                thirdlength={6}
                secondchild={
                  <Grid item style={{ marginTop: 15 }}>
                    Per
                  </Grid>
                }
                firstchild={
                  <FormFieldInputAdornmentNumber
                    label="Price"
                    input="€"
                    value={String(form.input.price.amount)}
                    setValue={(a) => {
                      setForm({
                        input: {
                          name: form.input.name,
                          category: form.input.category,
                          material: form.input.material,
                          brand: form.input.brand,
                          quantityPerUnit: form.input.quantityPerUnit,
                          quantityTeaSpoon: form.input.quantityTeaSpoon,
                          quantityEatSpoon: form.input.quantityEatSpoon,
                          price: {
                            amount: Number(a),
                            quantity: form.input.price.quantity,
                          },
                          nutrition: form.input.nutrition,
                        },
                      });
                    }}
                  />
                }
                thirdchild={
                  <FormQuantity
                    amount={amount}
                    setamount={setamount}
                    label="Per"
                    options={UnitOptions}
                    value={form.input.quantityPerUnit}
                    setValue={(a: QuantityInput) => {
                      const mat = toMaterial(a.unit);
                      setForm({
                        input: {
                          name: form.input.name,
                          category: form.input.category,
                          material: mat,
                          brand: form.input.brand,
                          quantityPerUnit: a,
                          quantityTeaSpoon: a,
                          quantityEatSpoon: a,
                          price: form.input.price,
                          nutrition: form.input.nutrition,
                        },
                      });
                    }}
                  />
                }
              />
              <Box sx={{ mt: 10 }}>
                <GridRowOneItem
                  before={0}
                  after={0}
                  child={<H6 title="Nutrition" />}
                />
                <GridRowOneItem
                  before={0}
                  after={0}
                  child={
                    <>
                      <FillInNutrition
                        form={form}
                        setForm={(a) => setForm(a)}
                      />
                    </>
                  }
                />
              </Box>
            </>
          }
        />
      </Box>
    </Box>
  );
};

export const FillInNutrition = ({
  form,
  setForm,
}: {
  form: AddProductVariables;
  setForm: (a: AddProductVariables) => void;
}) => {
  const [amount, setamount] = useState(
    String(form.input.quantityPerUnit.quantity)
  );

  return (
    <Table component={Paper}>
      <TableHead>
        <TableCell colSpan={2}>Nutritional Value Per:</TableCell>
        <TableCell colSpan={5}>
          <FormQuantity
            amount={amount}
            setamount={setamount}
            options={UnitOptions}
            value={form.input.quantityPerUnit}
            setValue={(a: QuantityInput) => {
              const mat = toMaterial(a.unit);
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: mat,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    quantity: a,
                    nutrition: form.input.nutrition.nutrition,
                  },
                },
              });
            }}
          />
        </TableCell>
      </TableHead>
      <TableRow>
        <TableCell>
          <FormField
            num={true}
            label="Kcal"
            value={String(form.input.nutrition.nutrition.kcal)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: Number(name),
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Proteins"
            value={String(form.input.nutrition.nutrition.prottotal)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: Number(name),
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Carbs"
            value={String(form.input.nutrition.nutrition.carbscarbs)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: Number(name),
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Sugar"
            value={String(form.input.nutrition.nutrition.carbssugar)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: Number(name),
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Total Fat"
            value={String(form.input.nutrition.nutrition.fatstotal)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: Number(name),
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Satured Fat"
            value={String(form.input.nutrition.nutrition.saturedfat)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.nutrition.nutrition.natrium,
                      saturedfat: Number(name),
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
        <TableCell>
          <FormField
            num={true}
            label="Salt"
            value={String(form.input.nutrition.nutrition.natrium)}
            setValue={(name) => {
              setForm({
                input: {
                  name: form.input.name,
                  category: form.input.category,
                  material: form.input.material,
                  brand: form.input.brand,
                  quantityPerUnit: form.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.quantityEatSpoon,
                  price: form.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.nutrition.nutrition.kcal,
                      carbscarbs: form.input.nutrition.nutrition.carbscarbs,
                      carbssugar: form.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.nutrition.nutrition.prottotal,
                      starch: form.input.nutrition.nutrition.starch,
                      fibres: form.input.nutrition.nutrition.fibres,
                      cholesterol: form.input.nutrition.nutrition.cholesterol,
                      natrium: Number(name),
                      saturedfat: form.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.nutrition.quantity,
                  },
                },
              });
            }}
          />
        </TableCell>
      </TableRow>
    </Table>
  );
};
