import {
  Autocomplete,
  Box,
  TableCell,
  TextField,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { getData } from "../../../../components/edamam/GetData";
import {
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { UnitOptions } from "../../../../components/utils/UnitFunctions";
import { MeasureInput, QuantityInput } from "../../../../globalTypes";
import { FormQuantity } from "../../../chefsbase/recipes/addrecipes/CreateYourRecipe";
import { QuantityToProduct } from "../types/QuantityToProduct";
import {
  existinglabels,
  formMeasureInputOptions,
  measureInputOptions,
  optionsFromMeasureInfo,
  small,
} from "../../../../components/defaults/portions/PortionInput";
import { FormFieldInputAdornmentNumber } from "../../../../components/forms/singleForms/FormField";
import { VscCheck, VscEdit, VscTrash } from "react-icons/vsc";
import { Box2mt } from "../../../../components/Boxes";
import {
  emptyhealthlabelinfo,
  zeroNutritionInput,
} from "../../../chefsbase/dishes/components/EmptyVariables";
import {
  EdamamToNutritionInput,
  measureToMeasureInput,
} from "./DishCard/Mappers";
import { MeasurementInfo, QuantityToRecipe } from "../../types/types";
import { recipeHealthLabels } from "./DishCard/RecipeAndIngredients";
import { NametoId } from "../../../../components/types/types";
import { SubmitButton } from "../../../../components/Buttons";

export const OneIngredient = ({
  product,
  setproduct,
}: {
  product: QuantityToProduct;
  setproduct: (a: QuantityToProduct) => void;
}) => {
  const [productname, setproductname] = useState(product.product.food.label);
  const [filteredproducts, setproducts] = useState<QuantityToProduct[]>([]);
  const [amount, setamount] = useState(String(product.quantity.quantity));

  return (
    <>
      <GridRowTwoItems
        before={0}
        inbetween={0.5}
        firstlength={6}
        secondlength={5.5}
        firstchild={
          <Box sx={{ mt: 1 }}>
            <Autocomplete
              defaultValue={productname}
              id={productname}
              value={productname}
              disablePortal
              sx={{ height: 1, width: 200 }}
              size="small"
              options={
                filteredproducts.length > 0
                  ? filteredproducts.map(
                      (pr) =>
                        pr &&
                        pr.product &&
                        pr.product.food &&
                        pr.product.food.label
                    )
                  : ["Please type.."]
              }
              onChange={(event: any, newValue: string | null) => {
                if (
                  newValue !== null &&
                  filteredproducts.find(
                    (i) =>
                      i &&
                      i.product &&
                      i.product.food &&
                      i.product.food.label === newValue
                  )
                ) {
                  setproductname(newValue);
                  getData(
                    newValue,
                    true,
                    (a) => {},
                    (b) => setproducts(b),
                    product
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  defaultValue={productname}
                  id={productname}
                  value={productname}
                  name={productname}
                  onChange={(a) =>
                    a.target.value &&
                    getData(
                      a.target.value,
                      true,
                      (a) => setproduct(a),
                      (b) => setproducts(b),
                      product
                    )
                  }
                />
              )}
            />
          </Box>
        }
        secondchild={
          <FormQuantity
            amount={amount}
            setamount={setamount}
            options={UnitOptions}
            value={product.quantity}
            setValue={(value: QuantityInput) => {
              setproduct({
                product: product.product,
                quantity: value,
              });
            }}
          />
        }
      />
    </>
  );
};

export const emptyQuantityToRecipe: QuantityToRecipe = {
  quantity: small.quantity,
  recipe: {
    healthLabelInfo: emptyhealthlabelinfo,
    id: "",
    name: "",
    nutrition: { quantity: small.quantity, nutrition: zeroNutritionInput },
  },
};
export const OneRecipe = ({
  recipe,
  recipes,
  setrecipe,
  deleteItem,
  price,
  setprice,
}: {
  setprice?: (a: number) => void;
  price?: number;
  recipes: QuantityToRecipe[];
  deleteItem: () => void;
  recipe: QuantityToRecipe;
  setrecipe: (a: QuantityToRecipe) => void;
}) => {
  const [amount, setamount] = useState(String(recipe.quantity.quantity));

  return (
    <GridRowThreeItems
      before={0}
      inbetweenfirsttwo={0.5}
      inbetweensecondtwo={0}
      firstlength={4.5}
      secondlength={6.5}
      thirdlength={0.5}
      thirdchild={
        <Box2mt
          mt={2}
          childComp={
            <VscTrash style={{ cursor: "pointer" }} onClick={deleteItem} />
          }
        />
      }
      secondchild={
        <GridRowTwoItems
          before={0}
          inbetween={0}
          firstlength={11.5}
          secondlength={0.5}
          firstchild={
            <FormQuantity
              amount={amount}
              setamount={setamount}
              options={measureInputOptions(recipe.quantity.unit.label).map(
                (i) => ({ name: i.label, id: i.uri })
              )}
              value={recipe.quantity}
              setValue={(value: QuantityInput) => {
                setrecipe({
                  quantity: value,
                  recipe: recipe.recipe,
                });
              }}
            />
          }
          secondchild={
            <>
              {price !== undefined && setprice !== undefined && (
                <FormFieldInputAdornmentNumber
                  w="vh"
                  value={String(price)}
                  setValue={(a) => setprice(Number(a))}
                  input="€"
                />
              )}
            </>
          }
        />
      }
      firstchild={
        <Box2mt
          mt={1}
          childComp={
            <Autocomplete
              sx={{ height: 1 }}
              size={"small"}
              options={recipes.map((r) => r && r.recipe.name)}
              // placeholder={recipe.recipe.name}
              value={recipe.recipe.name}
              onChange={(event: any, value: string | null) => {
                const found =
                  value !== null &&
                  recipes.find((r) => r.recipe.name === value);
                found && setamount(String(found.quantity.quantity));
                value !== null &&
                  setrecipe({
                    quantity: found ? found!.quantity : recipe.quantity,
                    recipe: {
                      healthLabelInfo: found
                        ? found!.recipe.healthLabelInfo
                        : emptyhealthlabelinfo,
                      nutrition: found
                        ? found!.recipe.nutrition
                        : recipe.recipe.nutrition,
                      name: value,
                      id: found ? found.recipe.id : "",
                    },
                  });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: 0.4 }}
                  defaultValue={recipe.recipe}
                  id={recipe.recipe.name}
                  value={recipe.recipe.name}
                  placeholder={recipe.recipe.name}
                  name={recipe.recipe.name}
                  onChange={(a) => {
                    const found =
                      a.target.value &&
                      recipes.find(
                        (r) => r && r.recipe.name === a.target.value
                      );
                    found && setamount(String(found.quantity.quantity));
                    a.target.value &&
                      setrecipe({
                        quantity: found ? found!.quantity : recipe.quantity,
                        recipe: {
                          healthLabelInfo: found
                            ? found!.recipe.healthLabelInfo
                            : emptyhealthlabelinfo,
                          nutrition: found
                            ? found!.recipe.nutrition
                            : recipe.recipe.nutrition,
                          id: found ? found.recipe.id : "",
                          name: a.target.value,
                        },
                      });
                  }}
                />
              )}
            />
          }
        />
      }
    />
  );
};

export const OneRecipeTable = ({
  recipe,
  recipes,
  setrecipe,
  deleteItem,
}: {
  recipes: QuantityToRecipe[];
  deleteItem: () => void;
  recipe: QuantityToRecipe;
  setrecipe: (a: QuantityToRecipe) => void;
}) => {
  const [amount, setamount] = useState(String(recipe.quantity.quantity));

  return (
    <GridRowTwoItems
      before={0}
      firstchild={
        <Autocomplete
          sx={{ height: 1 }}
          size={"small"}
          options={recipes.map((r) => r && r.recipe.name)}
          // placeholder={recipe.recipe.name}
          value={recipe.recipe.name}
          onChange={(event: any, value: string | null) => {
            value !== null &&
              setrecipe({
                quantity: {
                  quantity: 0,
                  unit: recipes.find((r) => r.recipe.name === value)
                    ? recipes.find((r) => r.recipe.name === value)!.quantity
                        .unit
                    : recipe.quantity.unit,
                },
                recipe: {
                  healthLabelInfo: recipes.find((r) => r.recipe.name === value)
                    ? recipes.find((r) => r.recipe.name === value)!.recipe
                        .healthLabelInfo
                    : recipe.recipe.healthLabelInfo,
                  nutrition: recipes.find((r) => r.recipe.name === value)
                    ? recipes.find((r) => r.recipe.name === value)!.recipe
                        .nutrition
                    : recipe.recipe.nutrition,
                  name: value,
                  id: recipes.filter((r) => r.recipe.name === value)[0].recipe
                    .id,
                },
              });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ height: 0.4 }}
              defaultValue={recipe.recipe}
              id={recipe.recipe.name}
              value={recipe.recipe.name}
              placeholder={recipe.recipe.name}
              name={recipe.recipe.name}
              onChange={(a) =>
                a.target.value &&
                setrecipe({
                  quantity: {
                    quantity: 0,
                    unit: recipe.quantity.unit,
                  },
                  recipe: {
                    healthLabelInfo: recipe.recipe.healthLabelInfo,
                    nutrition: recipe.recipe.nutrition,
                    id:
                      recipes.filter(
                        (r) => r && r.recipe.name === a.target.value
                      ).length > 0
                        ? recipes.filter(
                            (r) => r && r.recipe.name === a.target.value
                          )[0].recipe.id
                        : "",
                    name: a.target.value,
                  },
                })
              }
            />
          )}
        />
      }
      secondlength={4}
      firstlength={8}
      inbetween={0}
      secondchild={
        <FormQuantity
          w={true}
          amount={amount}
          setamount={setamount}
          options={measureInputOptions(
            recipe.recipe.nutrition.quantity.unit.label
          ).map((i) => ({ name: i.label, id: i.uri }))}
          value={recipe.quantity}
          setValue={(value: QuantityInput) => {
            setrecipe({
              quantity: value,
              recipe: {
                ...recipe.recipe,
                nutrition: recipes.find((r) => r.recipe.id === recipe.recipe.id)
                  ? recipes.find((r) => r.recipe.id === recipe.recipe.id)!
                      .recipe.nutrition
                  : recipe.recipe.nutrition,
                healthLabelInfo: recipes.find(
                  (r) => r.recipe.id === recipe.recipe.id
                )
                  ? recipes.find((r) => r.recipe.id === recipe.recipe.id)!
                      .recipe.healthLabelInfo
                  : recipe.recipe.healthLabelInfo,
              },
            });
          }}
        />
      }
    />
  );
};

export function removeDuplicates(arr: string[]): string[] {
  const unique = new Map();

  return arr.filter((obj) => !unique.has(obj) && unique.set(obj, obj));
}

export const OneIngredientWide = ({
  product,
  setproduct,
  price,
  setprice,
  deleteItem,
}: {
  deleteItem: () => void;
  price?: number;
  setprice: (a: number) => void;
  product: QuantityToProduct;
  setproduct: (a: QuantityToProduct) => void;
}) => {
  const [productname, setproductname] = useState(product.product.food.label);
  const [filteredproducts, setproducts] = useState<QuantityToProduct[]>([]);
  const [measures, setmeasures] = useState<MeasureInput[]>(
    measureInputOptions(product.quantity.unit.label)
  );
  const [firstmeasurementInfo, setfirstmeasurementInfo] = useState(
    product.product.measurementInfo
  );

  const [firstNutrition, setFirstNutrition] = useState(
    EdamamToNutritionInput(product.product.food.nutrients)
  );
  const [amount, setamount] = useState(String(product.quantity.quantity));

  return (
    <>
      <GridRowThreeItems
        before={0}
        inbetweenfirsttwo={0.5}
        inbetweensecondtwo={0}
        firstlength={4.5}
        secondlength={6.5}
        thirdlength={0.5}
        thirdchild={
          <Box2mt
            mt={2}
            childComp={
              <VscTrash style={{ cursor: "pointer" }} onClick={deleteItem} />
            }
          />
        }
        firstchild={
          <Box sx={{ mt: 1 }}>
            <Autocomplete
              defaultValue={productname}
              id={productname}
              value={product.product.food.label}
              disablePortal
              size="small"
              options={
                filteredproducts.length > 0
                  ? removeDuplicates(
                      filteredproducts.map(
                        (pr) =>
                          pr &&
                          pr.product &&
                          pr.product.food &&
                          pr.product.food.label
                      )
                    )
                  : ["Please type.."]
              }
              onChange={(event: any, newValue: string | null) => {
                if (
                  newValue !== null &&
                  filteredproducts.find(
                    (i) =>
                      i &&
                      i.product &&
                      i.product.food &&
                      i.product.food.label === newValue
                  )
                ) {
                  setproductname(newValue);
                  {
                    const a = filteredproducts.find(
                      (i) =>
                        i &&
                        i.product &&
                        i.product.food &&
                        i.product.food.label === newValue
                    )!!;
                    const measures = a.product.measures;
                    setmeasures(measures.map((i) => measureToMeasureInput(i)));
                    const frac = 1;
                    setFirstNutrition(
                      EdamamToNutritionInput(a.product.food.nutrients)
                    );
                    const serving = measures.find((m) => m.label === "Serving");
                    const cup = measures.find((m) => m.label === "Cup");
                    const whole = measures.find((m) => m.label === "Whole");
                    const teaspoon = measures.find(
                      (m) => m.label === "Teaspoon"
                    );
                    const tablespoon = measures.find(
                      (m) => m.label === "Tablespoon"
                    );
                    const measurementInfo: MeasurementInfo = {
                      serving: serving?.weight,
                      cup: cup?.weight,
                      whole: whole?.weight,
                      teaspoon: teaspoon?.weight,
                      tablespoon: tablespoon?.weight,
                    };
                    setfirstmeasurementInfo(measurementInfo);

                    setproduct({
                      product: {
                        ...a.product,
                        measurementInfo: measurementInfo,
                        food: {
                          ...a.product.food,
                          nutrients: {
                            ENERC_KCAL:
                              a.product.food.nutrients.ENERC_KCAL * frac,
                            FAT: a.product.food.nutrients.FAT * frac,
                            CHOCDF: a.product.food.nutrients.CHOCDF * frac,
                            PROCNT: a.product.food.nutrients.PROCNT * frac,
                            FIBTG: a.product.food.nutrients.FIBTG * frac,
                            SUGAR: a.product.food.nutrients.SUGAR * frac,
                            FASAT: a.product.food.nutrients.FASAT * frac,
                            NA: a.product.food.nutrients.NA * frac,
                          },
                        },
                      },
                      quantity: {
                        ...a.quantity,
                        quantity:
                          a.quantity.quantity === 0
                            ? Number(amount)
                            : a.quantity.quantity,
                      },
                    });
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ height: 0.5 }}
                  defaultValue={productname}
                  id={productname}
                  value={productname}
                  name={productname}
                  onChange={(a) =>
                    a.target.value &&
                    getData(
                      a.target.value,
                      true,
                      (a) => {},
                      (b) => setproducts(b),
                      product
                    )
                  }
                />
              )}
            />
          </Box>
        }
        secondchild={
          <GridRowTwoItems
            before={0}
            inbetween={0}
            firstlength={11.5}
            secondlength={0.5}
            firstchild={
              <FormQuantity
                amount={amount}
                setamount={setamount}
                options={measures
                  .filter((m) =>
                    existinglabels
                      .map((i) => i && i.toLowerCase())
                      .includes(m && m.label && m.label.toLowerCase())
                  )
                  .map((m) => ({ id: m.uri, name: m.label }))}
                value={product.quantity}
                setValue={(value: QuantityInput) => {
                  const frac = 1;
                  setproduct({
                    product: {
                      ...product.product,
                      measurementInfo: firstmeasurementInfo,
                      food: {
                        ...product.product.food,
                        nutrients: {
                          ENERC_KCAL: firstNutrition.kcal * frac,
                          FAT: firstNutrition.fatstotal * frac,
                          CHOCDF: firstNutrition.carbscarbs * frac,
                          PROCNT: firstNutrition.prottotal * frac,
                          FIBTG: firstNutrition.fibres * frac,
                          SUGAR: firstNutrition.carbssugar * frac,
                          FASAT: firstNutrition.saturedfat * frac,
                          NA: firstNutrition.natrium * frac,
                        },
                      },
                    },
                    quantity: value,
                  });
                }}
              />
            }
            secondchild={
              <>
                {price !== undefined && (
                  <FormFieldInputAdornmentNumber
                    w="vh"
                    value={String(price)}
                    setValue={(a) => setprice(Number(a))}
                    input="€"
                  />
                )}
              </>
            }
          />
        }
      />
    </>
  );
};

export type NametoIdToQuantityInput = {
  quantity: QuantityInput;
  nameToId: NametoId;
};
export const OneNameToIdWide = ({
  product,
  setproduct,
  stopEdit,
}: {
  stopEdit?: () => void;
  product: NametoIdToQuantityInput;
  setproduct: (a: NametoIdToQuantityInput) => void;
}) => {
  const [productname, setproductname] = useState(product.nameToId.name);
  const [filteredproducts, setproducts] = useState<NametoIdToQuantityInput[]>(
    []
  );
  const [amount, setamount] = useState(String(product.quantity.quantity));

  return (
    <GridRowThreeItems
      key={product.nameToId.id}
      before={0.5}
      firstlength={4}
      secondlength={5}
      thirdlength={1}
      inbetweenfirsttwo={0.5}
      inbetweensecondtwo={0.5}
      firstchild={
        <Autocomplete
          defaultValue={productname}
          id={productname}
          value={product.nameToId.name}
          disablePortal
          size="small"
          options={
            filteredproducts.length > 0
              ? removeDuplicates(
                  filteredproducts.map((pr) => pr && pr.nameToId.name)
                )
              : ["Please type.."]
          }
          onChange={(event: any, newValue: string | null) => {
            if (
              newValue !== null &&
              filteredproducts.find((i) => i && i.nameToId.name === newValue)
            ) {
              setproductname(newValue);

              const a = filteredproducts.find(
                (i) => i && i.nameToId.name === newValue
              )!!;
              setproduct({
                ...product,
                nameToId: a.nameToId,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ height: 0.5 }}
              defaultValue={productname}
              id={productname}
              value={productname}
              name={productname}
              onChange={(a) =>
                a.target.value &&
                getData(
                  a.target.value,
                  true,
                  (a) => {},
                  (b) =>
                    setproducts(
                      b.map((qtop) => ({
                        quantity: qtop.quantity,
                        nameToId: {
                          name: qtop.product.food.label,
                          id: qtop.product.food.foodId,
                        },
                      }))
                    )
                )
              }
            />
          )}
        />
      }
      secondchild={
        <FormQuantity
          amount={amount}
          setamount={setamount}
          options={formMeasureInputOptions(product.quantity.unit.label)}
          value={product.quantity}
          setValue={(value: QuantityInput) => {
            setproduct({
              ...product,
              quantity: value,
            });
          }}
        />
      }
      thirdchild={
        stopEdit ? (
          <VscCheck onClick={stopEdit} style={{ cursor: "pointer" }} />
        ) : (
          <></>
        )
      }
    />
  );
};

export const OneIngredientWideTable = ({
  product,
  setproduct,
  price,
  setprice,
  deleteItem,
}: {
  deleteItem: () => void;
  price?: number;
  setprice: (a: number) => void;
  product: QuantityToProduct;
  setproduct: (a: QuantityToProduct) => void;
}) => {
  const [productname, setproductname] = useState(product.product.food.label);
  const [filteredproducts, setproducts] = useState<QuantityToProduct[]>([]);
  const [measures, setmeasures] = useState<MeasureInput[]>(
    measureInputOptions(product.quantity.unit.label)
  );
  const [firstmeasurementInfo, setfirstmeasurementInfo] = useState(
    product.product.measurementInfo
  );

  const [firstNutrition, setFirstNutrition] = useState(
    EdamamToNutritionInput(product.product.food.nutrients)
  );
  const [amount, setamount] = useState(String(product.quantity.quantity));

  return (
    <GridRowTwoItems
      before={0}
      firstchild={
        <Autocomplete
          defaultValue={productname}
          id={productname}
          value={product.product.food.label}
          disablePortal
          size="small"
          options={
            filteredproducts.length > 0
              ? removeDuplicates(
                  filteredproducts.map(
                    (pr) =>
                      pr &&
                      pr.product &&
                      pr.product.food &&
                      pr.product.food.label
                  )
                )
              : ["Please type.."]
          }
          onChange={(event: any, newValue: string | null) => {
            if (
              newValue !== null &&
              filteredproducts.find(
                (i) =>
                  i &&
                  i.product &&
                  i.product.food &&
                  i.product.food.label === newValue
              )
            ) {
              setproductname(newValue);
              {
                const a = filteredproducts.find(
                  (i) =>
                    i &&
                    i.product &&
                    i.product.food &&
                    i.product.food.label === newValue
                )!!;
                const measures = a.product.measures;
                setmeasures(measures.map((i) => measureToMeasureInput(i)));

                const frac = 1;
                setFirstNutrition(
                  EdamamToNutritionInput(a.product.food.nutrients)
                );
                const serving = measures.find((m) => m.label === "Serving");
                const cup = measures.find((m) => m.label === "Cup");
                const whole = measures.find((m) => m.label === "Whole");
                const teaspoon = measures.find((m) => m.label === "Teaspoon");
                const tablespoon = measures.find(
                  (m) => m.label === "Tablespoon"
                );
                const measurementInfo: MeasurementInfo = {
                  serving: serving?.weight,
                  cup: cup?.weight,
                  whole: whole?.weight,
                  teaspoon: teaspoon?.weight,
                  tablespoon: tablespoon?.weight,
                };

                setproduct({
                  product: {
                    ...a.product,
                    measurementInfo: measurementInfo,
                    food: {
                      ...a.product.food,
                      nutrients: {
                        ENERC_KCAL: a.product.food.nutrients.ENERC_KCAL * frac,
                        FAT: a.product.food.nutrients.FAT * frac,
                        CHOCDF: a.product.food.nutrients.CHOCDF * frac,
                        PROCNT: a.product.food.nutrients.PROCNT * frac,
                        FIBTG: a.product.food.nutrients.FIBTG * frac,
                        SUGAR: a.product.food.nutrients.SUGAR * frac,
                        FASAT: a.product.food.nutrients.FASAT * frac,
                        NA: a.product.food.nutrients.NA * frac,
                      },
                    },
                  },
                  quantity: a.quantity,
                });
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ height: 0.5 }}
              defaultValue={productname}
              id={productname}
              value={productname}
              name={productname}
              onChange={(a) =>
                a.target.value &&
                getData(
                  a.target.value,
                  true,
                  (a) => {},
                  (b) => setproducts(b),
                  product
                )
              }
            />
          )}
        />
      }
      secondlength={4}
      firstlength={8}
      inbetween={0}
      secondchild={
        <FormQuantity
          w={true}
          amount={amount}
          setamount={setamount}
          options={
            product.product.measurementInfo
              ? optionsFromMeasureInfo(product.product.measurementInfo)
              : measures
                  .filter((m) => {
                    return existinglabels
                      .map((i) => i && i.toLowerCase())
                      .includes(m && m.label && m.label.toLowerCase());
                  })
                  .map((m) => ({ id: m.uri, name: m.label }))
          }
          value={product.quantity}
          setValue={(value: QuantityInput) => {
            const frac = 1;
            setproduct({
              product: {
                ...product.product,
                measurementInfo: firstmeasurementInfo,
                food: {
                  ...product.product.food,
                  nutrients: {
                    ENERC_KCAL: firstNutrition.kcal * frac,
                    FAT: firstNutrition.fatstotal * frac,
                    CHOCDF: firstNutrition.carbscarbs * frac,
                    PROCNT: firstNutrition.prottotal * frac,
                    FIBTG: firstNutrition.fibres * frac,
                    SUGAR: firstNutrition.carbssugar * frac,
                    FASAT: firstNutrition.saturedfat * frac,
                    NA: firstNutrition.natrium * frac,
                  },
                },
              },
              quantity: value,
            });
          }}
        />
      }
    />
  );
};
