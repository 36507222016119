import { gql, useMutation } from "@apollo/client";
import { useSimpleQuery } from "../../utils/apollo";
import { TinderQuery } from "./types/TinderQuery";
import { RecipesForTinder } from "./types/RecipesForTinder";
import {
  setRecipeViewed,
  setRecipeViewedVariables,
} from "./types/setRecipeViewed";

export const TheTinderQuery = gql`
  query TinderQuery {
    dishForTinder {
      healthLabelInfo {
        vegan
        lupine
        meat
        nut
        peanut
        shellfish
        selery
        sesame
        soy
        fish
        crustacean
        sulphide
        mustard
        dairy
        gluten
        egg
        milk
        pork
        mollusk
      }
      id
      method
      name
      type
      img
      foods {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        food {
          name
          healthLabelInfo {
            vegan
            lupine
            meat
            nut
            peanut
            shellfish
            selery
            sesame
            soy
            fish
            crustacean
            sulphide
            mustard
            dairy
            gluten
            egg
            milk
            pork
            mollusk
          }
          price {
            supplier {
              name
              id
            }
            amountPerQuantity {
              amount
              quantity {
                quantity
                unit {
                  weight
                  label
                  uri
                }
              }
            }
          }
          measurementInfo {
            serving
            cup
            tablespoon
            whole
            teaspoon
          }
          id
          name
          img
          nutrition {
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
            nutrition {
              kcal
              carbscarbs
              carbssugar
              fatstotal
              prottotal
              starch
              fibres
              cholesterol
              natrium
              saturedfat
            }
          }
        }
      }
      recipes {
        healthLabelInfo {
          vegan
          lupine
          meat
          nut
          peanut
          shellfish
          selery
          sesame
          soy
          fish
          crustacean
          sulphide
          mustard
          dairy
          gluten
          egg
          milk
          pork
          mollusk
        }
        name
        id
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        nutrition {
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          nutrition {
            kcal
            saturedfat
            carbscarbs
            carbssugar
            fatstotal
            prottotal
            starch
            fibres
            cholesterol
            natrium
          }
        }
      }
    }
  }
`;

export const RecipesForTinderQuery = gql`
  query RecipesForTinder {
    recipesForTinder {
      healthLabelInfo {
        vegan
        lupine
        meat
        nut
        peanut
        shellfish
        selery
        sesame
        soy
        fish
        crustacean
        sulphide
        mustard
        dairy
        gluten
        egg
        milk
        pork
        mollusk
      }
      id
      method
      name
      type
      img
      nutrition {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        nutrition {
          kcal
          saturedfat
          carbscarbs
          carbssugar
          fatstotal
          prottotal
          starch
          fibres
          cholesterol
          natrium
        }
      }
      foods {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        food {
          name
          id
        }
      }
    }
  }
`;

export const useTinderRecipesQuery = ({
  onCompleted,
}: {
  onCompleted: (recipes: RecipesForTinder) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(
    RecipesForTinderQuery,
    {
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { loading, data, error, refetch };
};

export const useTinderQuery = ({
  onCompleted,
}: {
  onCompleted: (dishes: TinderQuery) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(TheTinderQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error, refetch };
};

export const setRecipeViewedMutation = gql`
  mutation setRecipeViewed($recipeid: String!, $liked: Boolean) {
    setRecipeViewed(recipeid: $recipeid, liked: $liked)
  }
`;

export const useRecipeViewedMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [setViewed, { loading, error }] = useMutation<
    setRecipeViewed,
    setRecipeViewedVariables
  >(setRecipeViewedMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    setViewed,
    loading,
    error,
  };
};
