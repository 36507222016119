import { gramMeasure } from "../../../../components/defaults/portions/PortionInput";
import { NutritionApi } from "../../../../components/edamam/types";
import { NutritionInput } from "../../../../globalTypes";
import { CurrentMenu_currentMenu_courses_composables_composableRounds_foods_nutrition } from "../../types/CurrentMenu";

export const mapNutritionApiToNutrition = (
  a: NutritionApi
): CurrentMenu_currentMenu_courses_composables_composableRounds_foods_nutrition => ({
  __typename: "QuantityToNutrition",
  quantity: {
    __typename: "Quantity",
    quantity: 100,
    unit: gramMeasure,
  },
  nutrition: {
    __typename: "Nutrition",
    kcal: a.ENERC_KCAL && a.ENERC_KCAL.quantity ? a.ENERC_KCAL.quantity : 0,
    carbscarbs: a.CHOCDF && a.CHOCDF.quantity ? a.CHOCDF.quantity : 0,
    carbssugar: a.SUGAR && a.SUGAR.quantity ? a.SUGAR.quantity : 0,
    fatstotal: a.FAT && a.FAT.quantity ? a.FAT.quantity : 0,
    prottotal: a.PROCNT && a.PROCNT.quantity ? a.PROCNT.quantity : 0,
    starch: 0,
    fibres: a.FIBTG && a.FIBTG.quantity ? a.FIBTG.quantity : 0,
    cholesterol: 0,
    natrium: a.NA && a.NA.quantity ? a.NA.quantity : 0,
    saturedfat: a.FASAT && a.FASAT.quantity ? a.FASAT.quantity : 0,
  },
});
export const mapNutritionToInput = (a: NutritionApi): NutritionInput => ({
  kcal: a.ENERC_KCAL && a.ENERC_KCAL.quantity ? a.ENERC_KCAL.quantity : 0,
  carbscarbs: a.CHOCDF && a.CHOCDF.quantity ? a.CHOCDF.quantity : 0,
  carbssugar: a.SUGAR && a.SUGAR.quantity ? a.SUGAR.quantity : 0,
  fatstotal: a.FAT && a.FAT.quantity ? a.FAT.quantity : 0,
  prottotal: a.PROCNT && a.PROCNT.quantity ? a.PROCNT.quantity : 0,
  starch: 0,
  fibres: a.FIBTG && a.FIBTG.quantity ? a.FIBTG.quantity : 0,
  cholesterol: 0,
  natrium: a.NA && a.NA.quantity ? a.NA.quantity : 0,
  saturedfat: a.FASAT && a.FASAT.quantity ? a.FASAT.quantity : 0,
});

export const totalNutrition = (
  nutrition: NutritionInput[]
): NutritionInput => ({
  kcal: sumItem(nutrition.map((n) => n.kcal)),
  carbscarbs: sumItem(nutrition.map((n) => n.carbscarbs)),
  carbssugar: sumItem(nutrition.map((n) => n.carbssugar)),
  fatstotal: sumItem(nutrition.map((n) => n.fatstotal)),
  prottotal: sumItem(nutrition.map((n) => n.prottotal)),
  starch: sumItem(nutrition.map((n) => n.starch)),
  fibres: sumItem(nutrition.map((n) => n.fibres)),
  cholesterol: sumItem(nutrition.map((n) => n.cholesterol)),
  natrium: sumItem(nutrition.map((n) => n.natrium)),
  saturedfat: sumItem(nutrition.map((n) => n.saturedfat)),
});

export const sumItem = (items: number[]): number =>
  items
    .map((item) => {
      return item;
    })
    .reduce((partialSum, a) => partialSum + a, 0);

export const scaleNutrition = (
  factor: number,
  nutrition: NutritionInput
): NutritionInput => ({
  kcal: nutrition.kcal * factor,
  carbscarbs: nutrition.carbscarbs * factor,
  carbssugar: nutrition.carbssugar * factor,
  fatstotal: nutrition.fatstotal * factor,
  prottotal: nutrition.prottotal * factor,
  starch: nutrition.starch * factor,
  fibres: nutrition.fibres * factor,
  cholesterol: nutrition.cholesterol * factor,
  natrium: nutrition.natrium * factor,
  saturedfat: nutrition.saturedfat * factor,
});
