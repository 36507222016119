import { createBrowserRouter } from "react-router-dom";
import { SignIn } from "./screens/sign_in";
import React from "react";
import { Menus } from "./screens/chefsbase/menus";
import { AddDish } from "./screens/chefsbase/dishes/addDish";
import { Dishes } from "./screens/chefsbase/dishes";
import { Recipes } from "./screens/chefsbase/recipes";
import { AddRecipe } from "./screens/chefsbase/recipes/addrecipes";
import { AddMenu } from "./screens/chefsbase/menus/addMenu";
import { AddProduct } from "./screens/chefsbase/products/addProduct";
import { Products } from "./screens/chefsbase/products";
import { ProductsApi } from "./screens/chefsbase/productsfromapi";
import {
  ClientPage,
  SwipingChefMarketingPage,
  SwipingChefSupportPage,
  SwipingChefTermsPage,
  TinderContainerPage,
} from "./screens/clientbase";
import { CreateClientPage } from "./screens/clientbase/createClientPage";
import { ComposablesPage } from "./screens/chefsbase/dishes/Composables";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Production } from "./screens/chefsbase/production";
import { FrontPage } from "./PageFrames";
import { ManageInventory } from "./screens/chefsbase/inventory";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";
import { HomeScreen } from "./screens/Home";
import {LoginScreen} from "./screens/login";

const ProtectedClientPage = withAuthenticationRequired(ClientPage);

export const routes = (value: number) => {
  return createBrowserRouter([
    {
      path: "/login",
      element: <LoginScreen/>
    },
    {
      path: "/",
      element: (
        <ThemeProvider theme={theme}>
          <HomeScreen />
        </ThemeProvider>
      ),
    },
    {
      path: "menus",
      element: <FrontPage childComp={<Menus />} />,
    },
    {
      path: "dishes",
      element: <FrontPage childComp={<Dishes />} />,
    },
    {
      path: "production",
      element: <FrontPage childComp={<Production />} />,
    },
    {
      path: "inventory",
      element: <FrontPage childComp={<ManageInventory />} />,
    },
    {
      path: "creatables",
      element: <FrontPage childComp={<ComposablesPage />} />,
    },
    {
      path: "recipes",
      element: <FrontPage childComp={<Recipes />} />,
    },
    {
      path: "products",
      element: <FrontPage childComp={<Products />} />,
    },
    {
      path: "signin",
      element: <FrontPage childComp={<SignIn />} />,
    },
    {
      path: "addmenu",
      element: <AddMenu />,
    },
    {
      path: "adddish",
      element: <AddDish />,
    },
    {
      path: "addrecipe",
      element: <AddRecipe />,
    },
    {
      path: "addproduct",
      element: <AddProduct />,
    },
    {
      path: "fatsecret",
      element: <FrontPage childComp={<ProductsApi />} />,
    },
    {
      path: "/cleanfoods",
      element: <ClientPage user={"e9f5791c-5ab7-30f1-bdc7-82548b86d0a2"} />,
    },
    {
      path: "/moccavenlo",
      element: <ClientPage user={"c04bbca9-d336-3c85-b8c8-a767b3a36b18"} />,
      // 1a910d85-93aa-3805-8223-bb2121685810"} />,
    },
    {
      path: "/soupbros",
      element: <ClientPage user={"24823359-ae00-3bc4-ba99-e28bf72dfb3d"} />,
    },
    {
      path: "/equismate",
      element: <ClientPage user={"c5681dfb-24c1-3594-bd90-443e4f2fabe6"} />,
    },
    {
      path: "/pokelabamsterdam",
      element: <ClientPage user={"10b07fc5-869e-3212-a2af-15412e81ceff"} />,
    },
    {
      path: "/burritos",
      element: <ClientPage user={"30560371-ad3f-3e74-a0b9-fe1158c80655"} />,
    },
    {
      path: "/createClientPage",
      element: <CreateClientPage />,
    },
    {
      path: "/grenzeloos",
      element: <ClientPage user={"42feb396-01c6-31c7-b0a9-7d1d9838bb6d"} />,
    },
    {
      path: "tinder",
      element: <TinderContainerPage />,
    },
    {
      path: "swipingchef/privacy",
      element: <SwipingChefTermsPage />,
    },
    {
      path: "swipingchef/marketing",
      element: <SwipingChefMarketingPage />,
    },
    {
      path: "swipingchef/support",
      element: <SwipingChefSupportPage />,
    },
    {
      path: "office",
      element: <ClientPage user={"c5681dfb-24c1-3594-bd90-443e4f2fabe6"} />,
    },
  ]);
};
