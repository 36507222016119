import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { VscAdd, VscRefresh, VscTrash } from "react-icons/vsc";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import { FormField } from "../../../components/forms/singleForms/FormField";
import {
  HealthLabelInfo,
  KitchenType,
  QuantityInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../globalTypes";
import {
  useLazyRecipesAndIngredientsForDishQuery,
  useSearchDishesQuery,
} from "./api";
import { Dish, Dish_dish } from "./types/Dish";
import { dishes, dishes_dishes } from "./types/dishes";
import { toDishForm } from "./utils/MapToInput";
import { DishDialog } from "./dishDialog";
import { DeleteDialog, DeletesDialog } from "../components/DeleteDialog";
import {
  gramMeasureInput,
  small,
} from "../../../components/defaults/portions/PortionInput";
import {
  measureToMeasureInput,
  quantityToInput,
} from "../../clientbase/createClientPage/forms/DishCard/Mappers";
import {
  resultToProductsInput,
  resultToRecipesInput,
} from "../../clientbase/components/mappers/DishResultToAddDishVariables";
import { AddDishVariables } from "./addDish/types/AddDish";
import { useUpdateDishMutation } from "./addDish/api";
import { UpdateDishVariables } from "./addDish/types/UpdateDish";
import { Loader } from "../../../components/Loading/Loader";
import { ProcessingDialog } from "../../../components/Loading/Processing";
import { standard } from "./components/StandardWeight";
import { AllergyForm } from "../../../components/edamam/GetNutrition";
import {
  allergiesToHealthLabelInfo,
  healthLabelInfoToFound,
} from "../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import { useNavigate } from "react-router-dom";
import { useAccountQuery } from "../../api";
import {
  aestheticBackground,
  buttonColor,
} from "../../../components/layouts/Colors";

export const Dishes = () => {
  const [name, setName] = useState("");
  const [type, settype] = useState("");
  const [firstdishes, setfirstdishes] = useState<dishes_dishes[]>([]);
  const [filtereddishes, setdishes] = useState<dishes_dishes[]>([]);

  const [selecteddishes, setselecteddishes] = useState<dishes_dishes[]>([]);

  const {
    data,
    loading: loadingDishes,
    refetch,
  } = useSearchDishesQuery({
    page: 0,
    onCompleted: (result: dishes) => {
      setfirstdishes(result.dishes);
      setdishes(result.dishes);
    },
  });

  const dishes: dishes_dishes[] = data ? data.dishes : [];

  const setNameFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filtereddishes.filter((i) =>
            i.name.toLowerCase().includes(a.toLowerCase())
          )
        : firstdishes;

    setdishes(n);
  };
  const setTypeFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filtereddishes.filter((i) =>
            i.type.toLowerCase().includes(a.toLowerCase())
          )
        : dishes;

    setdishes(n);
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [areYouSure, setAreYouSure] = useState(false);
  const navigate = useNavigate();
  const [noAccount, setNoAccount] = useState<boolean>(false);

  const { data: dataAccount } = useAccountQuery({
    onCompleted: (result) => {
      if (
        result.account !== null &&
        result.account.accesstoken == result.account.restaurantName
      )
        setNoAccount(true);
    },
  });
  return (
    <MainBox
      childComp={
        noAccount ? (
          <>
            Please send a request to <br />
            <a href="mailto::mychefsbase@gmail.com">mychefsbase@gmail.com</a>
            to request for the use of MyChefsbase. Or sign up for the waiting{" "}
            <br />
            list on <a href="www.mychefsbase.com">www.mychefsbase.com</a>
          </>
        ) : (
          <>
            <BoxTitle title="Dishes" />
            <div style={{ height: 300, width: "100%" }}>
              <Table>
                {!isMobile && (
                  <TopRow
                    name={name}
                    type={type}
                    setNameFilter={(a: string) => setNameFilter(a)}
                    setTypeFilter={(a: string) => setTypeFilter(a)}
                    setname={(a: string) => setName(a)}
                    settype={(a: string) => settype(a)}
                  />
                )}
                <TableRow>
                  <TableCell colSpan={5}>
                    {loadingDishes && <Loader loading={loadingDishes} />}
                  </TableCell>
                </TableRow>
                {!isMobile && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <SubmitButton
                        variant="contained"
                        onClick={() => navigate("/adddish", { replace: true })}
                      >
                        <VscAdd />
                        Dish
                      </SubmitButton>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <SubmitButton
                        variant="contained"
                        onClick={() => window.location.reload()}
                      >
                        <VscRefresh />
                        Refresh
                      </SubmitButton>
                    </TableCell>
                  </TableRow>
                )}
                {selecteddishes.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={2}>
                      <SubmitButton
                        variant="contained"
                        onClick={() => {
                          setAreYouSure(true);
                        }}
                      >
                        {`Delete ${selecteddishes.length} Dishes`}
                      </SubmitButton>
                    </TableCell>
                  </TableRow>
                )}
                {filtereddishes.length > 0 && (
                  <BodyRows
                    isMobile={isMobile}
                    refetch={refetch}
                    selecteddishes={selecteddishes}
                    setselecteddishes={(a: dishes_dishes[]) =>
                      setselecteddishes(a)
                    }
                    filtereddishes={filtereddishes}
                    setfiltereddish={(f: dishes_dishes[]) => setdishes(f)}
                  />
                )}
                <DeletesDialog
                  open={areYouSure}
                  onClose={() => setAreYouSure(false)}
                  ids={selecteddishes.map((d) => d.id)}
                  kitchenType={KitchenType.Dish}
                  onCompleted={() => {
                    setAreYouSure(false);
                    setselecteddishes([]);
                  }}
                />
              </Table>
            </div>
          </>
        )
      }
    />
  );
};

const TopRow = ({
  name,
  type,
  setname,
  settype,
  setNameFilter,
  setTypeFilter,
}: {
  name: string;
  type: string;
  setname: (a: string) => void;
  settype: (a: string) => void;
  setTypeFilter: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Search Dish:</TableCell>
          <TableCell>
            <FormField
              value={name}
              setValue={(a) => {
                setname(a);
                setNameFilter(a);
              }}
            />
          </TableCell>
          <TableCell>Search On Type:</TableCell>
          <TableCell>
            <FormField
              value={type}
              setValue={(a) => {
                settype(a);
                setTypeFilter(a);
              }}
            />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

const BodyRows = ({
  filtereddishes,
  setfiltereddish,
  selecteddishes,
  setselecteddishes,
  refetch,
  isMobile,
}: {
  isMobile: boolean;
  refetch: () => void;
  selecteddishes: dishes_dishes[];
  setselecteddishes: (a: dishes_dishes[]) => void;
  filtereddishes: dishes_dishes[];
  setfiltereddish: (a: dishes_dishes[]) => void;
}) => {
  return (
    <>
      {filtereddishes.map((d, index) => (
        <TableRow>
          {!isMobile && (
            <TableCell>
              <Checkbox
                checked={selecteddishes.includes(d)}
                onChange={(e) => {
                  const old = [...selecteddishes];
                  if (e.target.checked) {
                    old.push(d);
                  } else {
                    old.splice(old.indexOf(d), 1);
                  }
                  setselecteddishes(old);
                }}
              />
            </TableCell>
          )}
          <DishRow
            isMobile={isMobile}
            refetch2={refetch}
            key={d.id}
            remove={() => {
              const newSelected = [...filtereddishes];
              newSelected.splice(index, 1);
              setfiltereddish(newSelected);
            }}
            d={d}
          />
        </TableRow>
      ))}
    </>
  );
};

export type AmountPerQuantitySupplierInput = {
  supplier: {
    name: string;
    id: string;
  };
  amountPerQuantity: {
    amount: number;
    quantity: QuantityInput;
  };
};
export type AmountPerQuantityToProduct = {
  product: {
    name: string;
    id: string;
  };
  amountPerQuantity: {
    amount: number;
    quantity: QuantityInput;
  };
};

export type QuantityToNameIdAndNutritionInputWithPrice = {
  price: AmountPerQuantitySupplierInput;
  quantityToNameIdAndNutritionInput: QuantityToNameIdAndNutritionInput;
};

const fillHealthLabelFromProductsQuery = (
  dishForm: Dish_dish
): HealthLabelInfo => {
  const allergies = {
    food: dishForm.products.foods.map((i) => ({
      foodid: i.food.id,
      healthlabels: healthLabelInfoToFound(i.food.healthLabelInfo),
    })),
    recipe: dishForm.recipes.recipes.map((i) => ({
      id: i.id,
      healthLabelInfo: i.healthLabelInfo,
    })),
  };

  return allergiesToHealthLabelInfo(allergies);
};
const DishRow = ({
  d,
  remove,
  isMobile,
  refetch2,
}: {
  isMobile: boolean;
  refetch2: () => void;
  remove: () => void;
  d: dishes_dishes;
}) => {
  const [products, setproducts] = useState<QuantityToNameIdAndNutritionInput[]>(
    []
  );

  const [productToPrice, setProductToPrice] =
    useState<AmountPerQuantityToProduct[]>();
  const [recipes, setrecipes] = useState<QuantityToNameIdAndNutritionInput[]>(
    []
  );
  const [openDish, setOpenDish] = useState(false);
  const foundPortion =
    d.portions &&
    d.portions.length > 0 &&
    d.portions.find((p) => p.name === "Manual");

  const manualPortion = foundPortion
    ? {
        ...foundPortion,
        quantity: quantityToInput(foundPortion.quantity),
      }
    : undefined;
  const [form, setForm] = useState(
    toDishForm(d, manualPortion, recipes, products)
  );
  const [updated, setupdated] = useState(false);

  const { updateDish, loading } = useUpdateDishMutation({
    onCompleted: () => {
      setupdated(true);
    },
  });

  const unitForSearch =
    d.hoursWorked !== undefined
      ? measureToMeasureInput(d.hoursWorked.quantity.unit)
      : gramMeasureInput();
  const quantityForQuery =
    (d.portions.length > 0 &&
      d.portions
        .map((p) => p.quantity.unit.label.toLowerCase())
        .includes("serving")) ||
    d.portions
      .map((p) => p.quantity.unit.label.toLowerCase())
      .includes("teaspoon") ||
    d.portions
      .map((p) => p.quantity.unit.label.toLowerCase())
      .includes("tablespoon") ||
    d.portions.map((p) => p.quantity.unit.label.toLowerCase()).includes("whole")
      ? 1
      : 0.0;
  const {
    getRecipesAndIngredientsForDish,
    loading: loadingDishIngredients,
    refetch,
  } = useLazyRecipesAndIngredientsForDishQuery({
    id: d.id,
    quantity: { quantity: quantityForQuery, unit: unitForSearch },
    onCompleted: (result: Dish) => {
      setrecipes(resultToRecipesInput(result));
      setproducts(resultToProductsInput(result));

      const foundManual =
        result.dish.products.dishPortionOutput.quantity.quantity >=
        result.dish.recipes.dishPortion.quantity.quantity
          ? result.dish.products.dishPortionOutput
          : result.dish.recipes.dishPortion;

      setProductToPrice(
        result.dish.products.foods
          .filter((f) => f.food.price !== undefined && f.food.price !== null)
          .map((f) => ({
            product: {
              name: f.food.name,
              id: f.food.id,
            },
            amountPerQuantity: {
              amount: f.food.price!.amountPerQuantity.amount,
              quantity: quantityToInput(
                f.food.price!.amountPerQuantity.quantity
              ),
            },
          }))
      );
      setForm({
        ...form,
        input: {
          ...form.input,
          urls: result.dish.img,
        },
        //        healthLabels: fillHealthLabelFromProductsQuery(result.dish),
        recipes: {
          dishPortion: {
            name: foundManual.name,
            quantity: quantityToInput(foundManual.quantity),
          },
          ingredients: resultToRecipesInput(result),
        },
        products: {
          dishPortion: {
            name: foundManual.name,
            quantity: quantityToInput(foundManual.quantity),
          },
          ingredients: resultToProductsInput(result),
        },
      });
    },
  });
  if (loading)
    return ProcessingDialog({
      loading: loading,
      title: "Updating your Dish. This might take a while..",
    });
  return (
    <>
      <DishTopRow
        isMobile={isMobile}
        id={d.id}
        form={form}
        setopen={(a: boolean) => {
          getRecipesAndIngredientsForDish();
          setOpenDish(true);
        }}
        remove={() => remove()}
      />
      <DishDialog
        isMobile={isMobile}
        productToPrice={productToPrice}
        loading={loadingDishIngredients}
        openDish={openDish}
        setOpenDish={() => setOpenDish(false)}
        form={form}
        setForm={(a: UpdateDishVariables) => setForm(a)}
        mutate={(options?: any) => {
          updateDish(options);
        }}
      />
      <Dialog
        open={updated}
        onClose={() => setupdated(false)}
        sx={{ backgroundColor: aestheticBackground }}
      >
        <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
          <Typography
            sx={{ fontFamily: "FairField Display, serif" }}
          >{`${form.input.name} has been updated!`}</Typography>
        </DialogTitle>
        <DialogActions sx={{ backgroundColor: aestheticBackground }}>
          <SubmitButton
            sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
            variant="contained"
            onClick={() => {
              refetch2();
              refetch();
              setupdated(false);
              setOpenDish(false);
            }}
          >
            Great!
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DishTopRow = ({
  form,
  setopen,
  remove,
  id,
  isMobile,
}: {
  isMobile: boolean;
  id: string;
  remove: () => void;
  form: AddDishVariables;
  setopen: (a: boolean) => void;
}) => {
  const [openDelete, setopenDelete] = useState(false);

  if (isMobile)
    return (
      <>
        <TableCell colSpan={3}>
          <SubmitButton
            fullWidth
            variant="contained"
            onClick={() => {
              setopen(true);
            }}
          >
            {form.input.name}
          </SubmitButton>
        </TableCell>
      </>
    );
  return (
    <>
      <TableCell>
        <SubmitButton
          variant="contained"
          onClick={() => {
            setopen(true);
          }}
        >
          Open
        </SubmitButton>
      </TableCell>
      <TableCell>{form.input.name}</TableCell>
      <TableCell>{form.input.type}</TableCell>
      <TableCell>
        <SubmitButton variant="contained" onClick={() => setopenDelete(true)}>
          <VscTrash />
        </SubmitButton>
      </TableCell>
      <DeleteDialog
        open={openDelete}
        onClose={() => setopenDelete(false)}
        name={form.input.name}
        id={id}
        kitchenType={KitchenType.Dish}
        onCompleted={() => {
          remove();
        }}
      />
    </>
  );
};
