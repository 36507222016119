import { Divider } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import {
  AddPageBox,
  MethodPageBox,
  AddPageTableBox,
} from "../../../components/Boxes";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../components/layouts/Grids";
import { AddMenuVariables } from "../../chefsbase/menus/addMenu/types/AddMenu";
import { ProcessingDialog } from "../../../components/Loading/Processing";
import { useAddMenuhMutation } from "../../chefsbase/menus/addMenu/api";
import { useSearchDishesQuery } from "../../chefsbase/dishes/api";
import { dishes, dishes_dishes } from "../../chefsbase/dishes/types/dishes";
import { emptyMenuForm } from "../../../components/defaults/menu/Menu";
import { backgroundField } from "../../../components/layouts/Colors";
import { NameAndNumberOfCoursesInput } from "./forms/NameAndNumberOfCoursesInput";
import { FormHeader } from "./forms/DishCard/FormHeader";
import { CoursesForm } from "./forms/CoursesForm";
import { ComposablesForm } from "./forms/ComposablesForm";
import {
  ComboForTypes,
  CombosForTypes,
  CombosForm,
  emptyCombosForTypes,
} from "./forms/combos/CombosForm";
import { TabsForMenu, TabsForMenuForUpdate } from "../components/TabsForMenu";
import { mapToMenuInput } from "../components/mappers/MapToMenuInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddPage } from "../../../PageFrames";
import { quantityToInput } from "./forms/DishCard/Mappers";
import {
  ComboOfferInput,
  CourseInput,
  PortionSelectedInput,
  PortionToDishInput,
} from "../../../globalTypes";
import {
  zeroNutritionInput,
  zeronutrition,
} from "../../chefsbase/dishes/components/EmptyVariables";
import { small } from "../../../components/defaults/portions/PortionInput";
import {
  distinctItems,
  distinctnumbers,
} from "../components/courses/DisplayDishButton";
import { roundToZero } from "../../chefsbase/productsfromapi";
import { createRange } from "../../../components/utils/CreateRange";
import { useNavigate } from "react-router-dom";
import { DishToPortion } from "../types/types";

export const CreateClientPage = withAuthenticationRequired(() => {
  const navigate = useNavigate();
  const { addMenu, loading } = useAddMenuhMutation({
    onCompleted: () => {
      navigate("/menus", { replace: true });
    },
  });
  const [dishes, setdishes] = useState<dishes_dishes[]>([]);
  const { data } = useSearchDishesQuery({
    onCompleted: (result: dishes) => setdishes(result.dishes),
  });
  if (loading)
    return <ProcessingDialog loading={loading} title="Adding your menu" />;
  return (
    <>
      <AddPage
        childComp={
          <>
            <FormContent
              dishes={dishes}
              setdishes={setdishes}
              menuForm={emptyMenuForm}
              mutate={(form: AddMenuVariables) =>
                addMenu({ variables: mapToMenuInput(form) })
              }
            />
          </>
        }
      />
    </>
  );
});

function generateComboOffers(
  courses: CourseInput[],
  combosForType: CombosForTypes[]
): ComboOfferInput[] {
  const comboOffers: ComboOfferInput[] = [];

  function generateCombis(
    dishesList: PortionToDishInput[][]
  ): PortionToDishInput[][] {
    if (dishesList.length === 0) {
      return [[]];
    }

    const [firstDishes, ...restDishesList] = dishesList;
    const combinations: PortionToDishInput[][] = [];

    firstDishes.forEach((firstDish) => {
      const restCombinations = generateCombis(restDishesList);
      restCombinations.forEach((restCombo) => {
        combinations.push([firstDish, ...restCombo]);
      });
    });

    return combinations;
  }

  combosForType.forEach((comboForType) => {
    const comboOfferList: ComboOfferInput[] = comboForType.typeToPortions.map(
      (ttp) => {
        const dishes: PortionToDishInput[] = !courses.find(
          (c) => c.name === ttp.type
        )
          ? []
          : courses
              .find((c) => c.name === ttp.type)!
              .selectables.map((s) => ({
                round: ttp.round,
                dishid: s.dishid,
                name: s.dishname,
                portion: !s.quantitiesSelected.find(
                  (qs) => qs.portion.name === ttp.portion
                )
                  ? small
                  : s.quantitiesSelected.find(
                      (qs) => qs.portion.name === ttp.portion
                    )!.portion,
                nutrition: zeroNutritionInput,
              }));
        const price = comboForType.supplements.find((sup) =>
          dishes.map((d) => d.dishid).includes(sup.dish.id)
        )
          ? comboForType.supplements.find((sup) =>
              dishes.map((d) => d.dishid).includes(sup.dish.id)
            )!.supplement + comboForType.price
          : comboForType.price;
        return {
          dishes: dishes,
          price: price,
        };
      }
    );

    const rounds: number[] = distinctnumbers(
      comboOfferList.map((c) => c.dishes.map((d) => d.round)).flat()
    );

    const roundDishesMap: { [round: number]: PortionToDishInput[] } = {};

    comboOfferList.forEach((combo) => {
      combo.dishes.forEach((dish) => {
        if (!roundDishesMap[dish.round]) {
          roundDishesMap[dish.round] = [];
        }
        roundDishesMap[dish.round].push(dish);
      });
    });

    const roundDishesLists = rounds.map((round) => roundDishesMap[round] || []);

    const allRoundCombinations = generateCombis(roundDishesLists);

    allRoundCombinations.forEach((combinations) => {
      const supplementsInCombo = comboForType.supplements.filter((sup) =>
        combinations.some((dish) => dish.dishid === sup.dish.id)
      );

      const comboPrice =
        supplementsInCombo.reduce((total, sup) => total + sup.supplement, 0) +
        comboForType.price;

      comboOffers.push({
        dishes: combinations,
        price: comboPrice,
      });
    });
  });

  return comboOffers;
}

const updateAddMenuVariableFormForCombosPerType = (
  form: AddMenuVariables,
  combosForTypes: CombosForTypes[]
): AddMenuVariables => {
  const oldComboOfferInput: ComboOfferInput[] = form.combos.filter(
    (c) => c.price !== 0 && c.dishes.filter((d) => d.dishid === "").length === 0
  );
  const additionalComboOfferInput: ComboOfferInput[] = generateComboOffers(
    form.courses,
    combosForTypes
  );
  // Create a Set to store unique ComboOfferInput objects
  const uniqueCombosSet = new Set<ComboOfferInput>();

  // Add oldComboOfferInput and additionalComboOfferInput to the Set
  oldComboOfferInput.forEach((combo) => uniqueCombosSet.add(combo));
  additionalComboOfferInput.forEach((combo) => uniqueCombosSet.add(combo));

  // Convert the Set back to an array
  const uniqueCombosArray: ComboOfferInput[] = Array.from(uniqueCombosSet);

  return {
    ...form,
    combos: uniqueCombosArray,
  };
};

export const FormContent = ({
  mutate,
  menuForm,
  dishes,
  setdishes,
  loadingUpdateMenu,
}: {
  loadingUpdateMenu?: boolean;
  dishes: dishes_dishes[];
  setdishes: (a: dishes_dishes[]) => void;
  menuForm: AddMenuVariables;
  mutate: (form: AddMenuVariables) => void;
}) => {
  const [value, setValue] = useState(1);

  const [form, setForm] = useState<AddMenuVariables>(menuForm);

  const [numberofcourses, setnumberofcourses] = useState(
    menuForm.courses.length
  );
  const [combosForTypes, setCombosForTypes] = useState<CombosForTypes[]>([]);

  const {
    data,
    loading: loadingDishes,
    refetch,
  } = useSearchDishesQuery({
    onCompleted: (result: dishes) => {
      setdishes(result.dishes);
    },
  });

  if (loadingUpdateMenu && loadingUpdateMenu === true)
    return (
      <ProcessingDialog title="Updating Menu" loading={loadingUpdateMenu} />
    );
  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <>
            <Box
              sx={{
                marginTop: 5,
                border: 1,
                borderColor: "#FFF",
                borderRadius: "16px",
                backgroundColor: backgroundField,
                width: "100%",
                height: "100%",
              }}
            >
              <Stack spacing={2}>
                <FormHeader
                  loading={loadingUpdateMenu}
                  mutate={mutate}
                  form={updateAddMenuVariableFormForCombosPerType(
                    form,
                    combosForTypes
                  )}
                />
                <GridRowOneItem before={0} after={0} child={<Divider />} />
                <NameAndNumberOfCoursesInput
                  form={form}
                  numberofcourses={numberofcourses}
                  setnumberofcourses={setnumberofcourses}
                  setForm={setForm}
                />
                <TabsForMenuForUpdate value={value} setValue={setValue} />
                {value === 1 && (
                  <CoursesForm
                    refetch={refetch}
                    loadingDishes={loadingDishes}
                    dishes={dishes}
                    form={form}
                    setForm={(a) => setForm(a)}
                    numberofcourses={numberofcourses}
                  />
                )}
                {value === 2 && (
                  <CombosForm
                    form={form}
                    setForm={(a) => setForm(a)}
                    combosForTypes={combosForTypes}
                    setCombosForTypes={setCombosForTypes}
                  />
                )}
                {value === 3 && (
                  <ComposablesForm
                    dishes={dishes}
                    form={form}
                    setForm={(a) => setForm(a)}
                    numberofcourses={numberofcourses}
                  />
                )}
                <GridRowOneItem before={0} after={0} child={<Divider />} />
              </Stack>
            </Box>
          </>
        }
      />
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={6}
        secondlength={4.5}
        firstchild={
          <>
            <AddPageBox childComp={<></>} />
          </>
        }
        secondchild={<MethodPageBox childComp={<></>} />}
      />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <AddPageTableBox
            childComp={
              <GridRowOneItem
                before={0}
                after={0}
                child={<AddPageTableBox childComp={<></>} />}
              />
            }
          />
        }
      />
    </>
  );
};
