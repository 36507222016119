import { AddDishVariables } from "../../../screens/chefsbase/dishes/addDish/types/AddDish";
import { emptyQuantityToNameIdAndNutritionInput } from "../baseProd/IngredientToQuantityToSalePriceInput";
import { defaultOptions } from "../options/DefaultOptions";
import {
  small,
  medium,
  zeroPortion,
  large,
  gramMeasureInput,
} from "../portions/PortionInput";

export const emptyAddDishVariables: AddDishVariables = {
  input: {
    name: "",
    type: "",
    method:
      "* Start by describing the required ingredients\n* Then explain what to do with them!",
    urls: [""],
  },
  portions: [small, medium, large],
  options: defaultOptions,
  hours: {
    amount: 0,
    quantity: {
      quantity: 0,
      unit: gramMeasureInput(),
    },
  },
  recipes: {
    dishPortion: zeroPortion,
    ingredients: [emptyQuantityToNameIdAndNutritionInput],
  },
  products: {
    dishPortion: zeroPortion,
    ingredients: [emptyQuantityToNameIdAndNutritionInput],
  },
};
