import React, { useState } from "react";
import { recipes } from "../../recipesAndIngredients/types/recipes";
import { SubmitButton } from "../../../../components/Buttons";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { ProcessingDialog } from "../../../../components/Loading/Processing";
import {
  replaceCommasInQuantity,
  replaceSingleQuote,
} from "../../../../components/utils/WriteText";
import { Methods } from "../../dishes/addDish/Method";
import { zeroNutritionInput } from "../../dishes/components/EmptyVariables";
import { useAddRecipeMutation } from "./api";
import { CreateYourRecipe } from "./CreateYourRecipe";
import { RecipeForm, RecipeInfoForm } from "./types";
import { AddRecipeVariables } from "./types/AddRecipe";
import {
  OneIngredientWide,
  OneRecipe,
} from "../../../clientbase/createClientPage/forms/IngredientForProductRow";
import { QuantityToProduct } from "../../../clientbase/createClientPage/types/QuantityToProduct";
import {
  measureInputOptions,
  small,
} from "../../../../components/defaults/portions/PortionInput";
import { VscAdd } from "react-icons/vsc";
import { sampleMethod } from "../../../../components/defaults/Method";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRecipesQuery } from "../../recipesAndIngredients/api";
import { recipeResultToQuantityToRecipe } from "../../../clientbase/components/mappers/RecipeToQuantityToRecipes";
import {
  mapToRecipe,
  mapToRecipeInput,
  mapToProduct,
  mapToQuantityToNameIdAndNutritionInput,
} from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { ImageSection } from "../../../clientbase/createClientPage/forms/DishCard/ImageSection";
import { readFileAsDataURL } from "../../../clientbase/components/imgReader/ReadFileAsDataUrl";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AddPage } from "../../../../PageFrames";
import { QuantityToRecipe } from "../../../clientbase/types/types";
import {
  AllergyForm,
  IdToHealthLabel,
  emptyAllergyForm,
  getHealthLabels,
} from "../../../../components/edamam/GetNutrition";
import {
  foundToHealthLabelInfo,
  dishHealthLabels,
  allergiesToHealthLabelInfo,
  writeHealthlabelInfo,
  recipeHealthLabels,
  tohealthLabelInfo,
  healthLabelInfoToFound,
} from "../../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import { HealthLabelInfo } from "../../../../globalTypes";
import { createRange } from "../../../../components/utils/CreateRange";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import {
  RecipesAndIngredientsHeader,
  RecipesAndIngredientsHeaderForRecipe,
} from "../../../clientbase/createClientPage/forms/UpdateRecipesAndIngredientsHeader";
import {
  aestheticBackground,
  buttonColor,
} from "../../../../components/layouts/Colors";

const toInput = (form: RecipeForm): AddRecipeVariables => ({
  healthLabels: form.healthLabels,
  input: {
    img: form.info.img,
    name: replaceSingleQuote(form.info.name),
    type: replaceSingleQuote(form.info.type),
    method: replaceSingleQuote(form.method),
    quantity: replaceCommasInQuantity(form.info.quantity),
  },
  recipes: {
    dishPortion: {
      name: "Manual",
      quantity: replaceCommasInQuantity(form.info.quantity),
      price: 0,
    },
    ingredients: form.recipes,
  },
  products: {
    dishPortion: {
      name: "Manual",
      quantity: replaceCommasInQuantity(form.info.quantity),
      price: 0,
    },
    ingredients: form.products,
  },
});

const emptyForm: RecipeForm = {
  healthLabels: {
    vegan: false,
    lupine: false,
    meat: false,
    nut: false,
    peanut: false,
    shellfish: false,
    selery: false,
    sesame: false,
    soy: false,
    fish: false,
    crustacean: false,
    sulphide: false,
    mustard: false,
    dairy: false,
    gluten: false,
    egg: false,
    milk: false,
    pork: false,
    mollusk: false,
  },
  info: {
    img: [""],
    name: "",
    type: "",
    quantity: {
      quantity: 0,
      unit: small.quantity.unit,
    },
  },
  method: sampleMethod,
  recipes: [
    {
      quantity: small.quantity,
      name: "",
      id: "",
      nutrition: zeroNutritionInput,
    },
  ],
  products: [
    {
      quantity: small.quantity,
      name: "",
      id: "",
      nutrition: zeroNutritionInput,
    },
  ],
  nutrition: zeroNutritionInput,
};
export const AddRecipe = withAuthenticationRequired(() => {
  const [form, setForm] = useState(emptyForm);
  const [recipes, setRecipes] = useState<QuantityToRecipe[]>([]);

  const { data, loading: loadingRecipe } = useRecipesQuery({
    onCompleted: (result: recipes) => {
      setRecipes(recipeResultToQuantityToRecipe(result));
    },
  });
  const [updated, setupdated] = useState(false);

  const { addRecipe, loading, error } = useAddRecipeMutation({
    onCompleted: () => {
      setupdated(true);
    },
  });

  if (loading || !data || loadingRecipe)
    return <ProcessingDialog loading={loading} title="Loading items " />;

  return (
    <>
      <AddPage
        childComp={
          <>
            <RecipeFormContent
              form={form}
              recipes={recipes}
              setForm={(a: RecipeForm) => setForm(a)}
              mutate={(variables?: any) => addRecipe(variables)}
            />
            <Dialog open={updated} onClose={() => setupdated(false)}>
              <DialogTitle>{`${form.info.name} has been created!`}</DialogTitle>
              <DialogActions>
                <SubmitButton
                  variant="contained"
                  onClick={() => {
                    setForm(emptyForm);
                    setupdated(false);
                  }}
                >
                  Great!
                </SubmitButton>
              </DialogActions>
            </Dialog>
          </>
        }
      />
    </>
  );
});

export const RecipeFormContent = ({
  form,
  mutate,
  setForm,
  recipes,
}: {
  recipes: QuantityToRecipe[];
  form: RecipeForm;
  setForm: (a: RecipeForm) => void;
  mutate: (options?: any) => void;
}) => {
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [allergies, setallergies] = useState<AllergyForm>({
    food: form.products
      .filter((p) => p.id != "")
      .map((p) => ({
        foodid: p.id,
        healthlabels: p.healthlabelInfo
          ? healthLabelInfoToFound(p.healthlabelInfo)
          : [],
      })),
    recipe: form.recipes
      .filter((i) => i.id !== "")
      .map((i) => ({
        id: i.id,
        healthLabelInfo: i.healthlabelInfo,
      })),
  });
  const recipesAndIngredientsSet =
    form.info.quantity.quantity !== 0 &&
    (form.products.filter((p) => p.name.length > 0).length > 0 ||
      form.recipes.filter((p) => p.name.length > 0).length > 0);

  const nameAndTypeSet = form.info.type.length > 0 && form.info.name.length > 0;
  const methodSet = form.method !== emptyForm.method && form.method.length > 0;
  const imgSet = form.info.img === emptyForm.info.img;

  const disabledSubmit =
    !recipesAndIngredientsSet || !nameAndTypeSet || !methodSet;

  return (
    <Box sx={{ backgroundColor: aestheticBackground }}>
      <GridRowOneItem
        before={3}
        after={3}
        child={
          <Box sx={{ mt: 2 }}>
            <Button
              disabled={disabledSubmit}
              fullWidth
              variant={disabledSubmit ? "outlined" : "contained"}
              color="primary"
              style={{
                backgroundColor: disabledSubmit ? "lightgrey" : buttonColor,
                borderRadius: 28,
                color: "black",
              }}
              onClick={() => {
                mutate({
                  variables: toInput(form),
                });
              }}
            >
              Add Recipe
            </Button>
            <Typography variant="body2">
              All fields need to be filled in
              <Checkbox style={{ color: "grey" }} checked={!disabledSubmit} />
              and all buttons need to be green
            </Typography>
          </Box>
        }
      />
      <Box sx={{ mt: 5, minHeight: "70vh" }}>
        <GridRowTwoItems
          before={0}
          firstlength={2}
          inbetween={0}
          secondlength={10}
          firstchild={
            <>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: nameAndTypeSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                  borderRadius: 28,
                  color: "black",
                }}
                fullWidth
                onClick={() => setValue(0)}
              >
                Recipe
              </Button>
              <Button
                style={{
                  backgroundColor: recipesAndIngredientsSet
                    ? buttonColor
                    : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                  borderRadius: 28,
                  color: "black",
                }}
                fullWidth
                variant={value === 1 ? "contained" : "outlined"}
                onClick={() => setValue(1)}
              >
                Recipes & Ingredients
              </Button>
              <Button
                style={{
                  backgroundColor: methodSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                  borderRadius: 28,
                  color: "black",
                }}
                fullWidth
                variant={value === 2 ? "contained" : "outlined"}
                onClick={() => setValue(2)}
              >
                Method
              </Button>
              <Button
                style={{
                  backgroundColor: imgSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                  borderRadius: 28,
                  color: "black",
                }}
                fullWidth
                variant={value === 3 ? "contained" : "outlined"}
                onClick={() => setValue(3)}
              >
                Pictures
              </Button>
            </>
          }
          secondchild={
            <>
              {value == 0 && (
                <RecipeNameAndType
                  setAllergies={(a) => {}}
                  allergies={allergies}
                  recipes={[]}
                  form={form}
                  setForm={setForm}
                  set={nameAndTypeSet}
                />
              )}
              {value == 1 && (
                <RecipesandIngredientsSection
                  recipes={recipes}
                  form={form}
                  setForm={setForm}
                  setAllergies={setallergies}
                  allergies={allergies}
                  set={recipesAndIngredientsSet}
                />
              )}
              {value == 2 && (
                <Method
                  setAllergies={(a) => {}}
                  allergies={allergies}
                  recipes={[]}
                  form={form}
                  setForm={setForm}
                  set={methodSet}
                />
              )}

              {value == 3 && (
                <PicturesSection
                  setAllergies={(a) => {}}
                  allergies={allergies}
                  recipes={[]}
                  set={imgSet}
                  form={form}
                  setForm={setForm}
                />
              )}
            </>
          }
        />
      </Box>
    </Box>
  );
};

interface TabProps {
  form: RecipeForm;
  setForm: (f: RecipeForm) => void;
  recipes: QuantityToRecipe[];
  allergies: AllergyForm;
  setAllergies: (a: AllergyForm) => void;
  set: boolean;
}

export const RecipeNameAndType: React.FC<TabProps> = ({
  form,
  setForm,
  set,
}) => {
  return (
    <>
      <SectionTitle label="Name and Type" />
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={5}
        secondlength={5}
        firstchild={
          <FormField
            label="Name"
            value={form.info.name}
            setValue={(a: string) =>
              setForm({
                ...form,
                info: {
                  ...form.info,
                  name: a,
                },
              })
            }
          />
        }
        secondchild={
          <FormField
            label="Type"
            value={form.info.type}
            setValue={(a: string) =>
              setForm({
                ...form,
                info: {
                  ...form.info,
                  type: a,
                },
              })
            }
          />
        }
      />
      <CheckPart label={"Name and Type Set?"} set={set} />
    </>
  );
};

export interface CheckPartProps {
  label: string;
  set: boolean;
}

export const CheckPart: React.FC<CheckPartProps> = ({ label, set }) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0}
      child={
        <FormControlLabel
          control={<Checkbox checked={set} style={{ color: "grey" }} />}
          label={label}
        />
      }
    />
  );
};
export const SectionTitle = ({ label }: { label: string }) => {
  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <Typography fontWeight={700} variant="h6">
            {label}
          </Typography>
        }
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
    </>
  );
};
export const Method: React.FC<TabProps> = ({ form, setForm, set }) => {
  return (
    <>
      <SectionTitle label="Method" />
      <GridRowOneItem
        before={0.5}
        after={2}
        child={
          <FormField
            mult={true}
            value={form.method}
            setValue={(name) => {
              setForm({
                ...form,
                method: name,
              });
            }}
          />
        }
      />
      <CheckPart label={"Method Set?"} set={set} />
    </>
  );
};

export const RecipesandIngredientsSection: React.FC<TabProps> = ({
  recipes,
  form,
  setForm,
  set,
  allergies,
  setAllergies,
}) => {
  return (
    <>
      <RecipesandIngredientsForRecipe
        set={set}
        form={form}
        recipes={recipes}
        setForm={setForm}
        allergies={allergies}
        setAllergies={setAllergies}
      />
      <CheckPart label={"Recipes and Ingredients Set?"} set={set} />
    </>
  );
};

export const PicturesSection: React.FC<TabProps> = ({ form, setForm, set }) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        setForm({
          ...form,
          info: {
            ...form.info,
            img: urls,
          },
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };

  return (
    <>
      <SectionTitle label="Images:" />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <>
            <ImageSection
              handleChangeFile={(a) => handleChangeFile(a)}
              imageUrls={imageUrls}
              urls={returnStringsIfNotNullOrUndefined(form.info.img)}
              setNoPic={() =>
                setForm({ ...form, info: { ...form.info, img: [""] } })
              }
            />
          </>
        }
      />
      <CheckPart label={"Pictures Set?"} set={set} />
    </>
  );
};

export const RecipesandIngredientsForRecipe: React.FC<TabProps> = ({
  recipes,
  form,
  setForm,
  allergies,
  setAllergies,
}) => {
  return (
    <>
      <GridRowOneItem
        before={5}
        after={0}
        child={
          <Typography fontWeight={700} variant="h5">
            Allergies:
          </Typography>
        }
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={writeHealthlabelInfo(allergiesToHealthLabelInfo(allergies))}
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
      <Box sx={{ mt: 3, mb: 3 }}>
        <RecipesAndIngredientsHeaderForRecipe
          fontWeight={900}
          form={form}
          setForm={setForm}
          options={measureInputOptions().map((a) => ({
            name: a.label,
            id: a.uri,
          }))}
        />
      </Box>
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />

      <GridRowTwoItems
        before={0.5}
        inbetween={0}
        firstlength={5.5}
        secondlength={6}
        secondchild={
          <>
            <Typography fontWeight={700} variant="h6">
              Recipes:
            </Typography>
          </>
        }
        firstchild={
          <>
            <Typography fontWeight={700} variant="h6">
              Ingredients:
            </Typography>
          </>
        }
      />
      <GridRowTwoItems
        before={0.5}
        inbetween={0}
        firstlength={5.5}
        secondlength={5.5}
        secondchild={
          <>
            {form.recipes.map((rec, index1) => (
              <GridRowOneItem
                before={0}
                after={0}
                child={
                  <OneRecipe
                    key={index1}
                    recipes={recipes}
                    deleteItem={() => {
                      const recs = [...form.recipes];
                      recs.splice(index1, 1);
                      const newAllergies: AllergyForm = {
                        food: allergies.food,
                        recipe: recs.map((r) => ({
                          id: r.id,
                          healthLabelInfo: r.healthlabelInfo,
                        })),
                      };
                      setAllergies(newAllergies);
                      setForm({
                        ...form,
                        recipes: recs,
                        healthLabels: allergiesToHealthLabelInfo(newAllergies),
                      });
                    }}
                    recipe={mapToRecipe(rec)}
                    setrecipe={(a: QuantityToRecipe) => {
                      const recs = [...form.recipes];
                      recs[index1] = mapToRecipeInput(a);
                      const newAllergies: AllergyForm = {
                        food: allergies.food,
                        recipe: recs.map((r) => ({
                          id: r.id,
                          healthLabelInfo: r.healthlabelInfo,
                        })),
                      };
                      setAllergies(newAllergies);

                      setForm({
                        ...form,
                        recipes: recs,
                        healthLabels: allergiesToHealthLabelInfo(newAllergies),
                      });
                    }}
                  />
                }
              />
            ))}
            <GridRowOneItem
              before={0}
              after={0}
              child={
                <SubmitButton
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    const old = form.recipes;
                    old.push({
                      quantity: small.quantity,
                      name: "",
                      id: "",
                      nutrition: zeroNutritionInput,
                    });
                    setForm({
                      ...form,
                      recipes: old,
                    });
                  }}
                >
                  <VscAdd />
                </SubmitButton>
              }
            />
          </>
        }
        firstchild={
          <>
            {form.products.map((prod, index1) => (
              <GridRowOneItem
                before={0}
                after={0}
                child={
                  <OneIngredientWide
                    setprice={(a) => {}}
                    deleteItem={() => {
                      const old = form.products;
                      const newAllergies = {
                        food: allergies.food.filter(
                          (f) => f.foodid !== old[index1].id
                        ),
                        recipe: allergies.recipe,
                      };
                      setAllergies(newAllergies);
                      old.splice(index1, 1);

                      setForm({
                        ...form,
                        products: old,
                        healthLabels: allergiesToHealthLabelInfo(newAllergies),
                      });
                    }}
                    key={index1}
                    product={mapToProduct(prod)}
                    setproduct={(a: QuantityToProduct) => {
                      const old = form.products;
                      old[index1] = mapToQuantityToNameIdAndNutritionInput(a);
                      setAllergies({
                        food: [],
                        recipe: allergies.recipe,
                      });
                      // setForm({
                      //   ...form,
                      //   healthLabels: allergiesToHealthLabelInfo(allergies),
                      //   // healthLabels: allergiesToHealthLabelInfo(newAllergies),
                      //   products: old,
                      // });
                      //TODO:
                      old.length > 0 &&
                        getHealthLabels(
                          old.map((i) => i.id),
                          allergies.food.filter((a) =>
                            old.map((i) => i.id).includes(a.foodid)
                          ),
                          (a) => {
                            const old1 = [...old];
                            const updated = old1.map((i) => {
                              const found = a.find(
                                (item) => item.foodid === i.id
                              );
                              const toreturn =
                                found !== undefined
                                  ? {
                                      ...i,
                                      healthlabelInfo: foundToHealthLabelInfo(
                                        found!
                                      ),
                                    }
                                  : i;

                              return toreturn;
                            });
                            const newAllergies: AllergyForm = {
                              food: a,
                              recipe: allergies.recipe,
                            };
                            setAllergies(newAllergies);
                            setForm({
                              ...form,
                              healthLabels:
                                allergiesToHealthLabelInfo(newAllergies),
                              products: updated,
                            });
                          }
                        );
                    }}
                  />
                }
              />
            ))}
            <GridRowOneItem
              before={0.9}
              after={8}
              child={
                <SubmitButton
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    const old = form.products;
                    old.push({
                      quantity: small.quantity,
                      name: "",
                      id: "",
                      nutrition: zeroNutritionInput,
                    });
                    setForm({
                      ...form,
                      products: old,
                    });
                  }}
                >
                  <VscAdd />
                </SubmitButton>
              }
            />
          </>
        }
      />
    </>
  );
};
