import { Box, Typography, Stack, Grid, Divider } from "@mui/material";
import { CurrentMenu_currentMenu_courses } from "../../types/CurrentMenu";
import { DisplayComposableButton } from "./DisplayComposableButton";
import {
  aestheticBackground,
  buttonColor,
} from "../../../../components/layouts/Colors";
import { MicroMacroField } from "../MicroMacroField/types";

export const PerComposable = ({
  course,
  microMacroForm,
}: {
  microMacroForm: MicroMacroField;
  course: CurrentMenu_currentMenu_courses;
}) => {
  return (
    <>
      <Box
        sx={{
          // border: 0.1,
          width: "100%",
          borderRadius: 28,
          backgroundColor: aestheticBackground,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          sx={{ fontFamily: "FairField Display, serif" }}
        >
          {course.name}
        </Typography>
        <Divider />
      </Box>
      <Grid container spacing={1}>
        {course.composables
          .filter((i) => i.name !== "Create Your Own")
          .map((sel, i) => (
            <>
              <Grid item xs={0.5} sm={0.5} md={0.5}></Grid>
              <Grid item xs={5.5} sm={5.5} md={5.5}>
                <DisplayComposableButton
                  key={i}
                  microMacroForm={microMacroForm}
                  dish={sel}
                />
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
};
