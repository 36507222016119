import { IngredientToQuantityToSalePriceInput } from "../../globalTypes";
import {
  Composables_composables_composableRounds_foods,
  Composables_composables_composableRounds_recipes,
} from "../../screens/chefsbase/menus/types/Composables";
import { ingredients_ingredients } from "../../screens/chefsbase/recipesAndIngredients/types/ingredients";
import { toNutritionInput } from "../../screens/clientbase/components/mappers/nutrition/ToNutritionInput";
import { NametoIdToType } from "../types/types";
import { QuantityToRecipe } from "../../screens/clientbase/types/types";
import {
  emptyhealthlabelinfo,
  mapHealthLabelInfo,
} from "../../screens/chefsbase/dishes/components/EmptyVariables";
import { healthlabelInfoToOutput } from "../../screens/chefsbase/dishes/utils/MapToInput";
import { quantityToInput } from "../../screens/clientbase/createClientPage/forms/DishCard/Mappers";

export const ingredientForComposable = (i: ingredients_ingredients): any => {};
//   return {
//     __typename: "IngredientToQuantityToSalePrice",
//     name: i.name,
//     id: i.id,
//     quantity: i.nutrition.quantity,
//     price: i.price.amount,
//     nutrition: i.nutrition.nutrition,
//   };
// };

export const toComposable = (i: NametoIdToType): any => {};
//   return {
//     __typename: "IngredientToQuantityToSalePrice",
//     name: i.name,
//     id: i.id,
//     quantity: i.nutrition.quantity,
//     price: 2,
//     nutrition: i.nutrition.nutrition,
//   };
// };

export const mapIngredientToQuantityToSalePriceInput = (
  a: IngredientToQuantityToSalePriceInput
): QuantityToRecipe => ({
  recipe: {
    healthLabelInfo: a.healthLabelInfo
      ? a.healthLabelInfo
      : emptyhealthlabelinfo,
    id: a.id,
    name: a.name,
    nutrition: a.nutrition,
  },
  quantity: { quantity: a.quantity.quantity, unit: a.quantity.unit },
});

export const mapToIngredientToQuantityToSalePriceInputForRecipe = (
  a: Composables_composables_composableRounds_recipes
): IngredientToQuantityToSalePriceInput => ({
  // healthLabelInfo: healthlabelInfoToOutput(a.healthLabelInfo),
  id: a.id,
  quantity: {
    quantity: a.quantity.quantity,
    unit: a.quantity.unit,
  },
  price: a.price,
  name: a.name,
  nutrition: {
    quantity: quantityToInput(a.nutrition.quantity),
    nutrition: toNutritionInput(a.nutrition.nutrition),
  },
  img: "",
});

export const mapToIngredientToQuantityToSalePriceInput = (
  a: Composables_composables_composableRounds_foods
): IngredientToQuantityToSalePriceInput => ({
  // healthLabelInfo: healthlabelInfoToOutput(a.healthLabelInfo),
  id: a.id,
  quantity: {
    quantity: a.quantity.quantity,
    unit: a.quantity.unit,
  },
  price: a.price,
  name: a.name,
  nutrition: {
    quantity: quantityToInput(a.nutrition.quantity),
    nutrition: toNutritionInput(a.nutrition.nutrition),
  },
  img: "",
});

export const mapToIngredientToQuantityToSalePriceInputBase = (
  a: Composables_composables_composableRounds_foods
): IngredientToQuantityToSalePriceInput => ({
  id: a.id,
  quantity: {
    quantity: a.quantity.quantity,
    unit: a.quantity.unit,
  },
  price: 1,
  name: a.name,
  nutrition: {
    quantity: quantityToInput(a.nutrition.quantity),
    nutrition: toNutritionInput(a.nutrition.nutrition),
  },
  img: "",
});
