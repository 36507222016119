import { Table, TableRow, TableCell } from "@mui/material";
import React, { useState } from "react";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import {
  TextOrField,
  TextOrFieldEuro,
  TextOrQuantity,
} from "../../../components/forms/formOrEdit/FormFieldEdit";
import { FormField } from "../../../components/forms/singleForms/FormField";
import { ProductForm } from "./addProduct";
import { AddProductVariables } from "./addProduct/types/AddProduct";
import { products, products_products } from "./types/products";
import { useMyFoodsQuery } from "../foods/api";
import { MyFoods, MyFoods_myfoods } from "../foods/types/MyFoods";
import { Loader } from "../../../components/Loading/Loader";

const ToForm = (a: products_products[]): ProductForm[] => {
  return a.map((p) => ({
    id: p.id,
    input: {
      input: {
        name: p.name,
        category: p.category,
        material: p.material,
        brand: p.brand,
        quantityPerUnit: p.quantityPerUnit,
        quantityTeaSpoon: p.quantityTeaSpoon,
        quantityEatSpoon: p.quantityEatSpoon,
        price: p.price,
        nutrition: p.nutrition,
      },
    },
  }));
};

export const Products = () => {
  const [name, setName] = useState("");
  const [filteredproducts, setproducts] = useState<MyFoods_myfoods[]>([]);

  const { data, loading } = useMyFoodsQuery({
    onCompleted: (result: MyFoods) => setproducts(result.myfoods),
  });

  const products = data ? data.myfoods : [];

  const setNameFilter = (a: string) => {
    const n =
      a && a !== ""
        ? filteredproducts.filter((i) =>
            i.name.toLowerCase().includes(a.toLowerCase())
          )
        : products;

    setproducts(n);
  };
  return (
    <MainBox
      childComp={
        <>
          <BoxTitle title="My Products" />
          <div style={{ height: 300, width: "100%" }}>
            <TopRow
              submitForm={() => {}}
              name={name}
              setNameFilter={(a: string) => setNameFilter(a)}
              setname={(a: string) => setName(a)}
            />
            <Table>
              {loading && (
                <>
                  Fetching Products...
                  <Loader loading={loading} />
                </>
              )}
              {!loading &&
                filteredproducts &&
                filteredproducts.length > 0 &&
                filteredproducts.map((form, i) => (
                  <ProductRow
                    form={form}
                    // setProduct={(a: AddProductVariables) => {
                    //   const old = [...filteredproducts];
                    //   old[i] = {
                    //     id: form.id,
                    //     input: {
                    //       input: {
                    //         name: form.input.input.name,
                    //         category: form.input.input.category,
                    //         material: form.input.input.material,
                    //         brand: form.input.input.brand,
                    //         quantityPerUnit: form.input.input.quantityPerUnit,
                    //         quantityTeaSpoon: form.input.input.quantityTeaSpoon,
                    //         quantityEatSpoon: form.input.input.quantityEatSpoon,
                    //         price: form.input.input.price,
                    //         nutrition: {
                    //           nutrition: {
                    //             kcal: form.input.input.nutrition.nutrition.kcal,
                    //             carbscarbs:
                    //               form.input.input.nutrition.nutrition
                    //                 .carbscarbs,
                    //             carbssugar:
                    //               form.input.input.nutrition.nutrition
                    //                 .carbssugar,
                    //             fatstotal:
                    //               form.input.input.nutrition.nutrition
                    //                 .fatstotal,
                    //             prottotal:
                    //               form.input.input.nutrition.nutrition
                    //                 .prottotal,
                    //             starch:
                    //               form.input.input.nutrition.nutrition.starch,
                    //             fibres:
                    //               form.input.input.nutrition.nutrition.fibres,
                    //             cholesterol:
                    //               form.input.input.nutrition.nutrition
                    //                 .cholesterol,
                    //             natrium: Number(name),
                    //             saturedfat:
                    //               form.input.input.nutrition.nutrition
                    //                 .saturedfat,
                    //           },
                    //           quantity: form.input.input.nutrition.quantity,
                    //         },
                    //       },
                    //     },
                    // };
                    // }}
                  />
                ))}
            </Table>
          </div>
        </>
      }
    />
  );
};
const TopRow = ({
  name,
  setname,
  setNameFilter,
  submitForm,
}: {
  submitForm: () => void;
  name: string;
  setname: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>Search Product:</TableCell>
        <TableCell>
          <FormField
            value={name}
            setValue={(a) => {
              setname(a);
              setNameFilter(a);
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 500 }}>
          <SubmitButton
            fullWidth
            variant="contained"
            onClick={() => submitForm()}
          >
            Submit changes
          </SubmitButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export const ProductRow = ({
  form,
}: // setProduct,
{
  form: MyFoods_myfoods;
  // setProduct: (a: AddProductVariables) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>
          {form.name}
          {/* <TextOrField
            value={form.input.input.name}
            setValue={(a: string) =>
              setProduct({
                input: {
                  name: a,
                  category: form.input.input.category,
                  material: form.input.input.material,
                  brand: form.input.input.brand,
                  quantityPerUnit: form.input.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.input.quantityEatSpoon,
                  price: form.input.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.input.nutrition.nutrition.kcal,
                      carbscarbs:
                        form.input.input.nutrition.nutrition.carbscarbs,
                      carbssugar:
                        form.input.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.input.nutrition.nutrition.prottotal,
                      starch: form.input.input.nutrition.nutrition.starch,
                      fibres: form.input.input.nutrition.nutrition.fibres,
                      cholesterol:
                        form.input.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.input.nutrition.nutrition.natrium,
                      saturedfat:
                        form.input.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.input.nutrition.quantity,
                  },
                },
              })
            }
          /> */}
        </TableCell>
        <TableCell>
          {`€${form.price?.amountPerQuantity.amount.toFixed(
            2
          )} per ${form.price?.amountPerQuantity.quantity.quantity.toFixed(
            2
          )} ${form.price?.amountPerQuantity.quantity.unit.label}`}
          {/* <TextOrField
            value={form.input.input.brand}
            setValue={(a: string) =>
              setProduct({
                input: {
                  name: form.input.input.name,
                  category: form.input.input.category,
                  material: form.input.input.material,
                  brand: a,
                  quantityPerUnit: form.input.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.input.quantityEatSpoon,
                  price: form.input.input.price,
                  nutrition: {
                    nutrition: {
                      kcal: form.input.input.nutrition.nutrition.kcal,
                      carbscarbs:
                        form.input.input.nutrition.nutrition.carbscarbs,
                      carbssugar:
                        form.input.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.input.nutrition.nutrition.prottotal,
                      starch: form.input.input.nutrition.nutrition.starch,
                      fibres: form.input.input.nutrition.nutrition.fibres,
                      cholesterol:
                        form.input.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.input.nutrition.nutrition.natrium,
                      saturedfat:
                        form.input.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.input.nutrition.quantity,
                  },
                },
              })
            }
          /> */}
        </TableCell>
        <TableCell>
          {form.price?.supplier.name}
          {/* <TextOrFieldEuro
            value={String(form.input.input.price.amount)}
            setValue={(a: string) =>
              setProduct({
                input: {
                  name: form.input.input.name,
                  category: form.input.input.category,
                  material: form.input.input.material,
                  brand: a,
                  quantityPerUnit: form.input.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.input.quantityEatSpoon,
                  price: {
                    amount: Number(a),
                    quantity: form.input.input.price.quantity,
                  },
                  nutrition: {
                    nutrition: {
                      kcal: form.input.input.nutrition.nutrition.kcal,
                      carbscarbs:
                        form.input.input.nutrition.nutrition.carbscarbs,
                      carbssugar:
                        form.input.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.input.nutrition.nutrition.prottotal,
                      starch: form.input.input.nutrition.nutrition.starch,
                      fibres: form.input.input.nutrition.nutrition.fibres,
                      cholesterol:
                        form.input.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.input.nutrition.nutrition.natrium,
                      saturedfat:
                        form.input.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.input.nutrition.quantity,
                  },
                },
              })
            }
          /> */}
        </TableCell>
        <TableCell>
          {/* <TextOrQuantity
            u={form.input.input.price.quantity.unit.label}
            value={String(form.input.input.price.quantity.quantity)}
            setValue={(a: string) =>
              setProduct({
                input: {
                  name: form.input.input.name,
                  category: form.input.input.category,
                  material: form.input.input.material,
                  brand: a,
                  quantityPerUnit: form.input.input.quantityPerUnit,
                  quantityTeaSpoon: form.input.input.quantityTeaSpoon,
                  quantityEatSpoon: form.input.input.quantityEatSpoon,
                  price: {
                    amount: form.input.input.price.amount,
                    quantity: {
                      quantity: Number(a),
                      unit: form.input.input.price.quantity.unit,
                    },
                  },
                  nutrition: {
                    nutrition: {
                      kcal: form.input.input.nutrition.nutrition.kcal,
                      carbscarbs:
                        form.input.input.nutrition.nutrition.carbscarbs,
                      carbssugar:
                        form.input.input.nutrition.nutrition.carbssugar,
                      fatstotal: form.input.input.nutrition.nutrition.fatstotal,
                      prottotal: form.input.input.nutrition.nutrition.prottotal,
                      starch: form.input.input.nutrition.nutrition.starch,
                      fibres: form.input.input.nutrition.nutrition.fibres,
                      cholesterol:
                        form.input.input.nutrition.nutrition.cholesterol,
                      natrium: form.input.input.nutrition.nutrition.natrium,
                      saturedfat:
                        form.input.input.nutrition.nutrition.saturedfat,
                    },
                    quantity: form.input.input.nutrition.quantity,
                  },
                },
              })
            }
          /> */}
        </TableCell>
      </TableRow>
    </>
  );
};
