import { Button, Paper, Table, TableCell, TableRow } from "@mui/material";
import { emptyIngredientToQuantityToSalePriceInput } from "../../../../components/defaults/baseProd/IngredientToQuantityToSalePriceInput";
import { backgroundField } from "../../../../components/layouts/Colors";
import {
  ComposableRoundInput,
  IngredientToQuantityToSalePriceInput,
} from "../../../../globalTypes";
import { QuantityToProduct } from "../types/QuantityToProduct";
import {
  EdamamToNutritionInput,
  mapToEdamamNutrition,
  mapToRecipeInput,
} from "./DishCard/Mappers";
import { OneIngredientWide, OneRecipe } from "./IngredientForProductRow";
import { mapIngredientToQuantityToSalePriceInput } from "../../../../components/utils/Mappers";
import { returnStringIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { Loader } from "../../../../components/Loading/Loader";
import { BaseOrVar, QuantityToRecipe } from "../../types/types";
import { gramMeasureInput } from "../../../../components/defaults/portions/PortionInput";

export enum RecipeOrFood {
  RECIPE,
  FOOD,
}
interface RecipesAndIngredientsForComposableForm {
  loadingRecipes: boolean;
  round: ComposableRoundInput;
  setRound: (a: ComposableRoundInput) => void;
  title: string;
  recipes: QuantityToRecipe[];
  addToRound: (a: RecipeOrFood) => void;
}

export const RecipeAndIngredientsForComposable: React.FC<
  RecipesAndIngredientsForComposableForm
> = ({ title, setRound, round, recipes, loadingRecipes, addToRound }) => {
  return (
    <>
      <Table component={Paper} style={{ backgroundColor: backgroundField }}>
        <TableRow>
          <TableCell colSpan={3}>{title}</TableCell>
        </TableRow>
        {round.foods.map((food, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <OneIngredientWide
                  key={num}
                  price={food.price}
                  setprice={(a: number) => {
                    const oldProd = [...round.foods];
                    oldProd[num] = {
                      ...oldProd[num],
                      price: a,
                    };
                    setRound({
                      ...round,
                      foods: oldProd,
                    });
                  }}
                  deleteItem={() => {
                    const oldProd = [...round.foods];
                    oldProd.splice(num, 1);
                    setRound({
                      ...round,
                      foods: oldProd,
                    });
                  }}
                  product={{
                    quantity: food.quantity,
                    product: {
                      measures: [
                        {
                          ...food.quantity.unit,
                          __typename: "Measure",
                        },
                      ],
                      food: {
                        label: food.name,
                        foodId: food.id,
                        category: "",
                        image: returnStringIfNotNullOrUndefined(food.img),
                        nutrients: mapToEdamamNutrition(
                          food.nutrition.nutrition
                        ),
                      },
                    },
                  }}
                  setproduct={(a: QuantityToProduct) => {
                    const oldProd = [...round.foods];
                    oldProd[num] = {
                      ...oldProd[num],
                      name: a.product.food.label,
                      id: a.product.food.foodId,
                      quantity: a.quantity,
                      measurementInfo: a.product.measurementInfo,
                      nutrition: {
                        quantity: {
                          quantity: 100,
                          unit: gramMeasureInput(),
                        },
                        nutrition: EdamamToNutritionInput(
                          a.product.food.nutrients
                        ),
                      },
                    };
                    setRound({
                      ...round,
                      foods: oldProd,
                    });
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        ))}
        {round.recipes.map((recipe, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <>
                  {loadingRecipes && (
                    <>
                      Fetching Recipes, hold on..{" "}
                      <Loader loading={loadingRecipes} />
                    </>
                  )}
                  <OneRecipe
                    price={recipe.price}
                    setprice={(a) => {
                      const oldProd = [...round.recipes];
                      oldProd[num] = {
                        ...oldProd[num],
                        price: a,
                      };
                      setRound({
                        ...round,
                        recipes: oldProd,
                      });
                    }}
                    recipes={recipes}
                    deleteItem={() => {
                      const oldProd = [...round.recipes];
                      oldProd.splice(num, 1);
                      setRound({
                        ...round,
                        recipes: oldProd,
                      });
                    }}
                    recipe={mapIngredientToQuantityToSalePriceInput(recipe)}
                    setrecipe={(a: QuantityToRecipe) => {
                      const oldRec = [...round.recipes];
                      const mapped = mapToRecipeInput(a);
                      oldRec[num] = {
                        ...oldRec[num],
                        name: mapped.name,
                        id: mapped.id,
                        quantity: mapped.quantity,
                        nutrition: {
                          quantity: a.quantity,
                          nutrition: mapped.nutrition,
                        },
                        healthLabelInfo: mapped.healthlabelInfo,
                      };
                      setRound({
                        ...round,
                        recipes: oldRec,
                      });
                    }}
                  />
                </>
              </TableCell>
            </TableRow>
          </>
        ))}
        <TableRow>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="primary"
              variant="contained"
              onClick={() => addToRound(RecipeOrFood.FOOD)}
            >
              + Ingredient
            </Button>
          </TableCell>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="primary"
              variant="contained"
              onClick={() => addToRound(RecipeOrFood.RECIPE)}
            >
              + Home Made Recipe
            </Button>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};

export const RecipeAndIngredientsForComposable1 = ({
  title,
  productsFromForm,
  recipesFromForm,
  recipes,
  changeRecipes,
  changeIngredients,
  baseOrVar,
  loadingRecipes,
}: {
  loadingRecipes: boolean;
  baseOrVar: BaseOrVar;
  recipesFromForm: IngredientToQuantityToSalePriceInput[];
  productsFromForm: IngredientToQuantityToSalePriceInput[];
  changeRecipes: (a: IngredientToQuantityToSalePriceInput[]) => void;
  changeIngredients: (a: IngredientToQuantityToSalePriceInput[]) => void;
  title: string;
  recipes: QuantityToRecipe[];
}) => {
  return (
    <>
      <Table component={Paper} style={{ backgroundColor: backgroundField }}>
        <TableRow>
          <TableCell colSpan={3}>{title}</TableCell>
        </TableRow>
        {productsFromForm.map((baseProd, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <OneIngredientWide
                  price={
                    baseOrVar === BaseOrVar.VAR ? baseProd.price : undefined
                  }
                  setprice={(a: number) => {
                    const oldProd = [...productsFromForm];
                    oldProd[num] = {
                      ...oldProd[num],
                      price: a,
                    };
                    changeIngredients(oldProd);
                  }}
                  deleteItem={() => {
                    const oldProd = [...productsFromForm];
                    oldProd.splice(num, 1);
                    changeIngredients(oldProd);
                  }}
                  product={{
                    quantity: baseProd.quantity,
                    product: {
                      measures: [
                        {
                          ...baseProd.quantity.unit,
                          __typename: "Measure",
                        },
                      ],
                      food: {
                        label: baseProd.name,
                        foodId: baseProd.id,
                        category: "",
                        image: returnStringIfNotNullOrUndefined(baseProd.img),
                        nutrients: mapToEdamamNutrition(
                          baseProd.nutrition.nutrition
                        ),
                      },
                    },
                  }}
                  setproduct={(a: QuantityToProduct) => {
                    const oldProd = [...productsFromForm];
                    oldProd[num] = {
                      ...oldProd[num],
                      name: a.product.food.label,
                      id: a.product.food.foodId,
                      quantity: a.quantity,
                    };
                    changeIngredients(oldProd);
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        ))}
        {recipesFromForm.map((baseRec, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <>
                  {loadingRecipes && (
                    <>
                      Fetching Recipes, hold on..{" "}
                      <Loader loading={loadingRecipes} />
                    </>
                  )}
                  <OneRecipe
                    price={
                      baseOrVar === BaseOrVar.VAR ? baseRec.price : undefined
                    }
                    setprice={
                      baseOrVar === BaseOrVar.BASE
                        ? undefined
                        : (a: number) => {
                            const oldRec = [...recipesFromForm];
                            oldRec[num] = {
                              ...oldRec[num],
                              price: a,
                            };
                            changeRecipes(oldRec);
                          }
                    }
                    recipes={recipes}
                    deleteItem={() => {
                      const oldRec = [...recipesFromForm];
                      oldRec.splice(num, 1);
                      changeRecipes(oldRec);
                    }}
                    recipe={mapIngredientToQuantityToSalePriceInput(baseRec)}
                    setrecipe={(a: QuantityToRecipe) => {
                      const oldRec = [...recipesFromForm];
                      const mapped = mapToRecipeInput(a);
                      oldRec[num] = {
                        ...oldRec[num],
                        name: mapped.name,
                        id: mapped.id,
                        quantity: mapped.quantity,
                        nutrition: {
                          quantity: a.quantity,
                          nutrition: mapped.nutrition,
                        },
                        healthLabelInfo: mapped.healthlabelInfo,
                      };
                      changeRecipes(oldRec);
                    }}
                  />
                </>
              </TableCell>
            </TableRow>
          </>
        ))}
        <TableRow>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="inherit"
              variant="outlined"
              onClick={() => {
                const oldProd = [...productsFromForm];
                oldProd.push(emptyIngredientToQuantityToSalePriceInput);
                changeIngredients(oldProd);
              }}
            >
              + Ingredient
            </Button>
          </TableCell>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="primary"
              variant="contained"
              onClick={() => {
                const oldRec = [...recipesFromForm];
                oldRec.push(emptyIngredientToQuantityToSalePriceInput);
                changeRecipes(oldRec);
              }}
            >
              + Home Made Recipe
            </Button>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};
