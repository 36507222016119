import { createTheme } from "@mui/material/styles";
import {
  aestheticBackground,
  backgroundContainer,
  backgroundField,
  buttonColor,
} from "./components/layouts/Colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: aestheticBackground,
      main: buttonColor,
    },
    secondary: {
      main: aestheticBackground, // Change secondary color to green
    },
  },
});
