import { gql, useLazyQuery } from "@apollo/client";
import { useSimpleQuery } from "../../../utils/apollo";
import { Clientmenus } from "./types/Clientmenus";
import { Composables } from "./types/Composables";
import { QuickMenus } from "./types/QuickMenus";

export const QuickClientMenusQuery = gql`
  query QuickMenus($limit: Int, $offset: Int) {
    menus(limit: $limit, offset: $offset) {
      id
      name
      courses {
        selectables {
          type
          selectables {
            quantitiesSelected {
              portion {
                name
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
              }
              selected
            }
            dish {
              name
              id
              portions {
                name
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const ClientMenusQuery = gql`
  query Clientmenus($limit: Int, $offset: Int) {
    menus(limit: $limit, offset: $offset) {
      current
      id
      name
      combos {
        comboid
        price
        dishes {
          round
          dish {
            name
            id
          }
          portion {
            nutrition {
              kcal
              prottotal
              fatstotal
              saturedfat
              carbscarbs
              carbssugar
              natrium
              fibres
              cholesterol
              starch
            }
            name
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
          }
        }
      }
      courses {
        name
        composables {
          img
          name
          basePrice
          type
          composableRounds {
            number
            name
            foods {
              measurementInfo {
                serving
                cup
                tablespoon
                whole
                teaspoon
              }
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
            recipes {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              name
              id
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                nutrition {
                  kcal
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  cholesterol
                  starch
                }
              }
              price
            }
          }
        }
        selectables {
          type
          selectables {
            quantitiesSelected {
              portion {
                nutrition {
                  kcal
                  cholesterol
                  prottotal
                  fatstotal
                  saturedfat
                  carbscarbs
                  carbssugar
                  natrium
                  fibres
                  starch
                }
                name
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
                price
              }
              selected
            }
            dish {
              healthLabelInfo {
                vegan
                lupine
                meat
                nut
                peanut
                shellfish
                selery
                sesame
                soy
                fish
                crustacean
                sulphide
                mustard
                dairy
                gluten
                egg
                milk
                pork
                mollusk
              }
              img
              name
              id
              type
              options {
                name
                option
              }
              portions {
                name
                price
                quantity {
                  quantity
                  unit {
                    label
                    uri
                    weight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useQuickClientMenusQuery = ({
  onCompleted,
}: {
  onCompleted: (result: QuickMenus) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(
    QuickClientMenusQuery,
    {
      variables: {
        offset: 0,
        limit: 3000,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { loading, data, error, refetch };
};

export const useClientMenusQuery = ({
  onCompleted,
}: {
  onCompleted: (result: Clientmenus) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(ClientMenusQuery, {
    variables: {
      offset: 0,
      limit: 3000,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error, refetch };
};

const ComposableQuery = gql`
  query Composables {
    composables {
      img
      name
      basePrice
      type
      composableRounds {
        number
        name
        foods {
          measurementInfo {
            serving
            cup
            tablespoon
            whole
            teaspoon
          }
          name
          id
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          nutrition {
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
            nutrition {
              kcal
              prottotal
              fatstotal
              saturedfat
              carbscarbs
              carbssugar
              natrium
              fibres
              cholesterol
              starch
            }
          }
          price
        }
        recipes {
          name
          id
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          nutrition {
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
            nutrition {
              kcal
              prottotal
              fatstotal
              saturedfat
              carbscarbs
              carbssugar
              natrium
              fibres
              cholesterol
              starch
            }
          }
          price
        }
      }
    }
  }
`;

export const useLazyComposableQuery = ({
  onCompleted,
}: {
  onCompleted: (result: Composables) => void;
}) => {
  const [getcomposables, { loading, data, error }] = useLazyQuery(
    ComposableQuery,
    {
      variables: {
        offset: 0,
        limit: 3000,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { getcomposables, loading, data, error };
};

export const useComposableQuery = ({
  onCompleted,
}: {
  onCompleted: (result: Composables) => void;
}) => {
  const { loading, data, error } = useSimpleQuery(ComposableQuery, {
    variables: {
      offset: 0,
      limit: 3000,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error };
};
