import "./App.css";
import { CssBaseline } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import React, { useState } from "react";
import { Provider } from "./utils/ApolloProvider";
import ErrorBoundary from "./utils/ErrorBoundary";
import { ErrorProvider } from "./utils/ErrorHandling";
import {
  Auth0Provider,
  AppState,
  AuthorizationParams,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { useNavigate } from "react-router";

const PrivateRoute = () => <div>Private</div>;

const PrivateRouteWithAuthenticationRequired = withAuthenticationRequired(
  PrivateRoute,
  {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
  }
);

interface Auth0ProviderWithRedirectCallbackProps {
  children: React.ReactNode;
  domain: string;
  clientId: string;
  authorizationParams?: AuthorizationParams | undefined;
}
interface ProtectedRouteProps {
  component: React.ComponentType;
  // Additional args you want to pass to withAuthenticationRequired
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...args
}) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback: React.FC<
  Auth0ProviderWithRedirectCallbackProps
> = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

function App() {
  const [value, setvalue] = useState(0);
  return (
    <>
      <ErrorProvider>
        <ErrorBoundary>
          <Auth0Provider
            domain="dev-mce4xl3fvhmwrm3o.us.auth0.com"
            clientId="d9fh9hVDsAKic81gVVrGwSMjQXU9cyzC"
            authorizationParams={{
              // issuer: "https://dev-mce4xl3fvhmwrm3o.us.auth0.com/",
              audience: "mychefsbase",
              redirect_uri: window.location.origin,
              // "http://localhost:3000/menus",
            }}
          >
            <Provider>
              <CssBaseline />
              <RouterProvider router={routes(value)} />
            </Provider>
          </Auth0Provider>
        </ErrorBoundary>
      </ErrorProvider>
    </>
  );
}

export default App;
