import {
  Stack,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Grid,
  Table,
  TableHead,
  Divider,
  DialogActions,
  Button,
  TableRow,
  TableCell,
} from "@mui/material";
import { VscTrash, VscAdd } from "react-icons/vsc";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import { aestheticBackground } from "../../../../components/layouts/Colors";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import {
  CurrentMenu_currentMenu_courses_composables_composableRounds_foods,
  CurrentMenu_currentMenu_courses_composables,
  CurrentMenu_currentMenu_courses_composables_composableRounds_recipes,
  CurrentMenu_currentMenu_courses_composables_composableRounds,
} from "../../types/CurrentMenu";
import { sumItem } from "../courses/Functions";
import { removeDuplicates } from "../../createClientPage/forms/IngredientForProductRow";
import { useState, useMemo } from "react";
import { HealthLabelInfo, NutritionInput } from "../../../../globalTypes";
import { getFractionWithServing } from "../../../../components/utils/GetFraction";
import Times from "../../../../assets/icon/times.png";
import AddIcon from "../Images/AddIcon.png";
import DelIcon from "../Images/del.png";
import Vactor from "../Images/Vactor.png";
import "./Diloag.css";

export const singleHealthLabelInfo = (
  foods: HealthLabelInfo[]
): HealthLabelInfo => {
  const lupine =
    foods.filter((f) => f === undefined || f === null || f!.lupine === true)
      .length > 0;
  const meat =
    foods.filter((f) => f === undefined || f === null || f!.meat === true)
      .length > 0;
  const nut =
    foods.filter((f) => f === undefined || f === null || f!.nut === true)
      .length > 0;
  const peanut =
    foods.filter((f) => f === undefined || f === null || f!.peanut === true)
      .length > 0;
  const shellfish =
    foods.filter((f) => f === undefined || f === null || f!.shellfish === true)
      .length > 0;
  const selery =
    foods.filter((f) => f === undefined || f === null || f!.selery === true)
      .length > 0;
  const sesame =
    foods.filter((f) => f === undefined || f === null || f!.sesame === true)
      .length > 0;
  const soy =
    foods.filter((f) => f === undefined || f === null || f!.soy === true)
      .length > 0;
  const fish =
    foods.filter((f) => f === undefined || f === null || f!.fish === true)
      .length > 0;
  const crustacean =
    foods.filter((f) => f === undefined || f === null || f!.crustacean === true)
      .length > 0;
  const sulphide = false; //!checkForString("");
  const mustard =
    foods.filter((f) => f === undefined || f === null || f!.mustard === true)
      .length > 0;
  const dairy =
    foods.filter((f) => f === undefined || f === null || f!.dairy === true)
      .length > 0;
  const gluten =
    foods.filter((f) => f === undefined || f === null || f!.gluten === true)
      .length > 0;
  const egg =
    foods.filter((f) => f === undefined || f === null || f!.egg === true)
      .length > 0;
  const pork =
    foods.filter((f) => f === undefined || f === null || f!.pork === true)
      .length > 0;
  const milk =
    foods.filter((f) => f === undefined || f === null || f!.milk === true)
      .length > 0;
  const mollusk =
    foods.filter((f) => f === undefined || f === null || f!.mollusk === true)
      .length > 0;

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
  };
};

export const DisplayHealthInfo = ({
  info,
  column,
  w,
}: {
  w?: number;
  column?: boolean;
  info: HealthLabelInfo;
}) => {
  const pics = [
    info.egg ? "/Egg.png" : "",
    info.fish ? "/Fish.png" : "",
    info.peanut ? "/Peanut.png" : "",
    info.nut ? "/Nut.png" : "",
    info.meat ? "/Meat.png" : "",
    info.selery ? "/Selery.png" : "",
    info.soy ? "/Soy.png" : "",
    info.gluten ? "/Gluten.png" : "",
    info.milk ? "/Milk.png" : "",
    info.shellfish ? "/Shellfish.png" : "",
    info.mollusk ? "/mollusk.png" : "",
    info.mustard ? "/Mustard.png" : "",
  ].filter((i) => i !== "");
  const texts = [
    info.egg ? "Egg" : "",
    info.fish ? "Fish" : "",
    info.peanut ? "Peanut" : "",
    info.nut ? "Nut" : "",
    info.meat ? "Meat" : "",
    info.selery ? "Selery" : "",
    info.soy ? "Soy" : "",
    info.gluten ? "Gluten" : "",
    info.milk ? "Milk" : "",
    info.shellfish ? "Shellfish" : "",
    info.mollusk ? "Mollusk" : "",
    info.mustard ? "Mustard" : "",
  ].filter((i) => i !== "");
  const [displaytext, setText] = useState(false);
  return (
    <Stack
      direction={column ? "column" : "row"}
      spacing={0.5}
      onClick={() => setText(!displaytext)}
    >
      {displaytext
        ? texts.map((p, i) => <b>{p}</b>)
        : pics.map((p, i) => (
            <DisplayImage
              key={i}
              height={w ? w : 100}
              width={w ? w : 100}
              img={p}
            />
          ))}
    </Stack>
  );
};

const localButtonColor = "#DDDAC4";

export const DialogComposable = ({
  dish,
  added,
  images,
  open,
  setopen,
  setadded,
}: {
  setadded: (
    a: CurrentMenu_currentMenu_courses_composables_composableRounds_foods[]
  ) => void;
  dish: CurrentMenu_currentMenu_courses_composables;
  added: CurrentMenu_currentMenu_courses_composables_composableRounds_foods[];
  images: string[];
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  return (
    <Dialog
      sx={{ minHeight: "100vh", backgroundColor: aestheticBackground }}
      open={open}
      onClose={() => setopen(false)}
      maxWidth="md"
      fullWidth
      scroll={"paper"}
    >
      <Stack direction="column" spacing={0}>
        <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
          <GridRowTwoItems
            before={0}
            firstlength={10}
            inbetween={0}
            secondlength={2}
            firstchild={
              <Typography
                align="center"
                noWrap
                variant="body1"
                sx={{
                  fontFamily: "FairField Display, serif",
                  fontSize: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <b>{dish.name}</b>
              </Typography>
            }
            secondchild={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // backgroundColor: aestheticBackground,
                }}
              >
                <Button
                  color="inherit"
                  sx={{
                    // backgroundColor: aestheticBackground,
                    borderRadius: 40,
                    borderWidth: 0,
                    paddingRight: 0,
                    paddingLeft: 0,
                    position: "relative",
                    left: 20,
                  }}
                  onClick={() => {
                    setopen(false);
                  }}
                  variant="outlined"
                >
                  <img src={Times} width="40" />
                </Button>
              </Box>
            }
          />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: aestheticBackground }}>
          <TotalValuesBox
            setadded={setadded}
            dish={dish}
            added={added}
            image={images[0]}
          />
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

const foodsToNutritionInput = (
  added: CurrentMenu_currentMenu_courses_composables_composableRounds_foods[]
): NutritionInput => {
  return {
    cholesterol: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.cholesterol;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    saturedfat: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.saturedfat;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    starch: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.starch;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    natrium: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.natrium;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    prottotal: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.prottotal;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    fatstotal: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.fatstotal;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    fibres: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.fibres;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    kcal: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.kcal;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    carbssugar: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.carbssugar;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
    carbscarbs: sumItem(
      added.map((a) => {
        const amount = a.nutrition.nutrition.carbscarbs;
        const fraction = getFractionWithServing(
          a.measurementInfo,
          a.nutrition.quantity,
          a.quantity
        );
        const result = amount * fraction;
        return result;
      })
    ),
  };
};
export const NutritionBox = ({
  direction,
  price,
  added,
  mb,
}: {
  mb?: number;
  direction: string;
  price?: number;
  added: NutritionInput;
}) => {
  if (direction === "row")
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: mb ? mb : 0,
          // backgroundColor: aestheticBackground,
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={4}
          style={{
            justifyContent: "space-between",
            // backgroundColor: aestheticBackground,
          }}
        >
          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Protein</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.prottotal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Fibers</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.fibres.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Carbs</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.carbscarbs.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Fats</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.fatstotal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Kcal</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.kcal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                paddingLeft: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              {price && (
                <Box className="PriceComponent CENTER">
                  <h5 className="Price">{`€${price}`}</h5>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  else
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // backgroundColor: aestheticBackground,
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={4}
          style={{
            justifyContent: "space-between",
            // backgroundColor: aestheticBackground,
          }}
        >
          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Kcal</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.kcal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Carbs</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.carbscarbs.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Protein</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.prottotal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} className="CENTER">
            <Box
              sx={{
                paddingRight: 0.6,
                backgroundColor: aestheticBackground,
              }}
            >
              <Box className="MacronutrientsComponent">
                <Typography className="MacronutrientsName">Fats</Typography>
                <Box className="MacronutrientsValueComponent">
                  <Typography className="MacronutrientsValue">
                    {added.fatstotal.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
};
export const TotalValuesBox = ({
  image,
  added,
  dish,
  setadded,
}: {
  setadded: (
    a: CurrentMenu_currentMenu_courses_composables_composableRounds_foods[]
  ) => void;
  dish: CurrentMenu_currentMenu_courses_composables;
  added: CurrentMenu_currentMenu_courses_composables_composableRounds_foods[];
  image: any;
}) => {
  const composableRounds = useMemo(() => {
    let composableRounds = [...dish.composableRounds];
    return composableRounds.sort((a, b) => a.number - b.number);
  }, [dish]);

  return (
    <Grid container spacing={0} className="MainSection">
      {/* Product Section */}
      {/* <Stack direction="column" spacing={0}> */}
      <Box className="Padding_15 w-100">
        <Box className="ProductSection">
          <Box className="ProductImageSection">
            {/* <h4 className='Bowl'>{props.dish.name}</h4> */}
            <img
              style={{
                objectFit: "cover",
                height: 350,
                width: 350,
                borderRadius: 20,
              }}
              src={image ? image : ""}
              alt="Product "
            />
          </Box>
          <NutritionBox
            direction="row"
            added={foodsToNutritionInput(added)}
            price={sumItem(added.map((a) => a.price)) + dish.basePrice}
          />
        </Box>
      </Box>
      <Grid container>
        {composableRounds.map((round, roundNumber) => (
          <Grid item md={6}>
            <Box className="Padding_15 belowSections w-100">
              <Box className="MacronutrientsHeadingBar">
                <Box
                  sx={{
                    backgroundImage: `url(${Vactor})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    objectFit: "contain",
                    width: 100,
                    whiteSpace: "nowrap",
                  }}
                >
                  <h5 className="MacronutrientsHeading">
                    <Typography
                      style={{
                        fontFamily: "FairField Display, serif",
                        fontSize: "20px",
                      }}
                    >
                      {round.name}
                    </Typography>
                  </h5>
                </Box>
              </Box>
            </Box>
            {/*  */}
            <Box sx={{ padding: 2 }}>
              <Box className="IngredientsSection">
                <Box className="Padding_15 w-100">
                  <Grid container>
                    {round.foods.map((h, foodNumber) => {
                      const fraction = getFractionWithServing(
                        h.measurementInfo,
                        h.nutrition.quantity,
                        h.quantity
                      );
                      return (
                        <Grid item xs={12}>
                          <Box
                            className="Ingredients"
                            style={{
                              backgroundColor: `${
                                added.map((a) => a.id).includes(h.id)
                                  ? localButtonColor
                                  : aestheticBackground
                              }`,
                            }}
                          >
                            <h5 className="IngredientName">{h.name}</h5>
                            <Box className="IngredientRightSection">
                              <Box className="IngredientPriceSection">
                                <h5 className="IngredientPrice">
                                  €{h.price.toFixed(2)}
                                </h5>
                                {added.map((a) => a?.id).includes(h?.id) ? (
                                  <Box
                                    sx={{
                                      backgroundColor: aestheticBackground,
                                    }}
                                    className="AddIngredientSection"
                                    onClick={() => {
                                      const old = [...added];
                                      const i = added
                                        .map((a) => a?.id)
                                        .indexOf(h.id);
                                      old.splice(i, 1);
                                      setadded(old);
                                    }}
                                  >
                                    <img
                                      className="AddIcon"
                                      src={DelIcon}
                                      alt="Icon"
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      backgroundColor: aestheticBackground,
                                    }}
                                    className="AddIngredientSection"
                                    onClick={() => {
                                      const old = [...added];
                                      old.push(h);
                                      setadded(old);
                                    }}
                                  >
                                    <img
                                      className="AddIcon"
                                      src={AddIcon}
                                      alt="Icon"
                                    />
                                  </Box>
                                )}
                              </Box>
                              <h3 className="IngredientKcal">{`${(
                                h.nutrition.nutrition.kcal * fraction
                              ).toFixed(0)} kcal`}</h3>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Box className="Padding_15 w-100 ">
                  <Grid container>
                    {round.recipes.map((h, foodNumber) => {
                      return (
                        <Grid item xs={16}>
                          {/* <Ingredients setadded={props.setadded} h={h} added={props.added} name={`${h.name} ${h.quantity.quantity} ${h.quantity.unit.label}`} price={`€${h.price} `} kcal={`${h.nutrition.nutrition.kcal.toFixed(0)} kcal`} /> */}
                          <Box
                            className="Ingredients"
                            style={{
                              backgroundColor: `${
                                added.map((a) => a?.id).includes(h?.id)
                                  ? localButtonColor
                                  : aestheticBackground
                              }`,
                            }}
                          >
                            <h5 className="IngredientName">{h.name}</h5>
                            <Box className="IngredientRightSection">
                              <Box className="IngredientPriceSection">
                                <h5 className="IngredientPrice">
                                  €{h.price.toFixed(2)}
                                </h5>
                                {added.map((a) => a?.id).includes(h?.id) ? (
                                  <Box
                                    sx={{
                                      backgroundColor: aestheticBackground,
                                    }}
                                    className="AddIngredientSection"
                                    onClick={() => {
                                      const old = [...added];
                                      const i = added
                                        .map((a) => a?.id)
                                        .indexOf(h.id);
                                      old.splice(i, 1);
                                      setadded(old);
                                    }}
                                  >
                                    <img
                                      className="AddIcon"
                                      src={DelIcon}
                                      alt="Icon"
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      backgroundColor: aestheticBackground,
                                    }}
                                    className="AddIngredientSection"
                                    onClick={() => {
                                      const old = [...added];
                                      old.push({
                                        ...h,
                                        measurementInfo: null,
                                      });
                                      setadded(old);
                                    }}
                                  >
                                    <img
                                      className="AddIcon"
                                      src={AddIcon}
                                      alt="Icon"
                                    />
                                  </Box>
                                )}
                              </Box>
                              <h3 className="IngredientKcal">{`${h.nutrition.nutrition.kcal.toFixed(
                                0
                              )} kcal`}</h3>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* </Stack> */}
    </Grid>
  );
};
