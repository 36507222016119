import { getFraction } from "../../../../components/utils/GetFraction";
import { quantityToInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { getFractionWithServing } from "../../../../components/utils/GetFraction";
import { Recipe_recipe_products_foods_food_measurementInfo } from "../../recipes/types/Recipe";
import { AddDishVariables } from "../addDish/types/AddDish";
import { pricesForFoods_pricesForFoods } from "../types/pricesForFoods";
import { standard } from "./StandardWeight";
import { AmountPerQuantityToProduct } from "..";

import { MeasurementInfo } from "../../../clientbase/types/types";

export const totalPrice = (
  form: AddDishVariables,
  foodPrices: AmountPerQuantityToProduct[] | undefined
) => {
  if (foodPrices === undefined) return 0;

  const allProd = form.products.ingredients
    .map((r) => {
      const measurementInfo:
        | Recipe_recipe_products_foods_food_measurementInfo
        | null
        | undefined = {
        __typename: "MeasurementInfo",
        serving: r.measurementInfo?.serving ? r.measurementInfo.serving : 0.0,
        cup: r.measurementInfo?.cup ? r.measurementInfo.cup : 0.0,
        tablespoon: r.measurementInfo?.tablespoon
          ? r.measurementInfo.tablespoon
          : 0.0,
        teaspoon: r.measurementInfo?.teaspoon
          ? r.measurementInfo.teaspoon
          : 0.0,
        whole: r.measurementInfo?.whole ? r.measurementInfo.whole : 0.0,
      };
      const foundProductToPrice =
        foodPrices && foodPrices.find((f) => f.product.id === r.id);
      const fr2 = foundProductToPrice
        ? getFractionWithServing(
            measurementInfo,
            foundProductToPrice.amountPerQuantity.quantity,
            r.quantity
          )
        : 1;
      const foundprice = foundProductToPrice
        ? foundProductToPrice.amountPerQuantity.amount
        : 0;

      return fr2 * foundprice;
    })
    .filter((n) => isNaN(n) === false);
  const productsTotal =
    allProd.length > 0 ? allProd.reduce((sum, p) => sum + p) : 0;

  return productsTotal;
};

export const totalKcal = (form: AddDishVariables, frac: number) => {
  const allProd = form.products.ingredients.map((r) => {
    const measurements: Recipe_recipe_products_foods_food_measurementInfo = {
      __typename: "MeasurementInfo",
      serving: r.measurementInfo?.serving ? r.measurementInfo.serving : 0.0,
      cup: r.measurementInfo?.cup ? r.measurementInfo.cup : 0.0,
      tablespoon: r.measurementInfo?.tablespoon
        ? r.measurementInfo.tablespoon
        : 0.0,
      teaspoon: r.measurementInfo?.teaspoon ? r.measurementInfo.teaspoon : 0.0,
      whole: r.measurementInfo?.whole ? r.measurementInfo.whole : 0.0,
    };
    const fr = getFractionWithServing(measurements, standard, r.quantity);

    return r.nutrition.kcal * frac * fr;
  });
  const productsTotal =
    allProd.length > 0 ? allProd.reduce((sum, p) => sum + p) : 0;
  const allRec = form.recipes.ingredients.map((r) => r.nutrition.kcal * frac);

  const recipesTotal =
    allRec.length > 0 ? allRec.reduce((sum, p) => sum + p) : 0;

  return productsTotal + recipesTotal;
};
