import {
  Dialog,
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { SubmitButton, TrashButton } from "../../../../components/Buttons";
import {
  aestheticBackground,
  backgroundContainer,
} from "../../../../components/layouts/Colors";
import {
  GridRowTwoItems,
  GridRowOneItem,
} from "../../../../components/layouts/Grids";
import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../components/forms/singleForms/FormField";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import { emptyComposable } from "../../../../components/defaults/composables/ComposablesInput";
import { useState } from "react";
import { useRecipesQuery } from "../../../chefsbase/recipesAndIngredients/api";
import { recipes } from "../../../chefsbase/recipesAndIngredients/types/recipes";
import { UpdateMenuVariables } from "../../../chefsbase/menus/addMenu/types/UpdateMenu";
import { readFileAsDataURL } from "../../components/imgReader/ReadFileAsDataUrl";
import { recipeResultToQuantityToRecipe } from "../../components/mappers/RecipeToQuantityToRecipes";
import { ImageSection } from "./DishCard/ImageSection";
import {
  RecipeAndIngredientsForComposable,
  RecipeAndIngredientsForComposable1,
  RecipeOrFood,
} from "./RecipesAndIngredientsForComposable";
import {
  mapToAddMenuVariables,
  mapToUpdateMenuVariables,
} from "../../../chefsbase/menus";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import {
  ComposableRoundInput,
  ComposablesInput,
  DishIngredientsInput,
  IngredientToQuantityToSalePriceInput,
} from "../../../../globalTypes";
import { EditableRecipesAndIngredients } from "./DishCard/RecipeAndIngredients";
import { BaseOrVar, QuantityToRecipe } from "../../types/types";
import { emptyIngredientToQuantityToSalePriceInput } from "../../../../components/defaults/baseProd/IngredientToQuantityToSalePriceInput";

export const ComposableDishDialog = ({
  open,
  setopen,
  form,
  setForm,
  index,
  i,
}: {
  i: number;
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  const [recipes, setRecipes] = useState<QuantityToRecipe[]>([]);
  const { data, loading } = useRecipesQuery({
    onCompleted: (result: recipes) =>
      setRecipes(recipeResultToQuantityToRecipe(result)),
  });

  const [imageUrls, setImageUrls] = useState<string[]>(
    returnStringsIfNotNullOrUndefined(form.courses[index].composables[i].img)
  );

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        const oldCourses = [...form.courses];
        const oldComposables = [...oldCourses[index].composables];
        oldComposables[i] = {
          ...oldComposables[i],
          img: urls,
        };
        oldCourses[index] = {
          ...oldCourses[index],
          composables: oldComposables,
        };

        setForm({
          ...form,
          courses: oldCourses,
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };
  const [initial, setinitial] = useState(
    form.courses[index].composables[i].name === "Create Your Own"
  );
  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: aestheticBackground }}>
        <DialogTitle align="center">{`Add a Customizable Dish`}</DialogTitle>
        <DialogTitle align="center">
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <SubmitButton
                color="primary"
                disabled={
                  form.courses[index].composables[i].name === "Create Your Own"
                }
                sx={{ mt: 2, borderRadius: 28 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  const oldCourses = [...form.courses];
                  const oldComposables = [...oldCourses[index].composables];
                  initial && oldComposables.push(emptyComposable);
                  oldCourses[index] = {
                    ...oldCourses[index],
                    composables: oldComposables,
                  };
                  setForm({
                    ...form,
                    courses: oldCourses,
                  });
                  setopen(false);
                }}
              >
                Submit
              </SubmitButton>
            }
          />
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <Stack direction="column" spacing={1}>
            <NameAndTypeRow
              composable={form.courses[index].composables[i]}
              changeName={(a) => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  name: a,
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
              changeType={(a) => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  type: a,
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
            />
            <GridRowOneItem
              child={
                <FormFieldInputAdornmentNumber
                  label="Base Price"
                  input="€"
                  value={String(form.courses[index].composables[i].basePrice)}
                  setValue={(a) => {
                    const oldcomposables = [...form.courses[index].composables];
                    oldcomposables[i] = {
                      ...oldcomposables[i],
                      basePrice: Number(a),
                    };
                    const oldCourses = [...form.courses];
                    oldCourses[index] = {
                      ...oldCourses[index],
                      composables: oldcomposables,
                    };
                    setForm({
                      ...form,
                      courses: oldCourses,
                    });
                  }}
                />
              }
              before={0.5}
              after={0}
            />
            {form.courses[index].composables[i].composableRounds.map(
              (round, roundNumber) => (
                <AddNewComposableRound
                  removeRound={() => {
                    const oldCourses = [...form.courses];
                    const oldComposables = [...oldCourses[index].composables];
                    const oldRounds = [...oldComposables[i].composableRounds];
                    oldRounds.splice(roundNumber, 1);

                    oldComposables[i] = {
                      ...oldComposables[i],
                      composableRounds: oldRounds,
                    };
                    oldCourses[index] = {
                      ...oldCourses[index],
                      composables: oldComposables,
                    };
                    setForm({
                      ...form,
                      courses: oldCourses,
                    });
                  }}
                  i={i}
                  form={form}
                  setForm={setForm}
                  loading={loading}
                  recipes={recipes}
                  index={index}
                  roundNumber={roundNumber}
                  key={roundNumber}
                  round={round}
                  setRound={(a) => {
                    const oldCourses = [...form.courses];
                    const oldComposables = [...oldCourses[index].composables];
                    const oldRounds = [...oldComposables[i].composableRounds];
                    oldRounds[roundNumber] = a;
                    oldComposables[i] = {
                      ...oldComposables[i],
                      composableRounds: oldRounds,
                    };
                    oldCourses[index] = {
                      ...oldCourses[index],
                      composables: oldComposables,
                    };
                    setForm({
                      ...form,
                      courses: oldCourses,
                    });
                  }}
                />
              )
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                const oldRounds = [...oldComposables[i].composableRounds];
                oldRounds.push(emptyComposableRound(oldRounds.length + 1));
                oldComposables[i] = {
                  ...oldComposables[i],
                  composableRounds: oldRounds,
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
            >
              + Another Round
            </Button>
            <ImageSection
              handleChangeFile={handleChangeFile}
              imageUrls={imageUrls}
              urls={returnStringsIfNotNullOrUndefined(
                form.courses[index].composables[i].img
              )}
              setNoPic={() => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  img: [""],
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => setopen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
interface AddComposableRoundProps {
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  loading: boolean;
  recipes: QuantityToRecipe[];
  i: number;
  index: number;
  roundNumber: number;
  round: ComposableRoundInput;
  setRound: (a: ComposableRoundInput) => void;
  removeRound: () => void;
}

export const emptyComposableRound = (no: number): ComposableRoundInput => {
  return {
    number: no,
    name: "Round",
    recipes: [],
    foods: [],
  };
};

export const AddNewComposableRound: React.FC<AddComposableRoundProps> = ({
  setRound,
  recipes,
  loading,
  round,
  removeRound,
}) => {
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <GridRowTwoItems
        before={0}
        firstlength={5}
        inbetween={1}
        secondlength={5}
        firstchild={
          <FormField
            label="Name this round"
            value={round.name}
            setValue={(a) => {
              setRound({
                ...round,
                name: a,
              });
            }}
          />
        }
        secondchild={
          <Box sx={{ mt: 1 }}>
            Remove Round
            <TrashButton onClick={removeRound} />
          </Box>
        }
      />
      <RecipeAndIngredientsForComposable
        title="Ingredients"
        round={round}
        setRound={(a) => setRound(a)}
        recipes={recipes}
        loadingRecipes={loading}
        addToRound={(a) => {
          const oldRecipes = [...round.recipes];
          oldRecipes.push(emptyIngredientToQuantityToSalePriceInput);
          const oldFoods = [...round.foods];
          oldFoods.push(emptyIngredientToQuantityToSalePriceInput);
          a === RecipeOrFood.RECIPE &&
            setRound({
              ...round,
              recipes: oldRecipes,
            });
          a === RecipeOrFood.FOOD &&
            setRound({
              ...round,
              foods: oldFoods,
            });
        }}
      />
    </Box>
  );
};
export const AddComposableDishDialog = ({
  open,
  setopen,
  form,
  setForm,
  index,
}: {
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  const [currentComposable, setCurrentcomposable] = useState(emptyComposable);
  const i = form.courses[index].composables.length;
  const [recipes, setRecipes] = useState<QuantityToRecipe[]>([]);
  const { data, loading } = useRecipesQuery({
    onCompleted: (result: recipes) =>
      setRecipes(recipeResultToQuantityToRecipe(result)),
  });

  const [imageUrls, setImageUrls] = useState<string[]>([""]);

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        setCurrentcomposable({
          ...currentComposable,
          img: urls,
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: aestheticBackground }}>
        <DialogTitle align="center">{`Add a Customizable Dish`}</DialogTitle>
        <DialogTitle align="center">
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <SubmitButton
                color="primary"
                disabled={currentComposable.name === "Create Your Own"}
                sx={{ mt: 2, borderRadius: 28 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  const oldCourses = [...form.courses];
                  const oldComposables = [...oldCourses[index].composables];
                  oldComposables.push(currentComposable);
                  oldCourses[index] = {
                    ...oldCourses[index],
                    composables: oldComposables,
                  };
                  setForm({
                    ...form,
                    courses: oldCourses,
                  });
                  setopen(false);
                }}
              >
                Submit
              </SubmitButton>
            }
          />
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <Stack direction="column" spacing={1}>
            <NameAndTypeRow
              composable={currentComposable}
              changeName={(a) => {
                setCurrentcomposable({
                  ...currentComposable,
                  name: a,
                });
              }}
              changeType={(a) => {
                setCurrentcomposable({
                  ...currentComposable,
                  type: a,
                });
              }}
            />
            <GridRowOneItem
              child={
                <FormFieldInputAdornmentNumber
                  label="Base Price"
                  input="€"
                  value={String(currentComposable.basePrice)}
                  setValue={(a) => {
                    setCurrentcomposable({
                      ...currentComposable,
                      basePrice: Number(a),
                    });
                  }}
                />
              }
              before={0.5}
              after={0}
            />
            {currentComposable.composableRounds.map((round, roundNumber) => (
              <AddNewComposableRound
                removeRound={() => {
                  const old = [...currentComposable.composableRounds];
                  old.splice(roundNumber, 1);
                  setCurrentcomposable({
                    ...currentComposable,
                    composableRounds: old,
                  });
                }}
                i={i}
                form={form}
                setForm={setForm}
                loading={loading}
                recipes={recipes}
                index={index}
                roundNumber={roundNumber}
                key={roundNumber}
                round={round}
                setRound={(a) => {
                  const oldRounds = [...currentComposable.composableRounds];
                  oldRounds[roundNumber] = a;
                  setCurrentcomposable({
                    ...currentComposable,
                    composableRounds: oldRounds,
                  });
                }}
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const oldRounds = [...currentComposable.composableRounds];
                oldRounds.push(emptyComposableRound(oldRounds.length + 1));
                setCurrentcomposable({
                  ...currentComposable,
                  composableRounds: oldRounds,
                });
              }}
            >
              + Another Round
            </Button>
            <ImageSection
              handleChangeFile={handleChangeFile}
              imageUrls={imageUrls}
              urls={returnStringsIfNotNullOrUndefined(currentComposable.img)}
              setNoPic={() => {
                // const oldCourses = [...form.courses];
                // const oldComposables = [...oldCourses[index].composables];
                // oldComposables[i] = {
                //   ...oldComposables[i],
                //   img: [""],
                // };
                // oldCourses[index] = {
                //   ...oldCourses[index],
                //   composables: oldComposables,
                // };
                // setForm({
                //   ...form,
                //   courses: oldCourses,
                // });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            color="primary"
            variant="contained"
            onClick={() => setopen(false)}
          >
            Close
          </SubmitButton>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export const ComposableDishDialogUpdate = ({
  open,
  setopen,
  form,
  setForm,
  index,
  i,
}: {
  i: number;
  index: number;
  form: UpdateMenuVariables;
  setForm: (a: UpdateMenuVariables) => void;
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  const [recipes, setRecipes] = useState<QuantityToRecipe[]>([]);
  const { data, loading } = useRecipesQuery({
    onCompleted: (result: recipes) =>
      setRecipes(recipeResultToQuantityToRecipe(result)),
  });

  const [imageUrls, setImageUrls] = useState<string[]>(
    returnStringsIfNotNullOrUndefined(form.courses[index].composables[i].img)
  );

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        const oldCourses = [...form.courses];
        const oldComposables = [...oldCourses[index].composables];
        oldComposables[i] = {
          ...oldComposables[i],
          img: urls,
        };
        oldCourses[index] = {
          ...oldCourses[index],
          composables: oldComposables,
        };

        setForm({
          ...form,
          courses: oldCourses,
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };
  const [initial, setinitial] = useState(
    form.courses[index].composables[i].name === "Create Your Own"
  );
  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: backgroundContainer }}>
        <DialogTitle align="center">{`Add a Customizable Dish`}</DialogTitle>
        <DialogTitle align="center">
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <SubmitButton
                color="primary"
                disabled={
                  form.courses[index].composables[i].name === "Create Your Own"
                }
                sx={{ mt: 2, borderRadius: 28 }}
                fullWidth
                variant="contained"
                onClick={() => {
                  const oldCourses = [...form.courses];
                  const oldComposables = [...oldCourses[index].composables];
                  initial && oldComposables.push(emptyComposable);
                  oldCourses[index] = {
                    ...oldCourses[index],
                    composables: oldComposables,
                  };
                  setForm({
                    ...form,
                    courses: oldCourses,
                  });
                  setopen(false);
                }}
              >
                Submit
              </SubmitButton>
            }
          />
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <Stack direction="column" spacing={1}>
            <NameAndTypeRow
              composable={form.courses[index].composables[i]}
              changeName={(a) => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  name: a,
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
              changeType={(a) => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  type: a,
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
            />
            <GridRowOneItem
              child={
                <FormFieldInputAdornmentNumber
                  label="Base Price"
                  input="€"
                  value={String(form.courses[index].composables[i].basePrice)}
                  setValue={(a) => {
                    const oldcomposables = [...form.courses[index].composables];
                    oldcomposables[i] = {
                      ...oldcomposables[i],
                      basePrice: Number(a),
                    };
                    const oldCourses = [...form.courses];
                    oldCourses[index] = {
                      name: oldCourses[index].name,
                      selectables: oldCourses[index].selectables,
                      composables: oldcomposables,
                    };
                    setForm({
                      ...form,
                      courses: oldCourses,
                    });
                  }}
                />
              }
              before={0.5}
              after={0}
            />

            <ImageSection
              handleChangeFile={handleChangeFile}
              imageUrls={imageUrls}
              urls={returnStringsIfNotNullOrUndefined(
                form.courses[index].composables[i].img
              )}
              setNoPic={() => {
                const oldCourses = [...form.courses];
                const oldComposables = [...oldCourses[index].composables];
                oldComposables[i] = {
                  ...oldComposables[i],
                  img: [""],
                };
                oldCourses[index] = {
                  ...oldCourses[index],
                  composables: oldComposables,
                };
                setForm({
                  ...form,
                  courses: oldCourses,
                });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setopen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

const NameAndTypeRow = ({
  composable,
  changeName,
  changeType,
}: {
  composable: ComposablesInput;
  changeName: (a: string) => void;
  changeType: (a: string) => void;
}) => {
  return (
    <GridRowTwoItems
      before={0.5}
      firstlength={5}
      inbetween={0.5}
      secondlength={4}
      firstchild={
        <FormField
          label="Name your Dish"
          value={composable.name}
          setValue={changeName}
        />
      }
      secondchild={
        <FormField
          label="Type if Dish"
          value={composable.type}
          setValue={changeType}
        />
      }
    />
  );
};

const NameAndType = ({
  composableForm,
  setComposable,
}: {
  composableForm: ComposablesInput;
  setComposable: (a: ComposablesInput) => void;
}) => {
  return (
    <GridRowTwoItems
      before={0.5}
      firstlength={5}
      inbetween={0.5}
      secondlength={4}
      firstchild={
        <FormField
          label="Name your Dish"
          value={composableForm.name}
          setValue={(a) => {
            setComposable({ ...composableForm, name: a });
          }}
        />
      }
      secondchild={
        <FormField
          label="Type of Dish"
          value={composableForm.type}
          setValue={(a) => {
            setComposable({ ...composableForm, type: a });
          }}
        />
      }
    />
  );
};

export const ComposableDialog = ({
  composableForm,
  open,
  setopen,
  setComposable,
  pushComposable,
  title,
  recipes,
}: {
  recipes: QuantityToRecipe[];
  pushComposable: () => void;
  composableForm: ComposablesInput;
  title: string;
  setComposable: (a: ComposablesInput) => void;
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>(
    returnStringsIfNotNullOrUndefined(composableForm.img)
  );

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setComposable({
          ...composableForm,
          img: urls,
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: backgroundContainer }}>
        <DialogTitle align="center">{title}</DialogTitle>
        <DialogTitle align="center">
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <SubmitButton
                color="primary"
                disabled={composableForm.name === "Create Your Own"}
                sx={{ mt: 2, borderRadius: 28 }}
                fullWidth
                variant="contained"
                onClick={pushComposable}
              >
                Submit
              </SubmitButton>
            }
          />
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <Stack direction="column" spacing={1}>
            <NameAndType
              composableForm={composableForm}
              setComposable={setComposable}
            />
            <GridRowOneItem
              child={
                <FormFieldInputAdornmentNumber
                  label="Base Price"
                  input="€"
                  value={String(composableForm.basePrice)}
                  setValue={(a) => {
                    setComposable({ ...composableForm, basePrice: Number(a) });
                  }}
                />
              }
              before={0.5}
              after={0}
            />
            <GridRowTwoItems
              before={0.5}
              firstlength={5.5}
              inbetween={0.5}
              secondlength={5.5}
              firstchild={
                <EditableRecipesAndIngredients
                  baseOrVar={BaseOrVar.BASE}
                  title="Base Products"
                  recipesFromForm={composableForm.composableRounds
                    .map((a) => a.foods)
                    .flat()}
                  productsFromForm={composableForm.composableRounds
                    .map((a) => a.foods)
                    .flat()}
                  setrecipes={(a) => {}}
                  setproducts={(a) => {}}
                  recipes={recipes}
                />
              }
              secondchild={
                <EditableRecipesAndIngredients
                  baseOrVar={BaseOrVar.VAR}
                  title="To Add:"
                  recipesFromForm={composableForm.composableRounds
                    .map((a) => a.foods)
                    .flat()}
                  productsFromForm={composableForm.composableRounds
                    .map((a) => a.foods)
                    .flat()}
                  setrecipes={
                    (a) => {}
                    // setComposable({ ...composableForm, varProd: a })
                  }
                  setproducts={
                    (a) => {}
                    // setComposable({ ...composableForm, varProd: a })
                  }
                  recipes={recipes}
                />
              }
            />
            <ImageSection
              handleChangeFile={handleChangeFile}
              imageUrls={imageUrls}
              urls={returnStringsIfNotNullOrUndefined(composableForm.img)}
              setNoPic={() => setComposable({ ...composableForm, img: [] })}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => setopen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
