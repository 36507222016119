import { Box, Button, TableCell } from "@mui/material";
import React, { useState } from "react";
import { VscEdit, VscCheck, VscAdd } from "react-icons/vsc";
import { SubmitButton, TrashButton } from "../../../../components/Buttons";
import {
  FormField,
  FormFieldInputAdornmentNumber,
} from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowFourItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { MeasureInput, PortionInput } from "../../../../globalTypes";
import { DishInfoForm } from "../addDish";
import {
  findMeasure,
  measureInputOptions,
} from "../../../../components/defaults/portions/PortionInput";
import { FormQuantity } from "../../recipes/addrecipes/CreateYourRecipe";

export const DisplayPortions = ({
  unit,
  edit,
  setportions,
  portions,
}: {
  unit: MeasureInput;
  edit: boolean;
  setportions: (a: PortionInput[]) => void;
  portions: PortionInput[];
}) => {
  return (
    <>
      {(!edit
        ? portions.sort((a, b) => (a.price ?? 0) - (b.price ?? 0))
        : portions
      ).map((p, index) => (
        <RowWrapped
          edit={edit}
          unit={unit}
          p={p}
          index={index}
          portions={portions}
          setportions={setportions}
        />
      ))}
      {edit && (
        <Button
          sx={{ borderRadius: 28 }}
          color="inherit"
          variant="outlined"
          onClick={() => {
            setportions([
              ...portions,
              {
                name: "Size",
                price: portions.length,
                quantity: {
                  quantity: portions[0]
                    ? portions[0].quantity.quantity * portions.length
                    : 100,
                  unit: unit,
                },
              },
            ]);
          }}
        >
          <VscAdd />
          Add portion
        </Button>
      )}
    </>
  );
};

export const RowWrapped = ({
  edit,
  p,
  setportions,
  index,
  portions,
  unit,
}: {
  index: number;
  unit: MeasureInput;
  edit: boolean;
  setportions: (a: PortionInput[]) => void;
  portions: PortionInput[];
  p: PortionInput;
}) => {
  const [amount, setamount] = useState(String(p.quantity.quantity));
  return (
    <GridRowFourItems
      key={index}
      before={0.5}
      firstchild={
        <>
          {!edit ? (
            <>{p.name}</>
          ) : (
            <FormField
              value={p.name}
              setValue={(a) => {
                const old = [...portions];
                old[index] = {
                  ...old[index],
                  name: a,
                };
                setportions(old);
              }}
            />
          )}
        </>
      }
      secondchild={
        <>
          {!edit ? (
            <>
              {p.quantity.quantity} {p.quantity.unit.label}
            </>
          ) : (
            <FormQuantity
              amount={amount}
              setamount={setamount}
              value={p.quantity}
              options={measureInputOptions(unit.label).map((t) => ({
                name: t.label,
                id: t.uri,
              }))}
              setValue={(a) => {
                const old = [...portions];
                old[index] = {
                  ...old[index],
                  quantity: a,
                };
                setportions(old);
              }}
            />
          )}
        </>
      }
      firstlength={2}
      secondlength={4}
      thirdlength={2}
      inbetweenfirsttwo={1}
      inbetweensecondtwo={1}
      thirdchild={
        <>
          {!edit ? (
            <>{`€${p.price?.toFixed(2)}`}</>
          ) : (
            <FormFieldInputAdornmentNumber
              w={"0.4"}
              input="€"
              value={String(p.price)}
              setValue={(a) => {
                const old = [...portions];
                old[index] = {
                  ...old[index],
                  price: Number(a),
                };
                setportions(old);
              }}
            />
          )}
        </>
      }
      fourthchild={
        <Box sx={{ mt: 1 }}>
          {edit && (
            <TrashButton
              onClick={() => {
                const old = [...portions];
                old.splice(index, 1);
                setportions(old);
              }}
            />
          )}
        </Box>
      }
      fourthlength={1}
      inbetweenthirdtwo={0.5}
    />
  );
};

export const PortionsRow = ({
  numberofportions,
  setInfo,
  info,
}: {
  numberofportions: number;
  setInfo: (a: DishInfoForm) => void;
  info: DishInfoForm;
}) => {
  return (
    <>
      {info.portions
        .map((p) => p.name)
        .map(
          (i, index) =>
            index < numberofportions && (
              <GridRowFourItems
                before={1.5}
                inbetweenfirsttwo={0.5}
                inbetweensecondtwo={0.5}
                inbetweenthirdtwo={0.5}
                firstlength={2}
                secondlength={2}
                thirdlength={2}
                fourthlength={2}
                firstchild={
                  <FormField
                    value={i}
                    setValue={(name) => {
                      const newSel = [...info.portions];
                      newSel[index] = {
                        price: newSel[index].price,
                        name: name,
                        quantity: newSel[index].quantity,
                      };
                      setInfo({
                        name: info.name,
                        type: info.type,
                        hoursWorked: info.hoursWorked,
                        portions: newSel,
                        options: info.options,
                        urls: info.urls,
                      });
                    }}
                  />
                }
                secondchild={
                  <FormField
                    num={true}
                    value={String(info.portions[index].quantity.quantity)}
                    setValue={(name) => {
                      const newSel = [...info.portions];
                      newSel[index] = {
                        price: newSel[index].price,
                        name: newSel[index].name,
                        quantity: {
                          quantity: Number(name),
                          unit: newSel[index].quantity.unit,
                        },
                      };
                      setInfo({
                        name: info.name,
                        type: info.type,
                        hoursWorked: info.hoursWorked,
                        portions: newSel,
                        options: info.options,
                        urls: info.urls,
                      });
                    }}
                  />
                }
                thirdchild={
                  <FormSelect
                    mt={1}
                    value={info.portions[index].quantity.unit.label}
                    options={measureInputOptions(undefined, 0)
                      .map((i) => i.label)
                      .map((i) => ({
                        name: String(i),
                        id: String(i),
                      }))}
                    setValue={(name) => {
                      const newSel = [...info.portions];
                      newSel[index] = {
                        price: newSel[index].price,
                        name: newSel[index].name,
                        quantity: {
                          quantity: newSel[index].quantity.quantity,
                          unit: findMeasure(name, 0),
                        },
                      };
                      setInfo({
                        name: info.name,
                        type: info.type,
                        hoursWorked: info.hoursWorked,
                        portions: newSel,
                        options: info.options,
                        urls: info.urls,
                      });
                    }}
                  />
                }
                fourthchild={
                  <FormFieldInputAdornmentNumber
                    input="€"
                    value={String(info.portions[index].price)}
                    setValue={(name) => {
                      const newSel = [...info.portions];
                      newSel[index] = {
                        ...newSel[index],
                        price: Number(name),
                      };
                      setInfo({
                        name: info.name,
                        type: info.type,
                        hoursWorked: info.hoursWorked,
                        portions: newSel,
                        options: info.options,
                        urls: info.urls,
                      });
                    }}
                  />
                }
              />
            )
        )}
    </>
  );
};

export const PortionsCell = ({
  editPortions,
  setOpenEditPortions,
  info,
  setPortions,
  setInfo,
}: {
  setPortions: (a: PortionInput[]) => void;
  setInfo: (a: DishInfoForm) => void;
  info: DishInfoForm;
  editPortions: boolean;
  setOpenEditPortions: (a: boolean) => void;
}) => {
  return (
    <TableCell>
      <>
        {editPortions === false ? (
          <>
            {info.portions.map((p, i) => (
              <>
                <li key={p.name}>{`${p.name} (${p.quantity.quantity.toFixed(
                  2
                )} ${p.quantity.unit} for €${p.price?.toFixed(2)})`}</li>
                {i === info.portions.length - 1 && (
                  <VscEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenEditPortions(!editPortions)}
                  />
                )}
              </>
            ))}
          </>
        ) : (
          <GridRowTwoItems
            firstchild={
              <SubmitButton
                variant="outlined"
                onClick={() => setOpenEditPortions(!editPortions)}
              >
                <VscCheck />
              </SubmitButton>
            }
            secondchild={
              <PortionsRow
                numberofportions={info.portions.length}
                info={info}
                setInfo={(a: DishInfoForm) => setInfo(a)}
              />
            }
            firstlength={1}
            secondlength={11}
            before={0}
            inbetween={0}
          />
        )}
      </>
    </TableCell>
  );
};
