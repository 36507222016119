import { gql, useMutation } from "@apollo/client";
import { AddFood, AddFoodVariables } from "./types/AddFood";
import { useSimpleQuery } from "../../../utils/apollo";
import { dishesQuery } from "../dishes/api";
import { MyFoods } from "./types/MyFoods";
import { foods } from "./types/foods";
import { pgadminfix, pgadminfixVariables } from "./types/pgadminfix";

const myFoodsQuery = gql`
  query MyFoods {
    myfoods {
      name
      id
      img
      nutrition {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        nutrition {
          kcal
        }
      }
      price {
        supplier {
          name
          id
        }
        amountPerQuantity {
          amount
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
      }
    }
  }
`;

const AddFoodMutation = gql`
  mutation AddFood($input: FoodInput!, $healthLabelInfo: HealthLabelInfo!) {
    addFood(input: $input, healthLabelInfo: $healthLabelInfo)
  }
`;
export const useMyFoodsQuery = ({
  onCompleted,
}: {
  onCompleted: (result: MyFoods) => void;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(myFoodsQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error, refetch };
};
const PgAdminMutation = gql`
  mutation pgadminfix($products: [QuantityToNameIdAndNutritionInput!]!) {
    pgadminfix(products: $products)
  }
`;

const FoodsQuery = gql`
  query foods {
    foods
  }
`;

export const useAddFoodMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addFood, { loading, error }] = useMutation<AddFood, AddFoodVariables>(
    AddFoodMutation,
    {
      onCompleted: () => onCompleted(),
    }
  );
  return {
    addFood,
    loading,
    error,
  };
};

export const usePgAdminMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [mutate, { loading, error }] = useMutation<
    pgadminfix,
    pgadminfixVariables
  >(PgAdminMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    mutate,
    loading,
    error,
  };
};

export const useGetAllFoods = ({
  onCompleted,
}: {
  onCompleted: (result: foods) => void;
}) => {
  const { loading, data } = useSimpleQuery(FoodsQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });

  return { loading, data };
};
