import {
  zerohealthlabelinfo,
  zeronutrition,
} from "../../../screens/chefsbase/dishes/components/EmptyVariables";
import {
  Dish_dish_products_foods,
  Dish_dish_products_foods_food,
  Dish_dish_recipes,
} from "../../../screens/chefsbase/dishes/types/Dish";
import { DishItems_dish_recipes_recipes } from "../../../screens/chefsbase/dishes/types/DishItems";
import { gramMeasure } from "../portions/PortionInput";

export const emptyRecipeForDish: Dish_dish_recipes = {
  __typename: "DishPortionToRecipes",
  dishPortion: {
    __typename: "Portion",
    name: "Small",
    quantity: {
      __typename: "Quantity",
      unit: gramMeasure,
      quantity: 100,
    },
  },
  nutrition: zeronutrition,
  recipes: [],
};
export const emptyRecipeForDishItem: DishItems_dish_recipes_recipes = {
  __typename: "Recipe",
  name: "",
  id: "",
  quantity: {
    __typename: "Quantity",
    unit: gramMeasure,
    quantity: 100,
  },
};

export const emptyFood: Dish_dish_products_foods_food = {
  __typename: "Food",
  healthLabelInfo: zerohealthlabelinfo,
  measurementInfo: null,
  price: null,
  id: "",
  name: "",
  img: "",
  nutrition: {
    __typename: "QuantityToNutrition",
    nutrition: zeronutrition,
    quantity: {
      __typename: "Quantity",
      quantity: 0,
      unit: gramMeasure,
    },
  },
};
export const emptyFoodResult: Dish_dish_products_foods = {
  __typename: "QuantityToFood",
  food: emptyFood,
  quantity: {
    __typename: "Quantity",
    quantity: 0,
    unit: gramMeasure,
  },
};
