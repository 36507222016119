import {
  MicroMacroField,
  MinMax,
} from "../../../screens/clientbase/components/MicroMacroField/types";

export const zeroToFiveThousand: MinMax = {
  min: 0,
  max: 5000,
};

export const zeroToFifty: MinMax = {
  min: 0,
  max: 50,
};

export const zeroToFivehundred: MinMax = {
  min: 0,
  max: 500,
};

export const startMicroMacroForm: MicroMacroField = {
  kcal: zeroToFiveThousand,
  prottotal: zeroToFivehundred,
  carbs: zeroToFivehundred,
  fats: zeroToFivehundred,
  price: zeroToFifty,
};
