import {
  Table,
  TableRow,
  TableCell,
  DialogContent,
  Dialog,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  ButtonGroup,
  Stack,
} from "@mui/material";
import Axios from "axios";
import React, { useState } from "react";
import { MainBox, BoxTitle } from "../../../components/Boxes";
import { SubmitButton } from "../../../components/Buttons";
import {
  TextOrField,
  TextOrFieldEuro,
  TextOrQuantity,
} from "../../../components/forms/formOrEdit/FormFieldEdit";
import { FormField } from "../../../components/forms/singleForms/FormField";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../components/layouts/Grids";
import {
  NutritionInput,
  OptionInput,
  OptionsInput,
  QuantityInput,
} from "../../../globalTypes";
import { ProductForm } from "../products/addProduct";
import { AddProductVariables } from "../products/addProduct/types/AddProduct";
import {
  NutritionApi,
  NutritionItem,
  TotalIngredientType,
} from "../../../components/edamam/types";
import {
  DishItems_dish_products_foods,
  DishItems_dish_recipes_recipes,
} from "../dishes/types/DishItems";
import { small } from "../../../components/defaults/portions/PortionInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  passAllergies,
  passMicroMacroForm,
  passOptions,
} from "../../clientbase/components/courses/DisplayDishButton";
import { createRange } from "../../../components/utils/CreateRange";
import { roundToZeroOr2 } from "../../../components/utils/DisplayQuantity";
import { MicroMacroField } from "../../clientbase/components/MicroMacroField/types";
import { sortPortionsWithNutrition } from "../dishes/dishDialog/DishContent";
import { PortionWithNutrition } from "../../clientbase/types/types";
import { NutritionBox } from "../../clientbase/components/composables/DialogComposable";

const appKey = "f5a92edd5859ca4e82a2004e59b2a227";
const appId = "ef2483f5";
type IngredientParameter = {
  quantity: number;
  measureURI: string;
  qualifiers: string[];
  foodId: string;
};
export type Parameters = {
  ingredients: IngredientParameter[];
};
type Product = {
  measures: [
    {
      label: string;
      uri: string;
      weight: number;
    }
  ];
  food: {
    label: string;
    foodId: string;
    category: string;
    image: string;
    nutrients: { ENERC_KCAL: number; FAT: number };
  };
};
export const configNutr = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  params: {
    app_id: "ef2483f5",
    app_key: "f5a92edd5859ca4e82a2004e59b2a227",
  },
};
export const ProductsApi = withAuthenticationRequired(() => {
  const [name, setName] = useState("");
  const [filteredproducts, setproducts] = useState<Product[]>([]);

  const url = `https://api.edamam.com/api/food-database/v2/parser?app_id=${appId}&app_key=${appKey}&ingr=${name}`;

  const getData = async () => {
    if (name !== "") {
      const result = await Axios.get(url);

      result &&
        result.data &&
        result.data.hints &&
        setproducts(result.data.hints);
    } else {
    }
  };
  return (
    <>
      <BoxTitle title="Products" />
      <div style={{ height: 300, width: "100%" }}>
        <TopRow
          submitForm={getData}
          name={name}
          setNameFilter={(a: string) => {}}
          setname={(a: string) => setName(a)}
        />
        <Table>
          {filteredproducts &&
            filteredproducts.length > 0 &&
            filteredproducts.map((form, i) => (
              <ProductRow form={form} setProduct={(a: Product) => {}} />
            ))}
        </Table>
      </div>
    </>
  );
});
const TopRow = ({
  name,
  setname,
  setNameFilter,
  submitForm,
}: {
  submitForm: () => void;
  name: string;
  setname: (a: string) => void;
  setNameFilter: (a: string) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>Search Product:</TableCell>
        <TableCell>
          <FormField
            value={name}
            setValue={(a) => {
              setname(a);
              setNameFilter(a);
            }}
          />
        </TableCell>
        <TableCell sx={{ width: 500 }}>
          <SubmitButton
            fullWidth
            variant="contained"
            onClick={() => submitForm()}
          >
            Submit changes
          </SubmitButton>
        </TableCell>
      </TableRow>
    </>
  );
};
export const emptykcal = {
  label: "Kcal",
  quantity: 0,
  unit: "g",
};
export const emptyprot = {
  label: "Proteins",
  quantity: 0,
  unit: "g",
};
export const emptyfat = {
  label: "Fats",
  quantity: 0,
  unit: "g",
};
export const emptysatured = {
  label: "Satured fats",
  quantity: 0,
  unit: "g",
};
export const emptyfibres = {
  label: "Fibres",
  quantity: 0,
  unit: "g",
};
export const emptySugar = {
  label: "Sugars",
  quantity: 0,
  unit: "g",
};
export const emptyCarbs = {
  label: "Carbs",
  quantity: 0,
  unit: "g",
};
export const emptyNa = {
  label: "Natrium (salt)",
  quantity: 0,
  unit: "g",
};

export const ProductRow = ({
  form,
  setProduct,
}: {
  form: Product;
  setProduct: (a: Product) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [dat, setData] = useState<Parameters>({ ingredients: [] });
  const [nutr, setNutrition] = useState<NutritionApi>({
    ENERC_KCAL: emptykcal,
    FASAT: emptysatured,
    FAT: emptyfat,
    SUGAR: emptySugar,
    CHOCDF: emptyCarbs,
    FIBTG: emptyfibres,
    PROCNT: emptyprot,
    NA: emptyNa,
    quantity: small.quantity,
    healthLabels: undefined,
  });

  const getNutr = async (dataInput: Parameters) => {
    if (form.food.foodId !== null) {
      const result = await Axios.post(
        "https://api.edamam.com/api/food-database/v2/nutrients",
        dataInput,
        configNutr
      );

      setNutrition({
        ENERC_KCAL: result.data.totalNutrients.ENERC_KCAL,
        FASAT: result.data.totalNutrients.FASAT,
        FAT: result.data.totalNutrients.FAT,
        SUGAR: result.data.totalNutrients.SUGAR,
        CHOCDF: result.data.totalNutrients.CHOCDF,
        FIBTG: result.data.totalNutrients.FIBTG,
        PROCNT: result.data.totalNutrients.PROCNT,
        NA: result.data.totalNutrients.NA,
        quantity: {
          quantity: result.data.totalWeight,
          unit: small.quantity.unit,
        },
        healthLabels: result.data.healthLabels,
      });
    } else {
    }
  };
  return (
    <>
      <TableRow>
        <TableCell>{form.food.label}</TableCell>
        <TableCell>
          <TextOrField value={`€...`} setValue={(a) => {}} />
        </TableCell>
        <TableCell>{form.food.category}</TableCell>
        <TableCell
          onClick={() => {
            const dataInput = {
              ingredients: [
                {
                  quantity: 100,
                  measureURI:
                    form.measures.find((i) => i.label === "Gram") &&
                    form.measures.find((i) => i.label === "Gram")!.uri
                      ? form.measures.filter((i) => i.label === "Gram")[0].uri
                      : "http://www.edamam.com/ontologies/edamam.owl#Measure_gram",
                  qualifiers: [""],
                  foodId: form.food.foodId,
                },
              ],
            };
            setData(dataInput);
            getNutr(dataInput);
            setOpen(true);
          }}
        >
          <SubmitButton variant="contained">Open</SubmitButton>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <Dialog
        sx={{ maxWidth: "xs" }}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <ProductCard
          nutr={nutr}
          onClose={() => setOpen(false)}
          product={form}
        />
      </Dialog>
    </>
  );
};

export const ProductCard = ({
  onClose,
  product,
  nutr,
}: {
  nutr: NutritionApi;
  onClose: () => void;
  product: Product;
}) => {
  const [yes, setyes] = useState(false);
  return (
    <>
      <Card sx={{ maxWidth: "xs" }}>
        {product.food.image && product.food.image != "" && (
          <CardMedia component="img" height="250" image={product.food.image} />
        )}
        <CardContent>
          <Typography align="center" gutterBottom variant="h6" component="div">
            {product.food.label} per {nutr.quantity.quantity}{" "}
            {nutr.quantity.unit.label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <GridRowTwoItems
              before={0.5}
              firstlength={5}
              inbetween={0.5}
              secondlength={5}
              firstchild={<Typography variant="h6">Nutrition</Typography>}
              secondchild={<Typography variant="h6">Measurement</Typography>}
            />
            <GridRowOneItem before={0} after={0} child={<Divider />} />
            <DisplayNutritionItem nutr={nutr.ENERC_KCAL} />
            <DisplayNutritionItem nutr={nutr.PROCNT} />
            <DisplayNutritionItem nutr={nutr.FAT} />
            <DisplayNutritionItem nutr={nutr.FASAT} />
            <DisplayNutritionItem nutr={nutr.CHOCDF} />
            <DisplayNutritionItem nutr={nutr.SUGAR} />
            <DisplayNutritionItem nutr={nutr.NA} />
            <DisplayNutritionItem nutr={nutr.FIBTG} />
          </Typography>

          <GridRowTwoItems
            before={0.5}
            firstlength={5}
            inbetween={0.5}
            secondlength={5}
            firstchild={
              <Typography variant="h6" color="text.secondary">
                See health labels?
              </Typography>
            }
            secondchild={
              <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled elevation buttons"
              >
                <Button
                  onClick={() => setyes(true)}
                  variant={yes ? "contained" : "outlined"}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => setyes(false)}
                  variant={yes ? "outlined" : "contained"}
                >
                  No
                </Button>
              </ButtonGroup>
            }
          />
          {nutr.healthLabels && yes && (
            <DisplayHealthLabels nutr={nutr.healthLabels} />
          )}
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => onClose()}>
            Close
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export const translateUnit = (a: string): string => {
  const u = a.split("_").pop();
  return u ? u : "gram";
};

export const DisplayRecipes = ({
  recipes,
  frac,
}: {
  frac: number;
  recipes: DishItems_dish_recipes_recipes[];
}) => {
  if (recipes.length > 0)
    return (
      <>
        {recipes.map((ing) => (
          <Typography noWrap variant="body2">
            *{ing.name}
          </Typography>
        ))}
      </>
    );
};

export const DisplayIngredients = ({
  products,
  frac,
}: {
  frac: number;
  products: DishItems_dish_products_foods[];
}) => {
  if (products.length > 0)
    return (
      <>
        {products.map((ing) => (
          <Typography noWrap variant="body2">
            *{ing.food.name}
          </Typography>
        ))}
      </>
    );
};

export const DisplayNutritionTitles = () => {
  return (
    <Stack direction="column">
      <GridRowOneItem before={0} after={6} child={"Portion"} />
      <GridRowOneItem before={0} after={6} child={"Kcal"} />
      <GridRowOneItem before={0} after={6} child={"Protein"} />
      <GridRowOneItem before={0} after={6} child={"Fats"} />
      <GridRowOneItem before={0} after={6} child={"Carbs"} />
    </Stack>
  );
};
export const writePortionName = (a: string): string => {
  let result = a;
  switch (true) {
    case a.toLowerCase() === "small":
      result = "S";
      break;
    case a.toLowerCase() === "medium":
      result = "M";
      break;
    case a.toLowerCase() === "large":
      result = "L";
      break;
    case a.toLowerCase() === "extra large":
      result = "XL";
      break;
    default:
      result = a;
      break;
  }

  return result;
};
export const roundToZero = (a: number): number => Number(a.toFixed(0));
export const DisplayNutritionPortion = ({
  portion,
}: {
  portion: PortionWithNutrition;
}) => {
  return (
    <Stack direction="column">
      <GridRowOneItem
        before={3}
        after={3}
        child={writePortionName(portion.name)}
      />
      <GridRowOneItem
        before={3}
        after={3}
        child={roundToZero(portion.nutrition.kcal)}
      />
      <GridRowOneItem
        before={3}
        after={3}
        child={roundToZero(portion.nutrition.prottotal)}
      />
      <GridRowOneItem
        before={3}
        after={3}
        child={roundToZero(portion.nutrition.fatstotal)}
      />
      <GridRowOneItem
        before={3}
        after={3}
        child={roundToZero(portion.nutrition.carbscarbs)}
      />
    </Stack>
  );
};

export const DisplayNutritionPortions = ({
  portions,
  micromacroform,
  options,
  name,
  dishOptions,
}: {
  dishOptions: OptionsInput[];
  name: string;
  options: string[];
  micromacroform: MicroMacroField;
  portions: PortionWithNutrition[];
}) => {
  return (
    <>
      <DisplayNutritionTitles />
      {portions.length > 0 &&
        sortPortionsWithNutrition(portions).map((p: PortionWithNutrition) => {
          const price = p.price ? p.price : 5;
          const passesNutrition = passMicroMacroForm(
            p.nutrition,
            price,
            micromacroform
          );
          const passed2 = true;

          if (passesNutrition && passed2)
            return <DisplayNutritionPortion portion={p} />;
        })}
    </>
  );
};

export const DisplayNutritionInput = ({
  nutritionInput,
  frac,
  price,
  mb,
  direction,
}: {
  direction: string;
  mb?: number;
  price?: number;
  frac: number;
  nutritionInput: NutritionInput;
}) => {
  return (
    <>
      <NutritionBox
        added={nutritionInput}
        direction={direction}
        price={price}
        mb={mb}
      />
      {/* <GridRowTwoItems
        before={0}
        firstlength={8}
        inbetween={0.5}
        secondlength={1.5}
        firstchild={"Kcal"}
        secondchild={(frac * nutritionInput.kcal).toFixed(2)}
      />
      <GridRowTwoItems
        before={0}
        firstlength={8}
        inbetween={0.5}
        secondlength={1.5}
        firstchild={"Proteins"}
        secondchild={(frac * nutritionInput.prottotal).toFixed(2)}
      />
      <GridRowTwoItems
        before={0}
        firstlength={8}
        inbetween={0.5}
        secondlength={1.5}
        firstchild={"Fats"}
        secondchild={(frac * nutritionInput.fatstotal).toFixed(2)}
      /> */}
      {/* <GridRowTwoItems
        before={0}
        firstlength={9}
        inbetween={0.5}
        secondlength={1}
        firstchild={"(Satured)"}
        secondchild={`(${(frac * nutritionInput.saturedfat).toFixed(2)})`}
      /> */}
      {/* <GridRowTwoItems
        before={0}
        firstlength={8}
        inbetween={0.5}
        secondlength={1.5}
        firstchild={"Carbs"}
        secondchild={(frac * nutritionInput.carbscarbs).toFixed(2)}
      /> */}
      {/* <GridRowTwoItems
        before={0}
        firstlength={9}
        inbetween={0.5}
        secondlength={1}
        firstchild={"(Sugar)"}
        secondchild={`(${(frac * nutritionInput.carbssugar).toFixed(2)})`}
      /> */}
    </>
  );
};

export const DisplayNutritionItem = ({ nutr }: { nutr: NutritionItem }) => {
  return (
    <GridRowTwoItems
      before={0.5}
      firstlength={5}
      inbetween={0.5}
      secondlength={5}
      firstchild={nutr && nutr.label ? nutr.label : ""}
      secondchild={
        nutr && nutr.label && nutr.unit
          ? `${nutr.quantity.toFixed(2)} ${nutr.unit.toLowerCase()}`
          : ""
      }
    />
  );
};
export const DisplayHealthLabels = ({ nutr }: { nutr: string[] }) => {
  if (nutr.length > 0)
    return (
      <Typography variant="body2" color="text.secondary">
        {nutr.map((n) => (
          <li>{n}</li>
        ))}
      </Typography>
    );
};
