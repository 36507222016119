import { Typography } from "@mui/material";
import React from "react";

export const H1 = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h1" component="h1" gutterBottom>
      {title}
    </Typography>
  );
};
export const H1Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h1" component="h1" gutterBottom>
      {title}
    </Typography>
  );
};

export const H2 = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h2" component="h2" gutterBottom>
      {title}
    </Typography>
  );
};
export const H2Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h2" component="h2" gutterBottom>
      {title}
    </Typography>
  );
};

export const H3 = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h3" component="h3" gutterBottom>
      {title}
    </Typography>
  );
};

export const H3Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h3" component="h3" gutterBottom>
      {title}
    </Typography>
  );
};

export const H4 = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h4" component="h4" gutterBottom>
      {title}
    </Typography>
  );
};
export const H4Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h4" component="h4" gutterBottom>
      {title}
    </Typography>
  );
};

export const H5 = ({ title }: { title: string }) => {
  return (
    <Typography
      align="center"
      variant="h5"
      component="h5"
      gutterBottom
      style={{ fontFamily: "Playfair Display, serif" }}
    >
      {title}
    </Typography>
  );
};

export const H5Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h5" component="h5" gutterBottom>
      {title}
    </Typography>
  );
};
export const H6 = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h6" component="h6" gutterBottom>
      {title}
    </Typography>
  );
};

export const H6Left = ({ title }: { title: string }) => {
  return (
    <Typography align="left" variant="h6" component="h6" gutterBottom>
      {title}
    </Typography>
  );
};
