import {
  Dialog,
  Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { emptySelectable } from "../../../../components/defaults/selectables/SelectableInput";
import {
  useAddDishMutation,
  useUpdateDishMutation,
} from "../../../chefsbase/dishes/addDish/api";
import { AddDishVariables } from "../../../chefsbase/dishes/addDish/types/AddDish";
import { AddMenuVariables } from "../../../chefsbase/menus/addMenu/types/AddMenu";
import { DishCard } from "./DishCardForm";
import {
  defaultHours,
  defaultOptions,
} from "../../../../components/defaults/options/DefaultOptions";
import { useState } from "react";
import { measureInputOptions } from "../../../../components/defaults/portions/PortionInput";
import { mapAddDishToSelectable } from "../../components/mappers/selectable/MapToSelectables";
import { distinctSelectables } from "../../helpers";
import { QuantityToRecipe } from "../../types/types";

export const NewDishDialog = ({
  open,
  setopen,
  form,
  setForm,
  i,
  index,
  dishForm,
  setDishForm,
  options,
  setoptions,
  recipes,
  loadingRecipes,
}: {
  loadingRecipes: boolean;
  recipes: QuantityToRecipe[];
  options: { name: string; id: string }[];
  setoptions: (a: { name: string; id: string }[]) => void;
  open: boolean;
  setopen: (a: boolean) => void;
  i: number;
  index: number;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  dishForm: AddDishVariables;
  setDishForm: (a: AddDishVariables) => void;
}) => {
  const { addDish } = useAddDishMutation({
    onCompleted: () => {},
  });

  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: "#d4e8c2" }}>
        <DialogTitleForDish
          i={i}
          index={index}
          form={form}
          setForm={(a) => setForm(a)}
          dishForm={dishForm}
          mutate={(options) => {
            addDish(options);
            const old = [...form.courses[index].selectables];
            old.push(mapAddDishToSelectable(dishForm));
            const oldcourses = [...form.courses];
            oldcourses[index] = {
              ...form.courses[index],
              selectables: old,
            };
            setForm({
              ...form,
              courses: oldcourses,
            });
          }}
          setopen={setopen}
        />
        <DialogContent>
          <DishCard
            loadingRecipes={loadingRecipes}
            recipes={recipes}
            i={i}
            options={options}
            setoptions={setoptions}
            index={index}
            form={form}
            dishForm={dishForm}
            setDishForm={(a) => setDishForm(a)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 28 }}
            color="inherit"
            variant="outlined"
            onClick={() => setopen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export const checkForDishUpdateErorr = (
  dishForm: AddDishVariables
): boolean => {
  let result = false;
  switch (true) {
    case dishForm.input.name === "Test Dish":
      console.log("name error");
      result = true;
      break;
    case dishForm.recipes.dishPortion.quantity.quantity === 0:
      console.log("dishPortion quantity error");

      result = true;
      break;
    case dishForm.portions[0] &&
      !measureInputOptions(dishForm.portions[0].quantity.unit.label)
        .map((i) => i.uri)
        .includes(dishForm.products.dishPortion.quantity.unit.uri):
      console.log("dishPortion error");
      result = true;
      break;
    case dishForm.recipes.ingredients
      .map((i) => i.quantity.quantity)
      .includes(0):
      result = true;
      break;
    case dishForm.products.ingredients
      .map((i) => i.quantity.quantity)
      .includes(0):
      result = true;
      break;
    case dishForm.recipes.ingredients
      .map((i) => i && i.quantity.unit.label)
      .includes(""):
      result = true;
      break;
    case dishForm.products.ingredients
      .map((i) => i && i.quantity.unit.label)
      .includes(""):
      result = true;
      break;
  }
  return result;
};
const DialogTitleForDish = ({
  setopen,
  form,
  setForm,
  index,
  dishForm,
  i,
  mutate,
}: {
  mutate: (options: any) => void;
  dishForm: AddDishVariables;
  setopen: (a: boolean) => void;
  form: AddMenuVariables;
  setForm: (a: AddMenuVariables) => void;
  index: number;
  i: number;
}) => {
  const disabled = checkForDishUpdateErorr(dishForm);

  return (
    <>
      <DialogTitle align="center">{`Dish for course ${form.courses[index].name}`}</DialogTitle>
      <DialogTitle align="center">
        <Button
          disabled={disabled}
          sx={{ mt: 2, borderRadius: 28 }}
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() => {
            const oldselected = [...form.courses[index].selectables];
            oldselected[i] = {
              dishid: dishForm.input.name,
              dishname: dishForm.input.name,
              quantitiesSelected: dishForm.portions.map((i) => ({
                portion: i,
                selected: true,
              })),
            };
            !oldselected.map((i) => i.dishname).includes("Test Dish") &&
              oldselected.push(emptySelectable);
            const oldCourses = [...form.courses];
            oldCourses[index] = {
              ...oldCourses[index],
              selectables: distinctSelectables(oldselected),
            };
            dishForm.input.urls &&
              dishForm.input.urls[0] &&
              setForm({
                ...form,
                courses: oldCourses,
              });
            const mappedForm = {
              ...dishForm,
              options: defaultOptions,
              hours: defaultHours,
              recipes: {
                ...dishForm.recipes,
                ingredients: dishForm.recipes.ingredients
                  .filter((i) => i.name !== "")
                  .map((i) => ({
                    id: i.id,
                    name: i.name,
                    quantity: i.quantity,
                    nutrition: i.nutrition,
                    img: "",
                  })),
              },
            };
            mutate({
              variables: mappedForm,
            });
            setopen(false);
          }}
        >
          Submit
        </Button>
      </DialogTitle>
    </>
  );
};
