import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { DishContent } from "./DishContent";
import { VscCheck, VscEdit } from "react-icons/vsc";
import { SubmitButton } from "../../../../components/Buttons";
import { ResultForProductSearch } from "../../../../components/edamam/types";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import {
  GridRowTwoItems,
  GridRowThreeItems,
  GridRowOneItem,
  GridRowFourItems,
} from "../../../../components/layouts/Grids";
import { DishDialogOptions } from "../addDish";
import {
  findMeasure,
  gramMeasureInput,
  mlMeasureInput,
  small,
} from "../../../../components/defaults/portions/PortionInput";
import { recipeResultToQuantityToRecipe } from "../../../clientbase/components/mappers/RecipeToQuantityToRecipes";
import { useLazyRecipesQuery } from "../../recipesAndIngredients/api";
import { recipes } from "../../recipesAndIngredients/types/recipes";
import { UpdateDishVariables } from "../addDish/types/UpdateDish";
import { useUpdateMeasurementTypeForDishMutation } from "../addDish/api";
import { UnitOptions } from "../../../../components/utils/UnitFunctions";
import { MeasureInput, QuantityInput } from "../../../../globalTypes";
import { AmountPerQuantityToProduct } from "..";
import { standard } from "../components/StandardWeight";
import { checkForDishUpdateErorr } from "../../../clientbase/createClientPage/forms/NewDishDialog";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { MdFoodBank } from "react-icons/md";
import { minimizeUnit } from "../../../../components/utils/DisplayQuantity";
import { QuantityToRecipe } from "../../../clientbase/types/types";
import { HaccpButton2 } from "../../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import {
  aestheticBackground,
  buttonColor,
} from "../../../../components/layouts/Colors";

export const getStandardQuantityForDish = (
  form: UpdateDishVariables
): QuantityInput => {
  return form.portions.length > 0
    ? form.portions[0].quantity
    : form.hours
    ? { quantity: form.hours.quantity.quantity, unit: form.hours.quantity.unit }
    : form.products.dishPortion.quantity;
};

export const DishDialog = ({
  loading,
  form,
  mutate,
  setForm,
  openDish,
  setOpenDish,
  productToPrice,
  isMobile,
}: {
  isMobile: boolean;
  productToPrice: AmountPerQuantityToProduct[] | undefined;
  loading: boolean;
  openDish: boolean;
  setOpenDish: (a: boolean) => void;
  form: UpdateDishVariables;
  setForm: (a: UpdateDishVariables) => void;
  mutate: (options?: any) => void;
}) => {
  const foundPortion =
    form.portions &&
    form.portions.length > 0 &&
    form.portions.find((p) => p.name === "Manual");

  const manualPortion = foundPortion
    ? {
        ...foundPortion,
        quantity: foundPortion.quantity,
      }
    : undefined;

  const standardQuantity = manualPortion
    ? manualPortion
    : form.hours !== undefined && form.hours !== null
    ? {
        name: "Manual",
        quantity: {
          quantity: standard.quantity,
          unit: form.hours.quantity.unit,
        },
      }
    : {
        name: "Manual",
        quantity: {
          quantity: standard.quantity,
          unit: standard.unit,
        },
      };
  const [quantityView, setQuantityView] = useState(standardQuantity.quantity);
  const [newUnit, setNewUnit] = useState(quantityView.unit);

  const [title, settitle] = useState("Change measure");
  const [openMeasureChangeDialog, setopenMeasureChangeDialog] = useState(false);
  const { updateMeasure, loading: loadingUpdateMeasure } =
    useUpdateMeasurementTypeForDishMutation({
      onCompleted: () => {
        setQuantityView({
          ...quantityView,
          unit: newUnit,
        });
        settitle("Great!");
      },
    });
  const [edit, setEdit] = useState(false);
  const [costs, setCosts] = useState(false);

  const disabled = edit && (loading || checkForDishUpdateErorr(form));
  const [recipes, setrecipes] = useState<QuantityToRecipe[]>([]);
  const {
    getRecipes,
    data: rec,
    loading: loadingRecipes,
  } = useLazyRecipesQuery({
    onCompleted: (result: recipes) =>
      setrecipes(recipeResultToQuantityToRecipe(result)),
  });

  const [dishdialogoptions, setdishdialogoptions] = useState<DishDialogOptions>(
    {
      calories: false,
      costs: false,
    }
  );
  if (isMobile)
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openDish}
        onClose={() => {
          setEdit(false);
          setOpenDish(false);
        }}
      >
        <DialogActions sx={{ backgroundColor: aestheticBackground }}>
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <SubmitButton
                sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                variant="contained"
                onClick={() => {
                  setEdit(false);
                  setOpenDish(false);
                }}
              >
                Close
              </SubmitButton>
            }
          />
        </DialogActions>
        <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
          {" "}
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <>
                <Typography
                  style={{
                    fontFamily: "PlayFair Display, serif",
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {`${form.input.name}`} {`   `}
                  {`(${form.input.type})`}
                </Typography>
              </>
            }
          />
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: aestheticBackground }}>
          <DishContent
            isMobile={isMobile}
            costs={costs}
            loadingRecipes={loadingRecipes}
            productToPrice={productToPrice}
            loading={loading}
            recipes={recipes}
            edit={edit}
            dishdialogoptions={dishdialogoptions}
            setdishdialogoptions={setdishdialogoptions}
            form={form}
            setopenmeasurementchange={setopenMeasureChangeDialog}
            setForm={setForm}
          />
        </DialogContent>
      </Dialog>
    );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={openDish}
      onClose={() => {
        setEdit(false);
        setOpenDish(false);
      }}
    >
      <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
        <GridRowFourItems
          firstchild={
            <>
              <GridRowThreeItems
                before={1}
                inbetweenfirsttwo={0}
                inbetweensecondtwo={0}
                firstchild={
                  <>
                    {edit ? (
                      <GridRowTwoItems
                        firstlength={5.5}
                        secondlength={5.5}
                        before={0}
                        inbetween={1}
                        firstchild={
                          <FormField
                            label="Name"
                            value={form.input.name}
                            setValue={(a: string) => {
                              setForm({
                                ...form,
                                input: {
                                  ...form.input,
                                  name: a,
                                },
                              });
                            }}
                          />
                        }
                        secondchild={
                          <FormField
                            label="Type"
                            value={form.input.type}
                            setValue={(a: string) => {
                              setForm({
                                ...form,
                                input: {
                                  ...form.input,
                                  type: a,
                                },
                              });
                            }}
                          />
                        }
                      />
                    ) : (
                      <>{`${form.input.name} (${form.input.type})`}</>
                    )}
                  </>
                }
                secondchild={<></>}
                thirdchild={<></>}
                firstlength={11}
                secondlength={0}
                thirdlength={0}
              />
            </>
          }
          secondchild={
            <>
              <SubmitButton
                sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                variant={!costs && !edit ? "outlined" : "contained"}
                color="inherit"
                onClick={() => {
                  setEdit(false);
                  setCosts(false);
                }}
              >
                <MdFoodBank size={50} />
              </SubmitButton>
            </>
          }
          thirdchild={
            <>
              <SubmitButton
                sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                variant={costs ? "outlined" : "contained"}
                onClick={() => {
                  setEdit(false);
                  setCosts(true);
                }}
              >
                <RiMoneyEuroCircleLine size={50} />
              </SubmitButton>
            </>
          }
          fourthchild={
            <>
              {edit ? (
                <SubmitButton
                  sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                  disabled={disabled}
                  variant="contained"
                  onClick={() => {
                    mutate({
                      variables: {
                        ...form,
                        products: {
                          ...form.products,
                          dishPortion: {
                            ...form.products.dishPortion,
                            name: "Manual",
                          },
                        },
                        recipes: {
                          ...form.recipes,
                          dishPortion: {
                            ...form.recipes.dishPortion,
                            name: "Manual",
                          },
                        },
                      },
                    });
                    setEdit(!edit);
                  }}
                >
                  <VscCheck size={50} />
                  {loading && <CircularProgress />}
                </SubmitButton>
              ) : (
                <SubmitButton
                  sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                  variant="contained"
                  onClick={() => {
                    recipes.length === 0 && getRecipes();
                    setEdit(!edit);
                  }}
                >
                  <VscEdit size={50} />
                </SubmitButton>
              )}
            </>
          }
          firstlength={7}
          secondlength={1}
          thirdlength={1}
          fourthlength={1}
          before={0}
          inbetweenfirsttwo={0}
          inbetweensecondtwo={0.5}
          inbetweenthirdtwo={0.5}
        />
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: aestheticBackground }}>
        {edit && (
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography align="center" variant="h6">
              Press{" "}
              <SubmitButton variant="contained">
                <VscCheck />
              </SubmitButton>{" "}
              on the topcorner when done updating
            </Typography>
          </Box>
        )}
        <DishContent
          isMobile={isMobile}
          costs={costs}
          loadingRecipes={loadingRecipes}
          productToPrice={productToPrice}
          loading={loading}
          recipes={recipes}
          edit={edit}
          dishdialogoptions={dishdialogoptions}
          setdishdialogoptions={setdishdialogoptions}
          form={form}
          setopenmeasurementchange={setopenMeasureChangeDialog}
          setForm={setForm}
        />
      </DialogContent>
      <Dialog
        open={openMeasureChangeDialog}
        onClose={() => {
          setopenMeasureChangeDialog(false);
        }}
      >
        <DialogContent sx={{ backgroundColor: aestheticBackground }}>
          <DialogTitle sx={{ backgroundColor: aestheticBackground }}>
            Change Measurement Type
          </DialogTitle>
          {title !== "Great" ? (
            <GridRowTwoItems
              before={0.5}
              inbetween={0.5}
              firstlength={5.5}
              secondlength={5.5}
              firstchild={`From ${quantityView.unit.label} to `}
              secondchild={
                <FormSelect
                  value={newUnit.label}
                  options={UnitOptions}
                  setValue={(a: string) => {
                    setNewUnit(findMeasure(a));
                  }}
                />
              }
            />
          ) : (
            <GridRowOneItem
              before={3}
              after={3}
              child={<Typography>You changed the measurement type!</Typography>}
            />
          )}
          <GridRowOneItem
            before={3}
            after={3}
            child={
              <>
                {loadingUpdateMeasure ? (
                  <CircularProgress />
                ) : title === "Great!" ? (
                  <SubmitButton
                    sx={{ backgroundColor: buttonColor }}
                    variant="contained"
                    onClick={() => {
                      setForm({
                        ...form,
                        portions: form.portions.map((p) => ({
                          ...p,
                          quantity: {
                            quantity: p.quantity.quantity,
                            unit: minimizeUnit(newUnit),
                          },
                        })),
                        hours: form.hours
                          ? {
                              ...form.hours,
                              quantity: {
                                ...form.hours!.quantity,
                                unit: newUnit,
                              },
                            }
                          : undefined,
                        products: {
                          ...form.products,
                          dishPortion: {
                            ...form.products.dishPortion,
                            quantity: {
                              ...form.products.dishPortion.quantity,
                              unit: newUnit,
                            },
                          },
                        },
                        recipes: {
                          ...form.products,
                          dishPortion: {
                            ...form.recipes.dishPortion,
                            quantity: {
                              ...form.recipes.dishPortion.quantity,
                              unit: newUnit,
                            },
                          },
                        },
                      });
                      setopenMeasureChangeDialog(false);
                      settitle("Change measure");
                    }}
                  >
                    {title}
                  </SubmitButton>
                ) : (
                  <SubmitButton
                    sx={{ backgroundColor: buttonColor }}
                    variant="contained"
                    onClick={() => {
                      updateMeasure({
                        variables: {
                          input: newUnit,
                          id: form.id,
                        },
                      });
                    }}
                  >
                    {title}
                  </SubmitButton>
                )}{" "}
              </>
            }
          />
        </DialogContent>
      </Dialog>
      <DialogActions sx={{ backgroundColor: aestheticBackground }}>
        <SubmitButton
          sx={{ backgroundColor: buttonColor }}
          variant="contained"
          onClick={() => {
            setEdit(false);
            setOpenDish(false);
          }}
        >
          Close
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export const emptyResult: ResultForProductSearch = {
  ingredients: [
    {
      parsed: [
        {
          food: "",
          foodId: "",
          measure: "gram",
          measureURI: "",
          quantity: 100,
          image: "",
        },
      ],
    },
  ],
  totalNutrients: {
    ENERC_KCAL: {
      label: "Kcal",
      quantity: 0,
      unit: "g",
    },
    FASAT: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    FAT: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    SUGAR: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    CHOCDF: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    FIBTG: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    PROCNT: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    NA: {
      label: "Fats",
      quantity: 0,
      unit: "g",
    },
    quantity: small.quantity,
    healthLabels: undefined,
  },
};

export const minimalForUnit = (newUnit: MeasureInput): MeasureInput => {
  if (newUnit.label.toLowerCase() === "kilogram") return gramMeasureInput();
  if (newUnit.label.toLowerCase() === "liter") return mlMeasureInput();
  else return newUnit;
};

export const createOptions = (
  measures: MeasureInput[]
): { name: string; id: string }[] => {
  return measures.map((a) => ({ name: a.label, id: a.uri }));
};
