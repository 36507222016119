import {
  Button,
  Divider,
  Box,
  Table,
  TableHead,
  TableCell,
} from "@mui/material";
import { GridRowOneItem } from "../../../../../components/layouts/Grids";
import { useState } from "react";
import { UpdateDishVariables } from "../../../../chefsbase/dishes/addDish/types/UpdateDish";
import { AmountPerQuantityInput } from "../../../../../globalTypes";
import { HoursWorked1 } from "../../../../chefsbase/dishes/components/HoursWorked";
import { DisplayPortions } from "../../../../chefsbase/dishes/components/PortionsRow";
import { getStandardQuantityForDish } from "../../../../chefsbase/dishes/dishDialog";
import { H6, H6Left } from "../../../../../components/TextTypes";
import { writeHealthlabelInfo } from "./RecipeAndIngredients";
import { hourlyRate } from "../../../../chefsbase/dishes/dishDialog/DishContent";
import { buttonColor } from "../../../../../components/layouts/Colors";

export const ExtraInfoSection = ({
  form,
  setForm,
  edit,
  isMobile,
}: {
  isMobile?: boolean;
  edit: boolean;
  form: UpdateDishVariables;
  setForm: (a: UpdateDishVariables) => void;
}) => {
  const [meth, setMeth] = useState<boolean>(false);

  return (
    <Box sx={{ mt: 0, mb: 5 }}>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <>
            <Button
              sx={{ width: 150, backgroundColor: buttonColor }}
              color="inherit"
              variant={meth ? "contained" : "outlined"}
              onClick={() => {
                setMeth(true);
              }}
            >
              Show Extras?
            </Button>
            <Button
              sx={{ width: 150, backgroundColor: buttonColor }}
              color="inherit"
              variant={!meth ? "contained" : "outlined"}
              onClick={() => {
                setMeth(false);
              }}
            >
              Do not show
            </Button>
          </>
        }
      />
      {meth && (
        <GridRowOneItem
          before={0.5}
          after={0.5}
          child={
            <ExtraSection
              isMobile={isMobile}
              checked={meth}
              setForm={setForm}
              edit={edit}
              form={form}
            />
          }
        />
      )}
    </Box>
  );
};

export const ExtraSection = ({
  form,
  setForm,
  edit,
  checked,
  isMobile,
}: {
  isMobile?: boolean;
  checked: boolean;
  form: UpdateDishVariables;
  edit: boolean;
  setForm: (a: UpdateDishVariables) => void;
}) => {
  const [hourlyWage, sethourlyWage] = useState(hourlyRate);
  return (
    <Box sx={{ width: "100vh" }}>
      {checked && (
        <>
          <Box sx={{ mt: 2, backgroundColor: buttonColor, borderRadius: 10 }}>
            <GridRowOneItem
              before={0.5}
              after={1}
              child={<H6Left title="Portions" />}
            />
          </Box>
          <DisplayPortions
            unit={getStandardQuantityForDish(form).unit}
            edit={edit}
            portions={form.portions.filter((p) => p.name !== "Manual")}
            setportions={(a) => {
              setForm({
                ...form,
                portions: a,
              });
            }}
          />
          <GridRowOneItem before={0} after={0} child={<Divider />} />
          <Box sx={{ mt: 2, backgroundColor: buttonColor, borderRadius: 10 }}>
            <GridRowOneItem
              before={0.5}
              after={1}
              child={<H6Left title="Labor Hours" />}
            />
          </Box>
          <GridRowOneItem
            before={0.5}
            after={0}
            child={
              edit ? (
                <HoursWorked1
                  hourlyWage={hourlyWage}
                  sethourlyWage={sethourlyWage}
                  setInfo={(a: AmountPerQuantityInput) => {
                    setForm({
                      ...form,
                      hours: {
                        quantity: a.quantity,
                        amount: a.amount,
                      },
                    });
                  }}
                  info={form.hours!}
                />
              ) : (
                `${form.hours!.amount.toFixed(2)} hours for ${
                  form.hours!.quantity.quantity
                } ${form.hours!.quantity.unit.label} at €${hourlyWage.toFixed(
                  2
                )} an hour`
              )
            }
          />
          <GridRowOneItem before={0} after={0} child={<Divider />} />
          <Box sx={{ mt: 2, backgroundColor: buttonColor, borderRadius: 10 }}>
            <GridRowOneItem
              before={0.5}
              after={1}
              child={<H6Left title="Allergies" />}
            />
          </Box>
          <GridRowOneItem
            before={0.5}
            after={0}
            child={writeHealthlabelInfo(form.healthLabels!, isMobile)}
          />
          <GridRowOneItem before={0} after={0} child={<Divider />} />
        </>
      )}
    </Box>
  );
};
