import { Box } from "@mui/material";
import { GridRowOneItem } from "../../../../../components/layouts/Grids";
import { QuantityInput } from "../../../../../globalTypes";
import { AddDishVariables } from "../../../../chefsbase/dishes/addDish/types/AddDish";
import { FormQuantity } from "../../../../chefsbase/recipes/addrecipes/CreateYourRecipe";
import {
  findMeasure,
  measureInputOptions,
} from "../../../../../components/defaults/portions/PortionInput";
import { useState } from "react";
import { RecipeForm } from "../../../../chefsbase/recipes/addrecipes/types";

export const UpdateDishQuantityForRecipes = ({
  dishForm,
  setDishForm,
  options,
}: {
  options: { name: string; id: string }[];
  dishForm: AddDishVariables;
  setDishForm: (dishForm: AddDishVariables) => void;
}) => {
  const [amount, setamount] = useState(
    String(dishForm.products.dishPortion.quantity)
  );

  return (
    <Box>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <FormQuantity
            amount={amount}
            setamount={setamount}
            options={options}
            value={dishForm.products.dishPortion.quantity}
            setValue={(value: QuantityInput) => {
              setDishForm({
                ...dishForm,
                recipes: {
                  ...dishForm.recipes,
                  dishPortion: {
                    name: "Manual",
                    quantity: value,
                  },
                },
                products: {
                  ...dishForm.products,
                  dishPortion: {
                    name: "Manual",
                    quantity: value,
                  },
                },
                hours: {
                  amount: dishForm.hours ? dishForm.hours.amount : 0,
                  quantity:
                    dishForm.hours === null ||
                    dishForm.hours === undefined ||
                    dishForm.hours?.quantity.quantity === 0 ||
                    !measureInputOptions(
                      dishForm.hours.quantity.unit.label
                    ).includes(value.unit)
                      ? value
                      : dishForm.hours.quantity,
                },
              });
            }}
          />
        }
      />
    </Box>
  );
};
