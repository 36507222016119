import {
  MeasureInput,
  PortionInput,
  PortionSelectedInput,
  QuantityInput,
} from "../../../globalTypes";
import { Dish_dish_recipes_dishPortion_quantity_unit } from "../../../screens/chefsbase/dishes/types/Dish";
import { MeasurementInfo } from "../../../screens/clientbase/types/types";

export const existinglabels = [
  "Gram",
  "Kilogram",
  "Milliliter",
  "Liter",
  "Serving",
  "Unit",
  "Cup",
  "TableSpoon",
  "Teaspoon",
  "Whole",
];
export const mlMeasure: Dish_dish_recipes_dishPortion_quantity_unit = {
  __typename: "Measure",
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_milliliter",
  label: "Milliliter",
  weight: 1,
};
export const servingMeasure: Dish_dish_recipes_dishPortion_quantity_unit = {
  __typename: "Measure",
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_serving",
  label: "Serving",
  weight: 1,
};
export const gramMeasure: Dish_dish_recipes_dishPortion_quantity_unit = {
  __typename: "Measure",
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_gram",
  label: "Gram",
  weight: 1,
};
export const milliliterMeasure: Dish_dish_recipes_dishPortion_quantity_unit = {
  __typename: "Measure",
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_milliliter",
  label: "Milliliter",
  weight: 1,
};
export const gramMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_gram",
  label: "Gram",
  weight: a ? a : 1,
});
export const mlMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_milliliter",
  label: "Milliliter",
  weight: a ? a : 1,
});
export const kilogramMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_kilogram",
  label: "Kilogram",
  weight: a ? a : 1,
});

export const milliliterMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_milliliter",
  label: "Milliliter",
  weight: a ? a : 1,
});

export const literMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_liter",
  label: "Liter",
  weight: a ? a : 1,
});

export const servingMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_serving",
  label: "Serving",
  weight: a ? a : 1,
});

export const unitMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_unit",
  label: "Unit",
  weight: a ? a : 1,
});

export const wholeMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_whole",
  label: "Whole",
  weight: a ? a : 1,
});

export const teaspoonMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_teaspoon",
  label: "Teaspoon",
  weight: a ? a : 1,
});

export const tablespoonMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_tablespoon",
  label: "Tablespoon",
  weight: a ? a : 1,
});

export const cupMeasureInput = (a?: number): MeasureInput => ({
  uri: "http://www.edamam.com/ontologies/edamam.owl#Measure_cup",
  label: "Cup",
  weight: a ? a : 1,
});

export const zeroPortion: PortionInput = {
  name: "Manual",
  quantity: { quantity: 0, unit: gramMeasureInput() },
  price: 1,
};
export const small: PortionInput = {
  name: "Small",
  quantity: { quantity: 200, unit: gramMeasureInput() },
  price: 1,
};

export const oneServing: QuantityInput = {
  quantity: 1,
  unit: servingMeasureInput(),
};

export const medium: PortionInput = {
  name: "Medium",
  quantity: { quantity: 300, unit: gramMeasureInput() },
  price: 1,
};
export const large: PortionInput = {
  name: "Large",
  quantity: { quantity: 500, unit: gramMeasureInput() },
  price: 1,
};
export const extralarge: PortionInput = {
  name: "XL",
  quantity: { quantity: 700, unit: gramMeasureInput() },
  price: 1,
};
export const smallSelected: PortionSelectedInput = {
  portion: small,
  selected: true,
};
export const mediumSelected: PortionSelectedInput = {
  portion: medium,
  selected: true,
};

export const largeSelected: PortionSelectedInput = {
  portion: large,
  selected: true,
};

export const extralargeSelected: PortionSelectedInput = {
  portion: extralarge,
  selected: true,
};

export const measureInputOptions2 = (st: string, a?: number) => {
  let result: MeasureInput[];
  switch (true) {
    case st.toLowerCase() == ("milligram" || "gram" || "kilogram"):
      result = [gramMeasureInput(a ? a : 0), kilogramMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === ("milliliter" || "liter"):
      result = [
        milliliterMeasureInput(a ? a : 0),
        literMeasureInput(a ? a : 0),
      ];
      break;
    case st.toLowerCase() === "servings":
      result = [servingMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === "units":
      result = [unitMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === "whole":
      result = [wholeMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === "cup":
      result = [cupMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === "tablespoon":
      result = [tablespoonMeasureInput(a ? a : 0)];
      break;
    case st.toLowerCase() === "teaspoon":
      result = [teaspoonMeasureInput(a ? a : 0)];
      break;
    default:
      result = [
        gramMeasureInput(a ? a : 0),
        kilogramMeasureInput(a ? a : 0),
        milliliterMeasureInput(a ? a : 0),
        literMeasureInput(a ? a : 0),
        servingMeasureInput(a ? a : 0),
        wholeMeasureInput(a ? a : 0),
        unitMeasureInput(a ? a : 0),
        cupMeasureInput(a ? a : 0),
      ];
  }
  return result;
};
export const optionsFromMeasureInfo = (
  a: MeasurementInfo
): { name: string; id: string }[] => {
  const result = [
    { name: gramMeasureInput().label, id: gramMeasureInput().uri },
  ];
  if (a.serving && a.serving > 0)
    result.push({
      name: servingMeasureInput().label,
      id: servingMeasureInput().uri,
    });
  if (a.cup && a.cup > 0)
    result.push({
      name: cupMeasureInput().label,
      id: cupMeasureInput().uri,
    });
  if (a.whole && a.whole > 0)
    result.push({
      name: wholeMeasureInput().label,
      id: wholeMeasureInput().uri,
    });
  if (a.teaspoon && a.teaspoon > 0)
    result.push({
      name: teaspoonMeasureInput().label,
      id: teaspoonMeasureInput().uri,
    });
  if (a.tablespoon && a.tablespoon > 0)
    result.push({
      name: tablespoonMeasureInput().label,
      id: tablespoonMeasureInput().uri,
    });
  if (a.cup && a.cup > 0)
    result.push({
      name: cupMeasureInput().label,
      id: cupMeasureInput().uri,
    });
  return result;
};
export const measureInputOptions = (st?: string, a?: number) => {
  var result;
  switch (true) {
    case ["milligram", "gram", "kilogram", "Gram", "Kilogram"].includes(
      st ? st.toLowerCase() : ""
    ):
      result = [gramMeasureInput(a ? a : 0), kilogramMeasureInput(a ? a : 0)];
      break;
    case ["milliliter", "liter"].includes(st ? st.toLowerCase() : ""):
      result = [
        milliliterMeasureInput(a ? a : 0),
        literMeasureInput(a ? a : 0),
      ];
      break;
    case ["serving"].includes(st ? st.toLowerCase() : ""):
      result = [servingMeasureInput(a ? a : 0)];
      break;
    case ["units"].includes(st ? st.toLowerCase() : ""):
      result = [unitMeasureInput(a ? a : 0)];
      break;
    case ["whole"].includes(st ? st.toLowerCase() : ""):
      result = [wholeMeasureInput(a ? a : 0)];
      break;
    case ["cup"].includes(st ? st.toLowerCase() : ""):
      result = [cupMeasureInput(a ? a : 0)];
      break;
    case ["tablespoon"].includes(st ? st.toLowerCase() : ""):
      result = [tablespoonMeasureInput(a ? a : 0)];
      break;
    case ["teaspoon"].includes(st ? st.toLowerCase() : ""):
      result = [teaspoonMeasureInput(a ? a : 0)];
      break;
    default:
      result = [
        gramMeasureInput(a ? a : 0),
        kilogramMeasureInput(a ? a : 0),
        milliliterMeasureInput(a ? a : 0),
        literMeasureInput(a ? a : 0),
        servingMeasureInput(a ? a : 0),
        wholeMeasureInput(a ? a : 0),
        unitMeasureInput(a ? a : 0),
        cupMeasureInput(a ? a : 0),
      ];
  }
  return result;
};

export const findMeasure = (a: string, q?: number): MeasureInput =>
  measureInputOptions(a, q ? q : 0).filter(
    (b) => b.label.toLowerCase() === a.toLowerCase()
  ).length > 0
    ? measureInputOptions(a, q ? q : 0).filter(
        (b) => b.label.toLowerCase() === a.toLowerCase()
      )[0]
    : gramMeasureInput(0);

export const findMeasureTinder = (a: string, q?: number): MeasureInput =>
  measureInputOptions(a, q ? q : 0).filter(
    (b) => b.label.toLowerCase() === a.toLowerCase()
  ).length > 0
    ? measureInputOptions(a, q ? q : 0).filter(
        (b) => b.label.toLowerCase() === a.toLowerCase()
      )[0]
    : {
        uri: `http://www.edamam.com/ontologies/edamam.owl#Measure_${a}`,
        label: a,
        weight: 1,
      };

export const formMeasureInputOptions = (
  st?: string,
  b?: number
): { name: string; id: string }[] => {
  return measureInputOptions(st, b).map((a) => ({
    name: a.label,
    id: a.uri,
  }));
};
