import { gql, useMutation } from "@apollo/client";
import { AddDish, AddDishVariables } from "./types/AddDish";
import { UpdateDish, UpdateDishVariables } from "./types/UpdateDish";
import {
  UpdateMeasurementTypeForDish,
  UpdateMeasurementTypeForDishVariables,
} from "./types/UpdateMeasurementTypeForDish";

const AddDishMutation = gql`
  mutation AddDish(
    $input: DishInput!
    $portions: [PortionInput!]!
    $options: [OptionsInput!]
    $hours: AmountPerQuantityInput
    $recipes: DishIngredientsInput!
    $products: DishIngredientsInput!
    $healthLabels: HealthLabelInfo
  ) {
    addDish(
      input: $input
      portions: $portions
      options: $options
      hours: $hours
      recipes: $recipes
      products: $products
      healthLabels: $healthLabels
    )
  }
`;

export const useAddDishMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addDish, { loading, error }] = useMutation<AddDish, AddDishVariables>(
    AddDishMutation,
    {
      onCompleted: () => onCompleted(),
    }
  );
  return {
    addDish,
    loading,
    error,
  };
};

const UpdateMeasurementTypeForDishMutation = gql`
  mutation UpdateMeasurementTypeForDish($id: String!, $input: MeasureInput!) {
    updateMeasurementTypeDish(id: $id, input: $input)
  }
`;

const UpdateDishMutation = gql`
  mutation UpdateDish(
    $id: String!
    $input: DishInput!
    $portions: [PortionInput!]!
    $options: [OptionsInput!]
    $hours: AmountPerQuantityInput
    $recipes: DishIngredientsInput!
    $products: DishIngredientsInput!
    $healthLabels: HealthLabelInfo
  ) {
    updateDish(
      id: $id
      input: $input
      portions: $portions
      options: $options
      hours: $hours
      recipes: $recipes
      products: $products
      healthLabels: $healthLabels
    )
  }
`;

export const useUpdateDishMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updateDish, { loading, error }] = useMutation<
    UpdateDish,
    UpdateDishVariables
  >(UpdateDishMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    updateDish,
    loading,
    error,
  };
};

export const useUpdateMeasurementTypeForDishMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updateMeasure, { loading, error }] = useMutation<
    UpdateMeasurementTypeForDish,
    UpdateMeasurementTypeForDishVariables
  >(UpdateMeasurementTypeForDishMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    updateMeasure,
    loading,
    error,
  };
};
