import {
  FormField,
  MyTextField,
} from "../../components/forms/singleForms/FormField";
import { backgroundContainer } from "../../components/layouts/Colors";
import { GridRowOneItem } from "../../components/layouts/Grids";
import { AppBlock, LeaderboardCard } from "../tinder/page";
import { useState } from "react";
import { VscCheck, VscEdit, VscTrash } from "react-icons/vsc";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { SubmitButton } from "../../components/Buttons";
import { DisplayNutritionInput } from "../chefsbase/productsfromapi";
import { DisplayHealthInfo } from "../clientbase/components/composables/DialogComposable";
import {
  zeroNutritionInput,
  zerohealthlabelinfo,
} from "../chefsbase/dishes/components/EmptyVariables";
import { readFileAsDataURL } from "../clientbase/components/imgReader/ReadFileAsDataUrl";
import {
  ImageBackdrop,
  ImageButton,
  ImageSrc,
} from "../../components/image/ImageButton";
import { returnStringsIfNotNullOrUndefined } from "../../components/utils/NullableStrings";
import OpenAI from "openai";
import {
  NutritionInput,
  HealthLabelInfo,
  QuantityInput,
  NameToIdInput,
  RecipeForTinderInput,
} from "../../globalTypes";
import { Loader } from "../../components/Loading/Loader";
import { useAddTinderRecipeMutation } from "./api";
import { writeTinderRecipe } from "./types/writeTinderRecipe";
import {
  findMeasure,
  oneServing,
} from "../../components/defaults/portions/PortionInput";

const OPENAI_KEY = "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";
const OPENAI_ORGANIZATION_ID = "org-ZtDPBjvM9mIRLqeBG4aNmI70";

const openai = new OpenAI({
  apiKey: OPENAI_KEY,
  organization: OPENAI_ORGANIZATION_ID,
  dangerouslyAllowBrowser: true,
});

export const AddTinderPage = () => {
  const [page, setPage] = useState(0);
  const options = ["Add Recipes", "Liked Dishes", "Leaderboard"];
  return (
    <div>
      <AppBlock setPage={setPage} options={options} />
      {page === 0 && <AddTinderRecipe />}
      {page === 1 && <AddedTinderRecipes />}
      {page === 2 && <LeaderboardCard />}
    </div>
  );
};

type QuantityToIngredient = {
  quantity: number;
  unit: string;
  ingredient: NameToIdInput;
};
type TinderRecipeForm = {
  name: string;
  type: string;
  time: string;
  method: string;
  urls: string[];
  nutrition: NutritionInput;
  healthLabelInfo: HealthLabelInfo;
  ingredients: QuantityToIngredient[];
};
const empty: TinderRecipeForm = {
  name: "",
  type: "",
  time: "",
  method:
    "* Start by mixing and heating up a tablespoon of oil and a pinch of salt and pepper in your pan\n* Then, ",
  urls: [],
  nutrition: zeroNutritionInput,
  healthLabelInfo: zerohealthlabelinfo,
  ingredients: [],
};

export const toTinderInput = (form: TinderRecipeForm): RecipeForTinderInput => {
  return {
    quantity: oneServing,
    id: form.name,
    name: form.name,
    type: form.type,
    // time: form.time,
    method: form.method,
    img: form.urls,
    nutrition: {
      quantity: oneServing,
      nutrition: form.nutrition,
    },
    healthLabelInfo: form.healthLabelInfo,
    foods: form.ingredients.map((i) => ({
      quantity: {
        quantity: i.quantity,
        unit: findMeasure(i.unit),
      },
      food: i.ingredient,
    })),
  };
};

export const AddTinderRecipe = () => {
  const [form, setForm] = useState(empty);

  const { writeTinderRecipe, loading, error } = useAddTinderRecipeMutation({
    onCompleted: () => {},
  });

  const findNutrition = (answer: string, nutrition: string): number => {
    const index = answer.indexOf(nutrition);
    if (index !== -1) {
      const substring = answer.substring(index + nutrition.length); // Adjust index to start from after the nutrition string
      const number = substring.match(/\d+/);
      return number ? Number(number[0]) : 0;
    } else {
      return 0; // Return 0 if the nutrition string is not found
    }
  };
  const findIngredients = (answer: string): QuantityToIngredient[] => {
    //The answer contains a numbered list of ingredients, make sure to get the ingredients and their quantities

    const index = answer.indexOf("Ingredients:");
    const substring = answer.substring(index + "Ingredients:".length);
    const ingredients = substring.split(";");
    const result: QuantityToIngredient[] = ingredients.map((ingredient) => {
      const name = ingredient.match(/Name: ([a-zA-Z]+)/);
      const quantity = ingredient.match(/Quantity: ([0-9]+)/);
      const unit = ingredient.match(/Unit: ([a-zA-Z]+)/);
      const n =
        name &&
        String(name[0]).substring(name[0].indexOf("Name: ") + "Name: ".length);
      const q =
        quantity &&
        String(quantity[0]).substring(
          quantity[0].indexOf("Quantity: ") + "Quantity: ".length
        );
      const u =
        unit &&
        String(unit[0]).substring(unit[0].indexOf("Unit: ") + "Unit: ".length);

      return {
        quantity: q ? Number(q) : 0,
        unit: u ? u : "",
        ingredient: {
          name: n ? n : "",
          id: n ? n : "",
        },
      };
    });
    return result.filter((r) => r.ingredient.name !== "");
  };

  const findHealthLabel = (answer: string, label: string): boolean => {
    //find index of label in answer (e.g. "nut-free")
    const index = answer.indexOf(label);
    //find the boolean after the index
    const boolean = answer.substring(index + label.length).match(/Yes|No/i);
    const result = boolean ? String(boolean[0]) : "No";

    return result === "Yes" ? true : false;
  };
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const callChatGPT = async (question: string) => {
    const quest = `Below, I am providing you with the instruction of making a dish. Can you pick out the required ingredients, estimate the macro-nutritional information from it and provide me with allergy information?
    Make sure that you provide me with the following output:
    Nutrition: 
    Kcal: X;
    Fibres: X;
    Fats: X;
    Protein: X;
    Satured Fats: X; 
    Salt: X;
    Cholesterol: X;
    Sugar: X;
    Carbs: X;
    Starch: X 
    (Make sure you estimate what tot total macros are for the recipe and replace X with the correct number. So for Kcal, the total number of calories of the recipe should be provided, for Carbs the total number of carbs, etc).

    Ingredients:  
    Name: firstIngredientsname, Quantity: firstIngredientquantity, Unit: firstIngredientsunit; 
    Name: secondIngredientsname, Quantity: secondIngredientsquantity, Unit: secondIngredientsunit; 
    etc for all ingredients (Make sure you replace the firstIngredientsname with the correct name, firstIngredientsquantity with the correct number and firstIngredientsunit with the correct units, either tablespoon, whole units, servings, pinches, cups, grams, milliliters or other serving measure, and the same for all other ingredients).
    
    Allergene info: Nut-free: Yes/No, Dairy-free: Yes/No, Crustacean-Ffree: Yes/No, Meat-free: Yes/No, Fish-free: Yes/No, Lupine-free: Yes/No, Pork-free: Yes/No, Shellfish-free: Yes/No, Celery-free: Yes/No, Sesame-free: Yes/No, Soy-free: Yes/No, Peanut-free: Yes/No, Sulphide-free: Yes/No, Mustard-free: Yes/No, Dairy-free: Yes/No, Gluten-free: Yes/No, Milk-free: Yes/No, Egg-free: Yes/No, Mollusk-free: Yes/No (replacing Yes/No with either a Yes or a No).
    The recipe is the following: ${question}`;

    try {
      setIsLoading(true); // Set loading state to true
      // Call OpenAI GPT-3
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: quest }],
        model: "gpt-3.5-turbo",
      });

      const answer = completion.choices[0].message.content;

      const nutritionInfo: NutritionInput = {
        kcal: findNutrition(String(answer), "Kcal:"), //get number from answer of chat gpt
        carbscarbs: findNutrition(String(answer), "Carbs:"), //get number from answer of chat gpt
        carbssugar: findNutrition(String(answer), "Sugar:"), //get number from answer of chat gpt
        fatstotal: findNutrition(String(answer), "Fats:"), //get number from answer of chat gpt
        prottotal: findNutrition(String(answer), "Protein:"), //get number from answer of chat gpt
        starch: findNutrition(String(answer), "Starch:"), //get number from answer of chat gpt
        fibres: findNutrition(String(answer), "Fibres:"), //get number from answer of chat gpt
        cholesterol: findNutrition(String(answer), "Cholesterol:"), //get number from answer of chat gpt
        natrium: findNutrition(String(answer), "Salt:"), //get number from answer of chat gpt
        saturedfat: findNutrition(String(answer), "Saturated Fats:"), //get number from answer of chat gpt
      };
      const healthLabelInfo: HealthLabelInfo = {
        lupine: findHealthLabel(String([answer]), "Lupine-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not lupine-free) else false
        meat: findHealthLabel(String([answer]), "Meat-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not meat-free) else false
        nut: findHealthLabel(String([answer]), "Nut-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not peanut-free) else false
        peanut: findHealthLabel(String([answer]), "Peanut-free:"), //etc
        shellfish: findHealthLabel(String([answer]), "Shellfish-free:"), //etc
        selery: findHealthLabel(String([answer]), "Celery-free:"), //etc
        sesame: findHealthLabel(String([answer]), "Sesame-free:"), //etc
        soy: findHealthLabel(String([answer]), "Soy-free:"), //etc
        fish: findHealthLabel(String([answer]), "Fish-free:"), //etc
        crustacean: findHealthLabel(String([answer]), "Crustacean-free:"), //etc
        sulphide: findHealthLabel(String([answer]), "Sulphide-free:"), //etc
        mustard: findHealthLabel(String([answer]), "Mustard-free:"), //etc
        dairy: findHealthLabel(String([answer]), "Dairy-free:"), //etc
        gluten: findHealthLabel(String([answer]), "Gluten-free:"), //etc
        egg: findHealthLabel(String([answer]), "Egg-free:"), //etc
        milk: findHealthLabel(String([answer]), "Milk-free:"), //etc
        pork: findHealthLabel(String([answer]), "Pork-free:"), //etc
        mollusk: findHealthLabel(String([answer]), "Mollusk-free:"), //etc
      };

      setForm({
        ...form,
        ingredients: findIngredients(String(answer)),
        nutrition: nutritionInfo,
        healthLabelInfo: healthLabelInfo,
      });
    } catch (error) {
      console.error("Error calling OpenAI GPT-3:", error);
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  const callChatGPT2 = async (ingredients: QuantityToIngredient[]) => {
    const quest = `Below, I am providing you with the ingredients and quantities.  Can you estimate the macro-nutritional information from it and provide me with allergy information?
    Make sure that you provide me with the following output:
    Nutrition: 
    Kcal: X;
    Fibres: X;
    Fats: X;
    Protein: X;
    Satured Fats: X; 
    Salt: X;
    Cholesterol: X;
    Sugar: X;
    Carbs: X;
    Starch: X 
    (Make sure you estimate what tot total macros are for the recipe and replace X with the correct number. So for Kcal, the total number of calories of the recipe should be provided, for Carbs the total number of carbs, etc).
    
    Allergene info: Nut-free: Yes/No, Dairy-free: Yes/No, Crustacean-Ffree: Yes/No, Meat-free: Yes/No, Fish-free: Yes/No, Lupine-free: Yes/No, Pork-free: Yes/No, Shellfish-free: Yes/No, Celery-free: Yes/No, Sesame-free: Yes/No, Soy-free: Yes/No, Peanut-free: Yes/No, Sulphide-free: Yes/No, Mustard-free: Yes/No, Dairy-free: Yes/No, Gluten-free: Yes/No, Milk-free: Yes/No, Egg-free: Yes/No, Mollusk-free: Yes/No (replacing Yes/No with either a Yes or a No).
    The recipe is the following: ${ingredients.map(
      (ing) =>
        `Name: ${ing.ingredient.name}, Quantity: ${ing.quantity}, Unit: ${ing.unit}; `
    )}`;

    try {
      setIsLoading(true); // Set loading state to true
      // Call OpenAI GPT-3
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: quest }],
        model: "gpt-3.5-turbo",
      });

      const answer = completion.choices[0].message.content;

      const nutritionInfo: NutritionInput = {
        kcal: findNutrition(String(answer), "Kcal:"), //get number from answer of chat gpt
        carbscarbs: findNutrition(String(answer), "Carbs:"), //get number from answer of chat gpt
        carbssugar: findNutrition(String(answer), "Sugar:"), //get number from answer of chat gpt
        fatstotal: findNutrition(String(answer), "Fats:"), //get number from answer of chat gpt
        prottotal: findNutrition(String(answer), "Protein:"), //get number from answer of chat gpt
        starch: findNutrition(String(answer), "Starch:"), //get number from answer of chat gpt
        fibres: findNutrition(String(answer), "Fibres:"), //get number from answer of chat gpt
        cholesterol: findNutrition(String(answer), "Cholesterol:"), //get number from answer of chat gpt
        natrium: findNutrition(String(answer), "Salt:"), //get number from answer of chat gpt
        saturedfat: findNutrition(String(answer), "Saturated Fats:"), //get number from answer of chat gpt
      };
      const healthLabelInfo: HealthLabelInfo = {
        lupine: findHealthLabel(String([answer]), "Lupine-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not lupine-free) else false
        meat: findHealthLabel(String([answer]), "Meat-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not meat-free) else false
        nut: findHealthLabel(String([answer]), "Nut-free:"), //get boolean from answer of chat gpt. true if the dish contains it (so it is not peanut-free) else false
        peanut: findHealthLabel(String([answer]), "Peanut-free:"), //etc
        shellfish: findHealthLabel(String([answer]), "Shellfish-free:"), //etc
        selery: findHealthLabel(String([answer]), "Celery-free:"), //etc
        sesame: findHealthLabel(String([answer]), "Sesame-free:"), //etc
        soy: findHealthLabel(String([answer]), "Soy-free:"), //etc
        fish: findHealthLabel(String([answer]), "Fish-free:"), //etc
        crustacean: findHealthLabel(String([answer]), "Crustacean-free:"), //etc
        sulphide: findHealthLabel(String([answer]), "Sulphide-free:"), //etc
        mustard: findHealthLabel(String([answer]), "Mustard-free:"), //etc
        dairy: findHealthLabel(String([answer]), "Dairy-free:"), //etc
        gluten: findHealthLabel(String([answer]), "Gluten-free:"), //etc
        egg: findHealthLabel(String([answer]), "Egg-free:"), //etc
        milk: findHealthLabel(String([answer]), "Milk-free:"), //etc
        pork: findHealthLabel(String([answer]), "Pork-free:"), //etc
        mollusk: findHealthLabel(String([answer]), "Mollusk-free:"), //etc
      };

      setForm({
        ...form,
        nutrition: nutritionInfo,
        healthLabelInfo: healthLabelInfo,
      });
    } catch (error) {
      console.error("Error calling OpenAI GPT-3:", error);
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  const [imageUrls, setImageUrls] = useState([""]);
  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        setForm({
          ...form,
          urls: urls,
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };
  const [edited, setEdited] = useState(false);
  return (
    <div>
      <Card sx={{ backgroundColor: backgroundContainer }}>
        <CardContent>
          <GridRowOneItem
            before={0.5}
            after={3}
            child={
              <FormField
                label="Name"
                value={form.name}
                setValue={(n: string) =>
                  setForm({
                    ...form,
                    name: n,
                  })
                }
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={3}
            child={
              <FormField
                label="Type"
                value={form.type}
                setValue={(n: string) =>
                  setForm({
                    ...form,
                    type: n,
                  })
                }
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <MyTextField
                value={form.time}
                setValue={(m) => setForm({ ...form, time: m })}
                label="Time in minutes it takes to make recipe"
                number
                required
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <MyTextField
                value={form.method}
                setValue={(m) => setForm({ ...form, method: m })}
                multiline
                label="Describe how you make the dish"
                enterStar
                required
              />
            }
          />
          <GridRowOneItem
            before={0.5}
            after={0.5}
            child={
              <>
                <>
                  <label htmlFor="file-upload">
                    <Button
                      sx={{ width: 150 }}
                      color="inherit"
                      variant={
                        imageUrls && imageUrls[0] && imageUrls[0] !== ""
                          ? "contained"
                          : "outlined"
                      }
                      component="span"
                    >
                      Add Pictures
                    </Button>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/png, image/jpeg"
                    multiple
                    onChange={(e) => {
                      e && e.target.files && handleChangeFile(e.target.files);
                    }}
                    style={{ display: "none" }}
                  />
                </>
                <Button
                  sx={{ width: 150 }}
                  color="inherit"
                  variant={
                    imageUrls && imageUrls.filter((i) => i !== "").length > 0
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() => {
                    setImageUrls([""]);
                    setForm({
                      ...form,
                      urls: [],
                    });
                  }}
                >
                  No Pictures
                </Button>
              </>
            }
          />
          {imageUrls && imageUrls.length > 0 && (
            <>
              <Stack direction="row" spacing={1}>
                {imageUrls
                  .filter((im) => im !== "")
                  .map((image: string, i: number) => (
                    <ImageButton
                      sx={
                        returnStringsIfNotNullOrUndefined(form.urls) &&
                        returnStringsIfNotNullOrUndefined(form.urls).includes(
                          image
                        )
                          ? { border: 2 }
                          : { border: 0 }
                      }
                      focusRipple
                      key={image}
                      style={{
                        width: 120,
                      }}
                      onClick={() => {}}
                    >
                      <ImageSrc style={{ backgroundImage: `url(${image})` }} />
                      <ImageBackdrop className="MuiImageBackdrop-root" />
                    </ImageButton>
                  ))}
              </Stack>
            </>
          )}
        </CardContent>
        <CardActionArea>
          <SubmitButton
            variant="contained"
            onClick={() => {
              callChatGPT(form.method);
            }}
          >
            Get allergies and nutrition
          </SubmitButton>
        </CardActionArea>
        <CardContent>
          {isLoading && <Loader loading={isLoading} />}
          {!isLoading && form.ingredients.length > 0 && (
            <>
              <DisplayHealthInfo info={form.healthLabelInfo} w={10} />
              <DisplayNutritionInput
                frac={1}
                nutritionInput={form.nutrition}
                direction="row"
              />
              <Table sx={{ width: "80%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      Ingredients
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {form.ingredients.map((ing, i) => (
                    <IngredientRow
                      key={ing.ingredient.id}
                      ingredient={ing}
                      index={i}
                      setForm={setForm}
                      form={form}
                      setEdited={setEdited}
                    />
                  ))}
                  <AddIngredientRow
                    form={form}
                    setForm={setForm}
                    setEdited={setEdited}
                  />
                </TableBody>
              </Table>
              {!edited ? (
                <SubmitButton
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    writeTinderRecipe({
                      variables: {
                        input: toTinderInput(form),
                      },
                    });
                    console.log(form);
                  }}
                >
                  <VscCheck />
                  Submit
                </SubmitButton>
              ) : (
                <SubmitButton
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    callChatGPT2(form.ingredients);
                    setEdited(false);
                  }}
                >
                  Get Nutrition and Allergies again
                </SubmitButton>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
interface AddIngredientRowProps {
  form: TinderRecipeForm;
  setForm: (form: TinderRecipeForm) => void;
  setEdited: (b: boolean) => void;
}
interface IngredientRowProps {
  form: TinderRecipeForm;
  ingredient: QuantityToIngredient;
  index: number;
  setForm: (form: TinderRecipeForm) => void;
  setEdited: (b: boolean) => void;
}
const IngredientRow: React.FC<IngredientRowProps> = ({
  form,
  ingredient,
  index,
  setForm,
  setEdited,
}) => {
  const [edit, setEdit] = useState(false);
  const [ingredientForm, setIngredientForm] = useState(ingredient);
  return (
    <TableRow key={ingredient.ingredient.id}>
      {edit ? (
        <TableCell align="center" colSpan={6}>
          <Table>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  value={ingredientForm.ingredient.name}
                  setValue={(a: string) => {
                    setIngredientForm({
                      ...ingredientForm,
                      ingredient: { name: a, id: a },
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  number={true}
                  value={String(ingredientForm.quantity)}
                  setValue={(a: string) => {
                    setIngredientForm({
                      ...ingredientForm,
                      quantity: Number(a),
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  value={ingredientForm.unit}
                  setValue={(a: string) => {
                    setIngredientForm({
                      ...ingredientForm,
                      unit: a,
                    });
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <SubmitButton
                  fullWidth
                  sx={{ color: "inherit" }}
                  variant="contained"
                  onClick={() => {
                    setEdited(true);
                    const old = [...form.ingredients];
                    old[index] = ingredientForm;
                    setForm({ ...form, ingredients: old });
                    setEdit(!edit);
                  }}
                >
                  Done
                </SubmitButton>
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      ) : (
        <>
          <TableCell sx={{ width: "5%" }}>{index + 1}</TableCell>
          <TableCell sx={{ width: "30%" }}>
            {ingredient.ingredient.name}
          </TableCell>
          <TableCell sx={{ width: "25%" }}>{ingredient.quantity}</TableCell>
          <TableCell sx={{ width: "30%" }}>{ingredient.unit}</TableCell>
          <TableCell sx={{ width: "5%" }}>
            <VscEdit
              style={{ cursor: "pointer" }}
              onClick={() => setEdit(!edit)}
            />
          </TableCell>
          <TableCell sx={{ width: "5%" }}>
            <VscTrash
              style={{ cursor: "pointer" }}
              onClick={() => {
                const old = [...form.ingredients];
                old.splice(index, 1);
                setForm({ ...form, ingredients: old });
              }}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const AddIngredientRow: React.FC<AddIngredientRowProps> = ({
  form,
  setForm,
  setEdited,
}) => {
  const [edit, setEdit] = useState(false);
  const [ingredient, setIngredient] = useState({
    ingredient: { name: "", id: "" },
    quantity: 1,
    unit: "serving",
  });

  return (
    <TableRow>
      {edit ? (
        <TableCell align="center" colSpan={6}>
          <Table>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  value={ingredient.ingredient.name}
                  setValue={(a: string) =>
                    setIngredient({
                      ...ingredient,
                      ingredient: { name: a, id: a },
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  number={true}
                  value={String(ingredient.quantity)}
                  setValue={(a: string) =>
                    setIngredient({ ...ingredient, quantity: Number(a) })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <MyTextField
                  value={ingredient.unit}
                  setValue={(a: string) =>
                    setIngredient({ ...ingredient, unit: a })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <SubmitButton
                  fullWidth
                  sx={{ color: "inherit" }}
                  variant="contained"
                  disabled={
                    ingredient.ingredient.name === "" ||
                    Number(ingredient.quantity) === 0
                  }
                  onClick={() => {
                    const old = [...form.ingredients];
                    old.push(ingredient);
                    setForm({ ...form, ingredients: old });
                    setEdit(!edit);
                    setEdited(true);
                  }}
                >
                  Done
                </SubmitButton>
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      ) : (
        <TableCell align="center" colSpan={6}>
          <SubmitButton
            fullWidth
            sx={{ color: "inherit" }}
            variant="contained"
            onClick={() => setEdit(!edit)}
          >
            + Add
          </SubmitButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export const AddedTinderRecipes = () => {
  const recipes = ["Recipe 1", "Recipe2"];
  return (
    <Card sx={{ backgroundColor: backgroundContainer }}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="center">
                Leaderboard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Recipe</TableCell>
              <TableCell>#Likes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recipes.map((rec, rowNumber) => (
              <TableRow>
                <TableCell>{rowNumber}</TableCell>
                <TableCell>{rec}</TableCell>
                <TableCell>{rowNumber + 14}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
