import {
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { NametoId } from "../../types/types";
import { MySelectablesInput } from "../../../screens/clientbase/createClientPage/types/MySelectablesInput";
import { PortionInput } from "../../../globalTypes";

export const FormAutoCompleteDishSelectable = ({
  label,
  value,
  options,
  setValue,
}: {
  label?: string;
  value: MySelectablesInput;
  options: MySelectablesInput[];
  setValue: (value: MySelectablesInput) => void;
}) => {
  return (
    <>
      {label && (
        <>
          <InputLabel variant="standard">{label}</InputLabel>
        </>
      )}
      <Autocomplete
        disablePortal
        sx={{ height: 1, width: 300, borderRadius: 28 }}
        size="small"
        defaultValue={value}
        options={options}
        getOptionLabel={(option) =>
          option.dishname !== undefined && option.dishname !== "Test Dish"
            ? option.dishname
            : "Select Dish"
        }
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: any | null
        ) => {
          newValue != null && setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
};

export const FormAutoComplete = ({
  label,
  value,
  options,
  setValue,
}: {
  label?: string;
  value: string;
  options: string[];
  setValue: (value: string) => void;
}) => {
  return (
    <>
      {label && (
        <>
          <InputLabel variant="standard">{label}</InputLabel>
        </>
      )}
      <Autocomplete
        disablePortal
        sx={{ height: 1, width: 300 }}
        size="small"
        id={value}
        defaultValue={value}
        options={options}
        onChange={(event: any, newValue: string | null) => {
          newValue != null && setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
};

export const FormSelect = ({
  label,
  error,
  value,
  options,
  setValue,
  place,
  fw,
  mt,
}: {
  error?: boolean;
  fw?: boolean;
  mt?: number;
  place?: NametoId;
  label?: string;
  value: string;
  options: NametoId[];
  setValue: (value: string) => void;
}) => {
  return (
    <>
      {label && (
        <Box sx={{ mb: 1 }}>
          <InputLabel variant="standard">{label}</InputLabel>
        </Box>
      )}
      <Select
        error={error}
        sx={{ height: 0.5, mt: mt, minWidth: 0.3, borderRadius: 28 }}
        size="small"
        fullWidth={fw}
        margin="dense"
        placeholder={place?.name}
        defaultValue={place?.id}
        value={value}
        name={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options.map((option) => (
          <MenuItem value={option.name}>{option.name}</MenuItem>
        ))}
      </Select>
    </>
  );
};

export const FormSelectItem = ({
  label,
  value,
  options,
  setValue,
}: {
  label?: string;
  value: any;
  options: any[];
  setValue: (value: any) => void;
}) => {
  return (
    <>
      {label && (
        <>
          <InputLabel variant="standard">{label}</InputLabel>
        </>
      )}
      <Select
        sx={{ height: 1, minWidth: 0.3 }}
        size="small"
        margin="dense"
        value={value}
        name={value}
        onChange={(e: any) => setValue(e.target.value)}
      >
        {options.map((option) => (
          <MenuItem value={option.id}>{option.name}</MenuItem>
        ))}
      </Select>
    </>
  );
};
