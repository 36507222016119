import { AccountCircle } from "@mui/icons-material";
import { AppBar, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";

export const TermsPage = () => {
  const [page, setPage] = useState(0);
  const options = ["Terms and Conditions", "Support", "Marketing"];
  return (
    <div>
      <AppBlock title={"Privacy & Terms and Conditions"} />
      <TermsAndConditions />
    </div>
  );
};

export const SupportPage = () => {
  return (
    <div>
      <AppBlock title={"Support"} />
      <Support />
    </div>
  );
};

export const MarketingPage = () => {
  return (
    <div>
      <AppBlock title={"Marketing"} />
      <Marketing />
    </div>
  );
};

const Marketing = () => {
  return (
    <>
      Welcome to SwipingChef <br />
      - Your Culinary Adventure Awaits! <br />
      Discover SwipingChef, the ultimate app for food lovers, home cooks, and
      culinary creators! <br />
      Join our vibrant community and explore an endless array of delicious
      recipes from around the world. Whether you are looking to find new meal
      ideas, share your own culinary masterpieces, or track your nutritional
      intake, SwipingChef has everything you need.
      <br /> Why Choose SwipingChef? <br />• Explore & Discover: Swipe through a
      wide variety of recipes, complete with photos, ingredients, and
      step-by-step instructions. <br />• Share Your Creations: Upload your own
      recipes and showcase your cooking skills to a global audience. <br />•
      Nutritional Insights: Get detailed nutritional information for each recipe
      to make informed dietary choices. <br />• Community Leaderboard: Earn
      likes and climb the ranks to become a top chef in our community.
    </>
  );
};
const Support = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:mychefsbase@gmail.com";
  };
  return (
    <>
      <>
        For Support: <br />
        You can reach us through the email address: <br />
      </>{" "}
      <Div8 onClick={handleEmailClick}>mychefsbase@gmail.com</Div8>
      <>We will respond to your email as quick as we can</>{" "}
    </>
  );
};
const Div8 = styled.div`
  cursor: pointer; /* Add cursor pointer for better UX */
  color: blue;
  font-feature-settings: "clig" off, "liga" off;
  font: 14px/171% Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const TermsAndConditions = () => {
  return (
    <>
      Terms and Conditions Effective Date: 31/7/2024 <br />
      By creating an account and using our app, you agree to the following terms
      and conditions regarding the handling of your personal data and the
      submission of recipes. Please read these terms carefully before
      proceeding. <br />
      Personal Data Protection <br />
      1. Confidentiality: <br />
      We value your privacy and are committed to protecting your personal data.
      All personal information you provide upon creating an account, including
      but not limited to your name, email address, and contact details, will be
      kept confidential and will not be shared with any third parties without
      your explicit consent, except as required by law. <br />
      2. Data Security: We employ industry-standard security measures to
      safeguard your personal data against unauthorized access, disclosure, or
      misuse. <br />
      3. Data Usage: Your personal data will be used solely for purposes of
      account management, app functionality, and communication related to our
      services. You have the right to access, modify, or delete your personal
      data at any time through your account settings. <br />
      Recipe Submissions <br />
      1. Ownership and Rights: By submitting a recipe to our app, you
      acknowledge and agree that the recipe and all associated content
      (including but not limited to text, images, and videos) become the
      property of the app owner. <br />
      2. Database Inclusion: Submitted recipes will be stored in our database
      and may be shared publicly within the app, on our website, and through our
      affiliated platforms. <br />
      3. Attribution: While we retain ownership rights, we may attribute the
      recipe to you, the user, unless you choose to submit anonymously. <br />
      4. Use of Recipes: The app owner reserves the right to use, edit, modify,
      distribute, and publish submitted recipes in any format, media, or
      platform, including for commercial purposes. <br />
      You will not be entitled to any compensation for such use. Acceptance of
      Terms By creating an account, you confirm that you have read, understood,
      and agree to be bound by these terms and conditions. If you do not agree
      with any part of these terms, please do not create an account or use the
      app. <br />
      Changes to Terms and Conditions <br />
      We may update these terms and conditions from time to time. Any changes
      will be posted on this page with an updated effective date. Your continued
      use of the app following the posting of changes constitutes your
      acceptance of such changes. <br />
      Contact Information <br />
      If you have any questions or concerns about these terms and conditions,
      please contact us at mychefsbase@gmail.com. Governing Law These terms and
      conditions are governed by and construed in accordance with the laws of
      the European Commission, and you irrevocably submit to the exclusive
      jurisdiction of the courts in that location. By clicking “Agree” or
      creating an account, you acknowledge that you have read, understood, and
      agree to these terms and conditions. <br />
      Last Updated: 31/07/2024
    </>
  );
};

export const AppBlock = ({ title }: { title: string }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          align="center"
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
