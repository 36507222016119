import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { useCurrentMenuQuery } from "./api";
import { CurrentMenu, CurrentMenu_currentMenu } from "./types/CurrentMenu";
import { ProcessingDialog } from "../../components/Loading/Processing";
import { aestheticBackground } from "../../components/layouts/Colors";
import { GridRowOneItem } from "../../components/layouts/Grids";
import { Head } from "./components/Head";
import { startMicroMacroForm } from "../../components/defaults/micromacroform/StartMicroMacroForm";
import { NutritionInputCard2 } from "./components/MicroMacroField";
import { TabsForMenu } from "./components/TabsForMenu";
import { ShowCombos } from "./components/combos/ShowCombos";
import { ShowCourses } from "./components/courses/ShowCourses";
import { ShowComposables } from "./components/composables/ShowComposables";
import { MicroMacroField } from "./components/MicroMacroField/types";
import { Menu_menu } from "./types/Menu";
import { AddPageNoClient } from "../../PageFrames";
import { TinderPage } from "../tinder/page";
import { MarketingPage, SupportPage, TermsPage } from "../swipingchef/page";

export const FilterForform = ({
  currentMenu,
}: {
  currentMenu: CurrentMenu_currentMenu;
}): CurrentMenu_currentMenu => {
  const selectables = currentMenu.courses
    .map((c) => c.selectables)
    .flat()
    .map((s) => s.selectables)
    .flat();
  selectables.filter((s) => true);
  return currentMenu;
};

export const mapClientMenuToCurrentMenu = (menu: Menu_menu): CurrentMenu => {
  return {
    currentMenu: {
      __typename: "McbMenu",
      id: menu.id,
      name: menu.name,
      courses: menu.courses,
      combos: menu.combos,
    },
  };
};

export const GetClientPage = ({ menu }: { menu: Menu_menu }) => {
  const data = mapClientMenuToCurrentMenu(menu);

  return (
    <>
      <AddPageNoClient
        childComp={
          <>
            <Content data={data} />
          </>
        }
      />
    </>
  );
};
export const tripadvisorLink =
  "https://www.tripadvisor.com/Restaurant_Review-g188575-d18894587-Reviews-Soup_Bros-Maastricht_Limburg_Province.html";

export const ClientPage = ({ user }: { user: string }) => {
  const { data, loading } = useCurrentMenuQuery({
    user: user,
    onCompleted: (result: CurrentMenu) => {},
  });

  if (loading)
    return <ProcessingDialog loading={!data} title="Loading your menu" />;
  return (
    <>
      <AddPageNoClient childComp={<>{data && <Content data={data} />}</>} />
    </>
  );
};

export const TinderContainerPage = () => {
  return (
    <>
      <AddPageNoClient childComp={<TinderPage />} />
    </>
  );
};

export const SwipingChefTermsPage = () => {
  return (
    <>
      <AddPageNoClient childComp={<TermsPage />} />
    </>
  );
};

export const SwipingChefSupportPage = () => {
  return (
    <>
      <AddPageNoClient childComp={<SupportPage />} />
    </>
  );
};
export const SwipingChefMarketingPage = () => {
  return (
    <>
      <AddPageNoClient childComp={<MarketingPage />} />
    </>
  );
};

const Content = ({ data }: { data: CurrentMenu | undefined }) => {
  const [value, setValue] = useState(1);

  const [microMacroForm, setmicromacroform] =
    useState<MicroMacroField>(startMicroMacroForm);
  const [allergyChoices, setAllergyChoices] = useState({
    first: [""],
    second: [""],
  });
  const [options, setOptions] = useState([""]);
  const [collapse, setcollapse] = useState(false);
  // const chooseFrom = data ? optionsForMenu(data) : [];

  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <>
            <Box
              sx={{
                marginTop: 1,
                borderColor: "#FFF",
                borderRadius: "16px",
                backgroundColor: aestheticBackground,
                width: "100%",
                height: "100%",
              }}
            >
              <Stack spacing={3}>
                <GridRowOneItem
                  before={0.5}
                  after={0.5}
                  child={
                    data ? (
                      <Head name={data.currentMenu.name} />
                    ) : (
                      <GridRowOneItem
                        before={5}
                        after={5}
                        child={<CircularProgress />}
                      />
                    )
                  }
                />
                <NutritionInputCard2
                  collapse={collapse}
                  setcollapse={setcollapse}
                  setAllergyChoices={setAllergyChoices}
                  microMacroForm={microMacroForm}
                  allergyChoices={allergyChoices}
                  setmicromacroform={setmicromacroform}
                />
                {/* <GridRowOneItem before={0} after={0} child={<Divider />} /> */}
                <TabsForMenu
                  value={value}
                  setValue={setValue}
                  data={data ? data.currentMenu : null}
                />
                {/* <GridRowOneItem before={0} after={0} child={<Divider />} /> */}
                {data ? (
                  <>
                    {value === 1 && (
                      <ShowCourses
                        options={options}
                        allergyChoices={allergyChoices}
                        microMacroForm={microMacroForm}
                        courses={data.currentMenu.courses}
                      />
                    )}
                    {value === 2 && (
                      <ShowCombos
                        microMacroForm={microMacroForm}
                        currentmenu={data.currentMenu}
                        options={options}
                      />
                    )}
                    {value === 3 && (
                      <ShowComposables
                        microMacroForm={microMacroForm}
                        courses={data.currentMenu.courses}
                      />
                    )}
                  </>
                ) : (
                  <GridRowOneItem
                    before={5}
                    after={5}
                    child={<CircularProgress />}
                  />
                )}

                <GridRowOneItem before={0} after={0} child={<Divider />} />
              </Stack>
            </Box>
          </>
        }
      />
    </>
  );
};
