import {
  Stack,
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  Typography,
  Tab,
  Tabs,
  FormControlLabel,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { SubmitButton } from "../../../../components/Buttons";
import {
  GridRowTwoItems,
  GridRowOneItem,
} from "../../../../components/layouts/Grids";
import { ProcessingDialog } from "../../../../components/Loading/Processing";
import { AmountPerQuantityInput, PortionInput } from "../../../../globalTypes";
import { useAddDishMutation } from "./api";
import { OptionForm } from "./types";
import { AddDishVariables } from "./types/AddDish";
import { FormField } from "../../../../components/forms/singleForms/FormField";
import { useRecipesQuery } from "../../recipesAndIngredients/api";
import { recipes } from "../../recipesAndIngredients/types/recipes";
import { recipeResultToQuantityToRecipe } from "../../../clientbase/components/mappers/RecipeToQuantityToRecipes";
import { emptyAddDishVariables } from "../../../../components/defaults/AddDishForm/EmptyAddDishVariables";
import { toInput } from "../components/MapToInput";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  findMeasure,
  measureInputOptions,
  measureInputOptions2,
  optionsFromMeasureInfo,
} from "../../../../components/defaults/portions/PortionInput";
import { measureToMeasureInput } from "../../../clientbase/createClientPage/forms/DishCard/Mappers";
import { AddPage } from "../../../../PageFrames";
import { QuantityToRecipe } from "../../../clientbase/types/types";
import Checkbox from "@mui/material/Checkbox";
import { MethodSection } from "../../../clientbase/createClientPage/forms/DishCard/MethodSection";
import { green, grey } from "@mui/material/colors";
import { RecipeAndIngredientsForDish } from "../../../clientbase/createClientPage/forms/DishCard/RecipeAndIngredients";
import { DisplayPortions } from "../components/PortionsRow";
import { HoursWorked1 } from "../components/HoursWorked";
import { readFileAsDataURL } from "../../../clientbase/components/imgReader/ReadFileAsDataUrl";
import { ImageSection } from "../../../clientbase/createClientPage/forms/DishCard/ImageSection";
import { returnStringsIfNotNullOrUndefined } from "../../../../components/utils/NullableStrings";
import { createRange } from "../../../../components/utils/CreateRange";
import { CheckBox } from "@mui/icons-material";
import {
  aestheticBackground,
  buttonColor,
} from "../../../../components/layouts/Colors";

export type DishInfoForm = {
  name: string;
  type: string;
  portions: PortionInput[];
  options: OptionForm[];
  hoursWorked: AmountPerQuantityInput;
  urls: string[] | null;
};

export const finalVariables = (a: AddDishVariables): AddDishVariables => {
  return {
    healthLabels: a.healthLabels,
    input: a.input,
    hours: a.hours,
    portions: a.portions,
    options: a.options,
    products: {
      ...a.products,
      ingredients: a.products
        ? a.products.ingredients.filter(
            (r) =>
              r !== null &&
              r !== undefined &&
              r.id !== "" &&
              r.id !== null &&
              r.id !== undefined
          )
        : [],
    },
    recipes: {
      ...a.recipes,
      ingredients: a.recipes
        ? a.recipes.ingredients.filter(
            (r) =>
              r !== null &&
              r !== undefined &&
              r.id !== "" &&
              r.id !== null &&
              r.id !== undefined
          )
        : [],
    },
  };
};
export const AddDish = withAuthenticationRequired(() => {
  const [form, setForm] = useState(emptyAddDishVariables);
  const [updated, setupdated] = useState(false);

  const { addDish, loading } = useAddDishMutation({
    onCompleted: () => {
      setupdated(true);
    },
  });
  const [numberofportions, setnumberofportions] = useState(
    form.portions.length
  );

  if (loading)
    return <ProcessingDialog loading={loading} title="Adding your dish" />;

  return (
    <>
      <AddPage
        childComp={
          <>
            <DishFormContent
              numberofportions={numberofportions}
              setnumberofportions={(a: number) => setnumberofportions(a)}
              form={form}
              setForm={(a: AddDishVariables) => setForm(a)}
              mutate={(variables?: any) => addDish(variables)}
            />
            <Dialog open={updated} onClose={() => setupdated(false)}>
              <DialogTitle>{`${form.input.name} has been created!`}</DialogTitle>
              <DialogActions>
                <SubmitButton
                  variant="contained"
                  onClick={() => {
                    setupdated(false);
                    setForm(emptyAddDishVariables);
                  }}
                >
                  Great!
                </SubmitButton>
              </DialogActions>
            </Dialog>
          </>
        }
      />
    </>
  );
});

export type DishDialogOptions = {
  calories: boolean;
  costs: boolean;
};

export const DishFormContent = ({
  form,
  mutate,
  setForm,
  numberofportions,
  setnumberofportions,
}: {
  numberofportions: number;
  setnumberofportions: (a: number) => void;
  form: AddDishVariables;
  setForm: (a: AddDishVariables) => void;
  mutate: (options?: any) => void;
}) => {
  const [value, setValue] = React.useState(0);

  const [recipes, setRecipes] = useState<QuantityToRecipe[]>([]);

  const { data, loading } = useRecipesQuery({
    onCompleted: (result: recipes) => {
      setRecipes(recipeResultToQuantityToRecipe(result));
    },
  });
  const isMobile = useMediaQuery("(max-width: 600px)");

  const nameAndTypeSet =
    form.input.name.length > 0 && form.input.type.length > 0;
  const methodSet =
    form.input.method.length > 0 &&
    form.input.method !== emptyAddDishVariables.input.method;
  const imgSet =
    form.input.urls !== null &&
    form.input.urls !== undefined &&
    form.input.urls.filter((u) => u.length > 0).length > 0;

  const portionsSet =
    form.portions.length > 0 &&
    form.portions.filter((p) => p.quantity.quantity === 0).length === 0 &&
    form.portions !== emptyAddDishVariables.portions;

  const recipesAndIngredientsSet =
    (form.products.ingredients.filter((i) => i.name.length > 0).length > 0 ||
      form.recipes.ingredients.filter((i) => i.name.length > 0).length > 0) &&
    (form.products.dishPortion.quantity.quantity > 0 ||
      form.recipes.dishPortion.quantity.quantity > 0);
  const laborSet = form.hours !== emptyAddDishVariables.hours;

  const disabledSubmit =
    !nameAndTypeSet ||
    !methodSet ||
    !recipesAndIngredientsSet ||
    !portionsSet ||
    !laborSet;

  return (
    <Box sx={{ backgroundColor: aestheticBackground }}>
      <GridRowOneItem
        before={3}
        after={3}
        child={
          <Box sx={{ mt: 2 }}>
            <Button
              disabled={disabledSubmit}
              fullWidth
              variant={disabledSubmit ? "outlined" : "contained"}
              style={{
                borderRadius: 28,
                backgroundColor: disabledSubmit ? buttonColor : "lightgrey",
                color: "black",
              }}
              onClick={() => {
                mutate({
                  variables: toInput(numberofportions, form),
                });
              }}
            >
              Add Dish
            </Button>
            <Typography variant="body2">
              All fields need to be filled in
              <Checkbox
                style={{ color: aestheticBackground }}
                checked={!disabledSubmit}
              />
              and all buttons need to be green
            </Typography>
          </Box>
        }
      />
      <Box sx={{ mt: 5, minHeight: "70vh" }}>
        <GridRowTwoItems
          before={0}
          firstlength={2}
          inbetween={0}
          secondlength={10}
          firstchild={
            <>
              <Button
                style={{
                  borderRadius: 28,
                  backgroundColor: nameAndTypeSet ? buttonColor : "lightgrey",
                  color: "black",
                  fontSize: isMobile ? "8px" : "inherit",
                }}
                fullWidth
                variant={value === 0 ? "contained" : "outlined"}
                onClick={() => setValue(0)}
              >
                Dish
              </Button>
              <Button
                style={{
                  color: "black",
                  borderRadius: 28,
                  backgroundColor: recipesAndIngredientsSet
                    ? buttonColor
                    : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                }}
                fullWidth
                variant={value === 1 ? "contained" : "outlined"}
                onClick={() => setValue(1)}
              >
                Recipes & Ingredients
              </Button>
              <Button
                style={{
                  borderRadius: 28,
                  backgroundColor: methodSet ? buttonColor : "lightgrey",
                  color: "black",
                  fontSize: isMobile ? "8px" : "inherit",
                }}
                fullWidth
                variant={value === 2 ? "contained" : "outlined"}
                onClick={() => setValue(2)}
              >
                Method
              </Button>
              <Button
                style={{
                  color: "black",
                  borderRadius: 28,
                  backgroundColor: portionsSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                }}
                fullWidth
                variant={value === 3 ? "contained" : "outlined"}
                onClick={() => setValue(3)}
              >
                Portions
              </Button>
              <Button
                color="primary"
                style={{
                  backgroundColor: laborSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                  borderRadius: 28,
                  color: "black",
                }}
                fullWidth
                variant={value === 4 ? "contained" : "outlined"}
                onClick={() => setValue(4)}
              >
                Labor Hours
              </Button>
              <Button
                color="primary"
                style={{
                  color: "black",
                  borderRadius: 28,
                  backgroundColor: imgSet ? buttonColor : "lightgrey",
                  fontSize: isMobile ? "8px" : "inherit",
                }}
                fullWidth
                variant={value === 5 ? "contained" : "outlined"}
                onClick={() => setValue(5)}
              >
                Pictures
              </Button>
            </>
          }
          secondchild={
            <>
              {value == 0 && (
                <DishNameAndType
                  numberofportions={numberofportions}
                  setnumberofportions={(a) => {}}
                  loading={false}
                  recipes={[]}
                  form={form}
                  setForm={setForm}
                  set={nameAndTypeSet}
                />
              )}
              {value == 1 && (
                <RecipesandIngredientsSection
                  setnumberofportions={(a) => {}}
                  loading={loading}
                  recipes={recipes}
                  set={recipesAndIngredientsSet}
                  form={form}
                  setForm={setForm}
                  numberofportions={numberofportions}
                />
              )}
              {value == 2 && (
                <Method
                  setnumberofportions={(a) => {}}
                  numberofportions={numberofportions}
                  loading={false}
                  recipes={[]}
                  set={methodSet}
                  form={form}
                  setForm={setForm}
                />
              )}
              {value == 3 && (
                <PortionsSection
                  setnumberofportions={setnumberofportions}
                  numberofportions={numberofportions}
                  loading={false}
                  recipes={[]}
                  set={portionsSet}
                  form={form}
                  setForm={setForm}
                />
              )}
              {value == 4 && (
                <HoursSection
                  setnumberofportions={(a) => {}}
                  numberofportions={numberofportions}
                  loading={false}
                  recipes={[]}
                  set={laborSet}
                  form={form}
                  setForm={setForm}
                />
              )}
              {value == 5 && (
                <PicturesSection
                  setnumberofportions={(a) => {}}
                  numberofportions={numberofportions}
                  loading={false}
                  recipes={[]}
                  set={imgSet}
                  form={form}
                  setForm={setForm}
                />
              )}
            </>
          }
        />
      </Box>
    </Box>
  );
};

interface TabProps {
  form: AddDishVariables;
  setForm: (f: AddDishVariables) => void;
  set: boolean;
  recipes: QuantityToRecipe[];
  loading: boolean;
  setnumberofportions: (a: number) => void;
  numberofportions: number;
}

export const DishNameAndType: React.FC<TabProps> = ({ form, setForm, set }) => {
  const disabled = form.input.name.length === 0 || form.input.type.length === 0;
  return (
    <>
      <SectionTitle label="Name and Type" />
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={5}
        secondlength={5}
        firstchild={
          <FormField
            label="Name"
            value={form.input.name}
            setValue={(a: string) =>
              setForm({
                ...form,
                input: {
                  ...form.input,
                  name: a,
                },
              })
            }
          />
        }
        secondchild={
          <FormField
            label="Type"
            value={form.input.type}
            setValue={(a: string) =>
              setForm({
                ...form,
                input: {
                  ...form.input,
                  type: a,
                },
              })
            }
          />
        }
      />
      <CheckPart set={set} label={"Name and Type Set?"} />
    </>
  );
};

export interface CheckPartProps {
  label: string;
  set: boolean;
}

export const CheckPart: React.FC<CheckPartProps> = ({ label, set }) => {
  return (
    <GridRowOneItem
      before={0.5}
      after={0}
      child={
        <FormControlLabel
          control={
            <Checkbox checked={set} style={{ color: aestheticBackground }} />
          }
          label={label}
        />
      }
    />
  );
};
export const SectionTitle = ({ label }: { label: string }) => {
  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <Typography fontWeight={700} variant="h6">
            {label}
          </Typography>
        }
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
    </>
  );
};
export const Method: React.FC<TabProps> = ({ form, setForm, set }) => {
  const disabled = form.input.method.length === 0;
  return (
    <>
      <SectionTitle label="Method" />
      <GridRowOneItem
        before={0.5}
        after={2}
        child={
          <FormField
            mult={true}
            value={form.input.method}
            setValue={(name) => {
              setForm({
                ...form,
                input: {
                  ...form.input,
                  method: name,
                },
              });
            }}
          />
        }
      />
      <CheckPart label={"Method Set?"} set={set} />
    </>
  );
};

export const RecipesandIngredientsSection: React.FC<TabProps> = ({
  loading,
  recipes,
  form,
  setForm,
  set,
}) => {
  const disabled =
    form.recipes.dishPortion.quantity.quantity === 0 &&
    form.products.dishPortion.quantity.quantity === 0;
  return (
    <>
      <RecipeAndIngredientsForDish
        loadingRecipes={loading}
        dishForm={form}
        recipes={recipes}
        setDishForm={setForm}
      />
      <CheckPart set={set} label={"Recipes and Ingredients Set?"} />
    </>
  );
};

export const PortionsSection: React.FC<TabProps> = ({
  numberofportions,
  setnumberofportions,
  form,
  setForm,
  set,
}) => {
  return (
    <>
      <SectionTitle label="Portions" />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <>
            <DisplayPortions
              unit={form.products.dishPortion.quantity.unit}
              edit={true}
              portions={form.portions}
              setportions={(a) => {
                setnumberofportions(a.length);
                setForm({
                  ...form,
                  portions: a,
                });
              }}
            />
            <CheckPart set={set} label={"Portions Set?"} />
          </>
        }
      />
    </>
  );
};

export const HoursSection: React.FC<TabProps> = ({ set, form, setForm }) => {
  const disabled =
    form.hours === undefined || form.hours === null || form.hours.amount === 0;
  return (
    <>
      <SectionTitle label="Labour Hours:" />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <>
            <HoursWorked1
              setInfo={(a: AmountPerQuantityInput) => {
                setForm({
                  ...form,
                  hours: {
                    quantity: a.quantity,
                    amount: a.amount,
                  },
                });
              }}
              info={form.hours!}
            />
            <CheckPart set={set} label={"Hours Set?"} />
          </>
        }
      />
    </>
  );
};

export const PicturesSection: React.FC<TabProps> = ({ form, setForm, set }) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const handleChangeFile = async (files: FileList | null) => {
    if (files && files.length > 0) {
      try {
        const imageFiles = Array.from(files).filter(
          (file) => file.type === "image/png" || file.type === "image/jpeg"
        );
        const urls = await Promise.all(
          imageFiles.map((file) => readFileAsDataURL(file))
        );
        setImageUrls(urls);
        setForm({
          ...form,
          input: {
            ...form.input,
            urls: urls,
          },
        });
      } catch (error) {
        console.error("Error reading files:", error);
      }
    }
  };

  return (
    <>
      <SectionTitle label="Images:" />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={
          <>
            <ImageSection
              urls={returnStringsIfNotNullOrUndefined(form.input.urls)}
              setNoPic={() =>
                setForm({
                  ...form,
                  input: {
                    ...form.input,
                    urls: [],
                  },
                })
              }
              handleChangeFile={handleChangeFile}
              imageUrls={imageUrls}
            />
          </>
        }
      />
      <CheckPart set={set} label={"Pictures Set?"} />
    </>
  );
};
