import { Box, Button, Divider, Typography } from "@mui/material";
import { H5 } from "../../../components/TextTypes";
import {
  GridRowOneItem,
  GridRowTwoItems,
} from "../../../components/layouts/Grids";
import {
  aestheticBackground,
  buttonColor,
} from "../../../components/layouts/Colors";
import { FaTripadvisor } from "react-icons/fa";
import { tripadvisorLink } from "..";

export const getTripAdvisorLink = (name: string): string => {
  if (name === "Summer Menu 2023")
    return "https://www.tripadvisor.com/Restaurant_Review-g188575-d18894587-Reviews-Soup_Bros-Maastricht_Limburg_Province.html";
  if (name === "Clean Foods Menu") return "https://www.tripadvisor.com/";
  return "https://www.tripadvisor.nl/Restaurant_Review-g188578-d17754742-Reviews-Mocca-Venlo_Limburg_Province.html";
};

export const Head = ({ name }: { name: string }) => {
  return (
    <Box
      sx={{
        mt: 5,
        // border: 0.5,
        backgroundColor: aestheticBackground,
        borderRadius: 28,
      }}
    >
      <GridRowTwoItems
        before={0}
        firstlength={9}
        secondlength={1}
        inbetween={1}
        firstchild={
          <Typography
            align="left"
            variant="h4"
            component="div"
            style={{ fontFamily: "Playfair Display, serif" }}
          >
            {name}
          </Typography>
        }
        secondchild={
          <Button
            fullWidth
            sx={{ borderRadius: 28, backgroundColor: buttonColor }}
            variant="contained"
            href={getTripAdvisorLink(name)}
            target="_blank"
          >
            <FaTripadvisor size={30} />
          </Button>
        }
      />
      <Divider />
    </Box>
  );
};
