export const distinctstrings = (a: string[], b: string[]): string[] => {
  const set = new Set([...a, ...b]);

  return Array.from(set.values());
};

export const distinctstringsOneArray = (a: string[]): string[] => {
  const set = new Set([...a]);

  return Array.from(set.values());
};
