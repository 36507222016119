import {
  IngredientToQuantityToSalePriceInput,
  MeasureInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../globalTypes";
import {
  zeroNutritionInput,
  zerohealthlabelinfo,
  zeronutrition,
} from "../../../screens/chefsbase/dishes/components/EmptyVariables";
import { CurrentMenu_currentMenu_courses_composables_composableRounds_foods } from "../../../screens/clientbase/types/CurrentMenu";
import { gramMeasure, gramMeasureInput, small } from "../portions/PortionInput";

export const emptyIngredientToQuantityToSalePriceInput: IngredientToQuantityToSalePriceInput =
  {
    nutrition: { quantity: small.quantity, nutrition: zeroNutritionInput },
    quantity: small.quantity,
    price: 1,
    id: "",
    name: "",
    img: "",
  };

export const emptyCurrentMenu_currentMenu_courses_composables_varProd: CurrentMenu_currentMenu_courses_composables_composableRounds_foods =
  {
    __typename: "IngredientToQuantityToSalePrice",
    measurementInfo: {
      __typename: "MeasurementInfo",
      serving: 0,
      whole: 0,
      tablespoon: 0,
      teaspoon: 0,
      cup: 0,
    },
    name: "",
    id: "",
    quantity: {
      __typename: "Quantity",
      quantity: 0,
      unit: gramMeasure,
    },
    price: 0,
    nutrition: {
      __typename: "QuantityToNutrition",
      quantity: {
        __typename: "Quantity",
        quantity: 100,
        unit: gramMeasure,
      },
      nutrition: zeronutrition,
    },
    healthLabelInfo: zerohealthlabelinfo,
  };

export const emptyQuantityToNameIdAndNutritionInput: QuantityToNameIdAndNutritionInput =
  {
    name: "",
    id: "",
    quantity: small.quantity,
    img: "",
    nutrition: zeroNutritionInput,
  };

export const anyMeasure: MeasureInput = {
  uri: "",
  label: "",
  weight: 1,
};

export const fullyEmptyQuantityToNameIdAndNutritionInput: QuantityToNameIdAndNutritionInput =
  {
    name: "",
    id: "",
    quantity: {
      quantity: 0,
      unit: anyMeasure,
    },
    img: "",
    nutrition: zeroNutritionInput,
  };
