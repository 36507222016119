import { useTheme } from "@emotion/react";
import {
  Grid,
  LinearProgress,
  Button,
  Dialog,
  DialogContent,
  Avatar,
  DialogActions,
} from "@mui/material";
import React, { useState } from "react";
import { H5 } from "../TextTypes";
import { SubmitButton } from "../Buttons";

export const ProcessingContent = ({ title }: { title: string }) => {
  return (
    <>
      <Grid item xs={12}>
        <H5 title={title} />
      </Grid>
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
};

export const ErrorDialog = () => {
  const [open, setopen] = useState(false);
  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            Not updated, please try again
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SubmitButton variant="contained" onClick={() => setopen(false)}>
          Close
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
};
export const ProcessingDialog = ({
  loading,
  title,
}: {
  loading: boolean;
  title: string;
}) => {
  const theme = useTheme();
  return (
    <Dialog open={loading}>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Avatar
              sx={{ mr: 2 }}
              variant="rounded"
              alt=""
              src="/images/MCB_logo.png"
            />
          </Grid>
          <ProcessingContent title={title} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
