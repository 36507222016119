import React, { useState } from "react";
import { GrPowerReset } from "react-icons/gr";
import {
  Card,
  Typography,
  CardContent,
  Stack,
  InputLabel,
  Grid,
  Button,
  Box,
  Divider,
} from "@mui/material";
import { VscArrowUp, VscArrowDown, VscCheck } from "react-icons/vsc";
import { startMicroMacroForm } from "../../../../components/defaults/micromacroform/StartMicroMacroForm";
import { FormSelectMultiple } from "../../../../components/forms/singleForms/FormSelectMultiple";
import {
  aestheticBackground,
  backgroundField,
  buttonColor,
} from "../../../../components/layouts/Colors";
import {
  GridRowFourItems,
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { MicroMacroFieldRow } from "./MicroMacroFieldRow";
import { MicroMacroField } from "./types";

interface NutritionInputCardProps {
  collapse: boolean;
  setmicromacroform: (a: MicroMacroField) => void;
  setAllergyChoices: (a: AllergyChoices) => void;
  setcollapse: (a: boolean) => void;
  microMacroForm: MicroMacroField;
  allergyChoices: AllergyChoices;
}

export type AllergyChoices = {
  first: string[];
  second: string[];
};
export const allergyOptions: AllergyChoices = {
  first: ["Fish-Free", "Meat-Free", "Vegan"],
  second: [
    "Egg-Free",
    "Dairy-Free",
    "Gluten-Free",
    "Lupine-Free",
    "Milk-Free",
    "Mustard-Free",
    "Pork-Free",
    "Shellfish-Free",
    "Soy-Free",
    "Sesame-Free",
    "Celery-Free",
    "Crustaceans-Free",
    "Peanut-Free",
    "Nut-Free",

    // "Mollusk"
    // "Sulphur dioxide",
  ],
};
export const NutritionInputCard2: React.FC<NutritionInputCardProps> = ({
  collapse,
  setmicromacroform,
  setAllergyChoices,
  setcollapse,
  microMacroForm,
  allergyChoices,
}) => {
  return (
    <GridRowOneItem
      before={0}
      after={0}
      child={
        <Box
          sx={{
            maxWidth: "95%",
            backgroundColor: aestheticBackground,
            // borderRadius: collapse ? 5 : 28,
          }}
        >
          <Box sx={{ mt: 0, ml: 3 }}>
            <Box onClick={() => setcollapse(!collapse)}>
              <GridRowTwoItems
                before={0}
                firstlength={10}
                secondlength={1}
                inbetween={1}
                firstchild={
                  <Typography
                    align="left"
                    variant="h6"
                    component="div"
                    style={{ fontFamily: "Playfair Display, serif" }}
                  >
                    Choose your nutrition
                  </Typography>
                }
                secondchild={
                  collapse ? (
                    <VscArrowUp
                      style={{ cursor: "pointer", height: 30, width: 30 }}
                    />
                  ) : (
                    <VscArrowDown
                      style={{ cursor: "pointer", height: 30, width: 30 }}
                    />
                  )
                }
              />
              <Divider />
            </Box>
            {collapse && (
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                <Box sx={{ mt: 2 }}>
                  <GridRowTwoItems
                    firstchild={
                      <FormSelectMultiple
                        label="1. Fish/Meat/Vegan"
                        options={allergyOptions.first}
                        value={allergyChoices.first}
                        handleChange={(e) =>
                          typeof e.target.value === "string"
                            ? setAllergyChoices({
                                first: [e.target.value],
                                second: allergyChoices.second,
                              })
                            : setAllergyChoices({
                                first: e.target.value,
                                second: allergyChoices.second,
                              })
                        }
                      />
                    }
                    secondchild={
                      <FormSelectMultiple
                        label="2. Allergies"
                        options={allergyOptions.second}
                        value={allergyChoices.second}
                        handleChange={(e) =>
                          typeof e.target.value === "string"
                            ? setAllergyChoices({
                                second: [e.target.value],
                                first: allergyChoices.first,
                              })
                            : setAllergyChoices({
                                second: e.target.value,
                                first: allergyChoices.first,
                              })
                        }
                      />
                    }
                    firstlength={5.5}
                    secondlength={5.5}
                    before={0}
                    inbetween={0.5}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <GridRowOneItem
                    before={0}
                    after={0}
                    child={
                      <Box sx={{ mb: 2 }}>
                        <Stack direction="column" spacing={0.5}>
                          <Grid container spacing={0}>
                            <Grid item xs={4}>
                              <div style={{ margin: "0 -8px" }}>
                                <Box sx={{ mb: 1 }}>
                                  <InputLabel variant="standard">
                                    3. Macros
                                  </InputLabel>
                                </Box>
                              </div>
                            </Grid>
                            <Grid item xs={4}></Grid>
                          </Grid>
                          <Stack direction="row" spacing={0.5}>
                            {/* <MicroMacroFieldRow
                          micro="Price"
                          microMacroForm={microMacroForm}
                          setmicromacroform={(a) => setmicromacroform(a)}
                        /> */}
                            <MicroMacroFieldRow
                              micro="Kcal"
                              microMacroForm={microMacroForm}
                              setmicromacroform={(a) => setmicromacroform(a)}
                            />
                            <MicroMacroFieldRow
                              micro="Prot"
                              microMacroForm={microMacroForm}
                              setmicromacroform={(a) => setmicromacroform(a)}
                            />
                            <MicroMacroFieldRow
                              micro="Fat"
                              microMacroForm={microMacroForm}
                              setmicromacroform={(a) => setmicromacroform(a)}
                            />
                            <MicroMacroFieldRow
                              micro="Carbs"
                              microMacroForm={microMacroForm}
                              setmicromacroform={(a) => setmicromacroform(a)}
                            />
                          </Stack>
                        </Stack>
                      </Box>
                    }
                  />
                </Box>
                <GridRowTwoItems
                  firstchild={
                    <Button
                      sx={{
                        borderRadius: 28,
                        height: 30,
                        backgroundColor: buttonColor,
                      }}
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        setAllergyChoices({
                          second: [""],
                          first: [""],
                        });
                        setmicromacroform(startMicroMacroForm);
                      }}
                    >
                      <GrPowerReset />
                    </Button>
                  }
                  secondchild={
                    <Button
                      sx={{
                        borderRadius: 28,
                        height: 30,
                        backgroundColor: buttonColor,
                      }}
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        setcollapse(false);
                      }}
                    >
                      <VscCheck />
                    </Button>
                  }
                  before={0.5}
                  firstlength={4}
                  secondlength={5}
                  inbetween={2}
                />
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const transparentBackground = hexToRgba(aestheticBackground, 0.1);
