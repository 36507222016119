import Axios from "axios";
import { configNutr } from "../../screens/chefsbase/productsfromapi";
import { HealthLabelInfo, QuantityInput } from "../../globalTypes";
import { ResultForProductSearch } from "./types";
import {
  gramMeasure,
  gramMeasureInput,
} from "../defaults/portions/PortionInput";
import { useState } from "react";

export const distinctIdToHealthLabel = (
  food: IdToHealthLabel[]
): IdToHealthLabel[] => {
  const distinct: IdToHealthLabel[] = [];
  food.map((sel) => {
    if (!distinct.map((d) => d.foodid).includes(sel.foodid)) {
      distinct.push(sel);
    }
  });
  return distinct;
};

export const distinctIdToHealthLabelRecipe = (
  recipes: IdToHealthLabelInfo[]
): IdToHealthLabelInfo[] => {
  const distinct: IdToHealthLabelInfo[] = [];
  recipes.map((sel) => {
    if (!distinct.map((d) => d.id).includes(sel.id)) {
      distinct.push(sel);
    }
  });
  return distinct;
};

export type AllergyForm = {
  food: IdToHealthLabel[];
  recipe: IdToHealthLabelInfo[];
};
export const emptyAllergyForm: AllergyForm = {
  food: [],
  recipe: [],
};
export type IdToHealthLabel = {
  foodid: string;
  healthlabels: string[];
};
export type IdToHealthLabelInfo = {
  id: string;
  healthLabelInfo: HealthLabelInfo | null | undefined;
};
export const getHealthLabels = async (
  foodids: string[],
  idToHealthlabels: IdToHealthLabel[],
  setIdToHealthLabels: (a: IdToHealthLabel[]) => void
) => {
  try {
    const promises = foodids.map(async (foodid) => {
      const dataInput = {
        ingredients: [
          {
            quantity: 100,
            measureURI: gramMeasure.uri,
            foodId: foodid,
          },
        ],
      };
      const result = await Axios.post(
        "https://api.edamam.com/api/food-database/v2/nutrients",
        dataInput,
        configNutr
      );
      return {
        foodid: foodid,
        healthlabels: result.data.healthLabels,
      };
    });

    const newIdToHealthLabels = await Promise.all(promises);

    // Merge the new health labels with the existing ones
    const updatedIdToHealthLabels = [
      ...idToHealthlabels,
      ...newIdToHealthLabels,
    ];
    setIdToHealthLabels(updatedIdToHealthLabels);
  } catch (error) {
    console.error("Error fetching health labels:", error);
  }
};
export const setNutr = async (
  id: string,
  quantity: QuantityInput,
  setNutrition: (a: ResultForProductSearch) => void
) => {
  if (id !== "") {
    const dataInput = {
      ingredients: [
        {
          quantity: quantity.quantity,
          measureURI: quantity.unit.uri,
          foodId: id,
        },
      ],
    };
    const result = await Axios.post(
      "https://api.edamam.com/api/food-database/v2/nutrients",
      dataInput,
      configNutr
    );

    setNutrition({
      ingredients: result.data.ingredients,
      totalNutrients: result.data.totalNutrients,
    });
  } else {
  }
};

export const getNutr = async (
  id: string,
  quantity: QuantityInput
): Promise<ResultForProductSearch> => {
  if (id === "") return emptyResult();
  const dataInput = {
    ingredients: [
      {
        quantity: quantity.quantity,
        measureURI: quantity.unit.uri,
        foodId: id,
      },
    ],
  };
  const result = await Axios.post(
    "https://api.edamam.com/api/food-database/v2/nutrients",
    dataInput,
    configNutr
  );

  return {
    ingredients: result.data.ingredients,
    totalNutrients: result.data.totalNutrients,
  };
};

const emptyResult = ():
  | ResultForProductSearch
  | PromiseLike<ResultForProductSearch> => ({
  ingredients: [
    {
      parsed: [
        { food: "", foodId: "", measure: "", measureURI: "", quantity: 0 },
      ],
    },
  ],
  totalNutrients: {
    ENERC_KCAL: { label: "", quantity: 0, unit: "" },
    FAT: { label: "", quantity: 0, unit: "" },
    FASAT: { label: "", quantity: 0, unit: "" },
    CHOCDF: { label: "", quantity: 0, unit: "" },
    FIBTG: { label: "", quantity: 0, unit: "" },
    SUGAR: { label: "", quantity: 0, unit: "" },
    PROCNT: { label: "", quantity: 0, unit: "" },
    NA: { label: "", quantity: 0, unit: "" },
    quantity: { quantity: 0, unit: gramMeasureInput() },
    healthLabels: [],
  },
});
