import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { buttonColor } from "./layouts/Colors";

interface ParentCompProps {
  childComp?: React.ReactNode;
  mt?: number;
  md?: number;
  w?: string;
}
export const BoxTitle = ({ title }: { title: string }) => {
  return (
    <Typography align="center" variant="h5">
      {title}
    </Typography>
  );
};

export const Box2mt: React.FC<ParentCompProps> = (props) => {
  const { mt, childComp, md } = props;
  return (
    <Box
      sx={{
        mt: mt ? mt : 0,
        mb: md ? md : 0,
      }}
    >
      {childComp}
    </Box>
  );
};
export const FirstBox: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;
  return (
    <Box
      sx={{
        width: "80vw",
        marginTop: 8,
        border: 0.1,
        borderRadius: "16px",
        borderColor: "blue",
      }}
    >
      {childComp}
    </Box>
  );
};

export const MainBox: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "300%",
        marginTop: 4,
        borderRadius: "16px",
      }}
    >
      {childComp}
    </Box>
  );
};

export const AddPageBox: React.FC<ParentCompProps> = (props) => {
  const { childComp, w, mt } = props;
  return (
    <Box
      sx={{
        width: w ? w : "50vw",
        marginTop: mt ? mt : 8,
        marginLeft: 0,
        // border: 0.1,
        borderRadius: "16px",
        borderColor: "blue",
      }}
    >
      {childComp}
    </Box>
  );
};

export const AddPageTableBox: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;
  return (
    <Box
      sx={{
        width: "100vw",
        marginTop: 8,
        // border: 0.1,
        borderRadius: "16px",
        borderColor: "blue",
      }}
    >
      {childComp}
    </Box>
  );
};

export const MethodPageBox: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;
  return (
    <Box
      sx={{
        width: "30vw",
        marginTop: 8,
        // border: 0.1,
        borderRadius: "16px",
        borderColor: "blue",
      }}
    >
      {childComp}
    </Box>
  );
};

export const SecondBox: React.FC<ParentCompProps> = (props) => {
  const { childComp } = props;
  return (
    <Box
      component="main"
      sx={{
        mt: 5,
        width: 600,
        alignItems: "center",
        borderRadius: "16px",
        backgroundColor: buttonColor,
        border: 1,
        borderColor: "blue",
      }}
    >
      {childComp}
    </Box>
  );
};
