import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  GridRowOneItem,
  GridRowThreeItems,
  GridRowTwoItems,
} from "../../../../components/layouts/Grids";
import { aestheticBackground } from "../../../../components/layouts/Colors";
import { totalNutrition } from "../courses/Functions";
import {
  DisplayIngredients,
  DisplayNutritionInput,
  DisplayRecipes,
  writePortionName,
} from "../../../chefsbase/productsfromapi";
import { MicroMacroField } from "../MicroMacroField/types";
import {
  emptyFoodResult,
  emptyRecipeForDishItem,
} from "../../../../components/defaults/RecipeForDish/EmptyDishComponents";
import { measureToMeasureInput } from "../../createClientPage/forms/DishCard/Mappers";
import { useItemsForDishQuery } from "../../../chefsbase/dishes/api";
import {
  CurrentMenu_currentMenu,
  CurrentMenu_currentMenu_combos,
  CurrentMenu_currentMenu_combos_dishes,
} from "../../types/CurrentMenu";
import { Loader } from "../../../../components/Loading/Loader";
import {
  DishItems,
  DishItems_dish_products_foods,
  DishItems_dish_recipes_recipes,
} from "../../../chefsbase/dishes/types/DishItems";
import { mapCombosForSlides, SlideDishes } from "../courses/DisplayDishButton";
import { roundToZeroOr2 } from "../../../../components/utils/DisplayQuantity";
import { sortPortionsWithNutrition } from "../../../chefsbase/dishes/dishDialog/DishContent";
import { createRange } from "../../../../components/utils/CreateRange";
import { createNameToId } from "../../../../components/utils/CreateNameToId";
import { FormSelect } from "../../../../components/forms/singleForms/FormSelect";
import { DishToIndexToPortion } from "../../types/types";

export const numberOrZero = (a: number | null | undefined): number => {
  return a !== undefined && a !== null ? a : 0;
};

export const ShowCombos = ({
  currentmenu,
  microMacroForm,
  options,
}: {
  options: string[];
  microMacroForm: MicroMacroField;
  currentmenu: CurrentMenu_currentMenu;
}) => {
  const maxRound = Math.max(
    ...currentmenu.combos.map((i) => i.dishes.map((d) => d.round)).flat()
  );
  const dishes = currentmenu.courses
    .map((c) => c.selectables)
    .flat()
    .map((s) => s.selectables)
    .flat()
    .map((s) => ({
      img: s.dish.img ? s.dish.img : ["/images/MCB_logo.png"],
      id: s.dish.id,
    }));
  const mappedCombos = mapCombosForSlides(
    dishes,
    options,
    currentmenu.combos,
    maxRound
  );
  const [rounds, setRounds] = useState(Math.min(...[maxRound, 2]));
  const [indicesToPortionToDish, setIndicesToPortionToDish] = useState<
    DishToIndexToPortion[]
  >(
    mappedCombos
      .filter((mappedCombo, index) => index < rounds)
      .map((mappedCombo, index) => ({
        dish: mappedCombo.options[0],
        index: 0,
        portion: sortPortionsWithNutrition(
          mappedCombo.options[0].portions.filter((p) => p.name !== "Manual")
        )[0],
      }))
  );

  const totalNutr = totalNutrition(
    indicesToPortionToDish.map((i) => i.portion.nutrition)
  );
  const titleForCombo = (
    indicesToPortionToDish: DishToIndexToPortion[]
  ): string[] => {
    return indicesToPortionToDish.map(
      (indexToPortionToDish, i) =>
        `${indexToPortionToDish.dish.name} (${writePortionName(
          indexToPortionToDish.portion.name
        )})`
    );
  };
  const comboFound = currentmenu.combos.find(
    (combo) =>
      combo.dishes.length === indicesToPortionToDish.length &&
      indicesToPortionToDish.filter(
        (ind, i) =>
          combo.dishes.find(
            (d) =>
              d.round === i + 1 &&
              d.dish.id === ind.dish.id &&
              ind.portion.name === d.portion.name
          ) !== undefined
      ).length === indicesToPortionToDish.length
  );

  const title = (
    <ol>
      {titleForCombo(indicesToPortionToDish).map((ii, index) => (
        <li key={index}>{ii}</li>
      ))}
    </ol>
  );

  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <Stack direction="column" spacing={2}>
            <GridRowThreeItems
              before={0}
              firstlength={1}
              inbetweenfirsttwo={0}
              secondlength={10}
              inbetweensecondtwo={0}
              thirdlength={1}
              firstchild={<></>}
              secondchild={
                <Card
                  sx={{
                    width: "65vw",
                    backgroundColor: aestheticBackground,
                    borderRadius: 5,
                  }}
                >
                  <CardContent>
                    <Typography
                      align="center"
                      variant="h6"
                      fontFamily={"FairField Display, serif"}
                      sx={{ mt: 0 }}
                    >
                      {title}
                    </Typography>
                    <DisplayNutritionInput
                      direction="row"
                      price={roundToZeroOr2(comboFound ? comboFound.price : 0)}
                      frac={1}
                      nutritionInput={totalNutr}
                    />
                    <Box sx={{ mt: 2 }}>
                      <GridRowTwoItems
                        before={0}
                        firstlength={6}
                        secondlength={5}
                        inbetween={1}
                        firstchild={"Number of rounds:"}
                        secondchild={
                          <FormSelect
                            value={String(rounds)}
                            options={createNameToId(
                              createRange(1, maxRound + 1)
                            )}
                            setValue={(a) => {
                              setRounds(Number(a));
                              setIndicesToPortionToDish(
                                mappedCombos
                                  .filter(
                                    (mappedCombo, index) => index < Number(a)
                                  )
                                  .map((mappedCombo, index) => ({
                                    dish: mappedCombo.options[0],
                                    index: 0,
                                    portion: sortPortionsWithNutrition(
                                      mappedCombo.options[0].portions.filter(
                                        (p) => p.name !== "Manual"
                                      )
                                    )[0],
                                  }))
                              );
                            }}
                          />
                        }
                      />
                    </Box>
                  </CardContent>
                </Card>
              }
              thirdchild={<></>}
            />
            {indicesToPortionToDish.map((indexToPortionToDish, i) => (
              <SlideDishes
                setPortion={(a) => {
                  const old = [...indicesToPortionToDish];
                  old[i] = {
                    ...indexToPortionToDish,
                    portion: a,
                  };
                  setIndicesToPortionToDish(old);
                }}
                indexToPortion={{
                  index: indexToPortionToDish.index,
                  portion: indexToPortionToDish.portion,
                }}
                micromacroform={microMacroForm}
                input={mappedCombos[i].options}
                index={indexToPortionToDish.index}
                setindex={(a) => {
                  const old = [...indicesToPortionToDish];
                  old[i] = {
                    ...old[i],
                    dish: mappedCombos[i].options[a],
                    index: a,
                    portion: sortPortionsWithNutrition(
                      mappedCombos[i].options[a].portions.filter(
                        (p) => p.name !== "Manual"
                      )
                    )[0],
                  };
                  setIndicesToPortionToDish(old);
                }}
                options={options}
              />
            ))}
          </Stack>
        }
      />
    </>
  );
};

export const OneComposable = ({
  dish,
}: {
  dish: CurrentMenu_currentMenu_combos;
}) => {
  return <></>;
};
//     <Grid container spacing={1}>
//       <Box sx={{ mt: 1 }} height={"40px"}>
//         <Typography variant="body1">
//           <b>{dish.name}</b>
//         </Typography>
//       </Box>
//       <Grid item xs={12} sm={6} md={6}>
//         <Divider />
//         <>
//           {dish.baseProd.length > 0 &&
//             dish.baseProd.map((prod) => (
//               <Typography noWrap variant="body2" key={prod.name}>
//                 * {prod.name} <br />
//                 {prod.quantity.quantity} {prod.quantity.unit.label}
//               </Typography>
//             ))}
//         </>
//       </Grid>
//       <Grid item xs={12} sm={6} md={6}>
//         <>
//           {dish.baseRec.length > 0 &&
//             dish.baseRec.map((prod) => (
//               <Typography noWrap variant="body2" key={prod.name}>
//                 * {prod.name} <br />
//                 {prod.quantity.quantity} {prod.quantity.unit.label}
//               </Typography>
//             ))}
//         </>
//       </Grid>
//     </Grid>
//   );
// };
