import { gql, useMutation } from "@apollo/client";
import { KitchenType } from "../../globalTypes";
import { DeleteItem, DeleteItemVariables } from "./types/DeleteItem";
import { DeletesItem, DeletesItemVariables } from "./types/DeletesItem";

const DeleteMutation = gql`
  mutation DeleteItem($id: String!, $kitchenType: KitchenType!) {
    delete(id: $id, kitchenType: $kitchenType)
  }
`;

const DeletesMutation = gql`
  mutation DeletesItem($ids: [String!]!, $kitchenType: KitchenType!) {
    deletes(ids: $ids, kitchenType: $kitchenType)
  }
`;

export const useDeletesItemMutation = ({
  onCompleted,
  ids,
  kitchenType,
}: {
  ids: string[];
  kitchenType: KitchenType;
  onCompleted: () => void;
}) => {
  const [deleteItems, { loading, error }] = useMutation<
    DeletesItem,
    DeletesItemVariables
  >(DeletesMutation, {
    variables: {
      ids: ids,
      kitchenType: kitchenType,
    },
    onCompleted: () => onCompleted(),
  });
  return {
    deleteItems,
    loading,
    error,
  };
};

export const useDeleteItemMutation = ({
  onCompleted,
  id,
  kitchenType,
}: {
  id: string;
  kitchenType: KitchenType;
  onCompleted: () => void;
}) => {
  const [deleteItem, { loading, error }] = useMutation<
    DeleteItem,
    DeleteItemVariables
  >(DeleteMutation, {
    variables: {
      id: id,
      kitchenType: kitchenType,
    },
    onCompleted: () => onCompleted(),
  });
  return {
    deleteItem,
    loading,
    error,
  };
};
