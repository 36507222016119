import {
  QuantityToNameIdAndNutritionInput,
  NutritionInput,
  MeasureInput,
  QuantityInput,
  MeasurementInfoInput,
} from "../../../../../globalTypes";
import {
  zeroNutritionInput,
  emptyhealthlabelinfo,
} from "../../../../chefsbase/dishes/components/EmptyVariables";
import {
  Dish_dish_recipes_dishPortion_quantity,
  Dish_dish_recipes_dishPortion_quantity_unit,
} from "../../../../chefsbase/dishes/types/Dish";
import { QuantityToProduct, Product } from "../../types/QuantityToProduct";

import { MeasurementInfo, QuantityToRecipe } from "../../../types/types";
import { scaleNutrition } from "../../../components/courses/Functions";
import { getFraction } from "../../../../../components/utils/GetFraction";

export const mapToRecipeInput = (
  t: QuantityToRecipe
): QuantityToNameIdAndNutritionInput => {
  return {
    quantity: t.quantity,
    name: t.recipe.name,
    nutrition:
      t.quantity.quantity > 0 && t.recipe.nutrition.quantity.quantity > 0
        ? scaleNutrition(
            getFraction(t.recipe.nutrition.quantity, t.quantity),
            t.recipe.nutrition.nutrition
          )
        : t.recipe.nutrition.nutrition,
    id: t.recipe.id,
    healthlabelInfo: t.recipe.healthLabelInfo,
  };
};

export const mapToRecipeInputs = (
  a: QuantityToRecipe[]
): QuantityToNameIdAndNutritionInput[] => a.map((t) => mapToRecipeInput(t));

export const mapToRecipe = (
  a: QuantityToNameIdAndNutritionInput,
  b?: number
): QuantityToRecipe => {
  return {
    quantity: b
      ? {
          quantity: b,
          unit: {
            label: a.quantity.unit.label,
            uri: a.quantity.unit.uri,
            weight: a.quantity.unit.weight,
          },
        }
      : a.quantity,
    recipe: {
      nutrition: { quantity: a.quantity, nutrition: a.nutrition },
      name: a.name,
      id: a.id,
      healthLabelInfo: a.healthlabelInfo
        ? a.healthlabelInfo
        : emptyhealthlabelinfo,
    },
  };
};

export const measureInputToMeasure = (
  a: MeasureInput
): Dish_dish_recipes_dishPortion_quantity_unit => ({
  ...a,
  __typename: "Measure",
});
export const measureToMeasureInput = (
  a: Dish_dish_recipes_dishPortion_quantity_unit
): MeasureInput => ({
  uri: a.uri,
  label: a.label,
  weight: a.weight,
});

export const quantityToInput = (
  a: Dish_dish_recipes_dishPortion_quantity
): QuantityInput => ({
  quantity: a.quantity,
  unit: {
    uri: a.unit.uri,
    label: a.unit.label,
    weight: a.unit.weight,
  },
});

export const measurementToMeasurement = (
  a: MeasurementInfoInput | undefined
): MeasurementInfo | undefined => {
  if (a == undefined) return undefined;
  return {
    serving: a.serving !== null ? a.serving : 0,
    cup: a.cup !== null ? a.cup : 0,
    teaspoon: a.teaspoon !== null ? a.teaspoon : 0,
    tablespoon: a.tablespoon !== null ? a.tablespoon : 0,
    whole: a.whole !== null ? a.whole : 0,
  };
};
export const mapToProduct = (
  a: QuantityToNameIdAndNutritionInput
): QuantityToProduct => ({
  quantity: a.quantity,
  product: {
    measurementInfo:
      a.measurementInfo !== null
        ? measurementToMeasurement(a.measurementInfo)
        : undefined,
    measures: [measureInputToMeasure(a.quantity.unit)],
    food: {
      label: a.name,
      foodId: a.id,
      category: "",
      image: a.img ? a.img : "",
      nutrients: {
        ENERC_KCAL: a.nutrition.kcal,
        FAT: a.nutrition.fatstotal,
        FASAT: a.nutrition.saturedfat,
        PROCNT: a.nutrition.prottotal,
        NA: a.nutrition.natrium,
        FIBTG: a.nutrition.fibres,
        SUGAR: a.nutrition.carbssugar,
        CHOCDF: a.nutrition.carbscarbs,
      },
    },
  },
});

export const mapToEdamamProduct = (
  a: QuantityToNameIdAndNutritionInput
): Product => ({
  measures: [measureInputToMeasure(a.quantity.unit)],
  food: mapToEdamamFood(a),
});

export const mapToEdamamNutrition = (a: NutritionInput) => ({
  ENERC_KCAL: a.kcal,
  FAT: a.fatstotal,
  FASAT: a.saturedfat,
  PROCNT: a.prottotal,
  NA: a.natrium,
  FIBTG: a.fibres,
  SUGAR: a.carbssugar,
  CHOCDF: a.carbscarbs,
});

export const mapToEdamamFood = (a: QuantityToNameIdAndNutritionInput) => ({
  label: a.name,
  foodId: a.id,
  category: "",
  image: a.img ? a.img : "",
  nutrients: mapToEdamamNutrition(a.nutrition),
});

export const mapToQuantityToNameIdAndNutritionInput = (
  a: QuantityToProduct
): QuantityToNameIdAndNutritionInput => ({
  quantity: a.quantity,
  name: a.product.food.label,
  nutrition: EdamamToNutritionInput(a.product.food.nutrients),
  id: a.product.food.foodId,
  measurementInfo: {
    serving: a.product.measurementInfo?.serving
      ? a.product.measurementInfo.serving
      : 0,
    cup: a.product.measurementInfo?.cup ? a.product.measurementInfo.cup : 0,
    whole: a.product.measurementInfo?.whole
      ? a.product.measurementInfo.whole
      : 0,
    teaspoon: a.product.measurementInfo?.teaspoon
      ? a.product.measurementInfo.teaspoon
      : 0,
    tablespoon: a.product.measurementInfo?.tablespoon
      ? a.product.measurementInfo.tablespoon
      : 0,
  },
});
export type EdamamNutrition = {
  ENERC_KCAL: number;
  FAT: number;
  FASAT: number;
  PROCNT: number;
  NA: number;
  FIBTG: number;
  SUGAR: number;
  CHOCDF: number;
};
export const EdamamToNutritionInput = (a: EdamamNutrition): NutritionInput => {
  return {
    kcal: a.ENERC_KCAL ? a.ENERC_KCAL : 0,
    fatstotal: a.FAT ? a.FAT : 0,
    saturedfat: a.FASAT ? a.FASAT : 0,
    prottotal: a.PROCNT ? a.PROCNT : 0,
    natrium: a.NA ? a.NA : 0,
    fibres: a.FIBTG ? a.FIBTG : 0,
    carbssugar: a.SUGAR ? a.SUGAR : 0,
    carbscarbs: a.CHOCDF ? a.CHOCDF : 0,
    starch: 0,
    cholesterol: 0,
  };
};
