import { gql, useLazyQuery } from "@apollo/client";
import { useSimpleQuery } from "../../../utils/apollo";
import { recipes } from "./types/recipes";
import { ingredients } from "./types/ingredients";

export const recipesQuery = gql`
  query recipes($offset: Int, $limit: Int) {
    recipes(offset: $offset, limit: $limit) {
      id
      img
      name
      type
      healthLabelInfo {
        vegan
        lupine
        meat
        nut
        peanut
        shellfish
        selery
        sesame
        soy
        fish
        crustacean
        sulphide
        mustard
        dairy
        gluten
        egg
        milk
        pork
        mollusk
      }
      nutrition {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        nutrition {
          kcal
          prottotal
          fatstotal
          saturedfat
          carbscarbs
          carbssugar
          natrium
          fibres
          cholesterol
          starch
        }
      }
      quantity {
        quantity
        unit {
          label
          uri
          weight
        }
      }
      method
    }
  }
`;

export const ingredientsQuery = gql`
  query ingredients($offset: Int, $limit: Int) {
    ingredients(offset: $offset, limit: $limit) {
      id
      name
      price {
        amount
      }
      category
      nutrition {
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
        nutrition {
          kcal
          prottotal
          fatstotal
          saturedfat
          carbscarbs
          carbssugar
          natrium
          fibres
          cholesterol
          starch
        }
      }
      material
      quantityPerUnit {
        quantity
        unit {
          label
          uri
          weight
        }
      }
      quantityTeaSpoon {
        quantity
        unit {
          label
          uri
          weight
        }
      }
      quantityEatSpoon {
        quantity
        unit {
          label
          uri
          weight
        }
      }
      price {
        amount
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
      }
    }
  }
`;

export const recipeRowsPerPage = 10;
export const useRecipesQuery = ({
  onCompleted,
  page,
}: {
  onCompleted: (recipes: recipes) => void;
  page?: number;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(recipesQuery, {
    variables: {
      offset: 0,
      limit: 10000,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error, refetch };
};

export const useLazyRecipesQuery = ({
  onCompleted,
}: {
  onCompleted: (result: recipes) => void;
}) => {
  const [getRecipes, { loading, data, error, refetch }] = useLazyQuery(
    recipesQuery,
    {
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { getRecipes, loading, data, error, refetch };
};
