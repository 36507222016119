import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

export const LoginScreen = () => {

    const {user, loginWithRedirect} = useAuth0();
    const navigate = useNavigate();

    if (user === undefined) {
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: window.location.origin + '/menus'
            }
        });
    } else {
        navigate("/menus", {replace: true})
    }

    return <></>
}