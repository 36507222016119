import { NutritionInput } from "../../../../../globalTypes";
import {
  recipes_recipes_nutrition,
  recipes_recipes_nutrition_nutrition,
} from "../../../../chefsbase/recipesAndIngredients/types/recipes";

export const toNutritionInput = (
  a: recipes_recipes_nutrition_nutrition
): NutritionInput => ({
  kcal: a.kcal,
  carbscarbs: a.carbscarbs,
  carbssugar: a.carbssugar,
  fatstotal: a.fatstotal,
  saturedfat: a.saturedfat,
  prottotal: a.prottotal,
  starch: a.starch,
  fibres: a.fibres,
  cholesterol: a.cholesterol,
  natrium: a.natrium,
});

export const toNutritionOutput = (
  a: NutritionInput
): recipes_recipes_nutrition_nutrition => ({
  __typename: "Nutrition",
  kcal: a.kcal,
  carbscarbs: a.carbscarbs,
  carbssugar: a.carbssugar,
  fatstotal: a.fatstotal,
  saturedfat: a.saturedfat,
  prottotal: a.prottotal,
  starch: a.starch,
  fibres: a.fibres,
  cholesterol: a.cholesterol,
  natrium: a.natrium,
});
