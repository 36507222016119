import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Dialog,
} from "@mui/material";
import { TinderQuery, TinderQuery_dishForTinder } from "./types/TinderQuery";
import { FaCross, FaHeart, FaHeartBroken } from "react-icons/fa";
import { useState } from "react";
import { DisplayImage } from "../../components/image/DisplayImage";
import { GridRowTwoItems } from "../../components/layouts/Grids";
import { writeText } from "../../components/utils/WriteText";
import { SubmitButton } from "../../components/Buttons";
import { ImCross } from "react-icons/im";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { MobileDishSlider } from "../chefsbase/dishes/dishDialog/DishContent";
import { DisplayNutritionInput } from "../chefsbase/productsfromapi";
import {
  toNutritionInput,
  toNutritionOutput,
} from "../clientbase/components/mappers/nutrition/ToNutritionInput";
import { totalNutrition } from "../clientbase/components/courses/Functions";
import { DisplayHealthInfo } from "../clientbase/components/composables/DialogComposable";
import { backgroundContainer } from "../../components/layouts/Colors";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AddTinderRecipe, AddedTinderRecipes } from "../addTinder/page";
import { useTinderQuery, useTinderRecipesQuery } from "./api";
import { ProcessingDialog } from "../../components/Loading/Processing";
import {
  zerohealthlabelinfo,
  zeronutrition,
} from "../chefsbase/dishes/components/EmptyVariables";
import {
  RecipesForTinder,
  RecipesForTinder_recipesForTinder,
} from "./types/RecipesForTinder";
import {
  findMeasure,
  findMeasureTinder,
  gramMeasure,
  milliliterMeasure,
  oneServing,
  servingMeasure,
} from "../../components/defaults/portions/PortionInput";

export const TemporaryDrawer = ({
  open,
  setOpen,
  setPage,
  options,
}: {
  options: string[];
  setPage: (n: number) => void;
  open: boolean;
  setOpen: (b: boolean) => void;
}) => {
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={() => setOpen(false)}>
      <List>
        {options.map((o, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => setPage(index)}>
              <ListItemIcon>
                <FaHeart />
              </ListItemIcon>
              <ListItemText primary={o} />
            </ListItemButton>
            <Divider />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export const tinderSettings = [
  "Account name: The Chef!",
  "Number of likes received: 98",
  "Number of liked dishes: 13",
];
export const AppBlock = ({
  options,
  setPage,
}: {
  options: string[];
  setPage: (n: number) => void;
}) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          align="center"
        >
          Swiping Dishes!
        </Typography>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleOpenUserMenu}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {tinderSettings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <TemporaryDrawer
        options={options}
        open={openDrawer}
        setOpen={setOpenDrawer}
        setPage={setPage}
      />
    </AppBar>
  );
};

const TinderCard = () => {
  const [recipes, setRecipes] = useState<RecipesForTinder_recipesForTinder[]>(
    []
  );

  const { data, loading } = useTinderRecipesQuery({
    onCompleted: (result: RecipesForTinder) => {
      setRecipes(result.recipesForTinder);
    },
  });
  const filtereddishes = recipes.filter(
    (d) =>
      d !== undefined &&
      d.img !== undefined &&
      d.img !== null &&
      d.img.length > 0
  );

  const [i, setI] = useState(0);
  const dish = filtereddishes[i];
  if (loading || dish === undefined)
    return <ProcessingDialog loading={!data} title="Loading..." />;

  return (
    <Card sx={{ backgroundColor: backgroundContainer }}>
      <TinderDishContent theDish={dish} />
      <CardActionArea>
        <GridRowTwoItems
          before={1}
          inbetween={3}
          firstlength={3}
          secondlength={3}
          firstchild={
            <Button
              variant="contained"
              onClick={() => setI(i + 1)}
              sx={{ borderRadius: 50, border: 0 }}
            >
              <FaHeart style={{ width: 80, height: 80, color: "white" }} />
            </Button>
          }
          secondchild={
            <Button
              onClick={() => setI(i + 1)}
              sx={{ borderRadius: 50, border: 0 }}
            >
              <ImCross style={{ width: 80, height: 80, color: "red" }} />
            </Button>
          }
        />
      </CardActionArea>
    </Card>
  );
};

export const LeaderboardCard = () => {
  const rows = [
    { name: "Cheffie", likes: "53" },
    { name: "Cheatday007", likes: "43" },
    { name: "Chefflix", likes: "10" },
    { name: "Veggie Chef", likes: "15" },
    { name: "Paleo Chef", likes: "55" },
    { name: "Seasoning King", likes: "32" },
    { name: "Meater", likes: "7" },
    { name: "Sourdough Champ", likes: "28" },
  ];
  const sortedRows = rows.sort((a, b) => parseInt(b.likes) - parseInt(a.likes));

  return (
    <Card sx={{ backgroundColor: backgroundContainer }}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} align="center">
                Leaderboard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>User</TableCell>
              <TableCell>#Likes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row, rowNumber) => (
              <TableRow>
                <TableCell>{rowNumber + 1}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.likes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export const TinderPage = () => {
  const [page, setPage] = useState(0);
  const options = [
    "Swipe!",
    "Liked Dishes",
    "Leaderboard",
    "Add new Dish",
    "Added Dishes",
  ];

  return (
    <div>
      <AppBlock setPage={setPage} options={options} />
      {page === 0 && <TinderCard />}
      {page === 1 && <LikedCard />}
      {page === 2 && <LeaderboardCard />}
      {page === 3 && <AddTinderRecipe />}
      {page === 4 && <AddedTinderRecipes />}
    </div>
  );
};

const LikedCard = () => {
  const dishes: RecipesForTinder_recipesForTinder[] = [
    {
      __typename: "RecipeForTinder",
      healthLabelInfo: zerohealthlabelinfo,
      id: "test1",
      method: "* mix all ingredients together\n* bake for 30 minutes",
      name: "Peanut Butter Pancakes",
      type: "Pancakes",
      img: ["MCB_logo.png"],
      foods: [
        {
          __typename: "QuantityToFood",
          food: {
            __typename: "Food",
            name: "Peanut Butter",
            id: "peanutbutter",
          },
          quantity: {
            __typename: "Quantity",
            quantity: 1,
            unit: servingMeasure,
          },
        },
        {
          __typename: "QuantityToFood",
          food: {
            __typename: "Food",
            name: "Milk",
            id: "milk",
          },
          quantity: {
            __typename: "Quantity",
            quantity: 100,
            unit: milliliterMeasure,
          },
        },
      ],
      nutrition: {
        __typename: "QuantityToNutrition",
        quantity: {
          __typename: "Quantity",
          quantity: 1,
          unit: servingMeasure,
        },
        nutrition: toNutritionOutput(zeronutrition),
      },
    },
    {
      __typename: "RecipeForTinder",
      healthLabelInfo: zerohealthlabelinfo,
      id: "test2",
      method:
        "* mix all ingredients together\n* bake for 30 minutes \n * serve with maple syrup",
      name: "Oatmeal Muffins with syrup",
      type: "Pancakes",
      img: ["MCB_logo.png"],
      foods: [
        {
          __typename: "QuantityToFood",
          food: {
            __typename: "Food",
            name: "Syrup 100%",
            id: "syrup",
          },
          quantity: {
            __typename: "Quantity",
            quantity: 1,
            unit: servingMeasure,
          },
        },
        {
          __typename: "QuantityToFood",
          food: {
            __typename: "Food",
            name: "Flour",
            id: "flour",
          },
          quantity: {
            __typename: "Quantity",
            quantity: 100,
            unit: gramMeasure,
          },
        },
      ],
      nutrition: {
        __typename: "QuantityToNutrition",
        quantity: {
          __typename: "Quantity",
          quantity: 1,
          unit: servingMeasure,
        },
        nutrition: toNutritionOutput(zeronutrition),
      },
    },
  ];
  const [theDish, setTheDish] = useState<
    RecipesForTinder_recipesForTinder | undefined
  >(undefined);
  const [openDish, setOpenDish] = useState<RecipesForTinder_recipesForTinder>();
  return (
    <Card sx={{ backgroundColor: backgroundContainer }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={1} align="center">
              Liked Dishes
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dishes.map((dish, i) => {
            if (i <= 10)
              return (
                <>
                  <TableRow key={i} onClick={() => setOpenDish(dish)}>
                    <TableCell
                      onClick={() =>
                        theDish !== dish
                          ? setTheDish(dish)
                          : setTheDish(undefined)
                      }
                    >
                      {dish.name}
                    </TableCell>
                  </TableRow>
                  {theDish === dish && (
                    <TableRow>
                      <Card>
                        <TinderDishContent theDish={theDish} />
                        <CardActionArea>
                          <SubmitButton onClick={() => setTheDish(undefined)}>
                            Return
                          </SubmitButton>
                        </CardActionArea>
                      </Card>
                    </TableRow>
                  )}
                </>
              );
          })}
        </TableBody>
      </Table>
      {openDish && (
        <Dialog open={true} onClose={() => setOpenDish(undefined)}>
          <TinderDishContent theDish={openDish} />
        </Dialog>
      )}
    </Card>
  );
};

const TinderDishContent = ({
  theDish,
}: {
  theDish: RecipesForTinder_recipesForTinder;
}) => {
  const [openIngredients, setOpenIngredients] = useState(false);
  const [openNutrition, setOpenNutrition] = useState(false);
  const [openMethod, setOpenMethod] = useState(false);
  return (
    <CardContent sx={{ backgroundColor: backgroundContainer }}>
      <MobileDishSlider urls={theDish.img!} />
      <Typography align="center" variant="h6" style={{ fontWeight: 600 }}>
        {theDish.name}
      </Typography>{" "}
      <DisplayHealthInfo info={theDish.healthLabelInfo} w={10} />
      <Typography variant="h6">Type: {theDish.type}</Typography>{" "}
      <Typography variant="h6">30 minutes</Typography>{" "}
      <SubmitButton
        color="inherit"
        variant="outlined"
        fullWidth
        onClick={() => setOpenIngredients(!openIngredients)}
      >
        See Ingredients {!openIngredients ? <ArrowDownward /> : <ArrowUpward />}
      </SubmitButton>
      {openIngredients && (
        <Table>
          <TableBody>
            {theDish.foods.map((f, ii) => (
              <TableRow key={ii}>
                <TableCell>{f.food.name}</TableCell>
                <TableCell>
                  {f.quantity.quantity.toFixed(2)} {f.quantity.unit.label}{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <SubmitButton
        color="inherit"
        variant="outlined"
        fullWidth
        onClick={() => setOpenNutrition(!openNutrition)}
      >
        See Nutrition {!openNutrition ? <ArrowDownward /> : <ArrowUpward />}
      </SubmitButton>
      {openNutrition && (
        <DisplayNutritionInput
          direction="column"
          nutritionInput={toNutritionInput(theDish.nutrition.nutrition)}
          frac={1}
        />
      )}
      <SubmitButton
        color="inherit"
        variant="outlined"
        fullWidth
        onClick={() => setOpenMethod(!openMethod)}
      >
        See Method {!openMethod ? <ArrowDownward /> : <ArrowUpward />}
      </SubmitButton>
      {openMethod && (
        <Typography variant="h6">{writeText(theDish.method)}</Typography>
      )}
    </CardContent>
  );
};
