import { Box, Typography, Grid, Divider } from "@mui/material";
import React, { useState } from "react";
import {
  CurrentMenu_currentMenu_courses,
  CurrentMenu_currentMenu_courses_selectables_selectables_dish,
  CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected,
} from "../../types/CurrentMenu";
import { DisplayDishButton } from "./DisplayDishButton";
import { aestheticBackground } from "../../../../components/layouts/Colors";
import { MicroMacroField } from "../MicroMacroField/types";
import { NutritionToInput } from "../../../chefsbase/dishes/components/RowRecipe";
import { DialogForDishForCourse } from "./DialogForDishForCourse";
import {
  emptyFoodResult,
  emptyRecipeForDish,
} from "../../../../components/defaults/RecipeForDish/EmptyDishComponents";
import {
  DishItems_dish_products_foods,
  DishItems_dish_recipes,
} from "../../../chefsbase/dishes/types/DishItems";
import { AllergyChoices } from "../MicroMacroField";

interface CourseProps {
  allergyChoices: AllergyChoices;
  options: string[];
  microMacroForm: MicroMacroField;
  course: CurrentMenu_currentMenu_courses;
}
export const PerCourse: React.FC<CourseProps> = ({
  course,
  microMacroForm,
  options,
  allergyChoices,
}) => {
  return (
    <>
      <Box
        sx={{
          // border: 0.1,
          width: "100%",
          borderRadius: 28,
          backgroundColor: aestheticBackground,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          style={{ fontFamily: "FairField Display, serif" }}
        >
          {course.name}
        </Typography>
        <Divider />
      </Box>
      {course.selectables.map((sel, i) => (
        <Grid container spacing={1}>
          {sel.selectables.map((s, index1) => (
            <>
              <GridPerDish
                index1={index1}
                key={index1}
                options={options}
                allergyChoices={allergyChoices}
                micromacroform={microMacroForm}
                dish={s.dish}
                quantitiesSelected={s.quantitiesSelected}
              />
            </>
          ))}
        </Grid>
      ))}
    </>
  );
};

export const GridPerDish = ({
  index1,
  options,
  micromacroform,
  dish,
  quantitiesSelected,
  allergyChoices,
}: {
  allergyChoices: AllergyChoices;
  index1: number;
  options: string[];
  micromacroform: MicroMacroField;
  quantitiesSelected: CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected[];
  dish: CurrentMenu_currentMenu_courses_selectables_selectables_dish;
}) => {
  const [products, setproducts] = useState<DishItems_dish_products_foods[]>([
    emptyFoodResult,
  ]);
  const [recipes, setrecipes] =
    useState<DishItems_dish_recipes>(emptyRecipeForDish);

  const [open, setopen] = useState(false);
  const availablePortions: CurrentMenu_currentMenu_courses_selectables_selectables_quantitiesSelected[] =
    quantitiesSelected
      .filter((p) => p.selected === true)
      .sort(
        (a, b) => a.portion.quantity.quantity - b.portion.quantity.quantity
      );

  return (
    <>
      <Grid xs={0.5} sm={0.5} md={0.5}></Grid>
      <Grid xs={5.5} sm={3.5} md={3.5}>
        <DisplayDishButton
          products={products}
          setrecipes={setrecipes}
          setproducts={setproducts}
          setopen={setopen}
          key={index1}
          availablePortions={availablePortions}
          allergyChoices={allergyChoices}
          microMacroForm={micromacroform}
          dish={dish}
          quantitiesSelected={quantitiesSelected}
        />
      </Grid>
      {availablePortions && availablePortions[0] && (
        <DialogForDishForCourse
          loading={
            products[0] === emptyFoodResult && recipes === emptyRecipeForDish
          }
          totalNutr={NutritionToInput(availablePortions[0].portion.nutrition)}
          dish={dish}
          recipes={recipes}
          products={products}
          open={open}
          setopen={setopen}
          availablePortions={availablePortions}
        />
      )}
    </>
  );
};
