import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { VscAdd } from "react-icons/vsc";
import {
  emptyIngredientToQuantityToSalePriceInput,
  emptyQuantityToNameIdAndNutritionInput,
} from "../../../../../components/defaults/baseProd/IngredientToQuantityToSalePriceInput";
import {
  measureInputOptions,
  small,
} from "../../../../../components/defaults/portions/PortionInput";
import {
  GridRowTwoItems,
  GridRowOneItem,
} from "../../../../../components/layouts/Grids";
import { AddDishVariables } from "../../../../chefsbase/dishes/addDish/types/AddDish";
import {
  emptyhealthlabelinfo,
  zeroNutritionInput,
  zeronutrition,
} from "../../../../chefsbase/dishes/components/EmptyVariables";
import { scaleNutrition } from "../../../components/courses/Functions";
import { QuantityToProduct } from "../../types/QuantityToProduct";
import { OneRecipe, OneIngredientWide } from "../IngredientForProductRow";
import {
  mapToRecipe,
  mapToRecipeInput,
  mapToProduct,
  EdamamToNutritionInput,
  mapToEdamamNutrition,
} from "./Mappers";
import {
  DishIngredientsInput,
  HealthLabelInfo,
  IngredientToQuantityToSalePriceInput,
  MeasureInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../../globalTypes";
import { backgroundField } from "../../../../../components/layouts/Colors";
import { mapIngredientToQuantityToSalePriceInput } from "../../../../../components/utils/Mappers";
import { returnStringIfNotNullOrUndefined } from "../../../../../components/utils/NullableStrings";
import { Loader } from "../../../../../components/Loading/Loader";
import {
  BaseOrVar,
  MeasurementInfo,
  QuantityToRecipe,
} from "../../../types/types";
import {
  AllergyForm,
  IdToHealthLabel,
  IdToHealthLabelInfo,
  distinctIdToHealthLabel,
  distinctIdToHealthLabelRecipe,
  getHealthLabels,
} from "../../../../../components/edamam/GetNutrition";
import { useState } from "react";
import { UpdateDishVariables } from "../../../../chefsbase/dishes/addDish/types/UpdateDish";
import { getFraction } from "../../../../../components/utils/GetFraction";
import { RecipesAndIngredientsHeader } from "../UpdateRecipesAndIngredientsHeader";

export const dishHealthLabels = (
  f: QuantityToNameIdAndNutritionInput[],
  recipeHealthLabels: HealthLabelInfo
): HealthLabelInfo => {
  const foods = f.filter(
    (f) =>
      f !== undefined &&
      f !== null &&
      f.healthlabelInfo !== undefined &&
      f.healthlabelInfo !== null
  );

  const lupine =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.lupine === true) &&
        recipeHealthLabels.lupine === true
    ).length > 0;
  const meat =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.meat === true) &&
        recipeHealthLabels.meat === true
    ).length > 0;
  const nut =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.nut === true) &&
        recipeHealthLabels.nut === true
    ).length > 0;
  const peanut =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.peanut === true) &&
        recipeHealthLabels.peanut === true
    ).length > 0;
  const shellfish =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.shellfish === true) &&
        recipeHealthLabels.shellfish === true
    ).length > 0;
  const selery =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.selery === true) &&
        recipeHealthLabels.selery === true
    ).length > 0;
  const sesame =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.sesame === true) &&
        recipeHealthLabels.sesame === true
    ).length > 0;
  const soy =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.soy === true) &&
        recipeHealthLabels.soy === true
    ).length > 0;
  const fish =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.fish === true) &&
        recipeHealthLabels.fish === true
    ).length > 0;
  const crustacean =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.crustacean === true) &&
        recipeHealthLabels.crustacean === true
    ).length > 0;
  const sulphide = false; //!checkForString("");
  const mustard =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.mustard === true) &&
        recipeHealthLabels.mustard === true
    ).length > 0;
  const dairy =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.dairy === true) &&
        recipeHealthLabels.dairy === true
    ).length > 0;
  const gluten =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.gluten === true) &&
        recipeHealthLabels.gluten === true
    ).length > 0;
  const egg =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.egg === true) &&
        recipeHealthLabels.egg === true
    ).length > 0;
  const pork =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.pork === true) &&
        recipeHealthLabels.pork === true
    ).length > 0;
  const milk =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.milk === true) &&
        recipeHealthLabels.milk === true
    ).length > 0;
  const mollusk =
    foods.filter(
      (f) =>
        (f === undefined || f!.healthlabelInfo!.mollusk === true) &&
        recipeHealthLabels.mollusk === true
    ).length > 0;

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
  };
};

export const recipeHealthLabels = (
  f: QuantityToNameIdAndNutritionInput[]
): HealthLabelInfo => {
  const foods = f.filter(
    (f) =>
      f !== undefined &&
      f !== null &&
      f.healthlabelInfo !== undefined &&
      f.healthlabelInfo !== null
  );
  const lupine =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.lupine === true
    ).length > 0;
  const meat =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.meat === true
    ).length > 0;
  const nut =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.nut === true
    ).length > 0;
  const peanut =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.peanut === true
    ).length > 0;
  const shellfish =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.shellfish === true
    ).length > 0;
  const selery =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.selery === true
    ).length > 0;
  const sesame =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.sesame === true
    ).length > 0;
  const soy =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.soy === true
    ).length > 0;
  const fish =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.fish === true
    ).length > 0;
  const crustacean =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.crustacean === true
    ).length > 0;
  const sulphide = false; //!checkForString("");
  const mustard =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.mustard === true
    ).length > 0;
  const dairy =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.dairy === true
    ).length > 0;
  const gluten =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.gluten === true
    ).length > 0;
  const egg =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.egg === true
    ).length > 0;
  const pork =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.pork === true
    ).length > 0;
  const milk =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.milk === true
    ).length > 0;
  const mollusk =
    foods.filter(
      (f) =>
        f === undefined ||
        f!.healthlabelInfo === undefined ||
        f!.healthlabelInfo!.mollusk === true
    ).length > 0;

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
  };
};

export const idToHealthLabelInfoToOnlyHealthLabelLabelInfo = (
  foods: IdToHealthLabelInfo[]
): HealthLabelInfo => {
  const lupine =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.lupine === true
    ).length > 0;
  const meat =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.meat === true
    ).length > 0;
  const nut =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.nut === true
    ).length > 0;
  const peanut =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.peanut === true
    ).length > 0;
  const shellfish =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.shellfish === true
    ).length > 0;
  const selery =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.selery === true
    ).length > 0;
  const sesame =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.sesame === true
    ).length > 0;
  const soy =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.soy === true
    ).length > 0;
  const fish =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.fish === true
    ).length > 0;
  const crustacean =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.crustacean === true
    ).length > 0;
  const sulphide = false; //!checkForString("");
  const mustard =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.mustard === true
    ).length > 0;
  const dairy =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.dairy === true
    ).length > 0;
  const gluten =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.gluten === true
    ).length > 0;
  const egg =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.egg === true
    ).length > 0;
  const pork =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.pork === true
    ).length > 0;
  const milk =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.milk === true
    ).length > 0;
  const mollusk =
    foods.filter(
      (f) =>
        f === undefined ||
        f === null ||
        f!.healthLabelInfo === undefined ||
        f!.healthLabelInfo!.mollusk === true
    ).length > 0;

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
  };
};

const Row2 = ({ item, label }: { item: boolean; label: string }) => {
  return (
    <Stack direction="column">
      {label}
      <Typography fontWeight={900} variant="subtitle2">{` ${
        item ? "N" : "Y"
      }`}</Typography>
    </Stack>
  );
};

const Row = ({ item, label }: { item: boolean; label: string }) => {
  return (
    <GridRowTwoItems
      before={0}
      firstlength={10}
      inbetween={0}
      secondlength={2}
      firstchild={<Typography variant="subtitle2">{label}</Typography>}
      secondchild={
        <Typography fontWeight={900} variant="subtitle2">{` ${
          item ? "No" : "Yes"
        }`}</Typography>
      }
    />
  );
};
const Column = ({ item, label }: { item: boolean[]; label: string[] }) => {
  return (
    <Stack direction="column" spacing={1}>
      {item.map((i, index) => (
        <Row label={label[index]} item={i} />
      ))}
    </Stack>
  );
};

const Column2 = ({ item, label }: { item: boolean[]; label: string[] }) => {
  return (
    <Stack direction="column" spacing={1}>
      {item.map((i, index) => (
        <Row2 label={label[index]} item={i} />
      ))}
    </Stack>
  );
};
export const writeHealthlabelInfo = (
  health: HealthLabelInfo,
  isMobile?: boolean
) => {
  if (isMobile)
    return (
      <Stack direction="row" spacing={4}>
        <Column2
          label={[
            `Lupine Free: `,
            `Meat Free: `,
            `Nut Free: `,
            `Peanut Free: `,
          ]}
          item={[health.lupine, health.meat, health.nut, health.peanut]}
        />
        <Column2
          label={[
            `Fish Free: `,
            `Crustacean Free: `,
            `Celery Free: `,
            `Mustard Free: `,
          ]}
          item={[health.fish, health.crustacean, health.selery, health.mustard]}
        />
        <Column2
          label={[`Egg Free: `, `Milk Free: `, `Mollusk Free: `, `Pork Free: `]}
          item={[health.egg, health.milk, health.mollusk, health.pork]}
        />
        <Column2
          label={[
            `Shellfish Free: `,
            `Sesame Free: `,
            `Dairy Free: `,
            `Gluten Free: `,
          ]}
          item={[health.shellfish, health.sesame, health.dairy, health.gluten]}
        />
      </Stack>
    );
  return (
    <Stack direction="row" spacing={4}>
      <Column
        label={[`Lupine Free: `, `Meat Free: `, `Nut Free: `, `Peanut Free: `]}
        item={[health.lupine, health.meat, health.nut, health.peanut]}
      />
      <Column
        label={[
          `Fish Free: `,
          `Crustacean Free: `,
          `Celery Free: `,
          `Mustard Free: `,
        ]}
        item={[health.fish, health.crustacean, health.selery, health.mustard]}
      />
      <Column
        label={[`Egg Free: `, `Milk Free: `, `Mollusk Free: `, `Pork Free: `]}
        item={[health.egg, health.milk, health.mollusk, health.pork]}
      />
      <Column
        label={[
          `Shellfish Free: `,
          `Sesame Free: `,
          `Dairy Free: `,
          `Gluten Free: `,
        ]}
        item={[health.shellfish, health.sesame, health.dairy, health.gluten]}
      />
    </Stack>
  );
};

export const foundToHealthLabelInfo = (
  allergies: IdToHealthLabel
): HealthLabelInfo => {
  const lupine = !allergies.healthlabels.includes("LUPINE_FREE");
  const isVegan = allergies.healthlabels.includes("VEGAN");
  const isVegetarian = allergies.healthlabels.includes("VEGETARIAN");
  const isPescetarian = allergies.healthlabels.includes("PESCATARIAN");
  const nut = !allergies.healthlabels.includes("TREE_NUT_FREE");
  const peanut = !allergies.healthlabels.includes("PEANUT_FREE");
  const shellfish =
    !allergies.healthlabels.includes("SHELLFISH_FREE") && !isPescetarian;
  const selery = !allergies.healthlabels.includes("CELERY_FREE");
  const sesame = !allergies.healthlabels.includes("SESAME_FREE");
  const soy = !allergies.healthlabels.includes("SOY_FREE");
  const fish = !allergies.healthlabels.includes("FISH_FREE");
  const meat = !isVegetarian ? !fish && !shellfish : false;
  const crustacean =
    !allergies.healthlabels.includes("CRUSTACEAN_FREE") && !isPescetarian;
  const sulphide = false; //!allergies.healthlabels.includes("");
  const mustard = !allergies.healthlabels.includes("MUSTARD_FREE");
  const dairy = !allergies.healthlabels.includes("DAIRY_FREE");
  const gluten = !allergies.healthlabels.includes("GLUTEN_FREE");
  const egg = !allergies.healthlabels.includes("EGG_FREE");
  const pork = !allergies.healthlabels.includes("PORK_FREE");
  const milk = !allergies.healthlabels.includes("MILK_FREE");
  const mollusk = !allergies.healthlabels.includes("MOLLUSK_FREE");

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
    vegan: isVegan,
  };
};

export const healthLabelInfoToFound = (
  allergies: HealthLabelInfo
): string[] => {
  const healthLabels: string[] = [];
  if (!allergies.lupine) healthLabels.push("LUPINE_FREE");
  //const isVegan =  if (!allergies.vegan) healthLabels.push("LUPINE_FREE); allergies.healthlabels.includes("VEGAN");
  if (!allergies.meat && !allergies.fish) healthLabels.push("VEGETARIAN");
  if (!allergies.nut) healthLabels.push("TREE_NUT_FREE"); //!allergies.healthlabels.includes("TREE_NUT_FREE");
  if (!allergies.peanut) healthLabels.push("PEANUT_FREE"); //!allergies.healthlabels.includes("PEANUT_FREE");
  if (!allergies.shellfish) healthLabels.push("SHELLFISH_FREE"); //!allergies.healthlabels.includes("SHELLFISH_FREE");
  if (!allergies.selery) healthLabels.push("CELERY_FREE"); //!allergies.healthlabels.includes("CELERY_FREE");
  if (!allergies.sesame) healthLabels.push("SESAME_FREE"); //!allergies.healthlabels.includes("SESAME_FREE");
  if (!allergies.soy) healthLabels.push("SOY_FREE"); // !allergies.healthlabels.includes("SOY_FREE");
  if (!allergies.fish) healthLabels.push("FISH_FREE"); // !allergies.healthlabels.includes("FISH_FREE");
  //const meat =  if (!allergies.lupine) healthLabels.push("VEGETARIAN); //!isVegetarian ? !fish && !shellfish : false;
  if (!allergies.crustacean) healthLabels.push("CRUSTACEAN_FREE"); //!allergies.healthlabels.includes("CRUSTACEAN_FREE");
  if (!allergies.sulphide) healthLabels.push("SULPHIDE_FREE"); //false; //!allergies.healthlabels.includes("");
  if (!allergies.mustard) healthLabels.push("MUSTARD_FREE"); //!allergies.healthlabels.includes("MUSTARD_FREE");
  if (!allergies.dairy) healthLabels.push("DAIRY_FREE"); //!allergies.healthlabels.includes("DAIRY_FREE");
  if (!allergies.gluten) healthLabels.push("GLUTEN_FREE"); // !allergies.healthlabels.includes("GLUTEN_FREE");
  if (!allergies.egg) healthLabels.push("EGG_FREE"); //!allergies.healthlabels.includes("EGG_FREE");
  if (!allergies.pork) healthLabels.push("PORK_FREE"); //!allergies.healthlabels.includes("PORK_FREE");
  if (!allergies.milk) healthLabels.push("MILK_FREE"); // !allergies.healthlabels.includes("MILK_FREE");
  if (!allergies.mollusk) healthLabels.push("MOLLUSK_FREE"); //!allergies.healthlabels.includes("MOLLUSK_FREE");

  return healthLabels;
};

export const allergiesToHealthLabelInfo = (
  all: AllergyForm
): HealthLabelInfo => {
  const allergies: AllergyForm = {
    food: distinctIdToHealthLabel(all.food),
    recipe: distinctIdToHealthLabelRecipe(
      all.recipe.filter((i) => i.id !== "")
    ),
  };

  const healthLabelsFromRecipe = allergies.recipe
    .map((a) => a.healthLabelInfo)
    .filter((h) => h !== undefined && h !== null);

  const allAre = (word: string): boolean => {
    const inProduct =
      allergies.food.filter((a) => a.healthlabels.includes(word)).length ===
      allergies.food.length;

    return inProduct;
  };

  const checkForMeat = (): boolean => {
    const inProduct =
      allergies.food.filter(
        (a) =>
          !a.healthlabels.includes("VEGETARIAN") &&
          a.healthlabels.includes("FISH_FREE")
      ).length > 0;
    return inProduct;
  };

  const lupine =
    !allAre("LUPINE_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.lupine).length > 0);
  const meat =
    checkForMeat() ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.meat).length > 0);
  const nut =
    !allAre("TREE_NUT_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.nut).length > 0);
  const peanut =
    !allAre("PEANUT_FREE") ||
    healthLabelsFromRecipe.length > 0 ||
    healthLabelsFromRecipe.filter((h) => h!.peanut).length > 0;
  const shellfish =
    !allAre("SHELLFISH_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.shellfish).length > 0);
  const selery =
    !allAre("CELERY_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.selery).length > 0);
  const sesame =
    !allAre("SESAME_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.sesame).length > 0);
  const soy =
    !allAre("SOY_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.soy).length > 0);
  const fish =
    !allAre("FISH_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.fish).length > 0);
  const crustacean =
    !allAre("CRUSTACEAN_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.crustacean).length > 0);
  const sulphide = false; //!allAre("");
  const mustard =
    !allAre("MUSTARD_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.mustard).length > 0);
  const dairy =
    !allAre("DAIRY_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.dairy).length > 0);
  const gluten =
    !allAre("GLUTEN_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.gluten).length > 0);
  const egg =
    !allAre("EGG_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.egg).length > 0);
  const pork =
    !allAre("PORK_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.pork).length > 0);
  const milk =
    !allAre("MILK_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.milk).length > 0);
  const mollusk =
    !allAre("MOLLUSK_FREE") ||
    (healthLabelsFromRecipe.length > 0 &&
      healthLabelsFromRecipe.filter((h) => h!.mollusk).length > 0);

  return allergies.food.length === 0 && allergies.recipe.length === 0
    ? emptyhealthlabelinfo
    : {
        lupine: lupine,
        meat: meat,
        nut: nut,
        peanut: peanut,
        shellfish: shellfish,
        selery: selery,
        sesame: sesame,
        soy: soy,
        fish: fish,
        crustacean: crustacean,
        sulphide: sulphide,
        mustard: mustard,
        dairy: dairy,
        gluten: gluten,
        egg: egg,
        milk: milk,
        mollusk: mollusk,
        pork: pork,
      };
};

export const tohealthLabelInfo = (
  foods: (HealthLabelInfo | null | undefined)[]
): HealthLabelInfo => {
  const lupine =
    foods.filter((f) => f === undefined || f === null || f!.lupine === true)
      .length > 0;
  const meat =
    foods.filter((f) => f === undefined || f === null || f!.meat === true)
      .length > 0;
  const nut =
    foods.filter((f) => f === undefined || f === null || f!.nut === true)
      .length > 0;
  const peanut =
    foods.filter((f) => f === undefined || f === null || f!.peanut === true)
      .length > 0;
  const shellfish =
    foods.filter((f) => f === undefined || f === null || f!.shellfish === true)
      .length > 0;
  const selery =
    foods.filter((f) => f === undefined || f === null || f!.selery === true)
      .length > 0;
  const sesame =
    foods.filter((f) => f === undefined || f === null || f!.sesame === true)
      .length > 0;
  const soy =
    foods.filter((f) => f === undefined || f === null || f!.soy === true)
      .length > 0;
  const fish =
    foods.filter((f) => f === undefined || f === null || f!.fish === true)
      .length > 0;
  const crustacean =
    foods.filter((f) => f === undefined || f === null || f!.crustacean === true)
      .length > 0;
  const sulphide = false; //!checkForString("");
  const mustard =
    foods.filter((f) => f === undefined || f === null || f!.mustard === true)
      .length > 0;
  const dairy =
    foods.filter((f) => f === undefined || f === null || f!.dairy === true)
      .length > 0;
  const gluten =
    foods.filter((f) => f === undefined || f === null || f!.gluten === true)
      .length > 0;
  const egg =
    foods.filter((f) => f === undefined || f === null || f!.egg === true)
      .length > 0;
  const pork =
    foods.filter((f) => f === undefined || f === null || f!.pork === true)
      .length > 0;
  const milk =
    foods.filter((f) => f === undefined || f === null || f!.milk === true)
      .length > 0;
  const mollusk =
    foods.filter((f) => f === undefined || f === null || f!.mollusk === true)
      .length > 0;

  return {
    lupine: lupine,
    meat: meat,
    nut: nut,
    peanut: peanut,
    shellfish: shellfish,
    selery: selery,
    sesame: sesame,
    soy: soy,
    fish: fish,
    crustacean: crustacean,
    sulphide: sulphide,
    mustard: mustard,
    dairy: dairy,
    gluten: gluten,
    egg: egg,
    milk: milk,
    mollusk: mollusk,
    pork: pork,
  };
};

export const HaccpButton2 = ({
  dishForm,
  setDishForm,
}: {
  dishForm: UpdateDishVariables;
  setDishForm: (a: UpdateDishVariables) => void;
}) => {
  const [allergies, setallergies] = useState<AllergyForm>({
    food: [],
    recipe: dishForm.recipes.ingredients.map((i) => ({
      id: i.id,
      healthLabelInfo: i.healthlabelInfo,
    })),
  });

  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      sx={{ borderRadius: 28 }}
      onClick={() => {
        // setallergies({
        //   food: [],
        //   recipe: dishForm.recipes.ingredients.map((i) => ({
        //     id: i.id,
        //     healthLabelInfo: i.healthlabelInfo,
        //   })),
        // });
        //TODO:
        dishForm.products.ingredients.length > 0 &&
          getHealthLabels(
            dishForm.products.ingredients.map((i) => i.id),
            allergies.food.filter((a) =>
              dishForm.products.ingredients.map((i) => i.id).includes(a.foodid)
            ),
            (a) => {
              const old = [...dishForm.products.ingredients];
              const updated = old.map((i) => {
                const found = a.find((item) => item.foodid === i.id);
                const toreturn =
                  found !== undefined
                    ? {
                        ...i,
                        healthlabelInfo: foundToHealthLabelInfo(found!),
                      }
                    : i;

                return toreturn;
              });

              const newAllergies: AllergyForm = {
                food: a,
                recipe: dishForm.recipes.ingredients.map((i) => ({
                  id: i.id,
                  healthLabelInfo: i.healthlabelInfo,
                })),
              };
              setallergies(newAllergies);

              setDishForm({
                ...dishForm,
                healthLabels: allergiesToHealthLabelInfo(newAllergies),
                products: {
                  ...dishForm.products,
                  ingredients: updated,
                },
              });
            }
          );
      }}
    >
      Check for HACCP allergenes
    </Button>
  );
};

export const HaccpButton = ({
  allergies,
  dishForm,
  setDishForm,
  setallergies,
}: {
  allergies: AllergyForm;
  dishForm: AddDishVariables;
  setallergies: (a: AllergyForm) => void;
  setDishForm: (a: AddDishVariables) => void;
}) => {
  return (
    <Button
      fullWidth
      color="secondary"
      variant="contained"
      sx={{ borderRadius: 28 }}
      onClick={() => {
        // setallergies({
        //   food: [],
        //   recipe: allergies.recipe,
        // });
        //TODO:
        dishForm.products.ingredients.length > 0 &&
          getHealthLabels(
            dishForm.products.ingredients.map((i) => i.id),
            allergies.food.filter((a) =>
              dishForm.products.ingredients.map((i) => i.id).includes(a.foodid)
            ),
            (a) => {
              const old = [...dishForm.products.ingredients];
              const updated = old.map((i) => {
                const found = a.find((item) => item.foodid === i.id);
                const toreturn =
                  found !== undefined
                    ? {
                        ...i,
                        healthlabelInfo: foundToHealthLabelInfo(found!),
                      }
                    : i;

                return toreturn;
              });
              const newAllergies: AllergyForm = {
                food: a,
                recipe: dishForm.recipes.ingredients.map((i) => ({
                  id: i.id,
                  healthLabelInfo: i.healthlabelInfo,
                })),
              };
              setallergies(newAllergies);
              setDishForm({
                ...dishForm,
                healthLabels: allergiesToHealthLabelInfo(newAllergies),
                products: {
                  ...dishForm.products,
                  ingredients: updated,
                },
              });
            }
          );
      }}
    >
      Check for HACCP allergenes
    </Button>
  );
};

const checkForHealthLabelInfo = (p: HealthLabelInfo | null | undefined) => {
  return (
    p &&
    p.crustacean &&
    p.dairy &&
    p.egg &&
    p.fish &&
    p.gluten &&
    p.lupine &&
    p.meat &&
    p.milk &&
    p.mollusk &&
    p.mustard &&
    p.nut &&
    p.peanut &&
    p.peanut &&
    p.pork &&
    p.selery &&
    p.selery &&
    p.sesame &&
    p.shellfish &&
    p.shellfish &&
    p.soy &&
    p.sulphide
  );
};
export const RecipeAndIngredientsForDish = ({
  dishForm,
  setDishForm,
  recipes,
  loadingRecipes,
}: {
  loadingRecipes: boolean;
  dishForm: AddDishVariables;
  recipes: QuantityToRecipe[];
  setDishForm: (a: AddDishVariables) => void;
}) => {
  const [allergies, setallergies] = useState<AllergyForm>({
    food: dishForm.products.ingredients
      .filter((p) => p.id !== "")
      .map((p) => ({
        foodid: p.id,
        healthlabels: checkForHealthLabelInfo(p.healthlabelInfo)
          ? healthLabelInfoToFound(p.healthlabelInfo!)
          : [],
      })),
    recipe: dishForm.recipes.ingredients
      .filter((i) => i.id !== "")
      .map((i) => ({
        id: i.id,
        healthLabelInfo: i.healthlabelInfo,
      })),
  });
  return (
    <>
      <GridRowOneItem
        before={5}
        after={0}
        child={
          <Typography fontWeight={700} variant="h5">
            Allergies:
          </Typography>
        }
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
      <GridRowOneItem
        before={0.5}
        after={0}
        child={writeHealthlabelInfo(allergiesToHealthLabelInfo(allergies))}
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
      <Box sx={{ mt: 3, mb: 3 }}>
        <RecipesAndIngredientsHeader
          fontWeight={900}
          dishForm={dishForm}
          setDishForm={(a) => {
            measureInputOptions(a.products.dishPortion.quantity.unit.label).map(
              (c) => ({
                name: c.label,
                id: c.uri,
              })
            );
            setDishForm(a);
          }}
          options={measureInputOptions().map((a) => ({
            name: a.label,
            id: a.uri,
          }))}
        />
      </Box>
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />

      <GridRowTwoItems
        before={0.5}
        inbetween={0}
        firstlength={5.5}
        secondlength={6}
        secondchild={
          <>
            <Typography fontWeight={700} variant="h6">
              Recipes:
            </Typography>
          </>
        }
        firstchild={
          <>
            <Typography fontWeight={700} variant="h6">
              Ingredients:
            </Typography>
          </>
        }
      />
      <GridRowTwoItems
        before={0.5}
        inbetween={0}
        firstlength={5.5}
        secondlength={6}
        secondchild={
          <>
            {loadingRecipes ? (
              <Loader loading={loadingRecipes} />
            ) : (
              dishForm.recipes.ingredients.map((rec, index1) => {
                return (
                  <OneRecipe
                    key={index1}
                    recipes={recipes}
                    deleteItem={() => {
                      const recs = [...dishForm.recipes.ingredients];
                      recs.splice(index1, 1);
                      const newAllergies: AllergyForm = {
                        food: allergies.food,
                        recipe: recs.map((r) => ({
                          id: r.id,
                          healthLabelInfo: r.healthlabelInfo,
                        })),
                      };
                      setallergies(newAllergies);
                      setDishForm({
                        ...dishForm,
                        healthLabels: allergiesToHealthLabelInfo(newAllergies),
                        recipes: {
                          dishPortion: dishForm.products.dishPortion,
                          ingredients: recs,
                        },
                      });
                    }}
                    recipe={mapToRecipe(rec)}
                    setrecipe={(a: QuantityToRecipe) => {
                      const recs = [...dishForm.recipes.ingredients];
                      recs[index1] = mapToRecipeInput(a);
                      const newAllergies: AllergyForm = {
                        food: allergies.food,
                        recipe: recs.map((r) => ({
                          id: r.id,
                          healthLabelInfo: r.healthlabelInfo,
                        })),
                      };
                      setallergies(newAllergies);
                      setDishForm({
                        ...dishForm,
                        healthLabels: allergiesToHealthLabelInfo(newAllergies),
                        recipes: {
                          dishPortion: dishForm.products.dishPortion,
                          ingredients: recs,
                        },
                      });
                    }}
                  />
                );
              })
            )}
            <GridRowOneItem
              before={0.9}
              after={8}
              child={
                <Button
                  sx={{ borderRadius: 28 }}
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    const old = dishForm.recipes.ingredients;
                    old.push(emptyQuantityToNameIdAndNutritionInput);
                    const variables = {
                      ...dishForm,
                      recipes: {
                        ...dishForm.recipes,
                        recipes: old,
                      },
                    };
                    setDishForm(variables);
                  }}
                >
                  <VscAdd />
                </Button>
              }
            />
          </>
        }
        firstchild={
          <>
            {dishForm.products.ingredients.map((prod, index1) => {
              const product: QuantityToProduct = mapToProduct(prod);
              return (
                <OneIngredientWide
                  key={index1}
                  deleteItem={() => {
                    const prods = [...dishForm.products.ingredients];
                    prods.splice(index1, 1);
                    const newhealthLabelInfo = dishHealthLabels(
                      prods,
                      tohealthLabelInfo(
                        allergies.recipe.map((r) => {
                          if (checkForHealthLabelInfo(r.healthLabelInfo))
                            return r.healthLabelInfo;
                        })
                      )
                    );
                    const variables = {
                      ...dishForm,
                      healthLabels: newhealthLabelInfo,
                      products: {
                        ...dishForm.products,
                        ingredients: prods,
                      },
                    };
                    setDishForm(variables);
                  }}
                  product={product}
                  setproduct={(a: QuantityToProduct) => {
                    const scaler =
                      a.product.measurementInfo !== undefined &&
                      checkIfOtherMeasurement(
                        a.quantity.unit.label.toLowerCase()
                      )
                        ? getMeasurementFractionFor(
                            a.product.measurementInfo,
                            a.quantity.unit.label.toLowerCase()
                          ) * a.quantity.quantity
                        : 1;

                    const oldForForm = [...dishForm.products.ingredients];
                    oldForForm[index1] = {
                      measurementInfo: a.product.measurementInfo,
                      quantity: a.quantity,
                      name: a.product.food.label,
                      nutrition: scaleNutrition(
                        scaler,
                        EdamamToNutritionInput(a.product.food.nutrients)
                      ),
                      id: a.product.food.foodId,
                    };
                    const variables = {
                      ...dishForm,
                      products: {
                        ...dishForm.products,
                        ingredients: oldForForm,
                      },
                    };
                    // setDishForm({
                    //   ...variables,
                    //   // healthLabels:
                    //   //   allergiesToHealthLabelInfo(newAllergies),
                    //   healthLabels: allergiesToHealthLabelInfo(allergies),
                    //   products: {
                    //     ...variables.products,
                    //     ingredients: oldForForm,
                    //   },
                    setallergies({
                      food: [],
                      recipe: allergies.recipe,
                    });
                    //TODO:
                    oldForForm.length > 0 &&
                      getHealthLabels(
                        oldForForm.map((i) => i.id),
                        allergies.food.filter((a) =>
                          oldForForm.map((i) => i.id).includes(a.foodid)
                        ),
                        (a) => {
                          const old = [...oldForForm];
                          const updated = old.map((i) => {
                            const found = a.find(
                              (item) => item.foodid === i.id
                            );
                            const toreturn =
                              found !== undefined
                                ? {
                                    ...i,
                                    healthlabelInfo: foundToHealthLabelInfo(
                                      found!
                                    ),
                                  }
                                : i;

                            return toreturn;
                          });

                          const newAllergies: AllergyForm = {
                            food: a,
                            recipe: allergies.recipe,
                          };
                          setallergies(newAllergies);
                          setDishForm({
                            ...variables,
                            healthLabels:
                              allergiesToHealthLabelInfo(newAllergies),
                            products: {
                              ...variables.products,
                              ingredients: updated,
                            },
                          });
                        }
                      );
                  }}
                  setprice={(a) => {}}
                />
              );
            })}
            <GridRowOneItem
              before={0.9}
              after={0}
              child={
                <Button
                  sx={{ borderRadius: 28 }}
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    const old = dishForm.products.ingredients;
                    old.push({
                      quantity: small.quantity,
                      name: "",
                      id: "",
                      nutrition: zeronutrition,
                    });
                    const variables = {
                      ...dishForm,
                      products: {
                        ...dishForm.products,
                        ingredients: old,
                      },
                    };
                    setDishForm(variables);
                  }}
                >
                  <VscAdd />
                </Button>
              }
            />
          </>
        }
      />
      <GridRowOneItem before={0.5} after={0} child={<Divider />} />
    </>
  );
};

export const checkIfOtherMeasurement = (label: string): boolean => {
  return (
    label === "serving" ||
    label === "cup" ||
    label === "whole" ||
    label === "teaspoon" ||
    label === "tablespoon"
  );
};

export const getMeasurementFractionFor = (
  measurementinfo: MeasurementInfo,
  label: string
): number => {
  let scaler = 1;
  switch (true) {
    case label === "serving" &&
      measurementinfo.serving !== undefined &&
      measurementinfo.serving !== null:
      scaler = measurementinfo.serving! / 100;
      break;
    case label === "whole" &&
      measurementinfo.whole &&
      measurementinfo.whole !== undefined &&
      measurementinfo.whole !== null:
      scaler = measurementinfo.whole! / 100;
      break;
    case label === "cup" &&
      measurementinfo.cup &&
      measurementinfo.cup !== undefined &&
      measurementinfo.cup !== null:
      scaler = measurementinfo.cup! / 100;
      break;
    case label === "tablespoon" &&
      measurementinfo.tablespoon &&
      measurementinfo.tablespoon !== undefined &&
      measurementinfo.tablespoon !== null:
      scaler = measurementinfo.tablespoon! / 100;
      break;
    case label === "teaspoon" &&
      measurementinfo.teaspoon &&
      measurementinfo.teaspoon !== undefined &&
      measurementinfo.teaspoon !== null:
      scaler = measurementinfo.teaspoon! / 100;
      break;
    default:
      scaler = 1;
  }
  return scaler;
};
export const EditRecipesAndIngredients = ({
  ingredientsFromForm,
  setIngredientsFromForm,
  recipesFromForm,
  setRecipesFromForm,
  recipes,
  allergies,
  setallergies,
}: {
  allergies: AllergyForm;
  setallergies: (a: AllergyForm) => void;
  ingredientsFromForm: DishIngredientsInput;
  setIngredientsFromForm: (a: DishIngredientsInput) => void;
  recipesFromForm: DishIngredientsInput;
  setRecipesFromForm: (a: DishIngredientsInput) => void;
  recipes: QuantityToRecipe[];
}) => {
  return (
    <>
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <>
            {ingredientsFromForm.ingredients.map((prod, index1) => {
              const product: QuantityToProduct = mapToProduct(prod);
              return (
                <OneIngredientWide
                  setprice={(a) => {}}
                  key={index1}
                  deleteItem={() => {
                    const prods = [...ingredientsFromForm.ingredients];
                    const newAllergies = {
                      food: allergies.food.filter(
                        (f) => f.foodid !== prods[index1].id
                      ),
                      recipe: allergies.recipe,
                    };
                    setallergies(newAllergies);
                    prods.splice(index1, 1);
                    const variables = {
                      ...ingredientsFromForm,
                      ingredients: prods,
                    };
                    setIngredientsFromForm(variables);
                  }}
                  product={product}
                  setproduct={(a: QuantityToProduct) => {
                    const oldForForm = [...ingredientsFromForm.ingredients];
                    oldForForm[index1] = {
                      measurementInfo: a.product.measurementInfo,
                      quantity: a.quantity,
                      name: a.product.food.label,
                      nutrition: scaleNutrition(
                        1,
                        EdamamToNutritionInput(a.product.food.nutrients)
                      ),
                      id: a.product.food.foodId,
                    };
                    setallergies({
                      food: [],
                      recipe: allergies.recipe,
                    });
                    const variables = {
                      ...ingredientsFromForm,
                      ingredients: oldForForm,
                    };
                    // setIngredientsFromForm(variables);
                    //TODO:
                    if (oldForForm.length > 0) {
                      getHealthLabels(
                        oldForForm.map((i) => i.id),
                        allergies.food.filter((a) =>
                          oldForForm.map((i) => i.id).includes(a.foodid)
                        ),
                        (a) => {
                          console.log(a);
                          const old1 = [...oldForForm];
                          const updated = old1.map((i) => {
                            const found = a.find(
                              (item) => item.foodid === i.id
                            );
                            console.log(found);
                            const toreturn =
                              found !== undefined
                                ? {
                                    ...i,
                                    healthlabelInfo: foundToHealthLabelInfo(
                                      found!
                                    ),
                                  }
                                : i;

                            return toreturn;
                          });

                          const newAllergies: AllergyForm = {
                            food: a,
                            recipe: allergies.recipe,
                          };
                          setallergies(newAllergies);
                          const variables = {
                            ...ingredientsFromForm,
                            ingredients: updated,
                          };
                          setIngredientsFromForm(variables);
                        }
                      );
                    }
                  }}
                />
              );
            })}
          </>
        }
      />
      <GridRowOneItem
        before={0}
        after={0}
        child={
          <>
            {recipesFromForm.ingredients.map((rec, index1) => {
              return (
                <OneRecipe
                  key={index1}
                  recipes={recipes}
                  deleteItem={() => {
                    const recs = [...recipesFromForm.ingredients];
                    recs.splice(index1, 1);
                    const newAllergies: AllergyForm = {
                      food: allergies.food,
                      recipe: recs.map((r) => ({
                        id: r.id,
                        healthLabelInfo: r.healthlabelInfo,
                      })),
                    };
                    setallergies(newAllergies);
                    setRecipesFromForm({
                      ...recipesFromForm,
                      ingredients: recs,
                    });
                  }}
                  recipe={mapToRecipe(rec)}
                  setrecipe={(a: QuantityToRecipe) => {
                    const recs = [...recipesFromForm.ingredients];
                    recs[index1] = mapToRecipeInput(a);
                    const newAllergies: AllergyForm = {
                      food: allergies.food,
                      recipe: recs.map((r) => ({
                        id: r.id,
                        healthLabelInfo: r.healthlabelInfo,
                      })),
                    };
                    setallergies(newAllergies);
                    setRecipesFromForm({
                      ...recipesFromForm,
                      ingredients: recs,
                    });
                  }}
                />
              );
            })}
          </>
        }
      />
      <GridRowTwoItems
        before={0.5}
        inbetween={0.5}
        firstlength={5.5}
        secondlength={5.5}
        firstchild={
          <Button
            sx={{ borderRadius: 28 }}
            color="inherit"
            variant="outlined"
            onClick={() => {
              const old = ingredientsFromForm.ingredients;
              old.push({
                quantity: small.quantity,
                name: "",
                id: "",
                nutrition: zeroNutritionInput,
              });
              const variables = {
                ...ingredientsFromForm,
                ingredients: old,
              };
              setIngredientsFromForm(variables);
            }}
          >
            <VscAdd />
            Ingredient
          </Button>
        }
        secondchild={
          <Button
            sx={{ borderRadius: 28 }}
            color="inherit"
            variant="outlined"
            onClick={() => {
              const old = recipesFromForm.ingredients;
              old.push(emptyQuantityToNameIdAndNutritionInput);
              const variables = {
                ...ingredientsFromForm,
                ingredients: old,
              };
              setRecipesFromForm(variables);
            }}
          >
            <VscAdd />
            Recipe
          </Button>
        }
      />
    </>
  );
};

export const EditableRecipesAndIngredients = ({
  title,
  productsFromForm,
  recipesFromForm,
  setproducts,
  setrecipes,
  recipes,
  baseOrVar,
}: {
  setproducts: (a: IngredientToQuantityToSalePriceInput[]) => void;
  setrecipes: (a: IngredientToQuantityToSalePriceInput[]) => void;
  baseOrVar: BaseOrVar;
  recipesFromForm: IngredientToQuantityToSalePriceInput[];
  productsFromForm: IngredientToQuantityToSalePriceInput[];
  title: string;
  recipes: QuantityToRecipe[];
}) => {
  return (
    <>
      <Table component={Paper} style={{ backgroundColor: backgroundField }}>
        <TableRow>
          <TableCell colSpan={3}>{title}</TableCell>
        </TableRow>
        {productsFromForm.map((baseProd, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <OneIngredientWide
                  setprice={(a) => {}}
                  deleteItem={() => {
                    const old = [...productsFromForm];
                    old.splice(num, 1);
                    setproducts(old);
                  }}
                  product={{
                    quantity: baseProd.quantity,
                    product: {
                      measures: [
                        {
                          __typename: "Measure",
                          label: baseProd.quantity.unit.label,
                          uri: baseProd.quantity.unit.uri,
                          weight: baseProd.quantity.unit.weight,
                        },
                      ],
                      food: {
                        label: baseProd.name,
                        foodId: baseProd.id,
                        category: "",
                        image: returnStringIfNotNullOrUndefined(baseProd.img),
                        nutrients: mapToEdamamNutrition(
                          baseProd.nutrition.nutrition
                        ),
                      },
                    },
                  }}
                  setproduct={(a: QuantityToProduct) => {
                    const old = [...productsFromForm];
                    old[num] = {
                      ...old[num],
                      measurementInfo: a.product.measurementInfo,
                      name: a.product.food.label,
                      id: a.product.food.foodId,
                      quantity: a.quantity,
                    };
                    setproducts(old);
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        ))}
        {recipesFromForm.map((baseRec, num) => (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <OneRecipe
                  recipes={recipes}
                  deleteItem={() => {
                    const old = [...recipesFromForm];
                    old.splice(num, 1);
                    setrecipes(old);
                  }}
                  recipe={mapIngredientToQuantityToSalePriceInput(baseRec)}
                  setrecipe={(a: QuantityToRecipe) => {
                    const old = [...recipesFromForm];
                    const mapped = mapToRecipeInput(a);
                    old[num] = {
                      ...old[num],
                      name: mapped.name,
                      id: mapped.id,
                      quantity: mapped.quantity,
                      nutrition: {
                        quantity: a.quantity,
                        nutrition: mapped.nutrition,
                      },
                      healthLabelInfo: mapped.healthlabelInfo,
                    };
                    setrecipes(old);
                  }}
                />
              </TableCell>
            </TableRow>
          </>
        ))}
        <TableRow>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="primary"
              variant="contained"
              onClick={() => {
                const old = [...productsFromForm];
                old.push(emptyIngredientToQuantityToSalePriceInput);
                setproducts(old);
              }}
            >
              + Ingredient
            </Button>
          </TableCell>
          <TableCell>
            <Button
              sx={{ borderRadius: 28 }}
              color="primary"
              variant="contained"
              onClick={() => {
                const old = [...recipesFromForm];
                old.push(emptyIngredientToQuantityToSalePriceInput);
                setrecipes(old);
              }}
            >
              + Home Made Recipe
            </Button>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};
