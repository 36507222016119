import { Button, Stack, useMediaQuery } from "@mui/material";
import { GridRowOneItem } from "../../../../../components/layouts/Grids";
import { AddDishVariables } from "../../../../chefsbase/dishes/addDish/types/AddDish";
import {
  ImageButton,
  ImageSrc,
  ImageBackdrop,
} from "../../../../../components/image/ImageButton";
import { useState } from "react";
import { buttonColor } from "../../../../../components/layouts/Colors";

export const ImgSection = ({
  pic,
  setPic,
  dishForm,
  setDishForm,
  imageUrls,
  handleChangeFile,
}: {
  handleChangeFile: (a: FileList) => void;
  imageUrls: string[];
  pic: boolean;
  setPic: (a: boolean) => void;
  dishForm: AddDishVariables;
  setDishForm: (a: AddDishVariables) => void;
}) => {
  return (
    <>
      <GridRowOneItem
        before={0.5}
        after={0.5}
        child={
          <>
            <>
              <label htmlFor="file-upload">
                <Button
                  sx={{ width: 150 }}
                  color="inherit"
                  variant={imageUrls && imageUrls[0] ? "contained" : "outlined"}
                  component="span"
                >
                  Add Pictures
                </Button>
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg"
                multiple
                onChange={(e) => {
                  e && e.target.files && handleChangeFile(e.target.files);
                }}
                style={{ display: "none" }}
              />
            </>
            <Button
              sx={{ width: 150 }}
              color="inherit"
              variant={!pic ? "contained" : "outlined"}
              onClick={() => {
                setPic(false);
                setDishForm({
                  ...dishForm,
                  input: {
                    ...dishForm.input,
                    urls: [],
                  },
                });
              }}
            >
              No Pictures
            </Button>
          </>
        }
      />

      {imageUrls && imageUrls.length > 0 && (
        <>
          <Stack direction="row" spacing={1}>
            {imageUrls
              .filter((im) => im !== "")
              .map((image: string, i: number) => (
                <ImageButton
                  sx={
                    dishForm.input.urls && dishForm.input.urls.includes(image)
                      ? { border: 2 }
                      : { border: 0 }
                  }
                  focusRipple
                  key={image}
                  style={{
                    width: 120,
                  }}
                  onClick={() => {}}
                >
                  <ImageSrc style={{ backgroundImage: `url(${image})` }} />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                </ImageButton>
              ))}
          </Stack>
        </>
      )}
    </>
  );
};

export const ImageSection = ({
  urls,
  setNoPic,
  imageUrls,
  handleChangeFile,
}: {
  handleChangeFile: (a: FileList) => void;
  imageUrls: string[];
  urls: string[];
  setNoPic: () => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <GridRowOneItem
        before={0}
        after={0.5}
        child={
          <>
            <>
              <label htmlFor="file-upload">
                <Button
                  sx={{ width: 150, backgroundColor: buttonColor }}
                  color="inherit"
                  variant={
                    imageUrls && imageUrls.filter((i) => i !== "").length > 0
                      ? "contained"
                      : "outlined"
                  }
                  component="span"
                >
                  Add Pictures
                </Button>
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg"
                multiple
                onChange={(e) => {
                  e && e.target.files && handleChangeFile(e.target.files);
                }}
                style={{ display: "none" }}
              />
            </>
            <Button
              sx={{ width: 150, backgroundColor: buttonColor }}
              color="inherit"
              variant={
                imageUrls && imageUrls.filter((i) => i !== "").length > 0
                  ? "outlined"
                  : "contained"
              }
              onClick={() => {
                setNoPic();
              }}
            >
              No Pictures
            </Button>
          </>
        }
      />

      {imageUrls && imageUrls.length > 0 && (
        <>
          <Stack direction="row" spacing={1}>
            {imageUrls
              .filter((im) => im !== "")
              .map((image: string, i: number) => (
                <ImageButton
                  sx={
                    urls && urls.includes(image) ? { border: 2 } : { border: 0 }
                  }
                  focusRipple
                  key={image}
                  style={{
                    width: 120,
                  }}
                  onClick={() => {}}
                >
                  <ImageSrc
                    style={{
                      backgroundImage: `url(${image})`,
                      height: 100,
                      width: 100,
                    }}
                  />
                  <ImageBackdrop className="MuiImageBackdrop-root" />
                </ImageButton>
              ))}
          </Stack>
        </>
      )}
    </>
  );
};
