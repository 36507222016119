import { gql, useLazyQuery } from "@apollo/client";
import { NameToIdInput, QuantityInput } from "../../../globalTypes";
import { useSimpleQuery } from "../../../utils/apollo";
import { Dish } from "./types/Dish";
import { DishItems } from "./types/DishItems";
import { dishes } from "./types/dishes";
import { small } from "../../../components/defaults/portions/PortionInput";
import {
  pricesForFoods,
  pricesForFoodsVariables,
} from "./types/pricesForFoods";

export const foodPricesForids = gql`
  query pricesForFoods($ids: [NameToIdInput!]!) {
    pricesForFoods(ids: $ids) {
      nameToId {
        name
        id
      }
      amountPerQuantitySupplier {
        supplier {
          name
          id
        }
        amountPerQuantity {
          amount
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
      }
    }
  }
`;
export const dishesQuery = gql`
  query dishes {
    dishes(limit: 3000, offset: 0) {
      name
      id
      img
      type
      method
      portions {
        price
        name
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
      }
      options {
        name
        option
      }
      hoursWorked {
        amount
        quantity {
          quantity
          unit {
            label
            uri
            weight
          }
        }
      }
      healthLabelInfo {
        vegan
        lupine
        meat
        nut
        peanut
        shellfish
        selery
        sesame
        soy
        fish
        crustacean
        sulphide
        mustard
        dairy
        gluten
        egg
        milk
        pork
        mollusk
      }
    }
  }
`;

const recipesAndIngredientsForDishQuery = gql`
  query Dish($id: String!, $quantity: QuantityInput!) {
    dish(id: $id) {
      img
      type
      recipes(quantity: $quantity) {
        dishPortion {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        nutrition {
          kcal
          carbscarbs
          carbssugar
          fatstotal
          prottotal
          starch
          fibres
          cholesterol
          natrium
          saturedfat
        }
        recipes {
          healthLabelInfo {
            vegan
            lupine
            meat
            nut
            peanut
            shellfish
            selery
            sesame
            soy
            fish
            crustacean
            sulphide
            mustard
            dairy
            gluten
            egg
            milk
            pork
            mollusk
          }
          name
          id
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          nutrition {
            quantity {
              quantity
              unit {
                label
                uri
                weight
              }
            }
            nutrition {
              kcal
              saturedfat
              carbscarbs
              carbssugar
              fatstotal
              prottotal
              starch
              fibres
              cholesterol
              natrium
            }
          }
        }
      }
      products(quantity: $quantity) {
        dishPortionOutput {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        foods {
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          food {
            healthLabelInfo {
              vegan
              lupine
              meat
              nut
              peanut
              shellfish
              selery
              sesame
              soy
              fish
              crustacean
              sulphide
              mustard
              dairy
              gluten
              egg
              milk
              pork
              mollusk
            }
            price {
              supplier {
                name
                id
              }
              amountPerQuantity {
                amount
                quantity {
                  quantity
                  unit {
                    weight
                    label
                    uri
                  }
                }
              }
            }
            measurementInfo {
              serving
              cup
              tablespoon
              whole
              teaspoon
            }
            id
            name
            img
            nutrition {
              quantity {
                quantity
                unit {
                  label
                  uri
                  weight
                }
              }
              nutrition {
                kcal
                carbscarbs
                carbssugar
                fatstotal
                prottotal
                starch
                fibres
                cholesterol
                natrium
                saturedfat
              }
            }
          }
        }
      }
    }
  }
`;

const itemsForDishQuery = gql`
  query DishItems($id: String!, $quantity: QuantityInput!) {
    dish(id: $id) {
      type
      recipes(quantity: $quantity) {
        dishPortion {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        recipes {
          name
          id
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
      }
      products(quantity: $quantity) {
        dishPortionOutput {
          name
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
        }
        foods {
          quantity {
            quantity
            unit {
              label
              uri
              weight
            }
          }
          food {
            id
            name
            img
          }
        }
      }
    }
  }
`;

export const useLazySearchDishesQuery = ({
  onCompleted,
  page,
}: {
  onCompleted: (dishes: dishes) => void;
  page?: number;
}) => {
  const offset = page ? page * 10 : 0;

  const [searchdishes, { loading, data }] = useLazyQuery(dishesQuery, {
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, searchdishes };
};

export const useSearchDishesQuery = ({
  onCompleted,
  page,
}: {
  onCompleted: (dishes: dishes) => void;
  page?: number;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(dishesQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading, data, error, refetch };
};

export const useLazyfoodPricesQuery = ({
  onCompleted,
  ids,
}: {
  onCompleted: (result: pricesForFoods) => void;
  ids: NameToIdInput[];
}) => {
  const [getFoods, { data, loading }] = useLazyQuery(foodPricesForids, {
    variables: {
      ids: ids,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { getFoods, loading };
};
export const useLazyItemsForDishQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (dishes: DishItems) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const [getItems, { data, loading }] = useLazyQuery(itemsForDishQuery, {
    variables: {
      id: id,
      quantity: quantity,
    },
    onCompleted: (result) => onCompleted(result),
  });
  return { getItems, loading };
};

export const useItemsForDishQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (dishes: DishItems) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const { data, loading } = useSimpleQuery(itemsForDishQuery, {
    variables: {
      id: id,
      quantity: quantity,
    },
    onCompleted: (result) => {
      onCompleted(result);
    },
  });
  return { loading };
};

export const useRecipesAndIngredientsForDishQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (dishes: Dish) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const { loading, data, error, refetch } = useSimpleQuery(
    recipesAndIngredientsForDishQuery,
    {
      variables: {
        id: id,
        quantity: quantity,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    }
  );
  return { loading, data, error, refetch };
};

export const useLazyRecipesAndIngredientsForDishQuery = ({
  onCompleted,
  id,
  quantity,
}: {
  onCompleted: (dishes: Dish) => void;
  id: String;
  quantity: QuantityInput;
}) => {
  const [getRecipesAndIngredientsForDish, { data, loading, refetch }] =
    useLazyQuery(recipesAndIngredientsForDishQuery, {
      variables: {
        id: id,
        quantity: quantity,
      },
      onCompleted: (result) => {
        onCompleted(result);
      },
    });
  return { getRecipesAndIngredientsForDish, data, loading, refetch };
};
