import {
  Dialog,
  Container,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { VscRefresh } from "react-icons/vsc";
import { SubmitButton } from "../../../../components/Buttons";
import { FormAutoCompleteDishSelectable } from "../../../../components/forms/singleForms/FormSelect";
import { DisplayImage } from "../../../../components/image/DisplayImage";
import {
  aestheticBackground,
  backgroundContainer,
  buttonColor,
} from "../../../../components/layouts/Colors";
import {
  GridRowTwoItems,
  GridRowOneItem,
} from "../../../../components/layouts/Grids";
import { dishes_dishes } from "../../../chefsbase/dishes/types/dishes";
import {
  mapDishToSelectable,
  mapMySelectableToSelectable,
} from "../../components/mappers/selectable/MapToSelectables";
import { MySelectablesInput } from "../types/MySelectablesInput";
import { PortionsForm } from "./PortionsForm";
import { useState } from "react";
import {
  addNewMySelectable,
  emptyMySelectable,
} from "../../../../components/defaults/selectables/SelectableInput";
import { SelectablesInput } from "../../../../globalTypes";
import { Loader } from "../../../../components/Loading/Loader";
import { measureInputOptions } from "../../../../components/defaults/portions/PortionInput";

export const filterUniqueDishes = (dishes: dishes_dishes[]) => {
  const old: dishes_dishes[] = [];
  dishes.forEach((d) => {
    if (!old.includes(d)) {
      old.push(d);
    }
  });
  return old;
};
export const AddDishDialog = ({
  open,
  setopen,
  dishes,
  onSubmit,
  loadingDishes,
  refetch,
}: {
  refetch: () => void;
  loadingDishes: boolean;
  onSubmit: (addedDish: SelectablesInput) => void;
  dishes: dishes_dishes[];
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  const [dish, setDish] = useState<MySelectablesInput>(emptyMySelectable);

  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: aestheticBackground }}>
        <DialogTitle align="center">
          <Typography
            variant="h5"
            style={{ fontFamily: "FairField Display, serif" }}
          >
            Choose your dish and portions
          </Typography>
        </DialogTitle>
        <DialogTitle align="center">
          <SubmitButton
            color="primary"
            disabled={dish.dishname === "Test Dish"}
            sx={{ mt: 2, borderRadius: 28 }}
            fullWidth
            variant="contained"
            onClick={() => {
              onSubmit(mapMySelectableToSelectable(dish));
              setDish(emptyMySelectable);
            }}
          >
            Submit
          </SubmitButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <>
            <GridRowTwoItems
              before={0.5}
              firstlength={5}
              inbetween={0.5}
              secondlength={4}
              firstchild={
                <>
                  {dishes.length > 0 ? (
                    <FormAutoCompleteDishSelectable
                      label="Select your Dish"
                      value={dish}
                      options={[addNewMySelectable].concat(
                        filterUniqueDishes(dishes).map((d) =>
                          mapDishToSelectable(d)
                        )
                      )}
                      setValue={(a) => {
                        if (a === addNewMySelectable) setopen(false);
                        a !== addNewMySelectable && setDish(a);
                      }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      sx={{ borderRadius: 28 }}
                      label="Select your Dish"
                      value={"Hold on while we fetch your dishes..."}
                      onChange={(e) => {}}
                    />
                  )}
                </>
              }
              secondchild={
                <>
                  {dish.img?.[0] && dish.img[0] != "" && (
                    <DisplayImage img={dish.img[0]} height={80} width={50} />
                  )}
                </>
              }
            />
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <SubmitButton
                  variant="contained"
                  onClick={() => refetch()}
                  sx={{ backgroundColor: buttonColor, borderRadius: 28 }}
                >
                  <VscRefresh /> Refresh dishes
                </SubmitButton>
              }
            />
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <Box sx={{ mt: 2 }}>
                  <PortionsForm
                    options={
                      dish.quantitiesSelected.length > 0
                        ? measureInputOptions(
                            dish.quantitiesSelected[0].portion.quantity.unit
                              .label
                          ).map((a) => ({ name: a.label, id: a.uri }))
                        : measureInputOptions().map((a) => ({
                            name: a.label,
                            id: a.uri,
                          }))
                    }
                    dish={dish}
                    setDish={(a) => {
                      setDish(a);
                    }}
                  />
                </Box>
              }
            />
          </>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            color="primary"
            variant="contained"
            onClick={() => setopen(false)}
          >
            Close
          </SubmitButton>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export const ChangeDishDialog = ({
  open,
  dish,
  setDish,
  setopen,
  dishes,
  onSubmit,
  loadingDishes,
  options,
}: {
  options: { name: string; id: string }[];
  loadingDishes: boolean;
  dish: MySelectablesInput;
  setDish: (a: MySelectablesInput) => void;
  onSubmit: (addedDish: SelectablesInput) => void;
  dishes: dishes_dishes[];
  open: boolean;
  setopen: (a: boolean) => void;
}) => {
  return (
    <Dialog open={open} onClose={() => setopen(false)} fullWidth maxWidth="lg">
      <Container style={{ backgroundColor: backgroundContainer }}>
        <DialogTitle align="center">
          {dish.dishname === "Test Dish"
            ? "Select or Create Dish"
            : dish.dishname}
        </DialogTitle>
        <DialogTitle align="center">
          <SubmitButton
            color="primary"
            disabled={dish.dishname === "Test Dish"}
            sx={{ mt: 2, borderRadius: 28 }}
            fullWidth
            variant="contained"
            onClick={() => {
              onSubmit(mapMySelectableToSelectable(dish));
            }}
          >
            Submit
          </SubmitButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "300px" }}>
          <>
            <GridRowTwoItems
              before={0.5}
              firstlength={5}
              inbetween={0.5}
              secondlength={4}
              firstchild={
                <>
                  {dishes.length > 0 ? (
                    <FormAutoCompleteDishSelectable
                      label="Select your Dish"
                      value={dish}
                      options={[addNewMySelectable].concat(
                        filterUniqueDishes(dishes).map((d) =>
                          mapDishToSelectable(d)
                        )
                      )}
                      setValue={(a) => {
                        if (a === addNewMySelectable) setopen(false);
                        a !== addNewMySelectable && setDish(a);
                      }}
                    />
                  ) : (
                    <>
                      Loading your Dishes
                      <Loader loading={loadingDishes} />
                    </>
                  )}
                </>
              }
              secondchild={
                <>
                  {dish.img?.[0] && dish.img[0] != "" && (
                    <DisplayImage img={dish.img[0]} height={80} width={50} />
                  )}
                </>
              }
            />
            <GridRowOneItem
              before={0.5}
              after={0.5}
              child={
                <PortionsForm
                  options={options}
                  dish={dish}
                  setDish={(a) => {
                    setDish(a);
                  }}
                />
              }
            />
          </>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            color="primary"
            variant="contained"
            onClick={() => setopen(false)}
          >
            Close
          </SubmitButton>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
