import { gql, useMutation } from "@apollo/client";
import { AddRecipe, AddRecipeVariables } from "./types/AddRecipe";
import { UpdateRecipe, UpdateRecipeVariables } from "./types/UpdateRecipe";

const AddRecipeMutation = gql`
  mutation AddRecipe(
    $input: RecipeInput!
    $recipes: DishIngredientsInput!
    $products: DishIngredientsInput!
    $healthLabels: HealthLabelInfo!
  ) {
    addRecipe(
      input: $input
      recipes: $recipes
      products: $products
      healthLabels: $healthLabels
    )
  }
`;

export const useAddRecipeMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [addRecipe, { loading, error }] = useMutation<
    AddRecipe,
    AddRecipeVariables
  >(AddRecipeMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    addRecipe,
    loading,
    error,
  };
};

const UpdateRecipeMutation = gql`
  mutation UpdateRecipe(
    $id: String!
    $input: RecipeInput!
    $recipes: DishIngredientsInput!
    $products: DishIngredientsInput!
    $healthLabels: HealthLabelInfo!
  ) {
    updateRecipe(
      id: $id
      input: $input
      recipes: $recipes
      products: $products
      healthLabels: $healthLabels
    )
  }
`;

export const useUpdateRecipeMutation = ({
  onCompleted,
}: {
  onCompleted: () => void;
}) => {
  const [updateRecipe, { loading, error }] = useMutation<
    UpdateRecipe,
    UpdateRecipeVariables
  >(UpdateRecipeMutation, {
    onCompleted: () => onCompleted(),
  });
  return {
    updateRecipe,
    loading,
    error,
  };
};
