import { TableRow, TableCell, Button } from "@mui/material";
import React from "react";
import {
  NutritionInput,
  QuantityToNameIdAndNutritionInput,
} from "../../../../globalTypes";
import { Product_product } from "../../products/types/Product";
import { products_products_nutrition_nutrition } from "../../products/types/products";
import { ingredients_ingredients } from "../../recipesAndIngredients/types/ingredients";
import { recipes_recipes, recipes_recipes_nutrition, recipes_recipes_nutrition_nutrition } from "../../recipesAndIngredients/types/recipes";
import {
  gramMeasure,
  gramMeasureInput,
} from "../../../../components/defaults/portions/PortionInput";

export const RowRecipe = ({
  data,
  setRecipe,
}: {
  data: recipes_recipes;
  setRecipe: (a: any) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>{`${data.name} (R)`}</TableCell>
        <TableCell align="left">{data.quantity?.quantity}</TableCell>
        <TableCell>{data.quantity?.unit.label}</TableCell>
        <TableCell>{`${data.nutrition.nutrition.kcal.toFixed(2)}`}</TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setRecipe({
                name: data.name,
                id: data.id,
                quantity: {
                  quantity: data.quantity?.quantity
                    ? data.quantity?.quantity
                    : 0.0,
                  unit: data.quantity?.unit ? data.quantity?.unit : "gram",
                },
              });
            }}
            color="primary"
            variant="outlined"
          >
            +
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export const RowIngredient = ({
  data,
  setIngredient,
}: {
  data: ingredients_ingredients;
  setIngredient: (a: QuantityToNameIdAndNutritionInput) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>{`${data.name} (I)`}</TableCell>
        <TableCell align="left">{data.nutrition?.quantity.quantity}</TableCell>
        <TableCell>{data.nutrition?.quantity.unit.label}</TableCell>
        <TableCell>
          {data && data.nutrition && data.nutrition.nutrition.kcal?.toFixed(2)}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setIngredient({
                name: data.name,
                id: data.id,
                quantity: {
                  quantity: data.nutrition?.quantity.quantity
                    ? data.nutrition.quantity.quantity
                    : 0.0,
                  unit: data.nutrition?.quantity.unit
                    ? data.nutrition?.quantity.unit
                    : gramMeasureInput(0),
                },
                nutrition: NutritionToInput(data.nutrition.nutrition),
              });
            }}
            color="primary"
            variant="outlined"
          >
            +
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export const RowProduct = ({
  data,
  setProduct,
}: {
  data: Product_product;
  setProduct: (a: QuantityToNameIdAndNutritionInput) => void;
}) => {
  return (
    <>
      <TableRow>
        <TableCell>{`${data.name} (P)`}</TableCell>
        <TableCell align="left">{data.nutrition?.quantity.quantity}</TableCell>
        <TableCell>{data.nutrition?.quantity.unit.label}</TableCell>
        <TableCell>
          {data && data.nutrition && data.nutrition.nutrition.kcal?.toFixed(2)}
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setProduct({
                name: data.name,
                id: data.id,
                quantity: {
                  quantity: data.nutrition?.quantity.quantity
                    ? data.nutrition.quantity.quantity
                    : 0.0,
                  unit: data.nutrition?.quantity.unit
                    ? data.nutrition?.quantity.unit
                    : gramMeasureInput(0),
                },
                nutrition: NutritionToInput(data.nutrition.nutrition),
              });
            }}
            color="primary"
            variant="outlined"
          >
            +
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export const NutritionToInput = (
  nutrition: recipes_recipes_nutrition_nutrition
): NutritionInput => ({
  kcal: nutrition.kcal,
  carbscarbs: nutrition.carbscarbs,
  carbssugar: nutrition.carbssugar,
  fatstotal: nutrition.fatstotal,
  prottotal: nutrition.prottotal,
  starch: nutrition.starch,
  fibres: nutrition.fibres,
  cholesterol: nutrition.cholesterol,
  natrium: nutrition.natrium,
  saturedfat: nutrition.saturedfat,
});

export const NutritionToInputProduct = (
  nutrition: products_products_nutrition_nutrition
): NutritionInput => ({
  kcal: nutrition.kcal,
  carbscarbs: nutrition.carbscarbs,
  carbssugar: nutrition.carbssugar,
  fatstotal: nutrition.fatstotal,
  prottotal: nutrition.prottotal,
  starch: nutrition.starch,
  fibres: nutrition.fibres,
  cholesterol: nutrition.cholesterol,
  natrium: nutrition.natrium,
  saturedfat: nutrition.saturedfat,
});
